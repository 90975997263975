import LogoPayment from '../../assets/images/payment_type/index'
import LogoPaymentCategory from '../../assets/images/payment_category/index'
const avatarMaster = [
  { key: 'credit', avatar: [LogoPayment.cc_complete], label: 'Kartu Kredit' },
  { key: 'credit-card', avatar: [LogoPayment.visa, LogoPayment.master_card, LogoPayment.jcb], label: '' },
  { key: 'akulaku', avatar: [LogoPayment.akulaku], label: 'Akulaku' },
  { key: 'dana', avatar: [LogoPayment.dana], label: 'Dana' },
  { key: 'gopay', avatar: [LogoPayment.gopay], label: 'Gopay' },
  { key: 'linkaja', avatar: [LogoPayment.link_aja], label: 'LinkAja' },
  { key: 'ovo', avatar: [LogoPayment.ovo], label: 'OVO' },
  { key: 'alfamart', avatar: [LogoPayment.alfamart], label: 'Alfamart' },
  { key: 'indomaret', avatar: [LogoPayment.indomaret], label: 'Indomaret' },
  { key: 'va-mandiri', avatar: [LogoPayment.mandiri], label: 'Mandiri Virtual Account' },
  { key: 'va-permata', avatar: [LogoPayment.permata], label: 'Permata Virtual Account' },
  { key: 'va-bca', avatar: [LogoPayment.bca], label: 'BCA Virtual Account' },
  { key: 'va-bni', avatar: [LogoPayment.bni], label: 'BNI Virtual Account' },
  { key: 'va-bri', avatar: [LogoPayment.bri], label: 'BRI Virtual Account' },
  { key: 'shopeepay', avatar: [LogoPayment.shopeepay], label: 'ShopeePay' }
]

const categoryLabelMaster = [
  { category_name: 'Credit Card', label: 'Kartu Kredit', icon: LogoPaymentCategory.credit_card },
  { category_name: 'Credit Cardless', label: 'Cicilan Tanpa Kartu', icon: LogoPaymentCategory.credit_cardless },
  { category_name: 'eWallet', label: 'Dompet Digital', icon: LogoPaymentCategory.ewallet },
  { category_name: 'Retail Outlet', label: 'Gerai Retail', icon: LogoPaymentCategory.retail_outlet },
  { category_name: 'Virtual Account', label: 'Virtual Account', icon: LogoPaymentCategory.virtual_account }
]

export function findDataLabel (payment_category_name) {
  return categoryLabelMaster.find(item => item.category_name === payment_category_name)
}

export function findDataAvatar (payment_type_name, isMobile, providerName) {
  if (payment_type_name.toLowerCase() === 'kartu kredit' || payment_type_name.toLowerCase() === 'credit-card' || payment_type_name.toLowerCase() === 'credit card') {
    let creditCardData = providerName.toLowerCase() === 'midtrans' ? { key: 'credit-card', avatar: [LogoPayment.visa, LogoPayment.master_card], label: '' } : avatarMaster.find(item => item.label.toLowerCase() === payment_type_name || item.key === payment_type_name)
    if (isMobile) {
      return {
        ...creditCardData,
        avatar: [providerName.toLowerCase() === 'midtrans' ? LogoPayment.cc_complete_midtrans : LogoPayment.cc_complete]
      }
    } else {
      return creditCardData
    }
  } else {
    return avatarMaster.find(item => item.key === payment_type_name || item.label.toLowerCase() === payment_type_name)
  }
}

export function parsePaymentMethodList (payment_methods) {
  let paymentCategory = {
    category_id: payment_methods[0].category_id,
    category_name: payment_methods[0].category_name,
    list: [
      payment_methods[0]
    ],
    visible: true
  }

  let listPayment = [paymentCategory]
  for (let i = 0; i < listPayment.length; i++) {
    for (let j = 1; j < payment_methods.length; j++) {
      if (listPayment[i].category_id === payment_methods[j].category_id) {
        payment_methods[j].visible = true
        listPayment[i].list.push(payment_methods[j])
      } else {
        listPayment.push({
          category_id: payment_methods[j].category_id,
          category_name: payment_methods[j].category_name,
          list: [
            payment_methods[j]
          ],
          visible: true
        })
        i++
      }
    }
  }

  return listPayment
}

export function inputLabelCategory (listPayment) {
  listPayment.forEach(category => {
    let categoryLabel = findDataLabel(category.category_name)
    category.label = categoryLabel.label
    category.icon = categoryLabel.icon
  })
  return listPayment
}

export function inputAvatarLabel (listPayment, isMobile) {
  listPayment.forEach(category => {
    category.list.forEach(type => {
      let mapingDataAvatar = findDataAvatar(type.slug_name, isMobile, type.provider_name)
      type.avatar = mapingDataAvatar.avatar
      type.label = mapingDataAvatar.label
      type.is_choice = false
    })
  })
  return listPayment
}

export function changeStatusPaymentType (listPayment, payload) {
  listPayment.forEach(paymentCategory => {
    paymentCategory.list.forEach(paymentType => {
      if (paymentType.slug_name === payload.slug_name && paymentCategory.category_id === payload.category_id && paymentType.provider_name === payload.provider_name) {
        paymentType.is_choice = true
      } else {
        paymentType.is_choice = false
      }
    })
  })
  return listPayment
}

export function changeVisiblePaymentCategory (listPayment, payload) {
  listPayment.forEach(paymentCategory => {
    if (paymentCategory.category_id === payload) {
      paymentCategory.visible = !paymentCategory.visible
    }
  })
  return listPayment
}
