import ProgramActivityAPI from '@/api/program-activity'

export default {
  data () {
    return {
      activityData: []
    }
  },
  computed: {
    setOfTopicResources: {
      get () {
        return this.$store.state.activityV2.setOfTopicResources
      },
      set (value) {
        this.$store.commit('activityV2/SAVE_SET_OF_TOPIC_RESOURCES', value)
      }
    },
    activitySearchParams () {
      return this.$store.state.activityV2.activitySearchParams
    }
  },
  methods: {
    async getChapterDetail (chapter_id, isVisible) {
      if (isVisible && !this.activityData.length) {
        try {
          const { program_id, user_id, program_user_id } = this.activityValidate
          const result = await ProgramActivityAPI.getChapterDetail({
            Authorization: this.authorization,
            data: {
              user_id,
              program_user_id,
              program_id,
              chapter_id
            }
          })
          this.activityData = result.data.data.resource_detail
          this.filterActivityData()
        } catch (error) {
          console.error(error)
        }
      }
    },
    generateFirstTopicNumbering (activityIndex) {
      return activityIndex + 1
    },
    generateSecondTopicNumbering (activityIndex) {
      let result = this.setOfTopicResources[0] + 1
      return activityIndex === 0 ? result : (result + activityIndex)
    },
    generateTheRestTopicNumbering (topicIndex, activityIndex) {
      let number = 0
      let result = this.setOfTopicResources
        .slice(0, topicIndex)
        .reduce((prevValue, currentValue) => {
          return prevValue + currentValue
        }, number) + 1
      return activityIndex === 0 ? result : (result + activityIndex)
    },
    setNumbering (topicIndex, activityIndex) {
      let number
      if (topicIndex === 0) {
        number = this.generateFirstTopicNumbering(activityIndex)
      } else if (topicIndex === 1) {
        number = this.generateSecondTopicNumbering(activityIndex)
      } else {
        number = this.generateTheRestTopicNumbering(topicIndex, activityIndex)
      }
      return number
    },
    filterActivityData () {
      if (this.activityData.length > 0) {
        this.activityData = this.activityData.map(activity => {
          let includes = true
          if (this.activitySearchParams.name.length > 0 && this.activitySearchParams.activityCategories.length > 0) {
            includes = activity.title.toLowerCase().includes(this.activitySearchParams.name.toLowerCase()) && this.activitySearchParams.activityCategories.includes(activity.activity_category)
          } else if (this.activitySearchParams.name.length > 0) {
            includes = activity.title.toLowerCase().includes(this.activitySearchParams.name.toLowerCase())
          } else if (this.activitySearchParams.activityCategories.length > 0) {
            includes = this.activitySearchParams.activityCategories.includes(activity.activity_category)
          }
          return {
            ...activity,
            isShow: includes
          }
        })
      }
    },
    resetFilterParams () {
      const searchParams = {
        name: '',
        activityCategories: []
      }
      this.$store.commit('activityV2/SET_ACTIVITY_SEARCH_PARAMS', searchParams)
    }
  },
  watch: {
    activitySearchParams: {
      handler () {
        this.filterActivityData()
      },
      deep: true
    }
  },
  mounted () {
    this.resetFilterParams()
  }
}
