<template>
  <div id="modal-register-oauth-2">
    <div
      class="font-title-4 modal-title"
    >
      Pendaftaran Akun
    </div>
    <div class="error-password general-message">{{ generalMessage }}</div>
    <form>
      <b-input
        type="text"
        class="font-body-3 register-input register-input-email"
        v-model="userInfo.email"
        disabled
      ></b-input>

      <select v-model="roleUser" class="font-body-3" :class="{'input-field-select': !roleUser, 'input-field-select-selected': roleUser}">
        <option disabled value="">Profil Pengguna</option>
        <template v-for="(item, index) in roleOptions">
          <option :value="item" :key="index">{{ item.display_profession_name || item.name }}</option>
        </template>
      </select>

      <select v-if="roleUser.name == 'Murid'" v-model="selectedYearLevel" class="font-body-3" :class="{'input-field-select': !selectedYearLevel, 'input-field-select-selected': selectedYearLevel}">
        <option disabled value="">Pilih Jenjang/Usia</option>
        <template v-for="(item, index) in yearLevelOptions">
          <option :value="item" :key="index">{{ item.name }}</option>
        </template>
      </select>

      <b-input
        :placeholder="namePlaceholder"
        type="text"
        name="full-name"
        class="font-body-3 register-input"
        v-model="fullName"
        :formatter="fullNameFormatter"
        :maxlength="30"
      ></b-input>

      <div
        class="pass-info"
      >
        Nama akan tercantum di sertifikat
      </div>

      <b-input
        type="text"
        class="font-body-3 register-input"
        :class="{'input-error': errorPhoneMessage}"
        :placeholder="`Nomor ponsel${isPaud?' orangtua':''}`"
        v-model="phoneNumber"
        :formatter="phoneNumberFormatter"
      ></b-input>

      <div
        class="pass-info error-password"
        v-if="errorPhoneMessage"
      >{{errorPhoneMessage}}</div>

      <div id="input-checkbox" class="font-body-4">
        Dengan daftar di Sekolah.mu, saya menyetujui
        <router-link
          id="form-register-condition"
          to="/syarat-ketentuan"
          target="_blank"
          class="terms-condition-link"
        >
          Syarat &amp; Ketentuan
        </router-link>
        serta
        <router-link
          id="form-register-terms"
          to="/kebijakan-privasi"
          target="_blank"
          class="terms-condition-link"
        >
          Kebijakan Privasi
        </router-link>
        yang berlaku
      </div>

      <button
        :class="isLoading || !isAllFormFilled ? 'button-register-disabled' : 'button-register'"
        :disabled="isLoading || !isAllFormFilled"
        @click.prevent="saveProfile"
      >
        <b-spinner v-if="isLoading" small></b-spinner> Daftar Sekarang
      </button>
    </form>
  </div>
</template>
<script>
import axiosServer from '../../configs/axiosServer'
import Toast from '../../configs/Toast'

export default {
  props: {
    isFromProgramDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fullName: '',
      roleUser: '',
      selectedYearLevel: '',
      roleOptions: [],
      yearLevelOptions: [],
      phoneNumber: '',
      generalMessage: '',
      errorPhoneMessage: '',
      isLoading: false
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    },
    isAllFormFilled () {
      if (this.roleUser?.name === 'Murid') {
        if (!this.selectedYearLevel) {
          return false
        }
      } else {
        if (!this.roleUser) {
          return false
        }
      }
      if (this.userInfo?.email &&
        this.fullName.length > 2 &&
        this.phoneNumber &&
        this.phoneNumber.length >= 9 &&
        this.phoneNumber.length <= 15
      ) {
        return true
      } else return false
    },
    isKid () {
      if (!this.selectedYearLevel) {
        return false
      } else {
        if (this.selectedYearLevel.name.split(' ')[3] < 10) {
          return true
        } else {
          return false
        }
      }
    },
    isPaud () {
      if (this.selectedYearLevel) {
        return this.selectedYearLevel.tag.includes('PAUD') && !this.selectedYearLevel.tag.includes('SD')
      } else {
        return false
      }
    },
    envBelajar () {
      return this.$store.state.envBelajar
    },
    namePlaceholder () {
      return this.placeholderFormatter('Nama lengkap')
    }

  },
  methods: {
    fullNameFormatter (value) {
      return value.replace(/[^\w'. -]/g, '').replace(/[0-9]/g, '')
    },
    phoneNumberFormatter (value) {
      return value.replace(/[^\d+]/g, '')
    },
    phoneNumberValidation () {
      if (this.phoneNumber.length >= 9 && this.phoneNumber.length <= 15 && (this.phoneNumber.match(/[^\d+]/g) || []).length === 0) {
        if (this.phoneNumber.includes('+')) {
          if (this.phoneNumber.split('+').length === 2 && this.phoneNumber.split('+')[0] === '') {
            return true
          } else {
            this.errorPhoneMessage = 'Nomor Ponsel Tidak Valid'
            return false
          }
        } else {
          return true
        }
      } else {
        this.errorPhoneMessage = 'Nomor Ponsel Tidak Valid'
        return false
      }
    },
    async fetchYearLevel () {
      try {
        this.isLoading = true
        const { data } = await axiosServer({
          url: '/user/profession/',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          },
          method: 'get'
        })
        if (data.status === 200) {
          this.roleOptions = data.data
          if (this.envBelajar) {
            this.roleUser = data.data.find(item => item.name === 'Murid') || ''
          } else {
            this.roleUser = data.data.find(item => item.name === 'Profesional') || ''
          }
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.error(error, 'error')
      }
    },
    async saveProfile () {
      try {
        if (this.phoneNumberValidation()) {
          this.isLoading = true
          let payload = {
            id: this.userInfo.id,
            email: this.userInfo.email,
            name: this.fullName,
            year_level_id: this.selectedYearLevel.id,
            phone: this.phoneNumber
          }
          await this.$store.dispatch('user/saveEditedProfile', payload)
          this.isLoading = false
          Toast.fire({
            icon: 'success',
            title: 'Berhasil Registrasi'
          })
          this.resetForm()
          if (this.isFromProgramDetail && this.$route.name === 'ProgramDetail') {
            this.$store.commit('user/CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL', true)
          } else {
            this.$store.commit('user/CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL', false)
          }
          this.$emit('closeAllModal')
        }
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
    },
    resetForm () {
      this.fullName = ''
      this.selectedYearLevel = ''
      this.phoneNumber = ''
    },
    placeholderFormatter (prefix) {
      let rolePlaceholder
      if (this.roleUser.name === 'Murid' || this.roleUser.name === 'Orangtua' || this.roleUser.name === 'Mahasiswa') {
        rolePlaceholder = `${prefix} ${this.roleUser.name.toLowerCase()}`
      } else {
        rolePlaceholder = `${prefix} pengguna`
      }
      return rolePlaceholder
    }
  },
  watch: {
    roleUser () {
      this.selectedYearLevel = ''
      if (this.roleUser.name !== 'Murid') {
        this.selectedYearLevel = this.roleUser.year_level[0]
      } else {
        this.selectedYearLevel = ''
      }
      this.yearLevelOptions = this.roleUser.year_level
    },
    $route (to, from) {
      this.$emit('closeAllModal')
    },
    phoneNumber () {
      if (this.errorPhoneMessage) {
        this.errorPhoneMessage = ''
      }
      this.yearLevelOptions = this.roleUser.year_level
    }
  },
  created () {
    this.fetchYearLevel()
  }
}
</script>
<style scoped>
.register-input{
  margin-top: 5px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 19px 15px;
  color: rgba(0, 0, 0, 0.7);
}
.register-input-email{
  background-image: url('../../assets/icons/shield-check.png');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
}
::placeholder {
  color: #9e9e9e;
}
.input-caution{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  color: #BDBDBD;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
#modal-register-oauth-2 {
  padding: 0;
  padding-bottom: 15px;
}

.modal-title {
  text-align: center;
  color: #172d5c;
  margin-top: 12px;
  margin-bottom: 20px;
}
.register-input{
  margin-top: 10px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 19px 15px;
  color: var(--color-ui-text-primary);
}

.register-input::placeholder {
  color: var(--color-ui-text-placeholder);
}

.input-field-select {
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;

  margin-top: 10px;
  width: 100%;
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 9px 15px;
  color: var(--color-ui-text-placeholder);

  background-image: url('https://cdn.sekolah.mu/assets/home/chevron_3.svg');
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 50%;
}

.input-field-select-selected {
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;

  margin-top: 10px;
  width: 100%;
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 9px 15px;
  color: var(--color-ui-text-primary);

  background-image: url('https://cdn.sekolah.mu/assets/home/chevron_3.svg');
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 50%;
}

.general-message {
  text-align: center;
}

input.input-error {
  border: 1px solid var(--color-ui-text-error);
}

.error-password {
  color: var(--color-ui-text-error) !important;
}

.pass-info {
  color: var(--color-ui-text-placeholder);;
  font-size: 11px;
  text-align: left;
  margin-top: 4px;
}

#input-checkbox {
  margin-top: 10px;
  color: var(--color-ui-text-primary);
  text-align: center;
}

.terms-condition-link {
  color: var(--color-persian-blue-500);
}

.button-register-disabled {
  background: #e0e0e0;
  border-radius: 10px;
  padding: 10px 0px;
  width: 100%;
  font-weight: 600;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 16px;
  transition: 0.3s;
}

.button-register {
  background: var(--color-persian-blue-500);
  border-radius: 10px;
  padding: 10px 0px;
  width: 100%;
  font-weight: 600;
  border: 1px solid var(--color-persian-blue-500);
  color: var(--color-neutral-100);
  margin-top: 16px;
  transition: 0.3s;
}

.button-register:hover {
  background-color: var(--color-persian-blue-400);
  border: 1px solid var(--color-persian-blue-400);
}
</style>
