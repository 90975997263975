<template>
  <div id="default-layout">
    <Navbar />
    <slot />
  </div>
</template>

<script>
import Navbar from '../components/navbar/Navbar'

export default {
  components: {
    Navbar
  },
  methods: {
  },
  computed: {
  },
  created () {
    this.$store.commit('SET_ENV_BELAJAR')
    this.$store.commit('SET_ACTIVE_ENVIRONMENT')
    this.$store.dispatch('subnavbar/initMenuList')
  }
}
</script>

<style scoped>
#default-layout {
  font-family: "Open Sans", sans-serif;
}
</style>
