import { render, staticRenderFns } from "./ActivityV2Layout.vue?vue&type=template&id=6a27644e&scoped=true"
import script from "./ActivityV2Layout.vue?vue&type=script&lang=js"
export * from "./ActivityV2Layout.vue?vue&type=script&lang=js"
import style0 from "./ActivityV2Layout.vue?vue&type=style&index=0&id=6a27644e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a27644e",
  null
  
)

export default component.exports