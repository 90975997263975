var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.comment.payload.replied_comment_id)?_c('div',{staticClass:"chat-reply-container"},[_c('div',{staticClass:"reply-box-left"}),_c('div',{staticClass:"reply-box-right"},[_c('div',{staticClass:"replied-name"},[_vm._v(_vm._s(_vm.comment.payload.replied_comment_sender_username))]),_c('div',{staticClass:"reply-content"},[(_vm.comment.payload.replied_comment_payload.url)?_c('span',{staticClass:"text-right",staticStyle:{"max-width":"100px"}},[(_vm.comment.payload.replied_comment_payload.url.toLowerCase().match(/\.(svg)$/))?[_c('div',{staticClass:"inter-medium_small-normal unsupported-svg"},[_vm._v("File svg tidak ditampilkan.")])]:[_c('a',{attrs:{"href":_vm.comment.payload.replied_comment_payload.url,"target":"_blank","rel":"noopener noreferrer"}},[(
                  _vm.comment.payload.replied_comment_payload.url &&
                    _vm.comment.payload.replied_comment_payload.url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/)
                )?_c('b-img',{style:(_vm.imageStyle),attrs:{"src":_vm.comment.payload.replied_comment_payload.url}}):(
                  _vm.comment.payload.replied_comment_payload.url &&
                    _vm.comment.payload.replied_comment_payload.url.match(/\.(m4a|mp3|ogg})$/)
                )?_c('audio',{style:(_vm.audioStyle),attrs:{"controls":"","src":_vm.comment.payload.replied_comment_payload.url}}):(
                  _vm.comment.payload.replied_comment_payload.url &&
                    _vm.comment.payload.replied_comment_payload.url.match(/\.(mp4|avi|3gp})$/)
                )?_c('video',{style:(_vm.videoStyle),attrs:{"controls":"","src":_vm.comment.payload.replied_comment_payload.url}}):_c('span',[_vm._v(" "+_vm._s(_vm.comment.payload.replied_comment_payload.file_name)+" "),_c('b-icon-download',{staticClass:"ml-2"})],1)],1)],_c('div',{style:(_vm.captionStyle)},[_vm._v(_vm._s(_vm.comment.payload.replied_comment_payload.caption))])],2):_c('span',{style:(_vm.textStyle),domProps:{"innerHTML":_vm._s(_vm.urlify(_vm.comment.payload.replied_comment_message))}})])])]):_vm._e(),(_vm.comment.payload.url)?_c('span',{staticClass:"text-right",staticStyle:{"max-width":"100px"}},[(_vm.comment.payload.url.toLowerCase().match(/\.(svg)$/))?[_c('div',{staticClass:"inter-medium_small-normal unsupported-svg"},[_vm._v("File svg tidak ditampilkan.")])]:[_c('a',{attrs:{"href":_vm.comment.payload.url,"target":"_blank","rel":"noopener noreferrer"}},[(
            _vm.comment.payload.url &&
              _vm.comment.payload.url.match(/\.(jpeg|jpg|gif|png)$/)
          )?_c('b-img',{style:(_vm.imageStyle),attrs:{"src":_vm.comment.payload.url}}):(
            _vm.comment.payload.url &&
              _vm.comment.payload.url.match(/\.(m4a|mp3|ogg})$/)
          )?_c('audio',{style:(_vm.audioStyle),attrs:{"controls":"","src":_vm.comment.payload.url}}):(
            _vm.comment.payload.url &&
              _vm.comment.payload.url.match(/\.(mp4|avi|3gp})$/)
          )?_c('video',{style:(_vm.videoStyle),attrs:{"controls":"","src":_vm.comment.payload.url}}):_c('span',[_vm._v(" "+_vm._s(_vm.comment.payload.file_name)+" "),_c('b-icon-download',{staticClass:"ml-2"})],1)],1)],_c('div',{style:(_vm.captionStyle)},[_vm._v(_vm._s(_vm.comment.payload.caption))])],2):_c('span',{class:{'ff-primary fs-14 fw-400 text-ui-primary': _vm.fromTanyamuKelasV2},style:(_vm.textStyle),domProps:{"innerHTML":_vm._s(_vm.urlify(_vm.comment.message))}}),_c('div',{staticClass:"my-chat-option",style:({top: _vm.optionChevronTop || `-2px`, right: _vm.optionChevronRight || `-20px`}),on:{"click":function($event){$event.preventDefault();_vm.openChatOption = !_vm.openChatOption}}},[_c('img',{attrs:{"src":require("../../assets/icons/chevron_down_2.svg"),"alt":"Sekolah.mu"}})]),_c('transition',{attrs:{"name":"fade"}},[(_vm.openChatOption)?_c('div',{staticClass:"my-chat-optionbox",style:({position: `absolute`, top: _vm.optionBoxTop, right: _vm.optionBoxRight})},[_c('div',{staticClass:"list-label",on:{"click":function($event){$event.preventDefault();return _vm.replyMessage.apply(null, arguments)}}},[_vm._v("Balas pesan")]),(_vm.canDeleteOthersMessage || (String(_vm.comment.email) === String(_vm.userId)))?_c('div',{staticClass:"list-label",on:{"click":function($event){$event.preventDefault();return _vm.deleteMessage.apply(null, arguments)}}},[_vm._v("Hapus pesan")]):_vm._e()]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }