const bottomSheet = {
  namespaced: true,
  state: {
    isShowing: false,
    isBottomSheetPersist: false
  },
  mutations: {
    SET_SHOW_BOTTOM_SHEET_STATUS (state, payload) {
      state.isShowing = payload
    },
    SET_BOTTOM_SHEET_PERSIST (state, payload) {
      state.isBottomSheetPersist = payload
    }
  }
}

export default bottomSheet
