// set mediaViewerList as empty array to not render media viewer components

const mediaViewer = {
  namespaced: true,
  state: {
    mediaViewerList: [],
    mediaViewerIndex: 0,
    mediaViewerConfig: {
      showDownload: false
    }
  },
  mutations: {
    SET_MEDIA_VIEWER_INDEX (state, payload) {
      state.mediaViewerIndex = payload
    },
    SET_MEDIA_VIEWER_LIST (state, payload) {
      if (payload.length) {
        window.document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      } else {
        window.document.getElementsByTagName('body')[0].style.overflow = 'auto'
      }
      state.mediaViewerList = payload
    },
    SET_MEDIA_VIEWER_CONFIG (state, payload) {
      state.mediaViewerConfig = payload
    }
  }
}

export default mediaViewer
