import axiosServerGolang from '@/configs/axiosServerGolang'
import objectToQueryParams from '@/functions/objectToQueryParams'
import { generateSuggestionURL } from '@/functions/sro'

const validCategories = ['program', 'learning-package']
export default {
  async getSearchResultByCategory (payload, Authorization, envPlatinum = false, conditionalState) {
    const {
      isSMM,
      isSRO = false
    } = conditionalState

    const { page = 1, perPage = 12, category, ...objQueries } = payload
    if (!validCategories.includes(category)) return null

    try {
      let queries = await objectToQueryParams(objQueries)
      let sroStr = isSRO ? '/sro' : ''
      if (envPlatinum) {
        queries.length > 0 ? queries += '&institution_filter=false' : queries += '?institution_filter=false'
      }
      if (isSMM) {
        queries.length > 0 ? queries += '&is_smm=true' : queries += '?is_smm=true'
      }
      const result = await axiosServerGolang({
        method: 'GET',
        url: `/discovery${sroStr}/${category}/list/${page}/${perPage}${queries}`,
        headers: { Authorization }
      })
      return result
    } catch (error) {
      return error
    }
  },
  async getRecommendationByCategory (payload, Authorization) {
    const { env, category, param, query } = payload
    if (!validCategories.includes(category)) return null

    try {
      const url = await generateSuggestionURL(env, category, { param, query })
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: { Authorization }
      })
      return result
    } catch (error) {
      return error
    }
  },
  async getSuggestionKeyword ({ env, param, query }, Authorization) {
    try {
      const url = await generateSuggestionURL(env, 'suggestion', { param, query })
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: { Authorization }
      })
      return result
    } catch (error) {
      return {
        ...error,
        data: { data: [] }
      }
    }
  },
  async removeSuggestionKeyword (Authorization) {
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url: `/discovery/remove-suggestion-keyword`,
        headers: { Authorization }
      })
      return result
    } catch (error) {
      return {
        ...error,
        data: { data: [] }
      }
    }
  }
}
