<template>
  <div class="p-3 d-flex flex-column parent-div" id="fab-tanyamu">
    <!-- <b-button
      variant="link"
      class="m-1 pr-4 py-0 text-right"
      v-if="isSubfloatShown"
      @click.prevent="$bvModal.show('modal-subject')"
    >
      <span class="float-text font-weight-bold gotham-book">Tanya Pelajaran</span>
      <b-img class="p-1" :src="require('../../assets/icons/float_subject.png')"></b-img>
    </b-button> -->
    <!-- <b-button
      variant="link"
      class="m-1 pr-4 py-0 text-right"
      v-if="isSubfloatShown"
      @click.prevent="askAdmin"
    >
      <span class="float-text font-weight-bold gotham-book">Tanya Admin</span>
      <b-img class="p-1" :src="require('../../assets/icons/float_admin.png')"></b-img>
    </b-button> -->
    <b-button
      variant="link"
      class="m-1 pr-4 py-0 text-right"
      v-if="isSubfloatShown && $route.name === 'activity-program'"
      @click.prevent="askProgramFacils"
    >
      <span class="float-text font-weight-bold gotham-book">Diskusi Program</span>
      <b-img :src="require('../../assets/icons/float_discussion.png')"></b-img>
    </b-button>

    <!-- <b-button
      variant="link"
      class="d-flex flex-column align-items-end"
      id="start-chat"
      v-if="facilRoomId"
      @click.prevent="askProgramFacils"
    >
      <b-img-lazy
        id="message-hover"
        :src="require('../../assets/icons/float_hover_message.png')"
        v-if="!isSubfloatShown"
      ></b-img-lazy>
      <b-img class="pr-2" :src="require('../../assets/icons/float_message.png')"></b-img>
    </b-button> -->
    <transition name="slide-fade">
      <FloatingChatBox
        v-if="isChatBoxOpen"
        @toggleChatbox="toggleChatbox"
        @gotoTanyamu="askProgramFacils"
        :facilRoomId="facilRoomId"
      />
    </transition>

    <template v-if="!isOpenChildProgram && !isTanyamuClosed">
      <b-button
        variant="link"
        class="d-flex flex-column align-items-end"
        id="start-chat"
        v-if="facilRoomId"
        @click.prevent="toggleChatbox"
      >
        <b-img-lazy
          id="message-hover"
          :src="require('../../assets/icons/float_hover_message.png')"
          v-if="!isSubfloatShown"
        ></b-img-lazy>
        <b-img class="pr-2" :src="require('../../assets/icons/float_message.png')"></b-img>
      </b-button>
    </template>

    <!-- <b-modal id="modal-subject" centered scrollable size="sm" :hide-footer="true">
      <template v-slot:modal-header="{ close }">
        <h5 class="open-sans text-dark font-weight-bold">Mau tanya apa?</h5>
        <b-button size="sm" variant="link" @click.prevent="close()">
          <font-awesome-icon icon="times" class="text-dark" />
        </b-button>
      </template>
      <span
        v-for="(subject, index) in subjects.filter(subject => subject.name.toLowerCase() !== 'admin')"
        :key="index"
      >
        <b-img :src="subject.avatar"></b-img>
        <b-button
          variant="link"
          class="text-decoration-none text-dark"
          @click.prevent="askSubjectById(subject.id)"
        >{{subject.name}}</b-button>
        <hr />
      </span>
    </b-modal>-->

    <b-modal id="modal-alert" centered title="Alert!" size="sm" :hide-footer="true">
      <p class="text-center">
        <b-icon-x-circle-fill font-scale="5" variant="danger"></b-icon-x-circle-fill>
      </p>
      <p class="my-4 text-center open-sans font-weight-light">{{errorMessage}}</p>
    </b-modal>

    <b-modal
      v-model="isQiscusRoomFullModal"
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <QiscusRoomFullErrMessage
        @closeModal="closeQiscusFullRoomModal"
      />
    </b-modal>
  </div>
</template>

<script>
import axiosServer from '@/configs/axiosServer'
import { BIconXCircleFill } from 'bootstrap-vue'
import FloatingChatBox from './FloatingChatBox.vue'
import QiscusRoomFullErrMessage from '@/components/validator/QiscusRoomFullErrMessage'

export default {
  components: {
    BIconXCircleFill,
    FloatingChatBox,
    QiscusRoomFullErrMessage
  },
  data () {
    return {
      isSubfloatShown: false,
      isChatBoxOpen: false,
      errorMessage: null,
      subjects: [],
      facilRoomId: null,
      isQiscusRoomFull: false,
      isQiscusRoomFullModal: false
    }
  },
  computed: {
    token () {
      return this.$store.state.storage.accessToken
    },
    dataProgramDescription: {
      get () {
        return this.$store.state.activity.dataProgramDescription
      },
      set (value) {
        this.$store.commit('activity/CHANGE_DATA_PROGRAM_DESCRIPTION', value)
      }
    },
    programId () {
      return this.$store.state.program.programId
    },
    currentUserId () {
      return this.$store.state.user.currentUser.id
    },
    isOpenChildProgram () {
      return this.$store.state.program.isOpenChildProgram
    },
    isTanyamuClosed () {
      return !!this.dataProgramDescription.is_tanyamu_closed
    }
  },
  methods: {
    toggleSubFloat () {
      this.isSubfloatShown = !this.isSubfloatShown
    },
    async toggleChatbox () {
      if (!this.$store.state.tanyamu.qiscus) {
        const dataLogin = await this.$store.dispatch('user/fetchUserData')
        if (dataLogin.status === 200) {
          await this.$store.dispatch('tanyamu/loginQiscus')
        }
        setTimeout(() => {
          this.isChatBoxOpen = !this.isChatBoxOpen
        }, 1000)
      } else {
        this.isChatBoxOpen = !this.isChatBoxOpen
      }
    },
    closeQiscusFullRoomModal () {
      this.isQiscusRoomFullModal = false
    },
    // async askSubjectById (subjectId) {
    //   try {
    //     const { data } = await axiosServer({
    //       url: '/chat/ask/',
    //       method: 'POST',
    //       headers: { Authorization: this.token },
    //       data: { subject_id: subjectId }
    //     })

    //     this.$bvModal.hide('modal-subject')
    //     if (data.status === 200) {
    //       this.$router.push({
    //         name: 'tanyamu',
    //         params: { initRoom: data.data.results.room.room_id }
    //       })
    //     } else if (data.message) {
    //       this.errorMessage = data.message
    //       this.$bvModal.show('modal-alert')
    //     }
    //     this.toggleSubFloat()
    //   } catch (err) {
    //     console.log(err)
    //   }
    // },
    // async askAdmin () {
    //   const subjectAdmin = this.subjects.filter(
    //     subject => subject.name.toLowerCase() === 'admin'
    //   )[0]
    //   if (subjectAdmin.id) {
    //     this.askSubjectById(subjectAdmin.id)
    //   }
    // },
    async initProgramFacils () {
      try {
        const programId = this.$store.state.program.programId
        const currentUserId = this.$store.state.user.currentUser.id
        const isOpenChildProgram = this.$store.state.program.isOpenChildProgram
        const folderId = this.$store.state.activity.folderId
        let url = `/program_activity/v2/product_by_activity/${this.$route.params.id}/description`
        if (isOpenChildProgram) {
          let userId = this.$store.state.storage.userId
          if (currentUserId) {
            userId = currentUserId
          }
          url = `/program_activity/v2/product_detail/enrolled/${userId}/${programId}/description`
        } else if (folderId) {
          url += `?folder_id=${folderId}`
        }
        const { data } = await axiosServer({
          url: url,
          method: 'get',
          headers: {
            Authorization: this.$store.state.storage.accessToken
          }
        })
        if (data.status === 200) {
          this.facilRoomId = parseInt(data.data.room_id)
          this.dataProgramDescription = data.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    async askProgramFacils () {
      try {
        const { data } = await axiosServer({
          url: '/chat/participant/',
          method: 'post',
          headers: {
            Authorization: this.token
          },
          data: {
            room_id: this.facilRoomId
          }
        })
        if (data.status === 200) {
          if (data.data.results.room) {
            this.$router.push({
              name: 'tanyamu',
              params: { initRoom: data.data.results.room.room_id }
            })
          } else {
            this.$router.push({
              name: 'tanyamu',
              params: { initRoom: this.facilRoomId }
            })
          }
          this.toggleSubFloat()
        } else if (data.status === 400 && data.message.includes(`room participants can't more than`)) {
          this.isQiscusRoomFull = true
          this.isQiscusRoomFullModal = true
        } else {
          this.$store.dispatch('storage/checkToken', data)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async initSubject () {
      try {
        const { data } = await axiosServer({
          url: '/chat/subject/',
          method: 'GET',
          headers: {
            Authorization: this.token
          }
        })
        if (data.status === 200) {
          this.subjects = data.data
        } else {
          this.$store.dispatch('storage/checkToken', data)
        }
      } catch (err) {
        console.log(err)
      }
    }
  },
  created () {
    this.initProgramFacils()
    // this.initSubject()
  },
  beforeDestroy () {
    this.isSubfloatShown = false
  }
  // watch: {
  //   $route (to, from) {

  //     if (!this.token || this.token !== this.$store.state.storage.accessToken) {
  //       this.token = this.$store.state.storage.accessToken
  //     }

  //     if (this.token) {
  //       if (!this.subjects.length) {
  //         this.initSubject()
  //       } else if (to.name === 'activity-program') {
  //         this.initProgramFacils()
  //       }
  //     } else {
  //       this.subjects = []
  //     }
  //   }
  // }
}
</script>

<style scoped>
.parent-div{
  position: fixed;
  bottom: 0;
  right: 0;
}

.float-text {
  font-size: 14px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.7);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#message-hover {
  display: none;
}

#start-chat:hover #message-hover {
  display: inline;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(398px);
  opacity: 1;
}
</style>
