export function initStatusShopingCart (items) {
  items.forEach(group => {
    group.is_choice = true
    group.is_disabled = false
    group.programs.forEach(program => {
      // addon resource by loki
      program.addons = combineAndManipulateAddonsData(program.addons, program.addons_resource)
      if (program.enrolled) {
        program = handleProgramEnrolled(program)
      } else {
        program = handleProgramUnenrolled(program)
      }
    })
    group.is_choice = checkStatusProgramInGroup(group.programs)
    group.is_disabled = checkDisabledStatus(group.programs)
  })
  return items
}

// customized by Harfi for addon activity
export function combineAndManipulateAddonsData (addons, addons_resource) {
  const newAddons = addons.concat(addons_resource).map(addon => ({
    ...addon,
    is_stock: addon.is_stock && !addon.is_expired
  }))
  return newAddons
}

export function handleProgramEnrolled (program) {
  let outOfStockAllAddon = true
  let addonExist = program.addons.length
  if (addonExist) {
    outOfStockAllAddon = outOfStockAddon(program)
  }
  program.is_choice = !outOfStockAllAddon
  program.addons = addonChoice(program.addons, !outOfStockAllAddon)
  return program
}

export function handleProgramUnenrolled (program) {
  program.is_choice = program.is_stock
  if (program.addons.length) {
    program.addons = addonChoice(program.addons, program.is_choice)
  }
  return program
}

export function checkProgramAvailable (listProgram) {
  let totalProgramAvailable = 0
  listProgram.forEach(program => {
    let addonAvailableToBuy = checkAddonAvailable(program.addons)
    if (!program.enrolled && program.is_stock) {
      totalProgramAvailable++
    } else if (program.enrolled && program.is_stock && addonAvailableToBuy) {
      totalProgramAvailable++
    } else if (program.enrolled && !program.is_stock && addonAvailableToBuy) {
      totalProgramAvailable++
    }
  })
  return totalProgramAvailable
}

export function evaluateChecklistProgram (program) {
  let totalAddonChecked = 0
  program.addons.forEach(addon => {
    if (addon.is_choice) {
      totalAddonChecked++
    }
  })

  if (program.enrolled) {
    if (totalAddonChecked > 0) {
      program.is_choice = true
    } else {
      program.is_choice = false
    }
  } else {
    program.is_choice = true
  }
  return program
}

export function evaluateChecklistCreator (group) {
  let totalProgramChecked = 0
  let totalProgramAvailable = checkProgramAvailable(group.programs)
  group.programs.forEach(program => {
    if (program.is_choice) {
      totalProgramChecked++
    }
  })

  if (!totalProgramAvailable) {
    group.is_choice = false
  } else if (totalProgramAvailable === totalProgramChecked) {
    group.is_choice = true
  } else {
    group.is_choice = false
  }
  return group
}

export function evaluateChecklistCart (data) {
  let totalCreatorChecked = 0
  let creatorCount = data.length
  let totalCreatorDisabled = 0
  data.forEach(group => {
    if (group.is_disabled) {
      totalCreatorDisabled++
    }
    if (group.is_choice) {
      totalCreatorChecked++
    }
  })

  // kalau
  if (totalCreatorChecked === creatorCount) {
    return true
  } else if ((creatorCount - totalCreatorDisabled) === totalCreatorChecked && totalCreatorChecked) {
    return true
  } else {
    return false
  }
}

// creator

export function creatorChecklist (data, { creatorId, status }) {
  data.forEach(group => {
    if (group.creator_id === creatorId) {
      group.is_choice = status
      group.programs.forEach(program => {
        let programAvailable = isProgramAvailable(program)
        if (programAvailable) {
          program.is_choice = status
          if (program.addons.length) {
            program.addons.forEach(addon => {
              if (addon.is_stock) {
                addon.is_choice = status
              }
            })
          }
        } else {
          program.is_choice = false
          if (program.addons.length) {
            program.addons.forEach(addon => {
              if (addon.is_stock) {
                addon.is_choice = false
              }
            })
          }
        }
      })
    }
  })
  return data
}

// program

export function programChecklist (data, { creatorId, programId, status }) {
  data.forEach(group => {
    if (creatorId === group.creator_id) {
      group.programs.forEach(program => {
        if (program.id === programId) {
          program.is_choice = status
          if (program.addons.length) {
            program.addons.forEach(addon => {
              if (addon.is_stock) {
                addon.is_choice = status
              }
            })
          }
        }
      })
      group = evaluateChecklistCreator(group)
    }
  })

  return data
}

export function checkStatusProgramInGroup (listProgram) {
  let totalProgramChecked = 0
  let totalProgramAvailable = checkProgramAvailable(listProgram)
  listProgram.forEach(program => {
    if (program.is_choice) {
      totalProgramChecked++
    }
  })

  if (!totalProgramAvailable) {
    return false
  } else if (totalProgramAvailable === totalProgramChecked) {
    return true
  } else {
    return false
  }
}

export function isProgramAvailable (program) {
  let status = false
  let addonAvailableToBuy = checkAddonAvailable(program.addons)
  if (!program.enrolled && program.is_stock) {
    status = true
  } else if (program.enrolled && program.is_stock && addonAvailableToBuy) {
    status = true
  } else if (program.enrolled && !program.is_stock && addonAvailableToBuy) {
    status = true
  }
  return status
}

// addon

export function addonChecklist (data, { creatorId, programId, addonId, status }) {
  data.forEach(creator => {
    if (creatorId === creator.creator_id) {
      creator.programs.forEach(program => {
        if (program.id === programId && program.addons.length) {
          program.addons.forEach(addon => {
            if (addon.id === addonId) {
              addon.is_choice = status
            }
          })
          program = evaluateChecklistProgram(program)
        }
      })
      creator = evaluateChecklistCreator(creator)
    }
  })
  return data
}

export function checkAddonAvailable (listAddon) {
  let status = false
  listAddon.forEach(addon => {
    if (addon.is_stock) {
      status = true
    }
  })
  return status
}

export function addonChoice (addons, status) {
  addons.forEach(addon => {
    if (!addon.is_stock) {
      addon.is_choice = false
    } else {
      addon.is_choice = status
    }
  })
  return addons
}

export function outOfStockAddon (program) {
  let status = true
  program.addons.forEach(addon => {
    if (addon.is_stock) {
      status = false
    }
  })
  return status
}

// level shopping cart

export function handleProgramEnrolledCart (program, status) {
  let outOfStockAllAddon = true
  let addonExist = program.addons.length
  if (addonExist) {
    outOfStockAllAddon = outOfStockAddon(program)
  }

  if (outOfStockAllAddon) {
    program.is_choice = false
    program.addons = addonChoice(program.addons, false)
  } else {
    program.is_choice = status
    program.addons = addonChoice(program.addons, status)
  }
  return program
}

export function handleProgramUnenrolledCart (program, status) {
  if (!program.is_stock) {
    program.is_choice = false
    if (program.addons.length) {
      program.addons = addonChoice(program.addons, false)
    }
  } else {
    program.is_choice = status
    if (program.addons.length) {
      program.addons = addonChoice(program.addons, status)
    }
  }
  return program
}

export function cartChecklist (items, { status }) {
  items.forEach(group => {
    group.is_choice = true
    group.is_disabled = false
    group.programs.forEach(program => {
      if (program.enrolled) {
        program = handleProgramEnrolledCart(program, status)
      } else {
        program = handleProgramUnenrolledCart(program, status)
      }
    })
    group.is_choice = checkStatusProgramInGroup(group.programs)
    group.is_disabled = checkDisabledStatus(group.programs)
  })
  return items
}

export function checkDisabledStatus (listProgram) {
  let totalProgramAvailable = checkProgramAvailable(listProgram)
  if (!totalProgramAvailable) {
    return true
  } else {
    return false
  }
}
