import { quizDetail, quizMasterData, quizProgramUserData, answerPayload } from '../../../dummies/store/quizV2'

const quizV2 = {
  namespaced: true,
  state: {
    currentQuizQuestion: {},
    currentQuizQuestionMeta: {},
    currentQuizAnswer: {
      answer: [],
      answer_essay: '',
      answer_file: []
    },
    hasQuizInstruction: false,
    isQuizV2Intro: false,
    isQuizLoading: false,
    isQuizV2Started: false,
    isQuizFinished: false,
    isQuizReview: false,
    isCheckAnswer: false,
    isQuizFinishedModalVisible: false,
    isQuizFileUploadInProgress: false,
    isGroupedQuestionModalVisible: false,
    answerPayload,
    questionReviewList: 0,
    questionReviewIndex: 0,
    // Activity Detail API
    isReflection: false,
    quizDetail,
    // Quiz Intro API
    isRubric: false,
    quizProgramUserData,
    quizMasterData,
    quizTransaction: [],
    // Scoring Category Detail API
    rubricAssessmentCriteria: [],
    rubricTransaction: [],
    // Quiz Start & Resume API
    questionList: [],
    questionPerBatch: 0,
    totalQuestion: 0,
    totalAnswered: 0,
    // Question Answer API (Quiz Review)
    isMultipleChoiceCorrectAnswerShown: false // Multiple Choice = question with Single Answer / Multiple Answer
  },
  mutations: {
    SET_QUIZ_TOTAL_QUESTION (state, payload) {
      state.totalQuestion = payload
    },
    SET_QUIZ_TOTAL_ANSWERED (state, payload) {
      state.totalAnswered = payload
    },
    SET_QUIZ_INTRO (state, payload) {
      state.isQuizV2Intro = payload
    },
    SET_QUIZ_START (state, payload) {
      state.isQuizV2Started = payload
    },
    SET_QUIZ_FINISH (state, payload) {
      state.isQuizFinished = payload
    },
    SET_QUIZ_LOADING (state, payload) {
      state.isQuizLoading = payload
    },
    SET_QUIZ_REVIEW (state, payload) {
      state.isQuizReview = payload
    },
    SET_QUIZ_FILE_UPLOAD_IN_PROGRESS (state, payload) {
      state.isQuizFileUploadInProgress = payload
    },
    SET_CHECK_ANSWER (state, payload) {
      state.isCheckAnswer = payload
    },
    SAVE_QUESTION_LIST (state, payload) {
      state.questionList = payload
    },
    SAVE_QUESTION_PER_BATCH (state, payload) {
      state.questionPerBatch = payload
    },
    SAVE_QUESTION_REVIEW_LIST (state, payload) {
      state.questionReviewList = payload
    },
    SET_IS_RUBRIC (state, payload) {
      state.isRubric = payload
    },
    SET_IS_REFLECTION (state, payload) {
      state.isReflection = payload
    },
    SET_HAS_QUIZ_INSTRUCTION (state, payload) {
      state.hasQuizInstruction = payload
    },
    SET_QUIZ_DETAIL (state, payload) {
      state.quizDetail = payload
    },
    SET_QUIZ_MASTER_DATA (state, payload) {
      state.quizMasterData = payload
    },
    SET_QUIZ_PROGRAM_USER_DATA (state, payload) {
      state.quizProgramUserData = payload
    },
    SET_QUIZ_TRANSACTION (state, payload) {
      state.quizTransaction = payload
    },
    SET_CURRENT_QUIZ_QUESTION (state, payload) {
      state.currentQuizQuestion = payload
    },
    SET_CURRENT_QUIZ_QUESTION_META (state, payload) {
      state.currentQuizQuestionMeta = payload
    },
    SET_CURRENT_QUIZ_ANSWER (state, payload) {
      state.currentQuizAnswer = payload
    },
    SET_ANSWER_PAYLOAD (state, payload) {
      state.answerPayload = payload
    },
    SET_RUBRIC_ASSESSMENT_CRITERIA (state, payload) {
      state.rubricAssessmentCriteria = payload
    },
    SET_RUBRIC_TRANSACTION (state, payload) {
      state.rubricTransaction = payload
    },
    SHOW_MULTIPLE_CHOICE_CORRECT_ANSWER (state, payload) {
      state.isMultipleChoiceCorrectAnswerShown = payload
    },
    TOGGLE_GROUPED_QUESTION_MODAL (state, payload) {
      state.isGroupedQuestionModalVisible = payload
    },
    TOGGLE_QUIZ_FINISH_MODAL (state, payload) {
      state.isQuizFinishedModalVisible = payload
    },
    NAVIGATE_QUESTION_REVIEW (state, payload) {
      state.questionReviewIndex = payload
    }
  },
  actions: {}
}
export default quizV2
