<template>
  <div>
    <div v-if="data.chapters.length === 1">
      <topic-wrapper>
        <topic-regular
          :data="data.chapters[0]"
          :has-enrolled="true"
          v-b-toggle="`accordion-${data.chapters[0].id}`"
          role="tab"
        />
        <b-collapse
          :id="`accordion-${data.chapters[0].id}`"
          role="tabpanel"
          @input="isVisible => getChapterDetail(data.chapters[0].id, isVisible)"
          :visible="data.chapters[0].id === activityValidate.chapter_id"
        >
          <activity
            v-for="(activity, activityIndex) in activityData"
            :number="setNumbering(topicIndex, activityIndex)"
            :is-linier="isLinier"
            :is-show="activity.isShow"
            :data="activity"
            :key="activity.id"
            :is-active="activity.id === currentActivityId"
          />
        </b-collapse>
      </topic-wrapper>
    </div>
    <div v-else-if="data.chapters.length > 1">
      <set-of-enrolled-addon-topics
        v-for="(topicAddon, topicAddonIndex) in data.chapters"
        :data="topicAddon"
        :key="topicAddon.id"
        :topic-index="topicIndex + topicAddonIndex"
      />
    </div>
  </div>
</template>
<script>
import TopicWrapper from '@/components/activityV2/Sidebar/TopicWrapper'
import TopicRegular from '@/components/activityV2/Sidebar/TopicRegular'
import Activity from '@/components/activityV2/Sidebar/Activity'
import SetOfEnrolledAddonTopics from '@/components/activityV2/Sidebar/Tabs/TabActivity/SetOfEnrolledAddonTopics'
import activityV2GeneralMixin from '@/mixins/activityV2/general'
import activityV2SidebarTabActivityMixin from '@/mixins/activityV2/sidebarTabActivity'

export default {
  name: 'TabActivitySectionEnrolledTopic',
  mixins: [activityV2GeneralMixin, activityV2SidebarTabActivityMixin],
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    topicIndex: Number,
    isLinier: Boolean
  },
  components: {
    TopicWrapper,
    TopicRegular,
    Activity,
    SetOfEnrolledAddonTopics
  },
  computed: {
    currentActivityId: {
      get () {
        return this.$store.state.activityV2.currentActivityId
      },
      set (value) {
        this.$store.commit('activityV2/SET_CURRENT_ACTIVITY_ID', value)
      }
    }
  },
  created () {
    this.currentActivityId = this.activityValidate.activity_id
  }
}
</script>
