import programActivityAPI from '@/api/program-activity'

const activityV2 = {
  namespaced: true,
  state: {
    activityDashboard: {
      id: null,
      completed_resources: 0,
      final_score: 0,
      name: '',
      percentage_progress: 0,
      pmo_id: '',
      room_id: null,
      slug: '',
      type_room: '',
      total_resources: 0,
      folder_id: 0,
      is_rated: 0,
      is_available_to_rate: false
    },
    activityFocus: {},
    activityResourceDetail: {},
    currentActivityId: 0,
    currentActivityNumber: 0,
    facilRoomId: null,
    facilitatorFeedback: [],
    isExperimental: false,
    isChatBoxOpen: false,
    isGroupSelectionVisible: false,
    isShowAddOnModal: false,
    isInStudyPageRevamp: false,
    isShowModalCompletionButton: false,
    isAcceptRevertCompletionButton: false,
    isFacilitatorFeedbackModalVisible: false,
    isRubricAssessmentCriteriaModalVisible: false,
    isVisibleProgramGroupActivityModal: false,
    isSidebarVisible: false,
    isDetailactivityModalVisible: false,
    isDetailactivityBottomSheetVisible: false,
    report: {
      show_report: false,
      show_certificate: false,
      recalculate_info: {
        status: 0
      }
    },
    setOfTopicResources: [],
    sidebarActiveTab: 0, // Tab Index, 0: activity, 1: announcement, 2: assessment
    userLandingTimestamp: null,
    validate: null,
    activitySearchParams: {
      name: '',
      activityCategories: []
    },
    isLoadingSetLastState: false,
    programGroupActivityModalType: 'PROGRAM_CLASS', // 'PROGRAM_CLASS', 'PROGRAM_ACTIVITY',
    quizHasContent: true
  },
  mutations: {
    SAVE_SET_OF_TOPIC_RESOURCES (state, payload) {
      state.setOfTopicResources = payload
    },
    SET_ACTIVITY_DASHBOARD (state, payload) {
      state.activityDashboard = payload
    },
    SET_ACTIVITY_VALIDATE (state, payload) {
      state.validate = payload
    },
    SET_SIDEBAR_ACTIVE_TAB (state, payload) {
      state.sidebarActiveTab = payload
    },
    SET_REPORT (state, payload) {
      state.report = payload
    },
    SET_RECALCULATE_INFO (state, payload) {
      state.report.recalculate_info = payload
    },
    SET_ACTIVITY_FOCUS (state, payload) {
      state.activityFocus = payload
    },
    SET_ACTIVITY_RESOURCE_DETAIL (state, payload) {
      state.activityResourceDetail = payload
    },
    SET_CURRENT_ACTIVITY_COMPLETE (state, payload) {
      state.activityResourceDetail.is_complete = payload
    },
    SET_CURRENT_ACTIVITY_ID (state, payload) {
      state.currentActivityId = payload
    },
    SET_CURRENT_ACTIVITY_NUMBER (state, payload) {
      state.currentActivityNumber = payload
    },
    SET_ADDON_BUY_MODAL_VISIBILITY (state, payload = false) {
      state.isShowAddOnModal = payload
    },
    SET_CHAT_BOX (state, payload) {
      state.isChatBoxOpen = payload
    },
    SET_FACIL_ROOM_ID (state, payload) {
      state.facilRoomId = payload
    },
    SET_IN_STUDY_PAGE_REVAMP (state, payload) {
      state.isInStudyPageRevamp = payload
    },
    SET_USER_LANDING_TIMESTAMP (state, payload) {
      state.userLandingTimestamp = payload
    },
    SET_SHOW_MODAL_COMPLETION_BUTTON (state, payload) {
      state.isShowModalCompletionButton = payload
    },
    SET_ACCEPT_COMPLETION_BUTTON (state, payload) {
      state.isAcceptRevertCompletionButton = payload
    },
    SET_LOCK_RESULT (state, payload) {
      state.activityFocus.lock_result = payload
    },
    SET_ACTIVITY_SEARCH_PARAMS (state, payload) {
      state.activitySearchParams = payload
    },
    SET_FACILITATOR_FEEDBACK (state, payload) {
      state.facilitatorFeedback = payload
    },
    TOGGLE_ACTIVITY_TYPE (state, payload) {
      state.activityResourceDetail.type = payload
    },
    TOGGLE_EXPERIMENTAL_FEATURES (state, payload) {
      state.isExperimental = payload
    },
    TOGGLE_GROUP_SELECTION (state, payload) {
      state.isGroupSelectionVisible = payload
    },
    TOGGLE_PROGRAM_GROUP_ACTIVITY_MODAL (state, payload) {
      state.isVisibleProgramGroupActivityModal = payload
    },
    PROGRAM_GROUP_ACTIVITY_MODAL_TYPE (state, payload) {
      state.programGroupActivityModalType = payload
    },
    TOGGLE_SIDEBAR (state, payload) {
      state.isSidebarVisible = payload
    },
    TOGGLE_RUBRIC_ASSESSMENT_CRITERIA_MODAL (state, payload) {
      state.isRubricAssessmentCriteriaModalVisible = payload
    },
    TOGGLE_FACILITATOR_FEEDBACK_MODAL (state, payload) {
      state.isFacilitatorFeedbackModalVisible = payload
    },
    TOGGLE_DETAIL_ACTIVITY_MODAL (state, payload) {
      state.isDetailactivityModalVisible = payload
    },
    TOGGLE_DETAIL_ACTIVITY_BOTTOM_SHEET (state, payload) {
      state.isDetailactivityBottomSheetVisible = payload
    },
    SET_USER_ACTIVITY (state, payload) {
      state.activityFocus.user_activity = payload
    },
    SET_IS_LOADING_LAST_STATE (state, payload) {
      state.isLoadingSetLastState = payload
    },
    SET_QUIZ_HAS_CONTENT (state, payload) {
      state.quizHasContent = payload
    }
  },
  actions: {
    async setActivityComplete ({ commit, state, dispatch, rootState }, { activityId, showWarning }) {
      const { is_complete, is_archived } = state.activityResourceDetail
      const { subscription_lock, program_user_id, is_joined, has_user_group, enrolled } = state.validate
      const { accessToken: Authorization } = rootState.storage
      const hasLimitedProgramAccess = !is_joined && has_user_group && !enrolled
      if (is_complete || is_archived || subscription_lock || hasLimitedProgramAccess) {
        return
      }
      try {
        const { data: { data, status } } = await programActivityAPI.setActivityComplete({
          Authorization,
          data: {
            program_user_id,
            resource_id: activityId,
            is_finish: true
          }
        })
        const isSuccess = status === 200
        const isWarningMessageAvailable = data.message?.length > 0
        const isSuccessWithWarning = isSuccess && isWarningMessageAvailable

        if (isSuccessWithWarning) {
          showWarning && dispatch('snackbar/showSnackbar', {
            text: 'Silakan simak keseluruhan video untuk menyelesaikan aktivitas.',
            variant: 'warning'
          }, {
            root: true
          })
        } else if (isSuccess) {
          commit('SET_CURRENT_ACTIVITY_COMPLETE', true)
        }
        // Todo: Refresh Activity Dashboard
      } catch (error) {
        console.error(error)
      }
    },
    async setActivityCompleteWithLastState ({ commit, state, rootState }, { activityId, last_state, number_of_play }) {
      commit('SET_IS_LOADING_LAST_STATE', true)
      const { is_archived } = state.activityResourceDetail
      const { subscription_lock, program_user_id, is_joined, has_user_group } = state.validate
      const { accessToken: Authorization } = rootState.storage
      const hasLimitedProgramAccess = !is_joined && has_user_group
      if (is_archived || subscription_lock || hasLimitedProgramAccess) {
        return
      }
      try {
        const { data: { data, status } } = await programActivityAPI.setActivityComplete({
          Authorization,
          data: {
            program_user_id,
            resource_id: activityId,
            is_finish: true,
            number_of_play,
            last_state: 0
          }
        })

        if (status === 200) {
          commit('SET_USER_ACTIVITY', data.user_activity)
          commit('SET_CURRENT_ACTIVITY_COMPLETE', true)
        }
      } catch (error) {
        console.error(error)
      } finally {
        commit('SET_IS_LOADING_LAST_STATE', false)
      }
    },
    async checkpointActivityDuration ({ commit, state, dispatch, rootState }, { activityId, last_state, number_of_play }) {
      commit('SET_IS_LOADING_LAST_STATE', true)

      const { accessToken: Authorization } = rootState.storage
      const { program_user_id } = state.validate

      const { data } = await programActivityAPI.setActivityComplete({
        Authorization,
        data: {
          program_user_id,
          resource_id: activityId,
          is_finish: false,
          last_state,
          number_of_play
        }
      })
      if (data.status === 200) {
        commit('SET_USER_ACTIVITY', data.data.user_activity)
      }
      commit('SET_IS_LOADING_LAST_STATE', false)
    },
    async setActivityStart ({ state, rootState }) {
      const { program_user_id } = state.validate
      const { id } = state.activityResourceDetail
      const { accessToken: Authorization } = rootState.storage
      try {
        await programActivityAPI.setActivityComplete({
          Authorization,
          data: {
            program_user_id,
            resource_id: id,
            is_finish: false
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
export default activityV2
