<template>
  <div class="ff-primary">
    <s-button
      id="others-menu-mobile"
      variant="ghost"
      @click.prevent="toggleBottomsheet"
    >
      <font-awesome-icon
        class="activity-icon ellipsis"
        :icon="['fas', 'ellipsis-v']"
      />
    </s-button>
    <s-bottom-sheet
      v-model="isShow"
      :expanded="false"
      :show-toggler="true"
      showToggler
      :clickToClose="true"
    >
      <template #default>
        <div class="content-wrapper">
          <button
            v-if="!isTanyamu"
            class="others-drodpown-item inter-medium_small-normal"
            @click.prevent="showTanyamuKelas"
          >
            <img
              class="activity-icon"
              alt="tanyamu"
              src="https://cdn.sekolah.mu/assets/v2/menu-icon/tanyamu.svg"
            />
            Tanyamu
          </button>
          <button
            v-if="hasProgramStudyGroupActivity"
            class="others-drodpown-item inter-medium_small-normal"
            href="#"
            @click="showProgramGroupActivityModal()"
          >
            <img
              class="activity-icon"
              alt="kelompok-belajar-kelas"
              src="https://cdn.sekolah.mu/assets/icon-mini/halaman-belajar/icon-kelompok-belajar-kelas.svg"
            />
            Kelompok Belajar Kelas
          </button>
          <button
            class="others-drodpown-item inter-medium_small-normal"
            v-if="isTanyamu"
            @click="showShareModal"
          >
            <img
              class="activity-icon"
              alt="bagikan-aktivitas"
              src="https://cdn.sekolah.mu/assets/icon-mini/halaman-belajar/icon-bagikan-aktivitas.svg"
            />
            Bagikan Aktivitas
          </button>
          <button
            class="others-drodpown-item inter-medium_small-normal"
            v-if="isShowTour"
            @click="initTour"
          >
            <img
              class="activity-icon"
              alt="eksplorasi"
              src="https://cdn.sekolah.mu/assets/icon-mini/halaman-belajar/icon-bagikan-aktivitas.svg"
            />
            Eksplorasi Halaman Belajar
          </button>
          <button
            class="others-drodpown-item inter-medium_small-normal"
            href="https://panduan.sekolah.mu/"
            target="_blank"
            @click.native="$emit('logEventAnalytic', 'halaman_belajar_panduan_click')"
          >
            <img
              class="activity-icon"
              src="https://cdn.sekolah.mu/assets/v2/menu-icon/bantuan.svg"
              alt="bantuan"
            />
            Panduan
          </button>
        </div>
      </template>
    </s-bottom-sheet>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import activityV2GeneralMixin from '@/mixins/activityV2/general'
import { mapState } from 'vuex'

export default {
  name: 'OthersBottomsheetMenu',
  mixins: [activityV2GeneralMixin],
  props: {
    isTanyamu: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    facilRoomId: {
      type: Number,
      default: 0
    },
    isTanyamuClosed: {
      type: Boolean,
      default: false
    },
    isShowTour: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShow: false
    }
  },
  components: {
    FontAwesomeIcon
  },
  computed: {
    ...mapState('user', ['userInfo', 'isLogin']),
    hasProgramStudyGroupActivity () {
      return this.$store.state.activityV2.validate.has_program_study_group_activity
    }
  },
  methods: {
    showShareModal () {
      this.$emit('showShareModal')
    },
    showTanyamuKelas () {
      this.$emit('toggleChatBox')
    },
    showProgramGroupActivityModal () {
      if (this.isLogin) {
        window.firebaseAnalytics.logEvent('users_belajar_click', {
          user_id: this.userInfo.id,
          user_is_admin: this.userInfo.is_admin,
          source: this.isMobile ? 'sekolahmu-webview' : 'sekolahmu-web'
        })
      }
      this.$store.commit('activityV2/TOGGLE_PROGRAM_GROUP_ACTIVITY_MODAL', true)
      this.$store.commit('activityV2/PROGRAM_GROUP_ACTIVITY_MODAL_TYPE', 'PROGRAM_CLASS')
      this.$emit('logEventAnalytic', 'kelompok_click')
    },
    toggleBottomsheet () {
      this.isShow = !this.isShow
      if (this.isShow) {
        this.$emit('logEventAnalytic', 'halaman_belajar_kebab_menu_click')
      }
    },
    // for future development
    initTour () {}
  }
}
</script>

<style scoped>
.others-drodpown-item {
  all: unset;
  display: block;
  width: 100%;
  padding: 12px 0;
  line-height: 24px;
}

.others-drodpown-item:not(:last-child) {
  border-bottom: solid 1px var(--color-neutral-500);
}
.others-drodpown-item .activity-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.activity-icon.ellipsis {
  color: var(--color-persian-blue-400);
  font-size: 18px;
}

.content-wrapper {
  padding: 16px;
}
</style>
