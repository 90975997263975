<template>
  <div class="activity-v2-sidebar position-fixed" :class="sidebarWrapperClasses">
    <button id="btn-close-sidebar" class="btn-close-sidebar position-absolute p-0" @click="toggleSidebar('halaman_belajar_exit_button_click')">
      <img src="@/assets/icons/icon_close_nav.svg" class="img-fluid icon-close-sidebar" />
    </button>
    <b-tabs v-model="activeTabIndex" content-class="p-3" fill>
      <b-tab title="Aktivitas" active @click="logEventAnalytic('AKTIVITAS_CLICK', undefined, 'activity')">
        <tab-activity />
      </b-tab>
      <b-tab :title-link-class="activityValidate.has_program_announcement ? 'd-block' : 'd-none'" @click="logEventAnalytic('pengumuman_click', undefined, 'announcement')">
        <template #title>
          Pengumuman
          <div class="d-inline-block align-middle rounded-circle notification-dot" v-if="unreadAnnouncementCount > 0"></div>
        </template>
        <div v-if="sidebarActiveTab === 'announcement'">
          <announcement-content v-if="isMobile" />
          <announcement-sidebar v-else />
        </div>
      </b-tab>
      <b-tab title="Penilaian" @click="logEventAnalytic('PENILAIAN_CLICK', undefined, 'assessment')">
        <div v-if="sidebarActiveTab === 'assessment'">
          <report-sidebar :is-mobile="isMobile" />
          <report v-if="isMobile" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import TabActivity from '@/components/activityV2/Sidebar/Tabs/TabActivity'
import activityV2GeneralMixin from '@/mixins/activityV2/general'
import activityV2SidebarMixin from '@/mixins/activityV2SidebarMixin'
import ReportSidebar from '@/components/activityV2/Report/ReportSidebar.vue'
import { isMobile, isIOS } from 'mobile-device-detect'
const AnnouncementSidebar = () => import('@/components/activityV2/Announcement/AnnouncementSidebar.vue')
const AnnouncementContent = () => import('@/components/activityV2/Announcement/AnnouncementContent.vue')
const Report = () => import('@/components/activityV2/Report')

export default {
  components: {
    TabActivity,
    AnnouncementSidebar,
    AnnouncementContent,
    ReportSidebar,
    Report
  },
  mixins: [activityV2GeneralMixin, activityV2SidebarMixin],
  data () {
    return {
      isVisible: false,
      isMobile,
      isIOS
    }
  },
  computed: {
    unreadAnnouncementCount () {
      return this.$store.state.program.unreadAnnouncementCount
    },
    userId () {
      return this.$store.state.storage.userId
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    sidebarWrapperClasses () {
      return {
        'sidebar-mobile-ios': this.isIOS,
        'width-tab-title-sidebar': !this.activityValidate.has_program_announcement
      }
    }
  },
  methods: {
    toggleNotificationDot () {
      this.isVisible = !this.isVisible
    },
    getUnreadAnnouncementsCount () {
      const documentPath = `/notifications/user_notifications/users/${this.userId}`
      const db = window.firestore.doc(documentPath)

      db.onSnapshot(documentSnapshot => {
        if (!documentSnapshot.empty) {
          documentSnapshot.data() && this.$store.commit('program/SET_UNREAD_ANNOUNCEMENT_COUNT', documentSnapshot.data().unread_count[this.activityValidate.program_id])
        } else {
          this.$store.commit('program/SET_UNREAD_ANNOUNCEMENT_COUNT', 0)
        }
      },
      error => {
        this.$store.commit('program/SET_UNREAD_ANNOUNCEMENT_COUNT', 0)
        console.error(error)
      })
    }
  },
  mounted () {
    if (!this.isMobile) {
      this.toggleSidebar()
    }
    this.getUnreadAnnouncementsCount()
  }
}
</script>
<style scoped>
.notification-dot {
  height: 8px;
  width: 8px;
  background-color: var(--color-crimson-500);
  margin: 4px 0;
}

.width-tab-title-sidebar >>> .nav-tabs .nav-link {
  width: 156px;
  text-align: center;
}

@media (max-width: 355px) {
  .width-tab-title-sidebar >>> .nav-tabs .nav-link {
  width: 150px;
  }
}

@media (max-width: 345px) {
  .width-tab-title-sidebar >>> .nav-tabs .nav-link {
  width: 145px;
  }
}

@media (max-width: 335px) {
  .width-tab-title-sidebar >>> .nav-tabs .nav-link {
  width: 140px;
  }
}

>>> .nav-tabs {
  border-bottom: none;
  padding: 8px;
  max-width: 296px;
}

>>> .nav-tabs .nav-link {
  border: none;
  text-align: center;
  padding: 8px;
  color: var(--color-ui-text-secondary);
  font-family: var(--ff-primary);
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 359px) {
  >>> .nav-tabs .nav-link {
    font-size: 14px;
  }
}

>>> .nav-tabs .active {
  position: relative;
  color: var(--color-persian-blue-500);
}

>>> .nav-tabs .active::before {
  content: "";
  position: absolute;
  display: block;
  width: calc(100% - 16px);
  bottom: 0;
  height: 4px;
  background-color: var(--color-persian-blue-500);
  border-radius: 5px;
}

.activity-v2-sidebar {
  width: 100%;
  max-width: 100%;
  right: -100%;
  top: 0;
  height: 100%;
  background-color: var(--color-neutral-100);
  -webkit-transition: right 0.3s ease-in-out;
  -o-transition: right 0.3s ease-in-out;
  transition: right 0.3s ease-in-out;
  z-index: 13;
}

@media screen and (min-width: 375px) {
  >>> .nav-tabs {
    max-width: 350px;
  }
}

@media (min-width: 500px) {
  .activity-v2-sidebar {
    max-width: 378px;
  }
}

.activity-v2-sidebar >>> .tab-content {
  max-height: 100vh;
  overflow: auto;
  padding-bottom: 60px!important;
}

.sidebar-mobile-ios >>> .tab-content {
  padding-bottom: 170px!important;
}

.btn-close-sidebar {
  border: none;
  background-color: var(--color-neutral-100);
  right: 8px;
  top: 15px;
  z-index: 2;
  width: 24px;
}

.btn-close-sidebar:active,
.btn-close-sidebar:focus {
  outline: none;
}

.icon-close-sidebar {
  width: 16px;
  height: 16px;
}

>>> .topic-title {
  line-height: 24px;
}

>>> .topic-addon-list::before {
  position: absolute;
  content: '';
  background-color: var(--color-eucalyptus-500);
  width: 6px;
  height: calc(100% - 32px);
  border-radius: 10px;
  top: 0;
  left: 0;
}

>>> .toggle-description-icon {
  filter: invert(14%) sepia(57%) saturate(5710%) hue-rotate(241deg) brightness(83%) contrast(104%);
}

>>> .meta-info {
  line-height: 17px;
}

>>> .btn-enroll-addon {
  min-width: 132px;
}
</style>
