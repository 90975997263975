import { isMobile } from 'mobile-device-detect'

const router = [
  {
    path: '/halaman-orang-tua/dokumen-panduan',
    name: 'GuideDocuments',
    meta: {
      headerTitle: 'Data Pendidikan',
      layout: isMobile ? 'blank' : 'default',
      page: 'GuideDocuments',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/halaman-orang-tua/child-profile',
    name: 'ChildProfile',
    meta: {
      headerTitle: 'Data Pendidikan',
      layout: isMobile ? 'blank' : 'default',
      page: 'ChildProfile',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/halaman-orang-tua/parent-profile',
    name: 'ParentProfile',
    meta: {
      headerTitle: 'Data Pendidikan',
      layout: isMobile ? 'blank' : 'default',
      page: 'ParentProfile',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-ayah',
    name: 'ParentProfile',
    meta: {
      headerTitle: 'Data Pendidikan',
      layout: isMobile ? 'blank' : 'default',
      page: 'ParentDetails',
      section: 'Father',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-ibu',
    name: 'ParentProfile',
    meta: {
      headerTitle: 'Data Pendidikan',
      layout: isMobile ? 'blank' : 'default',
      page: 'ParentDetails',
      section: 'Mother',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-wali',
    name: 'ParentProfile',
    meta: {
      headerTitle: 'Data Pendidikan',
      layout: isMobile ? 'blank' : 'default',
      page: 'ParentDetails',
      section: 'Guardian',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-ayah/edit',
    name: 'FatherProfileEdit',
    meta: {
      headerTitle: 'Ubah Data Identitas Ayah',
      layout: isMobile ? 'blank' : 'default',
      page: 'ParentEdit',
      section: 'Father',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-ibu/edit',
    name: 'MotherProfileEdit',
    meta: {
      headerTitle: 'Ubah Data Identitas Ibu',
      layout: isMobile ? 'blank' : 'default',
      page: 'ParentEdit',
      section: 'Mother',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-wali/edit',
    name: 'GuardianProfileEdit',
    meta: {
      headerTitle: 'Ubah Data Identitas Wali',
      layout: isMobile ? 'blank' : 'default',
      page: 'ParentEdit',
      section: 'Guardian',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-utama',
    name: 'ChildDetail',
    meta: {
      headerTitle: 'Data Pendidikan',
      layout: isMobile ? 'blank' : 'default',
      page: 'ChildDetail',
      tab: 'data-utama',
      tabComponent: 'TabDataUtama',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-utama/edit',
    name: 'ChildDetail',
    meta: {
      headerTitle: 'Data Utama',
      layout: isMobile ? 'blank' : 'default',
      page: 'StudentPrimaryDetailEdit',
      tab: 'data-utama',
      tabComponent: 'TabDataUtama',
      usingMobileHeader: false,
      hasBackButtonMobile: true,
      noMobileMenu: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-utama/siblings',
    name: 'ChildDetail',
    meta: {
      headerTitle: 'Data Utama',
      layout: isMobile ? 'blank' : 'default',
      page: 'SiblingsEdit',
      tab: 'data-utama',
      tabComponent: 'TabDataUtama',
      usingMobileHeader: false,
      hasBackButtonMobile: true,
      noMobileMenu: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-tambahan',
    name: 'ChildDetail',
    meta: {
      headerTitle: 'Data Pendidikan',
      layout: isMobile ? 'blank' : 'default',
      page: 'ChildDetail',
      tab: 'data-tambahan',
      tabComponent: 'TabDataTambahan',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-tambahan/edit/data-alamat/',
    name: 'EditAdressData',
    meta: {
      headerTitle: 'Ubah Data Alamat',
      layout: isMobile ? 'blank' : 'default',
      page: 'EditAdressData',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-tambahan/edit/data-periodik/',
    name: 'EditPeriodicData',
    meta: {
      headerTitle: 'Ubah Data Periodik',
      layout: isMobile ? 'blank' : 'default',
      page: 'EditPeriodicData',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-tambahan/tambah/data-kesejahteraan/',
    name: 'EditProsperityCard',
    meta: {
      headerTitle: 'Tambah Data Kesejahteraan',
      layout: isMobile ? 'blank' : 'default',
      page: 'EditProsperityCard',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-tambahan/edit/data-kesejahteraan/',
    name: 'EditProsperityCard',
    meta: {
      headerTitle: 'Ubah Data Kesejahteraan',
      layout: isMobile ? 'blank' : 'default',
      page: 'EditProsperityCard',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-tambahan/tambah/data-prestasi/',
    name: 'EditAchievementCard',
    meta: {
      headerTitle: 'Tambah Data Prestasi',
      layout: isMobile ? 'blank' : 'default',
      page: 'EditAchievementCard',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-tambahan/edit/data-prestasi/',
    name: 'EditAchievementCard',
    meta: {
      headerTitle: 'Ubah Data Prestasi',
      layout: isMobile ? 'blank' : 'default',
      page: 'EditAchievementCard',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-sekolah',
    name: 'SchoolDetail',
    meta: {
      headerTitle: 'Data Pendidikan',
      layout: isMobile ? 'blank' : 'default',
      page: 'ChildDetail',
      tab: 'data-sekolah',
      tabComponent: 'TabSchoolData',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  },
  {
    path: '/data-pendidikan/profil-anak/:childId/data-sekolah/edit',
    name: 'EditSchoolDetail',
    meta: {
      headerTitle: 'Ubah Data Sekolah',
      layout: isMobile ? 'blank' : 'default',
      page: 'InternalSchoolEdit',
      usingMobileHeader: true,
      hasBackButtonMobile: true
    },
    component: () => import(/* webpackChunkName: "ParentGuide" */ '@/views/parentPage/ParentGuide.vue')
  }
]

export default router
