<template>
  <div
    class="chat-popup"
    id="myForm"
  >

    <div class="chat">
      <div class="chat-header">
        <div class="profile">
          <div class="left">
            <h2 @click.prevent="gotoTanyamu">Program: {{currentChatRoom.name?currentChatRoom.name:'...'}}</h2>
            <span>{{ participantNamesHeader==''?'...':participantNamesHeader }}</span>
          </div>
          <div class="right">
            <img
              src="../../assets/icons/info_icon.svg"
              alt="info_icon"
              @click.prevent="toggleParticipantBox"
              class="icon icon-info"
            >
            <img
              src="../../assets/icons/chevron_down_2.svg"
              alt="chevron_down_icon"
              @click.prevent="closeChatBox"
              class="icon"
            >
          </div>
        </div>
      </div>

      <div
        class="chat-box"
        id="chat-messages"
      >

        <div v-if="!isLoading">
          <div
            v-if="loadingMoreMessage"
            style="text-align: center; margin-top: 2px;"
          >
            <b-spinner
              style="width: 20px; height: 20px;"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
          <div
            v-for="(comment, index) in comments"
            :key="index"
          >

            <div
              class="chat-r"
              v-if="parseInt(comment.email) === userId"
            >
              <div class="sp"></div>
              <div class="mess mess-r">
                <!-- <p>
                  {{ comment.message }}
                </p> -->
                <div style="max-width: 255px;">
                  <file-detector
                    :comment="comment"
                    :fromFloating="true"
                    :optionBoxTop="((index === (comments.length - 1)) && (index !== 0) )? `-74px` : `15px`"
                    :optionBoxRight="`-20px`"
                    :optionChevronTop="`-2px`"
                    :optionChevronRight="`-20px`"
                    @deleteMessage="deleteMessage"
                    @replyMessage="showReplyMessage"
                  ></file-detector>
                </div>
                <div class="check">
                  <span>{{ getDate(comment.timestamp)!=getDate(new Date())?getDate(comment.timestamp)+', ':null }} {{ getTime(comment.timestamp) }}</span>
                </div>
              </div>
            </div>

            <div
              class="chat-l"
              v-if="parseInt(comment.email) !== userId"
            >
              <div class="mess mess-l">
                <p style="font-size: small; font-weight: bolder; margin-bottom: 5px;">{{ comment.username }}</p>
                <!-- <p>
                  {{ comment.message }}
                </p> -->
                <div style="max-width: 255px;">
                  <file-detector
                    :comment="comment"
                    :fromFloating="true"
                    :optionBoxTop="`-5px`"
                    :optionBoxRight="`-60px`"
                    :optionChevronTop="`-28px`"
                    :optionChevronRight="`-20px`"
                    @deleteMessage="deleteMessage"
                    @replyMessage="showReplyMessage"
                  ></file-detector>
                </div>
                <div class="check">
                  <span>{{ getDate(comment.timestamp)!=getDate(new Date())?getDate(comment.timestamp)+', ':null }} {{ getTime(comment.timestamp) }}</span>
                </div>
              </div>
              <div class="sp"></div>
            </div>

          </div>
        </div>

        <div
          v-if="isLoading"
          style="text-align: center; margin-top: 40px;"
        >
          <b-spinner
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </div>

      </div>
      <transition name="slide-fade">
        <div
          class="participant-list"
          v-show="openParticipantBox"
        >
          <h2>Group Member</h2>
          <div
            v-for="(participant, index) in dataParticipant.participants"
            :key="index"
          >
            <div class="participant-card">
              <img
                :src="participant.avatar_url"
                alt="avatar"
                class="participant-avatar"
              >
              <h5 class="participant-username">{{ participant.username }}</h5>
            </div>
          </div>
        </div>
      </transition>

      <div class="chat-footer" style="position: relative;">
        <!-- <img src="../../assets/icons/attach_2.svg" alt="attach_icon" class="icon"> -->
        <div class="reply-message-container" v-if="isReplyingComment">
          <div class="reply-comment-body">
            <FileDetectorReplyComment
              :replyComment="selectedReplyComment"
              @closeReplyMessage="closeReplyMessage"
            />
          </div>
        </div>
        <form @submit.prevent="sendMessage">
          <input
            @focus="openParticipantBox = false"
            v-model="typedMessage"
            type="text"
            placeholder="Tulis pesan..."
            class="text-input"
          >
        </form>
        <img
          @click.prevent="sendMessage"
          src="../../assets/icons/send_icon.svg"
          alt="send_icon"
          class="icon"
        >
      </div>
    </div>

    <b-modal
      v-model="isQiscusRoomFullModal"
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <QiscusRoomFullErrMessage
        @closeModal="closeQiscusFullRoomModal"
      />
    </b-modal>
  </div>
</template>
<script>
import axiosServer from '@/configs/axiosServer'
// import QiscusCore from 'qiscus-sdk-core'
import moment from 'moment'
import FileDetector from '@/components/tanyamu/FileDetector'
import FileDetectorReplyComment from '@/components/tanyamu/FileDetectorReplyComment'
import QiscusRoomFullErrMessage from '@/components/validator/QiscusRoomFullErrMessage'
// import axios from 'axios'

export default {
  name: 'FloatingChatBox',
  data () {
    return {
      initRoomId: null,
      isLoading: false,
      loadingMessage: '',
      // qiscus: null,
      initMessage: '',
      // comments: [],
      rooms: [],
      // currentChatRoom: {
      //   name: ''
      // },
      loadingMoreMessage: false,
      typedMessage: '',
      dataParticipant: [],
      participantNamesHeader: '',
      openParticipantBox: false,
      isQiscusRoomFull: false,
      isQiscusRoomFullModal: false,
      selectedDeleteMessage: {
        room_id: '',
        chat_id: ''
      },
      selectedReplyComment: null,
      isReplyingComment: false
    }
  },
  components: {
    FileDetector,
    FileDetectorReplyComment,
    QiscusRoomFullErrMessage
  },
  props: ['facilRoomId'],
  methods: {
    showReplyMessage (comment_data) {
      this.selectedReplyComment = comment_data
      this.isReplyingComment = true
    },
    closeReplyMessage () {
      this.selectedReplyComment = null
      this.isReplyingComment = false
    },
    async deleteMessage (room_id, chat_id) {
      try {
        const { data } = await axiosServer({
          url: '/chat/messages/',
          method: 'DELETE',
          headers: {
            Authorization: this.$store.state.storage.accessToken
          },
          data: {
            unique_ids: [chat_id]
          }
        })
        if (data.status === 200) {
          this.fetchComments(room_id)
        } else {
          console.log(data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    closeChatBox () {
      this.$emit('toggleChatbox')
      setTimeout(() => {
        this.$store.commit('tanyamu/CHANGE_CURRENT_CHAT_ROOM_FLOATING', { id: '', name: '' })
        this.$store.commit('tanyamu/CHANGE_COMMENTS_FLOATING', [])
      }, 500)
    },
    gotoTanyamu () {
      this.$emit('gotoTanyamu')
    },
    toggleParticipantBox () {
      this.openParticipantBox = !this.openParticipantBox
      // this.$nextTick(this.scrollToEnd.bind(this))
      // console.log(this.openParticipantBox);
    },
    async askProgramFacilsFloatingChatBox () {
      try {
        const { data } = await axiosServer({
          url: '/chat/participant/',
          method: 'post',
          headers: {
            Authorization: this.token
          },
          data: {
            room_id: this.facilRoomId
          }
        })
        if (data.status === 200) {
          if (data.data.results.room) {
            // this.initRoomId = data.data.results.room.room_id
            this.initRoomId = this.facilRoomId
          } else {
            this.initRoomId = this.facilRoomId
          }
        } else if (data.status === 400 && data.message.includes(`room participants can't more than`)) {
          this.isQiscusRoomFull = true
          this.isQiscusRoomFullModal = true
        } else {
          this.initRoomId = this.facilRoomId
          this.$store.dispatch('storage/checkToken', data)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async sendMessage () {
      if (
        this.typedMessage.trim().length
        // (this.uploadedImage.url && this.uploadedImage.fileName)
      ) {
        // this.canType = true
        try {
          if (this.isReplyingComment) {
            let payloadReply = {
              replied_comment_id: this.selectedReplyComment.id,
              replied_comment_message: this.selectedReplyComment.message,
              replied_comment_sender_email: this.selectedReplyComment.email,
              replied_comment_sender_username: this.selectedReplyComment.username,
              replied_comment_type: this.selectedReplyComment.type,
              replied_comment_payload: this.selectedReplyComment.payload,
              text: this.typedMessage
            }
            await this.qiscus.sendComment(
              this.currentChatRoom.id,
              this.typedMessage,
              new Date().toString(),
              'reply',
              JSON.stringify(payloadReply)
            )
          } else {
            await this.qiscus.sendComment(
              this.currentChatRoom.id,
              this.typedMessage
            )
          }

          await this.fetchComments(parseInt(this.initRoomId))
          this.closeReplyMessage()
          this.typedMessage = ''
        } catch (error) {
          console.log(error)
        }
      }
    },
    async fetchComments (id) {
      try {
        this.comments = await this.qiscus.loadComments(id, {
          limit: 20
        })
        // this.comments.reverse()
        this.comments = this.comments.sort(function (a, b) {
          return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        })
        // this.currentChatRoom = this.rooms.filter(room => room.id === id)[0]
        this.currentChatRoom = this.rooms

        if (this.currentChatRoom.name.length > 18) {
          this.currentChatRoom.name = this.currentChatRoom.name.substring(0, 18) + `...`
        }
        this.isLoading = false

        this.$nextTick(this.scrollToEnd.bind(this))
      } catch (error) {
        console.log(error)
      }
    },
    async fetchRooms () {
      try {
        this.rooms = await this.qiscus.getRoomById(parseInt(this.facilRoomId))
      } catch (error) {
        console.log(error)
      }
    },
    async fetchParticipant (id) {
      this.dataParticipant = await this.qiscus.getParticipants(id, 1, 100)

      let participantNames = []
      this.dataParticipant.participants.forEach(item => {
        participantNames.push(item.username)
      })

      this.participantNamesHeader = participantNames.join(', ')
      if (this.participantNamesHeader.length > 30) {
        this.participantNamesHeader = this.participantNamesHeader.substring(0, 30) + `...`
      }
    },
    async loadMore (e) {
      if (
        e.target.scrollTop === 0 &&
        this.comments[0].comment_before_id !== 0
      ) {
        this.loadingMoreMessage = true
        try {
          if (this.comments[0]) {
            const loadedComments = await this.qiscus.loadComments(this.currentChatRoom.id, {
              last_comment_id: this.comments[0].id,
              limit: 20
            })

            this.comments = [...this.comments.reverse(), ...loadedComments]
            this.comments.sort(function (a, b) {
              return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
            })
          } else {
            this.fetchComments(this.currentChatRoom.id)
          }
        } catch (error) {
          console.log(error)
        }
        this.loadingMoreMessage = false
      }
    },
    scrollToEnd () {
      const container = this.$el.querySelector('#chat-messages')
      if (container.scrollHeight) {
        container.scrollTop = container.scrollHeight
        container.addEventListener('scroll', this.loadMore)
      }
    },
    getTime (value) {
      return moment(value).format('LT')
    },
    getDate (value) {
      return moment(value).format('MMM Do YYYY')
    },
    closeQiscusFullRoomModal () {
      this.isQiscusRoomFullModal = false
    }
  },
  computed: {
    qiscus () {
      return this.$store.state.tanyamu.qiscus
    },
    token () {
      return this.$store.state.storage.accessToken
    },
    userId () {
      return this.$store.state.storage.userId
    },
    name () {
      return this.$store.state.storage.name
    },
    avatar () {
      return this.$store.state.storage.avatar
    },
    comments: {
      get () {
        return this.$store.state.tanyamu.commentsFloating
      },
      set (value) {
        this.$store.commit('tanyamu/CHANGE_COMMENTS_FLOATING', value)
      }
    },
    currentChatRoom: {
      get () {
        return this.$store.state.tanyamu.currentChatRoomFloating
      },
      set (value) {
        this.$store.commit('tanyamu/CHANGE_CURRENT_CHAT_ROOM_FLOATING', value)
      }
    }
  },
  async created () {
    await this.askProgramFacilsFloatingChatBox()
    await this.fetchRooms()
    if (isNaN(this.initRoomId)) {
      console.log(this.initRoomId, 'error nan initRoomId')
    } else if (this.initRoomId) {
      await this.fetchComments(parseInt(this.initRoomId))
      await this.fetchParticipant(this.currentChatRoom.unique_id)
    }
  }
}
</script>
<style scoped>
/* Styling ChatBox */
.chat-popup {
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 10;
}

.chat {
  display: flex;
  flex-direction: column;
  height: 398px;
  width: 330px;
  background: #f7f6f2;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
}

.profile {
  width: 100%;
  height: 54px;
  background: var(--color-neutral-100);
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0px 10px;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #e0e0e0;
}

.profile h2 {
  font-family: "hind_gunturbold";
  margin-bottom: 0;
  padding-bottom: 0;
  vertical-align: bottom;
  color: var(--color-persian-blue-900);
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  cursor: pointer;
}

.profile span {
  font-family: "hind_gunturregular";
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 13px;
  font-weight: 92%;
  letter-spacing: 0.289px;
}

.right .icon {
  display: inline-block;
  width: 25px;
  margin-left: 8px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.profile .left {
  flex: 1;
}

.chat-box {
  background: #f7f6f2;
  background-attachment: fixed;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: scroll;
  flex: 1;
}

.chat-r {
  display: flex;
}

.chat-r .sp {
  flex: 1;
  min-width: 20px;
}

.chat-l {
  display: flex;
}

.chat-l .sp {
  flex: 1;
  min-width: 20px;
}

.chat-box .mess {
  font-family: "hind_gunturregular";
  max-width: 300px;
  min-width: 75px;
  padding: 10px;
  margin: 10px 0px;
}
.chat-box .mess-l {
  background: var(--color-neutral-100);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 10px;
  padding-right: 28px;
}
.chat-box .mess p {
  word-break: break-word;
  /* font-size: 18px; */
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.7);
}
.chat-box .mess-r {
  background: #ffea9e;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 10px;
  padding-right: 28px;
}

.chat-box .check {
  float: right;
}

.chat-box .check span {
  color: #888;
  font-size: 12px;
  font-weight: 700px;
}

.participant-list {
  font-family: "hind_guntursemibold";
  height: 290px;
  width: 100%;
  background: var(--color-neutral-100);
  overflow-y: scroll;
  padding: 15px 20px;
}

.participant-list h2 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  vertical-align: bottom;
  color: var(--color-persian-blue-900);
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid #bebebe;
}

.participant-card {
  display: flex;
  margin-bottom: 10px;
}

.participant-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 15px;
}

.participant-username {
  font-size: 14px;
  color: #4f4f4f;
  font-weight: bold;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.chat-footer {
  background: var(--color-neutral-100);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.chat-footer form {
  width: 100%;
}

.chat-footer input {
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 15px;
  width: 95%;
  width: -moz-available;
  height: 30px;
  padding: 5px 15px;
  margin: 5px 2px;
  margin-left: 7px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.chat-footer img {
  margin: 5px 7px;
  cursor: pointer;
}
.reply-message-container{
  padding: 10px;
}
.reply-message-container{
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  background: var(--color-neutral-100);
  z-index: 10;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(398px);
  opacity: 1;
}
</style>
