<template>
  <div id="otp-verification">
    <b-alert
      :show="dismissCountDown"
      dismissible
      fade
      :variant="alertVariant"
      @dismiss-count-down="countDownChanged"
      class="save-alert"
    >
      {{ alertMessage }}
    </b-alert>
    <transition name="fade">
      <div class="snackbar-verification-container" v-if="isShowUnfreezeVerificationSnackbar">
        <snackbar-verification @triggerVerification="openOtpVerification" />
      </div>
    </transition>
    <b-modal
      v-model="isOpenVerificationInvitation"
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      size="modal-304"
      content-class="modal-content-semi_round"
    >
      <verification-invitation
        @closeModal="isOpenVerificationInvitation = false"
        @openOtpModal="isOpenVerificationInvitation = false, isOpenVerificationOtp = true"
      />
    </b-modal>
    <b-modal
      v-model="isOpenVerificationOtp"
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      size="modal-login"
      content-class="modal-content-semi_round"
    >
      <base-otp-verification
        ref="baseOtpVerification"
        @doneVerification="isOpenVerificationOtp = false, showVerificationSuccessAlert()"
        @skipVerification="isOpenVerificationOtp = false"
        :hide-skip-verification="isHideSkipVerification"
      />
    </b-modal>
  </div>
</template>
<script>
import BaseOtpVerification from '@/components/otpUserVerification/BaseOtpVerification'
import VerificationInvitation from '@/components/otpUserVerification/VerificationInvitation'
import SnackbarVerification from '@/components/otpUserVerification/SnackbarVerification.vue'

export default {
  name: 'OtpVerification',
  components: {
    BaseOtpVerification,
    VerificationInvitation,
    SnackbarVerification
  },
  data () {
    return {
      dismissCountDown: 0,
      alertMessage: 'Selamat! akunmu telah terverifikasi',
      alertVariant: 'success'
    }
  },
  computed: {
    isOpenVerificationInvitation: {
      get () {
        return this.$store.state.user.isOpenVerificationInvitation
      },
      set (val) {
        this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_INVITATION', val)
      }
    },
    isOpenVerificationOtp: {
      get () {
        return this.$store.state.user.isOpenVerificationOtp
      },
      set (val) {
        this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_OTP', val)
      }
    },
    isHideSkipVerification: {
      get () {
        return this.$store.state.user.isHideSkipVerification
      },
      set (val) {
        this.$store.commit('user/CHANGE_IS_HIDE_SKIP_VERIFICATION', val)
      }
    },
    isVerificationMandatory: {
      get () {
        return this.$store.state.user.isVerificationMandatory
      },
      set (val) {
        this.$store.commit('user/CHANGE_IS_VERIFICATION_MANDATORY', val)
      }
    },
    isShowUnfreezeVerificationSnackbar: {
      get () {
        return this.$store.state.user.isShowUnfreezeVerificationSnackbar
      },
      set (val) {
        this.$store.commit('user/CHANGE_IS_SHOW_UNFREEZE_SNACKBAR', val)
      }
    }
  },
  methods: {
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showVerificationSuccessAlert () {
      this.dismissCountDown = 3
    },
    openOtpVerification () {
      this.isHideSkipVerification = true
      this.isOpenVerificationInvitation = true
    }
  }
}
</script>
<style scoped>
:deep(.modal-body) {
  padding: 24px;
}
.snackbar-verification-container{
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 552px;
  z-index: 200;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.save-alert{
  width: 34%;
  min-width: 500px;
  margin: auto;
  text-align: center;
  position: fixed;
  z-index: 20;
  top: 80px;
  left: calc(50% - 250px);
}
</style>
