<template>
  <div class="topic-addon">
    <topic-addon :data="data" />
    <div class="topic-addon-list position-relative pl-3 pb-3" role="tablist">
      <div v-for="(topicAddon, topicAddonIndex) in data.chapters" :key="topicAddon.id">
        <set-of-addon-topics
          :data="topicAddon"
          :topic-index="topicAddonIndex"
          :is-linier="isLinier"
        />
      </div>
    </div>
  </div>
</template>
<script>
import TopicAddon from '@/components/activityV2/Sidebar/TopicAddon'
import SetOfAddonTopics from '@/components/activityV2/Sidebar/Tabs/TabActivity/SetOfAddonTopics'

export default {
  name: 'TabActivitySectionAddonTopic',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    topicIndex: Number,
    isLinier: Boolean
  },
  components: {
    SetOfAddonTopics,
    TopicAddon
  }
}
</script>
