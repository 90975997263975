const objectToQueryParams = async (objParam) => {
  const queries = Object.keys(objParam).reduce((acc, key) => {
    const el = objParam[key]

    // both conditions below are objective to filter any elements that have values
    if (Array.isArray(el) && el.length > 0) {
      // push all strings that joined by comma when there are an array values
      acc.push(encodeURIComponent(key) + '=' + encodeURIComponent(el.join()))
    } else if (
      !Array.isArray(el) &&
      el !== '' &&
      el !== null &&
      el !== undefined
    ) {
      // push string with `key=value` pattern
      acc.push(encodeURIComponent(key) + '=' + encodeURIComponent(el))
    }
    return acc
  }, [])

  return queries.length > 0 ? '?' + queries.join('&') : ''
}

export default objectToQueryParams
