<template>
  <div id="search-activity" class="search-activity-sticky">
    <div class="search-activity">
      <s-searchbar
        placeholder="Cari aktivitas"
        class="w-100"
        :use-dropdown="false"
        @input="onSearch"
      />
      <s-button
        id="search-activity-filter-btn"
        @click="toggleShowFilter"
        :class="filterBtnClass"
        variant="secondary"
        class="filter-btn"
      >
        <img
          v-if="isFilterBtnUsingImage"
          src="@/assets/icons/outlined-filter.svg"
          alt="filter-icon"
          :class="filterBtnClass"
        />
        <div class="total-selected" v-else>
          {{ filterData.currentSelectedTotal }}
        </div>
      </s-button>
    </div>
    <web-filter
      v-if="filterData.isShowFilter && !isMobile"
      :filter-data="filterData"
      @applyFilter="onApplyFilter"
    />
    <mobile-filter
      v-else-if="isMobile"
      :filter-data="filterData"
      @applyFilter="onApplyFilter"
    />
  </div>
</template>

<script>
import deviceDetector from 'mobile-device-detect'
import WebFilter from './WebFilter.vue'
import MobileFilter from './MobileFilter.vue'
import debounce from '@/functions/debounce'
import activityFilterMixin from '@/mixins/activityV2/filterActivity.js'

export default {
  name: 'SectionSearchActivity',
  components: {
    WebFilter,
    MobileFilter
  },
  mixins: [activityFilterMixin],
  data () {
    return {
      searchValue: null,
      filterData: {
        selected: [],
        isShowFilter: false,
        currentSelectedTotal: 0
      },
      isMobile: deviceDetector.isMobile
    }
  },
  computed: {
    isFilterBtnUsingImage () {
      return this.filterData.currentSelectedTotal < 1
    },
    activitySearchParams () {
      return this.$store.state.activityV2.activitySearchParams
    },
    filterBtnClass () {
      if (!this.filterData.isShowFilter && this.filterData.selected.length === 0) {
        return 'grey-btn'
      }
      return ''
    }
  },
  methods: {
    toggleShowFilter () {
      this.sendClickEventTracker('hb_aktivitas_icon_filter_click')
      this.filterData.isShowFilter = !this.filterData.isShowFilter
    },
    onSearch: debounce(function (value) {
      this.sendSearchValueEventTracker('hb_aktivitas_search_type', value)
      const searchParams = {
        name: value,
        activityCategories: this.filterData.selected
      }
      this.$store.commit('activityV2/SET_ACTIVITY_SEARCH_PARAMS', searchParams)
    }, 1000),
    onApplyFilter () {
      const searchParams = {
        name: this.activitySearchParams.name,
        activityCategories: this.filterData.selected
      }
      this.$store.commit('activityV2/SET_ACTIVITY_SEARCH_PARAMS', searchParams)
    }
  }
}
</script>

<style scoped>
.search-activity {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0 0 16px 0;
}

.filter-btn {
  border-radius: 10px;
  min-width: 44px;
  width: 44px;
}
.total-selected {
  background-color: var(--color-persian-blue-500);
  color: var(--color-neutral-100);
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.grey-btn {
  color: var(--color-neutral-500);
  border: 1px solid var(--color-neutral-500);
}
.grey-btn img {
  filter: var(--color-text-300-filter);
  border: none;
}
.search-activity-sticky {
  position: sticky;
  z-index: 2;
  top: -16px;
  background-color: var(--color-neutral-100);
}
::v-deep .custom-checkbox {
  margin: 16px 0;
}
::v-deep .s-searchbar-icon {
  top: calc(50% - 13px)
}
::v-deep .s-searchbar .s-searchbar-input {
  height: 44px;
}
</style>
