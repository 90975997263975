const routes = [
  {
    path: '/sertifikat/:id',
    name: 'Sertifikat Regular',
    meta: {
      title: 'Sertifikat',
      layout: 'blank'
    },
    component: () => import(/* webpackChunkName: 'sertifikatPage' */ '../views/sertifikat/ProgramCertificate.vue')
  },
  {
    path: '/rapor/:program_user_id',
    name: 'Rapor Program Old',
    meta: {
      layout: 'blank'
    },
    // old rapor route should redirect to new laporan-belajar
    redirect: to => {
      return { path: '/laporan-belajar/:program_user_id' }
    }
  },
  {
    path: '/rapor/:program_user_id/:report_type',
    name: 'Rapor Program Type Old',
    meta: {
      layout: 'blank'
    },
    // old rapor route should redirect to laporan-belajar
    redirect: to => {
      return { path: '/laporan-belajar/:program_user_id/:report_type' }
    }
  },
  {
    path: '/laporan-belajar/:program_user_id',
    name: 'Rapor Program',
    meta: {
      layout: 'blank'
    },
    component: () => import('../views/report/ProgramReport.vue')
  },
  {
    path: '/laporan-belajar/:program_user_id/:report_type',
    name: 'Rapor Program Type',
    meta: {
      layout: 'blank'
    },
    component: () => import('../views/report/ProgramReport.vue')
  },
  {
    path: '/transkrip/:transcriptId',
    name: 'Transkrip Program',
    meta: {
      layout: 'blank'
    },
    redirect: () => {
      return { name: 'rapor-murid' }
    }
  },
  {
    path: '/rapor-murid/:transcriptId',
    name: 'rapor-murid',
    meta: {
      layout: 'blank'
    },
    component: () => import('../views/report/ProgramTranscriptV2.vue')
  }
]

export default routes
