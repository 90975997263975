import Vue from 'vue'
import Vuex from 'vuex'

import program from './modules/program'
import user from './modules/user'
import activity from './modules/activity'
import activityV2 from './modules/activityV2'
import prakerja from './modules/prakerja'
import login from './modules/login'
import certificate from './modules/certificate'
import storage from './modules/storage'
import subnavbar from './modules/subnavbar'
// import portofolio from './modules/portofolio'
import portofolioV2 from './modules/portofolioV2'
import institution from './modules/institution'
import institutionList from './modules/institutionList'
import profession from './modules/profession'
import project from './modules/project'
import payment from './modules/payment'
import paymentHistory from './modules/paymentHistory'
import notification from './modules/notification'
import programmu from './modules/programmu'
import receipt from './modules/receipt'
import reflection from './modules/refleksi'
import tanyamu from './modules/tanyamu'
import voucher from './modules/voucher'
import cart from './modules/cart'
import checkout from './modules/checkout'
import funReport from './modules/funReport'
import analytics from './modules/analytics'
import educationPayment from './modules/educationPayment'
import bottomSheet from './modules/bottomSheet'
import snackbar from './modules/snackbar'
import quizV2 from './modules/quizV2'
import coachmark from './modules/coachmark'
import checkoutV5 from './modules/checkoutV5'
import loadingState from './modules/loadingState'
import mediaViewer from './modules/mediaViewer'
import bill from './modules/bill'
import voucherV5 from './modules/voucherV5'
import accountMenu from './modules/accountMenu'

import { isMobile } from 'mobile-device-detect'
import firebaseEventNames from '@/constants/firebaseEventNames'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    envBelajar: false,
    domainState: 'init', // init, loading, ready
    envPlatinum: false,
    platinumSubdomain: '',
    activeEnv: 'localhost',
    hostDomain: 'localhost',
    crossSubDomainSuffix: '.dev.sekolah.mu',
    userVerification: null,
    codeVerification: null,
    ogMeta: ['og:title', 'og:type', 'og:image', 'og:url', 'og:description'],
    term: 'class',
    isSMM: false
  },
  getters: {
    termText: (state) => {
      return state.term === 'class' ? 'Paket Kelas' : 'Paket Belajar'
    },
    activeEnvText: (state) => {
      return state.activeEnv
    },
    isLocalhost: () => location.hostname.includes('localhost'),
    isDev: () => process.env.VUE_APP_URL_SERVER.includes('dev'),
    isStaging: () => process.env.VUE_APP_URL_SERVER.includes('staging'),
    isSandbox: (_, getters) => getters.isDev || getters.isStaging
  },
  mutations: {
    SET_ENV_BELAJAR (state) {
      const isPrakerja = location.hostname.toLowerCase().includes('prakerja')
      state.envBelajar = !isPrakerja
    },
    SET_ACTIVE_ENVIRONMENT (state) {
      let hostName = location.hostname.trim().toLowerCase()
      // includes ironman remove after merged to develop
      if (hostName.includes('.dev.sekolah.mu') || hostName.includes('.ironman.sekolah.mu')) {
        state.activeEnv = 'development'
      } else if (hostName.includes('.staging.sekolah.mu')) {
        state.activeEnv = 'staging'
        state.crossSubDomainSuffix = '.staging.sekolah.mu'
      } else if (hostName.includes('.sekolah.mu')) {
        state.activeEnv = 'production'
        state.crossSubDomainSuffix = '.sekolah.mu'
      } else {
        state.activeEnv = 'localhost'
        state.crossSubDomainSuffix = '.dev.sekolah.mu'
      }
    },
    SET_ENV_PLATINUM (state) {
      const nonPlatinumSubdomain = ['www', 'belajar', 'prakerja', 'localhost', 'bayar']
      const currentSubDomain = location.hostname.toLowerCase().split('.')[0]
      state.isSMM = currentSubDomain === 'smm'

      const isPlatinum = !nonPlatinumSubdomain.includes(currentSubDomain)

      if (isPlatinum) {
        state.envPlatinum = isPlatinum
        state.platinumSubdomain = currentSubDomain
        this.commit('SET_DOMAIN_STATE', 'loading')
      } else {
        this.commit('SET_DOMAIN_STATE', 'ready')
      }
    },
    SET_DOMAIN_STATE (state, payload) {
      state.domainState = payload
    },
    SET_HOST_DOMAIN (state) {
      if (location.hostname.toLowerCase().includes('sekolah')) {
        state.hostDomain = 'sekolah.mu'
      }
    },
    SET_VERIFICATION (state, { verification, code }) {
      state.userVerification = verification
      state.codeVerification = code
    },
    SET_LOGIN_REDIRECTION (_, payload) {
      localStorage.setItem('redirect-login-path', payload.path)
      localStorage.setItem('redirect-login', payload.name)
      localStorage.setItem('is-redirected', payload.isRedirected ? 1 : 0)
    }
  },
  actions: {
    logEventTracker ({ state }, payload) {
      let eventObj = null
      const {
        module,
        key = '',
        isTrack = true,
        additionalField = {}
      } = payload
      const validKey = key.toUpperCase()
      const validModuleName = module.toUpperCase()
      switch (validModuleName) {
        case 'ADDON_ACTIVITY':
        case 'TOPIC_EXPLORATION':
          if (isTrack && state.user.isLogin && state.user.userInfo) {
            eventObj = {
              user_profession: state.user.userInfo.profession_name,
              user_year_level: state.user.userInfo.year_level_name,
              user_is_admin: state.user.userInfo.is_admin,
              user_id: state.user.userInfo.id,
              source: isMobile ? 'web-mobile' : 'web'
            }
          }
          break

        case 'ACTIVITY_V2':
          const platform = 'sekolahmu'
          if (isTrack && state.user.isLogin && state.user.userInfo) {
            eventObj = {
              platform,
              user_id: state.user.userInfo.id,
              user_profession: state.user.userInfo.profession_name,
              user_year_level: state.user.userInfo.year_level_name,
              program_id: state.activityV2.validate.program_id,
              source: isMobile ? `mweb_${platform}` : `web_${platform}`
            }
          }
          break

        case 'SEARCH':
          eventObj = {
            user_profession: state.user.userInfo?.profession_name,
            user_year_level: state.user.userInfo?.year_level_name,
            user_is_admin: state.user.userInfo?.is_admin || false,
            user_id: state.user.userInfo?.id || null,
            user_is_logged_in: state.user.isLogin,
            source: isMobile ? `mweb_${platform}` : `web_${platform}`
          }
          break

        default:
          break
      }
      if (eventObj && firebaseEventNames[validModuleName][validKey]) {
        window.firebaseAnalytics.logEvent(firebaseEventNames[validModuleName][validKey], {
          ...eventObj,
          ...additionalField
        })
      }
    }
  },
  modules: {
    program,
    user,
    activity,
    activityV2,
    prakerja,
    login,
    payment,
    certificate,
    storage,
    subnavbar,
    // portofolio,
    portofolioV2,
    institution,
    institutionList,
    profession,
    project,
    notification,
    paymentHistory,
    programmu,
    receipt,
    reflection,
    tanyamu,
    voucher,
    cart,
    checkout,
    funReport,
    analytics,
    educationPayment,
    bottomSheet,
    snackbar,
    quizV2,
    coachmark,
    checkoutV5,
    loadingState,
    mediaViewer,
    bill,
    voucherV5,
    accountMenu
  }
})
