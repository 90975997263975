import axiosServer from '../../configs/axiosServer'
import axiosServerGolang from '../../configs/axiosServerGolang'

const receipt = {
  namespaced: true,
  state: {
    regularReceipt: {},
    prakerjaReceipt: {}
  },
  mutations: {
    SET_REGULAR_RECEIPT (state, payload) {
      state.regularReceipt = payload
    },
    SET_PRAKERJA_RECEIPT (state, payload) {
      state.prakerjaReceipt = payload
    }
  },
  actions: {
    async getRegularReceipt ({ rootState, commit }, payload) {
      try {
        const { data } = await axiosServerGolang({
          url: `/payment/v4/detail/${payload}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        commit('SET_REGULAR_RECEIPT', data.data)
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async getPrakerjaReceipt ({ rootState, commit }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/payment/invoice_preview/${payload}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        commit('SET_PRAKERJA_RECEIPT', data.data)
        return data
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default receipt
