const routes = [
  {
    path: '/tukar-voucher',
    name: 'Tukar Voucher',
    meta: {
      title: 'Tukar Voucher',
      isLoginNeeded: true,
      module: 'payment'
    },
    component: () => import(/* webpackChunkName: 'tukarVoucher' */ '../views/payment/RedeemVoucher.vue')
  },
  {
    path: '/payment/thankyou/:id',
    name: 'Thankyou Page Payment',
    meta: {
      title: 'Proses Pembayaran',
      layout: 'checkout'
    },
    component: () => import(/* webpackChunkName: 'thankyouPage' */ '../views/payment/ThankyouPage.vue')
  },
  {
    path: '/payment/status/:id',
    name: 'PaymentStatus',
    meta: {
      title: 'Status Pembayaran'
    },
    component: () => import(/* webpackChunkName: 'PaymentStatus' */ '../views/paymentStatus/BasePaymentStatus.vue')
  },
  {
    path: '/daftar-pilihan',
    name: 'Daftar Pilihan',
    meta: {
      title: 'Daftar Pilihan',
      isLoginNeeded: true,
      module: 'payment'
    },
    component: () => import(/* webpackChunkName: 'DaftarPilihan' */ '../views/cart/CartV2')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    meta: {
      title: 'Checkout',
      layout: 'checkout'
    },
    component: () => import(/* webpackChunkName: 'checkout' */ '../views/checkout/BaseCheckout.vue')
  },
  {
    path: '/checkout/v5',
    name: 'Checkout V5',
    meta: {
      title: 'Checkout',
      layout: 'checkout'
    },
    component: () => import(/* webpackChunkName: 'checkout' */ '../views/CheckoutSummary/CheckoutSummary.vue')
  },
  {
    path: '/payment/hosted/:type',
    name: 'Payment',
    meta: {
      title: 'Payment'
    },
    component: () => import(/* webpackChunkName: 'Pembayaran' */ '../views/payment/HostedPaymentBase.vue')
  },
  {
    path: '/payment-hosted',
    name: 'PaymentHosted',
    meta: {
      title: 'Halaman Pembayaran'
    },
    component: () => import(/* webpackChunkName: 'HalamanPembayaran' */ '../views/paymentResponse/BasePaymentHosted.vue')
  },
  {
    path: '/payment-hosted/v5',
    name: 'PaymentHosted',
    meta: {
      title: 'Halaman Pembayaran',
      layout: 'checkout'
    },
    component: () => import(/* webpackChunkName: 'PaymentHostedV2' */ '../views/paymentResponse/BasePaymentHostedV2.vue')
  },
  {
    path: '/daftar-transaksi',
    name: 'Daftar Transaksi',
    meta: {
      title: 'Daftar Transaksi',
      isLoginNeeded: true,
      module: 'payment'
    },
    component: () => import(/* webpackChunkName: "TransactionPageV2" */ '../views/transaction/PurchaseListV2.vue')
  },
  // redirect old route detail-pembelian to new route detail-transaksi incase user still using old url
  {
    path: '/detail-pembelian/:id',
    redirect: '/detail-transaksi/:id'
  },
  {
    path: '/detail-transaksi/:id',
    name: 'TransactionDetail',
    meta: {
      title: 'Detail Transaksi',
      isLoginNeeded: true,
      module: 'payment',
      usingMobileHeader: true,
      hasBackButtonMobile: true,
      backButtonMobileLink: '/daftar-transaksi',
      headerTitle: 'Detail Transaksi'
    },
    component: () => import(/* webpackChunkName: "detailtransaksi" */ '../views/transaction/TransactionDetail.vue')
  },
  {
    path: '/detail-tagihan/:id',
    name: 'PaymentBillDetail',
    meta: {
      title: 'Detail Tagihan'
    },
    component: () => import(/* webpackChunkName: "detailtagihan" */ '../views/transaction/BillDetailV2.vue')
  }
]

export default routes
