<template>
  <div id="group-selection-content" class="group-selection-content">
    <div class="searchbar-wrapper">
      <s-searchbar
        id="group-selection-content-searchbar"
        class="group-selection-content-searchbar"
        :value="searchValue"
        :timeout="200"
        placeholder="Cari kelompok belajar kelas"
        :minCharacter="1"
        :fullwidth="true"
        :useDropdown="false"
        :disabled="false"
        cluster="s"
        @input="handleSearch"
        @focus="logEventTracker('kelompok_belajar_search')"
      />
    </div>
    <div v-if="isLoading" class="loading-wrapper">
      <template-loading />
    </div>
    <div v-else-if="groupList.length" class="group-item-wrapper mt-3">
      <group-item
        v-for="groupItem in groupList"
        :key="groupItem.id"
        :item="groupItem"
        :is-selected="selectedItem.id === groupItem.id"
        :is-mobile="isMobile"
        @select="handleSelect"
      />
    </div>
    <div v-else-if="groupList.length === 0" class="empty-wrapper">
      <img src="https://cdn.sekolah.mu/assets/v2/empty-state/pencarian-tidak-ditemukan-square.png" alt="empty">
      <h1 class="empty-title font-body-1-new">Pencarian Tidak Ditemukan</h1>
      <h3 class="empty-sub mb-0 font-paragraph-5">Kata kunci tidak sesuai.</h3>
      <h3 class="empty-sub mb-0 font-paragraph-5">Silakan coba kata kunci yang berbeda.</h3>
    </div>
  </div>
</template>

<script>
import GroupItem from '@/components/programmu/GroupSelection/GroupItem.vue'
import deviceDetector from 'mobile-device-detect'
import TemplateLoading from '@/components/Table/TemplateLoading.vue'
import { mapState } from 'vuex'

export default ({
  name: 'GroupSelectionContent',
  components: {
    GroupItem,
    TemplateLoading
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    groupList: {
      type: Array,
      default: () => ([])
    },
    selectedItem: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      searchValue: '',
      isMobile: deviceDetector.isMobile
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'isLogin']),
    firebaseAnalyticsObj () {
      return {
        user_id: this.userInfo.id,
        source: this.isMobile ? 'sekolahmu-webview' : 'sekolahmu-web'
      }
    }
  },
  methods: {
    handleSearch (val) {
      this.searchValue = val
      this.$emit('search', val)
    },
    handleSelect (val) {
      this.$emit('select', val)
    },
    logEventTracker (event) {
      if (this.isLogin) {
        window.firebaseAnalytics.logEvent(event, this.firebaseAnalyticsObj)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.group-item-wrapper {
  max-height: 304px;
  min-height: 304px;
  scrollbar-width: thin;
  overflow-y: auto;
  padding-bottom: 16px;
}

.loading-wrapper {
  max-height: 304px;
  min-height: 304px;
  scrollbar-width: thin;
  overflow-y: auto;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 36px;
  padding-bottom: 36px;

  .empty-title {
    color: #001E65;
  }
  .empty-sub {
    color: var(--color-text-100);
  }
}

@media screen and (max-width:625px){
  .searchbar-wrapper {
    padding: 0 16px;
  }
  .empty-wrapper {
    margin-top: 20vh;
  }

  .group-item-wrapper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .group-item-wrapper::-webkit-scrollbar {
    display: none;
  }

  .group-item-wrapper {
    min-height: unset;
    max-height: unset;
    height: calc(100% - 75px);
  }

  .group-selection-content {
    position: relative;
    height: 80vh;

    &-searchbar {
      position: sticky;
      top: 0;
    }
  }
}
</style>
