const router = [
  {
    path: '/daftar-tagihan/detail/:id',
    name: 'BillListDetail',
    meta: {
      title: 'Detail Tagihan',
      module: 'payment'
    },
    component: () => import(/* webpackChunkName: "BillListDetail" */ '../views/EducationPayment/BillListDetail.vue')
  },
  {
    path: '/daftar-tagihan/skema-pembayaran',
    name: 'BillPaymentScheme',
    meta: {
      title: 'Skema Pembayaran',
      layout: 'blank',
      module: 'payment-scheme',
      hasBackButton: true,
      headerTitle: 'Pilih Skema Pembayaran',
      backButtonMobileLink: '/daftar-tagihan'
    },
    component: () => import(/* webpackChunkName: "BillPaymentScheme" */ '../views/EducationPayment/Scheme/PaymentScheme.vue')
  }
]

export default router
