const notification = {
  namespaced: true,
  state: {
    newNotif: false,
    notificationCounter: 0,
    chatCounter: 0,
    shoppingCartCounter: 0,
    msgService: null,
    unsubFirestoreFunc: null,
    isNotificationSupported: false,
    unSubMethods: []
  },
  mutations: {
    SET_NEW_NOTIF (state, payload) {
      state.newNotif = payload
    },
    SET_MSG_SERVICE (state, payload) {
      state.msgService = payload
    },
    SET_COUNTER_NOTIF (state, payload) {
      switch (payload.type) {
        case 'substract':
          state.notificationCounter -= payload.value
          break
        case 'add':
          state.notificationCounter += payload.value
          break
        case 'change':
        default:
          state.notificationCounter = payload.value
          break
      }
    },
    SET_COUNTER_CHAT_NOTIF (state, payload) {
      switch (payload.type) {
        case 'substract':
          state.chatCounter -= payload.value
          break
        case 'add':
          state.chatCounter += payload.value
          break
        case 'change':
        default:
          state.chatCounter = payload.value
          break
      }
    },
    SET_SHOPPING_CART_COUNTER (state, payload) {
      switch (payload.type) {
        case 'substract':
          state.shoppingCartCounter -= payload.value
          break
        case 'add':
          state.shoppingCartCounter += payload.value
          break
        case 'change':
        default:
          state.shoppingCartCounter = payload.value
          break
      }
    },
    SET_IS_NOTIFICATION_SUPPORTED (state, payload) {
      state.isNotificationSupported = payload
    },
    SET_UNSUB_METHOD (state, payload) {
      state.unSubMethods.push(payload)
    }
  },
  actions: {
    async getNotificationCounter ({ dispatch, rootState }) {
      dispatch('getSubscription', {
        path: `/cart/shopping_cart/users/${rootState.storage.userId}`,
        attributeName: 'item',
        commitName: 'SET_SHOPPING_CART_COUNTER'
      })
      dispatch('getSubscription', {
        path: `/notifications/general_notifications/users/${rootState.storage.username}`,
        attributeName: 'unread',
        commitName: 'SET_COUNTER_NOTIF'
      })
    },
    async getSubscription ({ commit }, payload) {
      const {
        path,
        attributeName,
        commitName } = payload

      const collectionPath = path
      const db = window.firestore.doc(collectionPath)

      const sub = db.onSnapshot(documentSnapshot => {
        let value = 0
        if (!documentSnapshot.empty) {
          const data = documentSnapshot.data()
          value = data ? data[attributeName] : 0
        }
        commit(commitName, { type: 'change', value })
      },
      error => {
        console.error(error)
      })
      commit('SET_UNSUB_METHOD', sub)
    },
    removeAllSubscription ({ rootState }) {
      rootState.notification.unSubMethods.forEach(unSubMethod => {
        unSubMethod()
      })
    }
  }
}

export default notification
