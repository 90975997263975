import crypto from '../../functions/crypto'
import axiosServer from '../../configs/axiosServer'

const institution = {
  namespaced: true,
  state: {
    professions: []
  },
  mutations: {
    CHANGE_PROFESSION_LIST (state, payload) {
      state.professions = payload
    }
  },
  actions: {
    async fetchProfessionList ({ commit, rootState, dispatch }, payload) {
      if (!window.localStorage.getItem('profession-list')) {
        try {
          const { data } = await axiosServer({
            url: '/user/profession/',
            method: 'GET',
            headers: {
              Authorization: rootState.storage.accessToken
            }
          })
          if (data.status === 200) {
            commit('CHANGE_PROFESSION_LIST', data.data)
            window.localStorage.setItem('profession-list', crypto.encrypt(data.data))
          } else {
            dispatch('storage/checkToken', data, { root: true })
            commit('CHANGE_PROFESSION_LIST', [])
          }
        } catch (error) {
          commit('CHANGE_PROFESSION_LIST', [])
        }
      } else {
        commit('CHANGE_PROFESSION_LIST', crypto.decrypt(window.localStorage.getItem('profession-list')))
      }
    }
  }
}

export default institution
