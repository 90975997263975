import moment from 'moment'
import 'moment/locale/id'

export const formatDateMixin = {
  methods: {
    formatDate (date, format, usingTimeInfo = false) {
      let currentDate = moment(date).utcOffset('+0700').format(format)
      let currentTime = usingTimeInfo
        ? moment(date).utcOffset('+0700').format('HH:mm')
        : ''
      return usingTimeInfo
        ? `${currentDate}, ${currentTime} WIB`
        : currentDate
    }
  }
}
