const login = {
  namespaced: true,
  state: {
    email: ''
  },
  mutations: {
    SET_EMAIL (state, payload) {
      state.email = payload
    }
  },
  actions: {
  }
}

export default login
