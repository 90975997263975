import axiosServer from '@/configs/axiosServer'

export default {
  async getQuizIntro ({ data, authorization }) {
    try {
      const result = await axiosServer({
        url: '/program_activity/v2/quiz/intro/',
        method: 'POST',
        data,
        headers: {
          Authorization: authorization
        }
      })
      return result
    } catch (error) {
      console.error(error)
    }
  },
  async getQuizQuestion ({ data, authorization }) {
    try {
      const result = await axiosServer({
        url: '/program_activity/v2/quiz/question/',
        method: 'POST',
        data,
        headers: {
          Authorization: authorization
        }
      })
      return result
    } catch (error) {
      console.error(error)
    }
  },
  async getQuizReview ({ data, authorization }) {
    try {
      const result = await axiosServer({
        url: '/program_activity/v2/quiz/answer_review/',
        method: 'POST',
        data,
        headers: {
          Authorization: authorization
        }
      })
      return result
    } catch (error) {
      console.error(error)
    }
  },
  async getArchivedQuizReview ({ data, authorization }) {
    try {
      const result = await axiosServer({
        url: '/program_activity/v2/quiz/question_answer/',
        method: 'POST',
        data,
        headers: {
          Authorization: authorization
        }
      })
      return result
    } catch (error) {
      console.error(error)
    }
  },
  async getRubricAssessmentCriteria ({ data, authorization }) {
    try {
      const result = await axiosServer({
        url: '/program_activity/v2/quiz/scoring_category_detail/',
        method: 'POST',
        data,
        headers: {
          Authorization: authorization
        }
      })
      return result
    } catch (error) {
      console.error(error)
    }
  },
  async endQuiz ({ data, authorization }) {
    try {
      const result = await axiosServer({
        url: '/program_activity/v2/quiz/end/',
        method: 'POST',
        data: {
          source: 'web',
          ...data
        },
        headers: {
          Authorization: authorization
        },
        generateQueryParamSource: true
      })
      return result
    } catch (error) {
      console.error(error)
    }
  },
  async resumeQuiz ({ data, authorization }) {
    try {
      const result = await axiosServer({
        url: '/program_activity/v2/quiz/resume/',
        method: 'POST',
        data,
        headers: {
          Authorization: authorization
        }
      })
      return result
    } catch (error) {
      console.error(error)
    }
  },
  async startQuiz ({ data, authorization }) {
    try {
      const result = await axiosServer({
        url: '/program_activity/v2/quiz/start/',
        method: 'POST',
        data,
        headers: {
          Authorization: authorization
        },
        generateQueryParamSource: true
      })
      return result
    } catch (error) {
      console.error(error)
    }
  },
  async sendQuizAnswer ({ data, authorization }) {
    try {
      const result = await axiosServer({
        url: '/program_activity/v2/quiz/answer/',
        method: 'POST',
        data: {
          source: 'web',
          ...data
        },
        headers: {
          Authorization: authorization
        },
        generateQueryParamSource: true
      })
      return result
    } catch (error) {
      console.error(error)
    }
  }
}
