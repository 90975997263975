<template>
  <div style="position: relative;">
    <div class="chat-reply-container" v-if="comment.payload.replied_comment_id">
      <div class="reply-box-left"></div>
      <div class="reply-box-right">
        <div class="replied-name">{{ comment.payload.replied_comment_sender_username }}</div>
        <div class="reply-content">
          <span v-if="comment.payload.replied_comment_payload.url" class="text-right" style="max-width: 100px">
            <template v-if="comment.payload.replied_comment_payload.url.toLowerCase().match(/\.(svg)$/)">
              <div class="inter-medium_small-normal unsupported-svg">File svg tidak ditampilkan.</div>
            </template>
            <template v-else>
              <a :href="comment.payload.replied_comment_payload.url" target="_blank" rel="noopener noreferrer">
                <b-img
                  :style="imageStyle"
                  :src="comment.payload.replied_comment_payload.url"
                  v-if="
                    comment.payload.replied_comment_payload.url &&
                      comment.payload.replied_comment_payload.url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/)
                  "
                ></b-img>
                <audio
                  controls
                  :style="audioStyle"
                  :src="comment.payload.replied_comment_payload.url"
                  v-else-if="
                    comment.payload.replied_comment_payload.url &&
                      comment.payload.replied_comment_payload.url.match(/\.(m4a|mp3|ogg})$/)
                  "
                ></audio>
                <video
                  controls
                  :style="videoStyle"
                  :src="comment.payload.replied_comment_payload.url"
                  v-else-if="
                    comment.payload.replied_comment_payload.url &&
                      comment.payload.replied_comment_payload.url.match(/\.(mp4|avi|3gp})$/)
                  "
                ></video>
                <span v-else>
                  {{ comment.payload.replied_comment_payload.file_name }}
                  <b-icon-download class="ml-2"></b-icon-download>
                </span>
              </a>
            </template>
            <div :style="captionStyle">{{ comment.payload.replied_comment_payload.caption }}</div>
          </span>
          <span :style="textStyle" v-else v-html="urlify(comment.payload.replied_comment_message)"></span>
        </div>
      </div>
    </div>
    <span v-if="comment.payload.url" class="text-right" style="max-width: 100px">
      <template v-if="comment.payload.url.toLowerCase().match(/\.(svg)$/)">
        <div class="inter-medium_small-normal unsupported-svg">File svg tidak ditampilkan.</div>
      </template>
      <template v-else>
        <a :href="comment.payload.url" target="_blank" rel="noopener noreferrer">
          <b-img
            :style="imageStyle"
            :src="comment.payload.url"
            v-if="
              comment.payload.url &&
                comment.payload.url.match(/\.(jpeg|jpg|gif|png)$/)
            "
          ></b-img>
          <audio
            controls
            :style="audioStyle"
            :src="comment.payload.url"
            v-else-if="
              comment.payload.url &&
                comment.payload.url.match(/\.(m4a|mp3|ogg})$/)
            "
          ></audio>
          <video
            controls
            :style="videoStyle"
            :src="comment.payload.url"
            v-else-if="
              comment.payload.url &&
                comment.payload.url.match(/\.(mp4|avi|3gp})$/)
            "
          ></video>
          <span v-else>
            {{ comment.payload.file_name }}
            <b-icon-download class="ml-2"></b-icon-download>
          </span>
        </a>
      </template>
      <div :style="captionStyle">{{ comment.payload.caption }}</div>
    </span>
    <!-- <span :style="textStyle" v-else>{{ comment.message }}</span> -->
    <span :style="textStyle" :class="{'ff-primary fs-14 fw-400 text-ui-primary': fromTanyamuKelasV2}" v-else v-html="urlify(comment.message)"></span>
    <div @click.prevent="openChatOption = !openChatOption" class="my-chat-option" :style="{top: optionChevronTop || `-2px`, right: optionChevronRight || `-20px`}">
      <img src="../../assets/icons/chevron_down_2.svg" alt="Sekolah.mu">
    </div>
    <transition name="fade">
      <div class="my-chat-optionbox" :style="{position: `absolute`, top: optionBoxTop, right: optionBoxRight}" v-if="openChatOption">
        <div class="list-label" @click.prevent="replyMessage">Balas pesan</div>
        <!-- <div class="list-label" v-if="(String(comment.email) === String(userId))" @click.prevent="deleteMessage">Hapus pesan</div> -->
        <div class="list-label" v-if="canDeleteOthersMessage || (String(comment.email) === String(userId))" @click.prevent="deleteMessage">Hapus pesan</div>
      </div>
    </transition>
  </div>
</template>

<script>
import { BIconDownload } from 'bootstrap-vue'

export default {
  // props: ['comment', 'fromFloating'],
  props: {
    comment: Object,
    fromFloating: Boolean,
    optionBoxTop: String,
    optionBoxRight: String,
    optionChevronTop: String,
    optionChevronRight: String,
    fromTanyamuKelasV2: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imageStyle: {
        maxWidth: '300px'
      },
      textStyle: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-word'
      },
      audioStyle: {},
      videoStyle: {},
      captionStyle: {
        whiteSpace: 'pre-line',
        maxWidth: '300px',
        textAlign: 'left',
        marginTop: '8px',
        wordBreak: 'break-word'
      },
      openChatOption: false
    }
  },
  components: {
    BIconDownload
  },
  methods: {
    replyMessage () {
      this.$emit('replyMessage', this.comment)
      this.openChatOption = false
    },
    deleteMessage () {
      this.$emit('deleteMessage', this.comment.room_id, this.comment.unique_temp_id)
      this.openChatOption = false
    },
    urlify (text) {
      let filtered = this.$XSSFilter(text)
      const urlRegex = /(https?:\/\/[^\s]+)/g
      return filtered.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>'
      })
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    },
    userId () {
      return this.$store.state.storage.userId
    },
    canDeleteOthersMessage () {
      if (this.userInfo.role.length) {
        if (this.userInfo.role.some(item => item.label === 'Super Admin' || item.label === 'Admin Sekolahmu' || item.label === 'Fasilitator')) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  created () {
    if (this.fromFloating) {
      this.imageStyle.maxWidth = '215px'

      this.textStyle.wordBreak = 'break-word'
      this.textStyle.fontWeight = 'normal'
      this.textStyle.fontSize = '14px'
      this.textStyle.lineHeight = '19px'
      this.textStyle.color = 'rgba(0, 0, 0, 0.7)'

      this.audioStyle.maxWidth = '215px'

      this.videoStyle.maxWidth = '215px'

      this.captionStyle.maxWidth = '215px'
    } else if (this.fromTanyamuKelasV2) {
      this.imageStyle.maxWidth = '300px'
      this.imageStyle.width = '100%'

      this.textStyle.wordBreak = 'break-word'
      this.textStyle.lineHeight = '19px'

      this.audioStyle.maxWidth = '245px'

      this.videoStyle.maxWidth = '245px'

      this.captionStyle.maxWidth = '245px'
    } else {
      this.imageStyle.maxWidth = '255px'
      this.videoStyle.maxWidth = '255px'
    }
  }
}
</script>
<style scoped>
.my-chat-option{
  position: absolute;
  /* top: -2px;
  right: -20px; */
  cursor: pointer;
}
.my-chat-option img{
  width: 14px;
}
.my-chat-optionbox{
  font-family: Open Sans;
  background: #fafafa;
  width: 120px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.my-chat-optionbox .list-label{
  color: #333333;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
}
.my-chat-optionbox .list-label:hover{
  color: var(--color-persian-blue-500);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.chat-reply-container{
  background: #FAFAFA;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
}
.reply-box-left{
  background: #FEC203;
  width: 3px;
  min-width: 3px !important;
  margin-right: 7px;
}
.chat-reply-container .replied-name{
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-persian-blue-900);
  margin-bottom: 4px;
}
.chat-reply-container .reply-content{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
}
.unsupported-svg{
  color: var(--color-neutral-800);
  background: var(--color-neutral-700);
  padding: 2px 14px;
  border-radius: 24px;
}
</style>
