<template>
  <div>
    <div style="position: relative;">
      <b-form-input
        id="input-lembaga"
        type="text"
        :class="{'input-text': isEditProfile, 'form-control': isEditProfile, 'register-input': !isEditProfile}"
        :state="validate"
        autocomplete="off"
        :placeholder="placeholder ? placeholder : `Cari ${wordingTitle}`"
        v-model="selected.name"
        @input="userTyping"
        @focus="onFocusInput"
        @blur="onBlurInput"
      ></b-form-input>
      <img
        src="../../assets/icons/icon_search.svg"
        class="search-lembaga-icon"
        @click.prevent="fetchInstitution()"
        style="background: #FFF"
      >
      <div class="search-icon-tooltip" v-if="isShowInstruction">
        <img src="../../assets/icons/suggestion_lembaga_instruction.svg" alt="Sekolah.mu">
      </div>
    </div>

    <div
      v-if="(institutions || isLoading || (!isLoading && !institutions.length)) && modal && selected.name"
      class="suggestion-list"
      :style="{width: `${inputWidth}px`}"
    >
      <b-button
        variant="outline-secondary"
        block
        disabled
        :style="{width: `${inputWidth}px`, fontSize: '14px'}"
        v-if="isLoading"
      >
        <b-spinner small variant="primary"></b-spinner>
        Mengambil data {{wordingTitle.toLowerCase()}}...
      </b-button>
      <b-button
        variant="link"
        block
        v-for="(institution, i) in institutions"
        :key="i"
        @click="setSelected(institution)"
        class="text-left m-0 mr-2 btn-list"
        v-else-if="institutions.length"
      >
        {{ institution.institution_complete_name || institution.name }}
      </b-button>
      <div v-else :style="{width: `${inputWidth}px`}">
        <b-button
          variant="link"
          block
          disabled
          class="text-left m-0 mr-2 btn-list"
        >
          Nama {{wordingTitle.toLowerCase()}} tidak ditemukan
        </b-button>
      </div>
    </div>

    <!-- invalid feedback -->
    <b-form-invalid-feedback>
      {{wordingTitle}} wajib diisi
    </b-form-invalid-feedback>
  </div>
</template>

<script>
// import debounce from '@/functions/debounce'
import userAPI from '@/api/user.js'

export default {
  props: ['wordingTitle', 'isEditProfile', 'initialValue', 'validate', 'formRegister2', 'inputHeight', 'placeholder'],
  data () {
    return {
      windowWidth: 1440,
      // inputWidth: 440,
      selected: {
        name: ''
      },
      isLoading: true,
      modal: false,
      institutions: [],
      isShowInstruction: false
    }
  },
  computed: {
    inputWidth () {
      if (document.getElementById('input-lembaga')) {
        return document.getElementById('input-lembaga').offsetWidth
      } else {
        return `400`
      }
    }
  },
  watch: {
    // selected: {
    //   handler: debounce(function (e) {
    //     e.name ? this.fetchInstitution() : this.setSelected(null)
    //   }, 500),
    //   deep: true
    // },
    'selected.name' () {
      if (!this.selected.name) {
        this.$emit('selectInstitution', '')
        this.modal = false
        this.isLoading = false
      }
    },
    windowWidth () {
      // this.inputWidth = document.getElementById('input-lembaga').offsetWidth
    }
  },
  methods: {
    onFocusInput (val) {
      this.isShowInstruction = true
    },
    onBlurInput (val) {
      this.isShowInstruction = false
    },
    userTyping () {
      // this.modal = true
      // this.isLoading = true
    },
    setSelected (selected) {
      this.$emit('selectInstitution', selected)
      if (selected) {
        this.selected = selected
        if (this.selected.institution_complete_name) {
          this.selected.name = this.selected.institution_complete_name
        }
        this.modal = false
      }
    },
    async fetchInstitution () {
      this.isLoading = true
      this.modal = true
      try {
        this.institutions = []
        const { data } = await userAPI.fetchInstitution({
          accessToken: this.$store.state.storage.accessToken,
          search_slug: this.selected.name
        })
        if (data.status === 200) {
          this.institutions = data.data
        } else {
          this.$store.dispatch('storage/checkToken', data)
          this.institutions = []
          // this.selected.name = ''
        }
      } catch (error) {
        this.institutions = []
        // this.selected.name = ''
      }
      this.isLoading = false
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    // this.inputWidth = document.getElementById('input-lembaga').offsetWidth
  },
  created () {
    if (this.initialValue) {
      this.selected.name = this.initialValue
      // this.fetchInstitution()
    }
  }
}
</script>

<style scoped>
.suggestion-list {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  max-height: 200px;
  background-color: var(--color-neutral-100);
  z-index: 1;
}

.btn-list{
  border: 1px solid #E0E0E0;
  border-radius: 0;
  text-decoration: none;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.btn-list:hover {
  color: #3598db;
}

ul {
  list-style: none;
}

::placeholder {
  font-family: "Inter", "Open Sans", sans-serif!important;
  font-weight: 400!important;
  font-size: 14px!important;
  color: var(--color-neutral-700);
}

.register-input {
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 22px 15px;
  color: rgba(0, 0, 0, 0.7);
}

.input-text {
  outline: 0px;
  font-size: 14px;
}

.input-text::placeholder {
  font-family: "Inter", "Open Sans", sans-serif!important;
  font-weight: 400!important;
  font-size: 14px!important;
  color: var(--color-neutral-700);
}

.search-lembaga-icon{
  height: 50%;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: 20px;
  cursor: pointer;
}
.search-icon-tooltip{
  position: absolute;
  width: 103.5%;
  left: -1.5%;
  z-index: 5;
}
.search-icon-tooltip img{
  width: 100%;
}
</style>
