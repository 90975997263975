<template>
  <div class="text-center">
    <img src="@/assets/images/blue-thumb.png" alt="image-alert" width="198px"/>
    <div class="gotham-bold oops-text my-2">Program Terbatas</div>
    <div class="open-sans detail-text">
      Program ini hanya dapat diakses oleh pengguna tertentu.
      Info lebih lanjut, hubungi penyelenggara program atau
      <span class="contact-info">{{contact.email}}</span>
    </div>
    <b-button
      id="blocker-program-terbatas-warning-button"
      pill
      class="gotham-book confirmation-btn mt-4 mb-3"
      to="/"
    >
      Kembali ke Beranda
    </b-button>
  </div>
</template>

<script>
import toggleInspectElement from '@/functions/toggleInspectElement'
import contact from '@/constants/contact.js'

export default {
  data () {
    return {
      contact
    }
  },
  mounted () {
    toggleInspectElement(false)
  },
  destroyed () {
    toggleInspectElement(true)
  }
}
</script>

<style scoped>
.detail-text {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.confirmation-btn {
  border: none;
  min-width: 230px;
  font-size: 14px;
  color: var(--color-neutral-100);
  background-color: var(--color-persian-blue-500);
}

.confirmation-btn:hover {
  background-color: var(--color-persian-blue-400);
}

.contact-info {
  color: #96C9EC;
}
</style>
