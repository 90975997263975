const programmu = {
  namespaced: true,
  state: {
    programmuTodo: [],
    isAlreadyFetchProgrammuTodo: false,
    isLoadingTodo: false,
    filterActivityTypes: [],
    mandatoryActivity: [
      {
        value: 'false',
        isPicked: true,
        text: 'Semua Aktivitas'
      },
      {
        value: 'true',
        isPicked: false,
        text: 'Aktivitas Wajib'
      }
    ],
    filterSchedule: [
      // {
      //   value: 'not_open',
      //   isPicked: false,
      //   text: 'Belum Dibuka'
      // },
      // {
      //   value: 'open',
      //   isPicked: false,
      //   text: 'Sedang Berjalan'
      // },
      // {
      //   value: 'complete',
      //   isPicked: false,
      //   text: 'Selesai'
      // },
      // {
      //   value: 'close',
      //   isPicked: false,
      //   text: 'Terlambat'
      // }
    ],
    requiredFilterType: [{
      value: '',
      isPicked: false,
      text: 'Semua'
    }],
    filterPeriodStart: new Date(),
    filterPeriodEnd: new Date(Date.parse(new Date()) + (2592000000 * 3)),
    todoNextUrl: '',
    todoPagination: 1,
    todoSearch: ''
  },
  getters: {
    selectedFilterSchedule (state) {
      const selectedActvitySchedules = []
      state.filterSchedule.forEach(value => {
        if (value.isPicked && value.value) {
          selectedActvitySchedules.push(value.value)
        }
      })
      return selectedActvitySchedules
    },
    selectedFilterActivityType (state) {
      const selectedActivityTypes = []
      state.filterActivityTypes.forEach(value => {
        if (value.isPicked && value.value) {
          selectedActivityTypes.push(value.value)
        }
      })
      return selectedActivityTypes
    }
  },
  mutations: {
    SET_PROGRAMMU_TODO (state, payload) {
      state.programmuTodo = payload
    },
    SET_IS_ALREADY_FETCH_PROGRAMMU_TODO (state, payload) {
      state.isAlreadyFetchProgrammuTodo = payload
    },
    SET_IS_LOADING_TODO (state, payload) {
      state.isLoadingTodo = payload
    },
    SET_FILTER_ACTIVITY_TYPE (state, payload) {
      state.filterActivityTypes = payload
    },
    SET_FILTER_MANDATORY_ACTIVITY (state, payload) {
      state.mandatoryActivity = payload
    },
    SET_FILTER_SCHEDULE (state, payload) {
      state.filterSchedule = payload
    },
    SET_FILTER_PERIOD_START (state, payload) {
      state.filterPeriodStart = payload
    },
    SET_FILTER_PERIOD_END (state, payload) {
      state.filterPeriodEnd = payload
    },
    SET_TODO_NEXT_URL (state, payload) {
      state.todoNextUrl = payload
    },
    SET_TODO_PAGINATION (state, payload) {
      state.todoPagination = payload
    },
    SET_TODO_SEARCH (state, payload) {
      state.todoSearch = payload
    },
    RESET_REQUIRED_FILTER_TYPE (state) {
      state.requiredFilterType = [{
        value: '',
        isPicked: false,
        text: 'Semua'
      }]
    }
  },
  actions: {
    resetTodoFilter ({ commit }) {
      commit('SET_PROGRAMMU_TODO', [])
      commit('SET_IS_ALREADY_FETCH_PROGRAMMU_TODO', false)
      commit('SET_FILTER_PERIOD_START', new Date())
      commit('SET_FILTER_PERIOD_END', new Date(new Date(Date.parse(new Date()) + (2592000000 * 3))))
      commit('SET_TODO_NEXT_URL', '')
      commit('SET_TODO_PAGINATION', 1)
      commit('SET_TODO_SEARCH', '')
      commit('RESET_REQUIRED_FILTER_TYPE')
    }
  }
}

export default programmu
