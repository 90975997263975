<template>
  <div id="snackbar-verification">
    <p class="font-label-md-rg-lh20 snackbar-text">{{ isUsingDynamicText ? snackbarText[textShowType] :  snackbarText['active'] }}</p>
    <div class="font-label-md-sb-lh16 trigger-verification" id="snackbar-verification-trigger-verification-button" @click="$emit('triggerVerification')">
      Verifikasi
    </div>
  </div>
</template>
<script>
import deviceDetector from 'mobile-device-detect'

export default {
  name: 'SnackbarVerification',
  props: {
    isUsingDynamicText: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      snackbarText: {
        active: 'Verifikasi akunmu sekarang agar dapat mengakses seluruh fitur di Sekolah.mu.',
        disabled: '',
        wasDisabled: 'Kamu sudah dapat melakukan verifikasi akun kembali.'
      },
      isMobile: deviceDetector.isMobile
    }
  },
  computed: {
    isTemporarilyFreezeOtpVerification () {
      return this.$store.state.user.isTemporarilyFreezeOtpVerification
    },
    textShowType () {
      return this.$store.state.user.unfreezeVerificationSnackbarTextType
    },
    userInfo () {
      return this.$store.state.user.userInfo
    }
  },
  methods: {
    logAnalyticEvents () {
      let event = ''
      if (this.textShowType === 'wasDisabled') {
        event = 'verifikasi_voluntary_post_freeze_click'
      } else {
        event = 'verifikasi_voluntary_profil_click'
      }
      window.firebaseAnalytics.logEvent(event, {
        screen_url: window.location.href,
        user_profession: this.userInfo.profession_name,
        user_year_level: this.userInfo.year_level_name,
        user_is_admin: this.userInfo.is_admin,
        user_id: this.userInfo.id,
        source: this.isMobile ? 'web_mobile_sekolahmu' : 'web_sekolahmu'
      })
    }
  }
}
</script>
<style scoped>
h5, p{
  margin: 0;
}
#snackbar-verification{
  width: 100%;
  padding: 8px 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  background: var(--color-deep-lemon-100);
  border-radius: 10px;
  background:
  url(https://cdn.sekolah.mu/assets/background/ticker/warning-ticker.png) bottom 0px left 0px no-repeat,
  var(--color-deep-lemon-100);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.snackbar-text{
  max-width: 420px;
  padding-left: 24px;
  color: var(--color-ui-text-primary);
}
.trigger-verification{
  cursor: pointer;
  color: var(--color-gamboge-700);
}
</style>
