import axiosServerGolang from '@/configs/axiosServerGolang'

const baseUrl = '/payment/v2/cart'

async function apiCall ({ accessToken, url, payload, method, isV5 }, api) {
  try {
    const result = await api({
      method,
      url,
      headers: {
        Authorization: accessToken
      },
      data: payload,
      version: isV5 ? 'v5' : 'v2'
    })
    if (result.status === 200) {
      return result
    } else {
      throw new Error(result)
    }
  } catch (error) {
    return { status: error.status, message: error.message }
  }
}

export default {
  async getCartLists ({ accessToken }) {
    const url = baseUrl
    const method = 'GET'
    const result = await apiCall({ accessToken, url, method }, axiosServerGolang)
    return result
  },
  async deleteCartItem ({ accessToken, payload }) {
    const url = baseUrl + '/delete'
    const method = 'POST'
    const result = await apiCall({ accessToken, url, method, payload }, axiosServerGolang)
    return result
  },
  async undoDeleteCartItem ({ accessToken, payload }) {
    const url = baseUrl + '/undo'
    const method = 'POST'
    const result = await apiCall({ accessToken, url, method, payload }, axiosServerGolang)
    return result
  },
  // Multiple Bill / Package
  async getV5CheckoutCart ({ accessToken }) {
    const url = '/payment/checkout-cart'
    const method = 'GET'
    const result = await apiCall({ accessToken, url, method, isV5: true }, axiosServerGolang)
    return result
  },
  async updateV5CheckoutCart ({ accessToken, payload }) {
    const url = '/payment/checkout-cart'
    const method = 'PUT'
    const result = await apiCall({ accessToken, url, method, payload, isV5: true }, axiosServerGolang)
    return result
  },
  async deleteV5CartItem ({ accessToken, payload }) {
    const url = '/payment/checkout-cart'
    const method = 'DELETE'
    const result = await apiCall({ accessToken, url, method, payload, isV5: true }, axiosServerGolang)
    return result
  }
}
