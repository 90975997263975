const routes = [
  {
    path: '/paket/:slug',
    name: 'DetailLearningPackageV2',
    meta: {
      title: 'Detail Paket Belajar'
    },
    component: () => {
      return import(/* webpackChunkName: "LearningPackageV2" */ '../views/LearningPackageV2/DetailLearningPackageV2.vue')
    }
  }
]

export default routes
