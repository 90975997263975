// PAYMENT
export const TRANSACTION_ALREADY_EXIST = 112
export const BLOCK_PURCHASE_FREE_ADDON_VARIAN = 118
export const PENDING_PURCHASE_FREE_ADDON_VARIAN = 119
export const SUCCESS_PURCHASE_FREE_ADDON_VARIAN = 120
// USER
export const OTP_NOT_MATCH = 123
export const OTP_VERIFICATION_FAILED = 124
export const ACCOUNT_FREEZE = 130
export const ACCOUNT_REQUIRE_OTP = 132
export const CAPTCHA_ERROR_BACKEND = 133
export const FORGOT_PASSWORD_LIMIT_ATTEMPT = 134
