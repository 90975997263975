import axiosServerGolang from '../../configs/axiosServerGolang'
import crypto from '../../functions/crypto'
import router from '../../router/index.js'
import Swal from 'sweetalert2'

/* global $cookies */
/* eslint no-undef: "error" */
const storage = {
  namespaced: true,
  state: {
    accessToken: '',
    name: '',
    email: '',
    avatar: '',
    profession: '',
    userId: '',
    username: ''
  },
  mutations: {
    SET_ACCESS_TOKEN (state, payload) {
      state.accessToken = payload
    },
    SET_NAME (state, payload) {
      state.name = payload
    },
    SET_EMAIL (state, payload) {
      state.email = payload
    },
    SET_AVATAR (state, payload) {
      state.avatar = payload
    },
    SET_PROFESSION (state, payload) {
      state.profession = payload
    },
    SET_USER_ID (state, payload) {
      state.userId = payload
    },
    SET_USERNAME (state, payload) {
      state.username = payload
    }
  },
  actions: {
    loginSetStorage ({ state, commit, rootState, dispatch }, payload) {
      commit('SET_ACCESS_TOKEN', payload.token.token)
      commit('SET_NAME', payload.data.name)
      commit('SET_AVATAR', payload.data.avatar)
      commit('SET_PROFESSION', payload.data.profession_name)
      commit('SET_USER_ID', payload.data.id)
      commit('SET_EMAIL', payload.data.email)
      commit('SET_USERNAME', payload.data.username)

      // remove admin and admin-type
      $cookies.remove('admin-type', '', rootState.hostDomain)
      $cookies.remove('admin', '', rootState.hostDomain)
      $cookies.remove('finance', '', rootState.hostDomain)

      let secure = location.hostname !== 'localhost'
      $cookies.set('access-token', crypto.encrypt(state.accessToken), '', '/', rootState.hostDomain, secure)
      $cookies.set('auth._token.local3', state.accessToken, '', '/', rootState.hostDomain, secure)
      $cookies.set('auth._token.local4', state.accessToken, '', '/', rootState.hostDomain, secure)
      $cookies.set('auth.strategy', 'local4', '', '/', rootState.hostDomain, secure)
      $cookies.set('name', crypto.encrypt(state.name), '', '/', rootState.hostDomain, secure)
      $cookies.set('avatar', crypto.encrypt(state.avatar), '', '/', rootState.hostDomain, secure)
      $cookies.set('profession', crypto.encrypt(state.profession), '', '/', rootState.hostDomain, secure)
      $cookies.set('user-id', crypto.encrypt(state.userId), '', '/', rootState.hostDomain, secure)
      $cookies.set('username', crypto.encrypt(state.username), '', '/', rootState.hostDomain, secure)
      $cookies.set('year-level', crypto.md5Encrypt(payload.data.year_level_name), '', '/', rootState.hostDomain, secure)
      $cookies.set('profession-user', crypto.md5Encrypt(payload.data.profession_name), '', '/', rootState.hostDomain, secure)
      $cookies.set('email', crypto.encrypt(payload.data.email), '', '/', rootState.hostDomain, secure)
      $cookies.set('province', crypto.encrypt(payload.data.province_id), '', '/', rootState.hostDomain, secure)
      $cookies.set('district', crypto.encrypt(payload.data.district_id), '', '/', rootState.hostDomain, secure)

      dispatch('accountMenu/fetchAccountMenu', payload.token.token, { root: true })
    },
    setStorageApp ({ dispatch, commit }) {
      try {
        const dataStore = {
          token: crypto.decrypt($cookies.get('access-token')),
          name: crypto.decrypt($cookies.get('name')),
          avatar: crypto.decrypt($cookies.get('avatar')),
          profession: crypto.decrypt($cookies.get('profession')),
          userId: crypto.decrypt($cookies.get('user-id')),
          username: crypto.decrypt($cookies.get('username')),
          email: crypto.decrypt($cookies.get('email'))
        }
        commit('SET_ACCESS_TOKEN', dataStore.token)
        commit('SET_NAME', dataStore.name)
        commit('SET_EMAIL', dataStore.email)
        commit('SET_AVATAR', dataStore.avatar)
        commit('SET_PROFESSION', dataStore.profession)
        commit('SET_USER_ID', dataStore.userId)
        commit('SET_USERNAME', dataStore.username)
      } catch (error) {
        dispatch('logoutRemoveStorage', true)
      }
    },
    async logoutRemoveStorage ({ rootState, commit, dispatch }, forceLogout = false) {
      commit('SET_ACCESS_TOKEN', '')
      commit('SET_NAME', '')
      commit('SET_EMAIL', '')
      commit('SET_AVATAR', '')
      commit('SET_PROFESSION', '')
      commit('SET_USER_ID', '')
      commit('SET_USERNAME', '')
      commit('user/CHANGE_IS_TEMPORARILY_FREEZE_OTP_VERIFICATION', false, { root: true })
      if (!forceLogout) {
        await dispatch('clearSessionToken')
      }
      dispatch('clearLastActivity')
      $cookies.remove('access-token', '', rootState.hostDomain)
      $cookies.remove('auth._token.local1', '', rootState.hostDomain)
      $cookies.remove('auth._token.local2', '', rootState.hostDomain)
      $cookies.remove('auth._token.local3', '', rootState.hostDomain)
      $cookies.remove('auth._token.local4', '', rootState.hostDomain)
      $cookies.remove('auth.strategy', '', rootState.hostDomain)
      $cookies.remove('name', '', rootState.hostDomain)
      $cookies.remove('avatar', '', rootState.hostDomain)
      $cookies.remove('profession', '', rootState.hostDomain)
      $cookies.remove('user-id', '', rootState.hostDomain)
      $cookies.remove('username', '', rootState.hostDomain)
      $cookies.remove('year-level', '', rootState.hostDomain)
      $cookies.remove('profession-user', '', rootState.hostDomain)
      $cookies.remove('email', '', rootState.hostDomain)
      $cookies.remove('province', '', rootState.hostDomain)
      $cookies.remove('district', '', rootState.hostDomain)
      $cookies.remove('username', '', rootState.hostDomain)
      $cookies.remove('access-origin', '', rootState.hostDomain)
      commit('coachmark/UPDATE_COACHMARK_LIST', [], { root: true })
    },
    clearLastActivity () {
      setTimeout(() => {
        $cookies.remove('parentGuideLastActivity')
      }, 200) // because parent guide mouse tracking is debounced, so we need to wait for the last activity to be recorded
    },
    async clearSessionToken () {
      try {
        await axiosServerGolang({
          url: 'auth/logout',
          method: 'POST',
          headers: {
            Authorization: crypto.decrypt($cookies.get('access-token'))
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async checkToken ({ dispatch, commit }, payload) {
      if (payload.status === 401) {
        await dispatch('logoutRemoveStorage')
        commit('user/CHANGE_IS_LOGIN', false, { root: true })
        this.$store.commit('user/CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL', false, { root: true })
        commit('user/SET_IS_CIKAL', false, { root: true })
        router.push('/')
      } else if (payload.status === 400) {
        Swal.mixin({
          customClass: {
            confirmButton: 'button-primary-solid px-3 btn-block',
            cancelButton: 'd-none',
            image: 'm-0'
          },
          buttonsStyling: false
        }).fire({
          imageUrl: require('@/assets/alert/request-timeout.png'),
          imageWidth: 150,
          width: 400,
          title: `
            <p style="color: #1A2E5A;font-size: 20px;">
              Terjadi Kendala
            </p>`,
          html: `
            <p style="color: #828282;font-size: 15px;">
              ${payload.message}
            </p>`,
          confirmButtonText: 'Kembali'
        })
      }
    }
  }
}

export default storage
