const refHandler = (currentValue = '', newValue) => {
  const splitedCurrents = currentValue.split('_')
  if (splitedCurrents.includes(newValue)) {
    const index = splitedCurrents.indexOf(newValue)
    if (index > -1) {
      splitedCurrents.splice(index, 1)
    }
  }
  splitedCurrents.unshift(newValue)
  return splitedCurrents
    .filter(current => !!current)
    .join('_')
}

export default refHandler
