<template>
  <div id="s-input" :class="wrapperClasses">
    <input
      ref="s-input-text"
      :value="formatter(value)"
      :type="type"
      :maxlength="maxlength"
      :disabled="disabled"
      :autocomplete="autoComplete"
      :name="inputName"
      class="input"
      autocorrect="off"
      :autocapitalize="autoCapitalize"
      @keypress="keypress"
      @keyup="$emit('keyup', $event)"
      @input="onInput"
      @change="onChange"
      @focus="toggleFocus"
      @blur="toggleFocus"
      :readonly="isReadOnly"
    >
    <p id="s-input-text-placeholder" class="placeholder">
      {{ placeholder }}
    </p>
    <button
      v-if="hasClearBtn && value.length"
      type="button"
      class="search-clear"
      @click="$emit('clear')"
    >
      <img
        class="clear-icon"
        src="https://cdn.sekolah.mu/assets/icons/voucher/clear-icon.svg"
        alt="clear"
      >
    </button>
  </div>
</template>

<script>
export default {
  name: 'SInputText',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: 'placeholder'
    },
    isError: {
      type: Boolean,
      default: false
    },
    formatter: {
      type: Function,
      default: (value) => value
    },
    deformatter: {
      type: Function,
      default: (value) => value
    },
    maxlength: {
      type: [Number, String],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasClearBtn: {
      type: Boolean,
      default: false
    },
    keypress: {
      type: Function,
      default: () => true
    },
    block: {
      type: Boolean,
      default: false
    },
    isInputReturnEvent: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    autoComplete: {
      type: String,
      default: 'off'
    },
    autoCapitalize: {
      type: String,
      default: 'off'
    },
    inputName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isFocus: false
    }
  },
  computed: {
    wrapperClasses () {
      return {
        's-input-wrapper': true,
        'base-border': true,
        'is-error error-border': this.isError,
        'is-focused focus-border': this.isFocus,
        'default-border': !this.isFocus,
        'is-filled': this.value,
        'width-100': this.block
      }
    }
  },
  methods: {
    onInput (e) {
      if (this.isInputReturnEvent) {
        this.$emit('input', e)
      } else {
        this.$emit('input', this.deformatter(e.target.value))
      }
    },
    onChange (e) {
      this.$emit('change', e)
    },
    toggleFocus () {
      this.isFocus = !this.isFocus
      this.$emit('focus')
      if (!this.isFocus) this.$emit('blur')
    },
    focus () {
      this.$refs['s-input-text'].focus()
    }
  }
}
</script>

<style scoped>

.s-input-wrapper {
  position: relative;
  z-index: 1;
  min-width: 200px;
  width: 100%;
  height: 44px;
  font-family: "Inter", "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: padding 0s;
}

.s-input-wrapper .input {
  border: none;
  background: none;
  color: black;
  width: 100%;
  height: 100%;
  margin: 0;
  padding-right: 22px;
  padding-left: 12px;
  color: var(--color-ui-element-black);
  border-radius: 8px;
  transition: margin 0s;
}

.s-input-wrapper .input ~ .placeholder {
  /* Positioning Behind Input */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  /* Styling */
  letter-spacing: 0px;
  text-align: left;
  padding: 10px 12px 0 12px;
  color: var(--color-ui-text-secondary);
  background: none;
  width: max-content;
  margin-bottom: 0;

  /*transition: font-size 0.3s, padding-top 0.3s;*/
  transition: font-size 0.3s, top 0.3s, left 0.3s, background 0.3s, padding 0.3s;
}

.s-input-wrapper .input:-webkit-autofill ~ .placeholder,
.s-input-wrapper.is-focused .input ~ .placeholder,
.s-input-wrapper.is-filled .input ~ .placeholder {
  padding: 2px 4px 2px 4px;
  top: -11px;
  left: 8px;
  background: var(--color-background-white);
  font-size: 12px;
  line-height: 16px;
  z-index: 1;
  border-radius: 5px;
}

.s-input-wrapper.is-focused .input ~ .placeholder {
  color: var(--color-persian-blue-500);
}

.s-input-wrapper.is-error:not(.is-focused):not(.is-filled) .input ~ .placeholder {
  color: var(--color-ui-text-secondary) !important;

}

.s-input-wrapper.is-error .input ~ .placeholder {
  color: var(--color-crimson-500);
}

.s-input-wrapper .input:disabled {
  color: var(--color-neutral-700)
}

.s-input-wrapper .input:disabled ~ .placeholder {
  color: var(--color-neutral-700)
}

.input:focus, .input:valid {
  outline: none;
}

.base-border {
  box-sizing: border-box;
  border-radius: 8px;
}

.default-border {
  border: 1px solid var(--disable-color);
}

.focus-border {
  border: 1px solid var(--color-persian-blue-500);
}

.error-border {
  border: 1px solid var(--color-crimson-500);
}

.width-100 {
  min-width: 100%;
}
.search-clear {
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 8px;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
}

.clear-icon {
  width: 16px;
  height: 16px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
