<template>
  <div id="modal-register-2" v-if="$store.state.profession.professions.length">
    <b-button id="btn-close-modal" variant="link" class="text-decoration-none p-0 pr-2 close-btn" @click="closeModal">
      <img src="@/assets/icons/icon_close.svg" />
    </b-button>
    <div class="form-header">
      <div class="title-header">
        <div class="left">
          <img :src="`${urlServerImage}/82x82,fit/${activeProfession.avatar}`" style="max-height: 50px">
        </div>
        <div class="right">
          <div class="right-top">
            Daftar Sebagai <span>{{ activeProfession.name === 'Guru'?'Guru/Dosen/Tendik': activeProfession.name }}</span>
          </div>
          <div class="right-bottom">
            Silakan lengkapi data berikut:
          </div>
        </div>
      </div>
    </div>
    <div class="form-body">
      <div class="form-body-title">Data {{ titleWording }}</div>
      <div class="field-top-margin">
        <div class="form-label">Nama {{ titleWording }} <span style="color: red;">*</span></div>
        <suggestion-lembaga
          style="margin-top: 5px"
          :formRegister2="true"
          :subDistrictId="sub_district.id"
          :wordingTitle="titleWording"
          @selectInstitution="fillInstitution"
          v-if="!showInstitutionAddress"
        ></suggestion-lembaga>
        <b-form-input
          v-else
          :placeholder="`Nama ${titleWording}`"
          type="text"
          class="register-input"
          v-model="institutionName"
        ></b-form-input>
      </div>
    </div>
    <div class="form-footer" style="margin-top: 30px;">
      <b-button
        class="footer-button"
        @click="submitFormRegister"
        v-if="canSave"
      >
        Simpan
      </b-button>
      <b-button
        class="footer-button-disable"
        disabled
        v-else
      >
        Simpan
      </b-button>
    </div>
  </div>
  <div v-else class="d-flex justify-content-center">
    <b-spinner variant="primary"></b-spinner>
  </div>
</template>
<script>
import SuggestionLembaga from '@/components/lembaga/SuggestionLembaga'
import axiosServer from '@/configs/axiosServer'
import Toast from '@/configs/Toast'

export default {
  components: {
    SuggestionLembaga
  },
  data () {
    return {
      institutionCategory: '',
      institutionId: '',
      institutionName: '',
      showInstitutionAddress: false,
      full_address: '',
      sub_district: {
        id: '',
        name: ''
      },
      selectedProvince: '',
      listProvince: []
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeAllModal')
    },
    async getListProvince () {
      try {
        const { data } = await axiosServer({
          url: `/user/domicile/`,
          method: 'GET',
          headers: {
            Authorization: this.$store.state.storage.accessToken || process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          this.listProvince = data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    fullNameFormatter (value) {
      return value.replace(/[^\w'. -]/g, '').replace(/[0-9]/g, '')
    },
    phoneNumberFormatter (value) {
      return value.replace(/[^\w*#+]/g, '').replace(/[a-zA-Z]/g, '')
    },
    fillInstitution (institution) {
      if (institution && !institution.id) {
        this.showInstitutionAddress = true
      } else {
        this.showInstitutionAddress = false
      }

      this.institutionId = ''
      this.institutionName = ''
      this.full_address = ''

      if (institution && institution.id) {
        this.institutionId = institution.id
      } else if (institution && institution.name) {
        this.institutionName = institution.name
      }
    },
    fillDistrict (district) {
      // this.institutionId = ''
      // this.institutionName = ''
      // this.full_address = ''
      if (district) {
        this.sub_district = district
      } else {
        this.sub_district = {
          id: '',
          name: ''
        }
      }
    },
    async submitFormRegister () {
      if (this.canSave) {
        this.isLoadingOnSave = true
        try {
          // let payloadRegister = {
          //   id: this.userInfo.id,
          //   email: this.userInfo.email,
          //   form_type: parseInt(this.institutionCategory),
          //   district: this.sub_district.id,
          //   sub_district_id: this.sub_district.sub_district[0].id,
          //   full_address: this.full_address,
          //   institution_id: this.institutionId || 0,
          //   institution_name: this.full_address,
          // }
          let payloadRegister
          if (parseInt(this.institutionCategory) !== 3) {
            payloadRegister = {
              id: this.userInfo.id,
              email: this.userInfo.email,
              form_type: 1,
              district: this.sub_district.id,
              institution_id: this.institutionId || 0,
              institution_name: this.institutionName,
              full_address: this.full_address
              // sub_district_id: this.sub_district.sub_district[0].id
            }
          } else {
            payloadRegister = {
              id: this.userInfo.id,
              email: this.userInfo.email,
              // form_type: parseInt(this.institutionCategory),
              district: this.sub_district.id,
              // sub_district_id: this.sub_district.sub_district[0].id,
              full_address: this.full_address,
              institution_name: this.full_address,
              institution_full_address: this.full_address
            }
          }
          if (this.showInstitutionAddress || Number(this.institutionCategory) === 3) {
            payloadRegister.sub_district_id = this.sub_district.sub_district[0].id
          }
          payloadRegister.year_level_id = this.userInfo.year_level_id
          await this.$store.dispatch('user/saveEditedProfile', payloadRegister)
          Toast.fire({
            icon: 'success',
            title: 'Registrasi Berhasil'
          })

          this.$emit('closeAllModal')
        } catch (error) {
          console.log(error)
        }
        this.isLoadingOnSave = false
      }
    }
  },
  watch: {
    $route (to, from) {
      this.$emit('closeAllModal')
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    },
    urlServerImage () {
      return process.env.VUE_APP_URL_SERVER_IMAGE
    },
    activeProfession () {
      if (this.$store.state.profession.professions.length) {
        return this.$store.state.profession.professions.filter(prof => {
          return this.userInfo.profession_name.includes(prof.name)
        })[0]
      } else {
        return {
          name: '',
          avatar: ''
        }
      }
    },
    titleWording () {
      if (this.activeProfession.name === 'Murid') {
        return 'Sekolah'
      } else if (this.activeProfession.name === 'Mahasiswa') {
        return 'Perguruan Tinggi'
      } else {
        return 'Tempat Kerja'
      }
    },
    canSave () {
      if (this.showInstitutionAddress) {
        switch (true) {
          case (!this.sub_district.id || this.sub_district <= 0):
          case ((!this.institutionId || this.institutionId <= 0) && !this.institutionName):
          case (this.showInstitutionAddress && !this.full_address):
          case (!this.full_address):
            return false
          default:
            return true
        }
      } else {
        const isFormSelected = ((this.institutionId && this.institutionId > 0) || this.institutionName.length > 0)

        return isFormSelected
      }
    }
  }
}
</script>
<style scoped>
.close-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.title-header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.right{
  margin-left: 10px;
}
.right .right-top{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-persian-blue-900);
}
.right .right-top span{
  font-weight: bold;
}
.right .right-bottom{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: var(--color-neutral-800);
  margin-top: 5px;
}
.form-body, .form-footer{
  margin: 12px 10px;
}
.form-body-title{
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 28px;
  color: #333333;
}
.form-label{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.7);
}
.register-input{
  margin-top: 5px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 22px 15px;
  color: rgba(0, 0, 0, 0.7);
}
.input-field {
  margin-top: 5px;
  width: 100%;
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.7);
}
.input-field-select{
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;

  margin-top: 5px;
  width: 100%;
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  color: #969696;

  background-image: url('../../assets/icons/polygon1.svg');
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 50%;
}
.input-field-select-selected{
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;

  margin-top: 5px;
  width: 100%;
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.7);

  background-image: url('../../assets/icons/polygon1.svg');
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 50%;
}
.footer-button{
  background: var(--color-persian-blue-500);
  border: 1px solid var(--color-persian-blue-500);
  border-radius: 10px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  color: var(--color-neutral-100);
}
.footer-button-disable{
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  color: #464654;
}
::placeholder {
  color: #9e9e9e;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.field-top-margin {
  margin-top: 10px;
}
</style>
