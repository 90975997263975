const bill = {
  namespaced: 'true',
  state: {
    selectedCardData: {},
    checkoutPayload: {},
    currentPaymentSchemePayload: {}
  },
  mutations: {
    SET_CARD_DATA (state, payload) {
      state.selectedCardData = payload
    },
    SET_CHECKOUT_PAYLOAD (state, payload) {
      state.checkoutPayload = payload
    },
    SET_CURRENT_PAYMENT_SCHEME_PAYLOAD (state, payload) {
      state.currentPaymentSchemePayload = payload
    }
  }
}

export default bill
