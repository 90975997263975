<template>
  <div id="base-app" class="ff-primary">
    <snackbar-wrapper />
    <s-alert v-if="isFullyLoad" variant="success" :show-alert="showAlert">
      <div class="font-body-3 alert-wrapper" @click="showAlert = 0">
        <img src="https://cdn.sekolah.mu/assets/icon/toast/success.svg" alt="success-icon" class="mr-2">
        <div v-html="sanitize(alertText)"></div>
      </div>
    </s-alert>
    <loading-overlay />
    <MediaViewer v-if="mediaViewerList.length"/>
    <transition name="component-fade" mode="out-in">
      <component :is="layout" ref="layoutComponent">
        <transition name="component-fade" mode="out-in">
          <router-view
            @triggerLoginModal="triggerLoginModal"
            @triggerRegisterModal="triggerRegisterModal"
            @toggleSearch="toggleSearch"
            @triggerEmailVerificationModal="triggerEmailVerificationModal"
            @shownavbar="hide"
            @isFooterShow="footerShown" :style="{'padding-top': paddingTopLogin}"
            @setSearch="setSearch"
            @activateOverlay="activateOverlay"
            @defineTanyamuFloatingBtn="defineTanyamuFloatingBtn"
            @openModalRegisterOAuthForm2="openModalRegisterOAuthForm2"
            @openModalRegisterForm2="openModalRegisterForm2"
            @openAlert="openAlert"
            @toggleModalAuthentication="toggleModalAuthentication"
            @toggleShowBottomSheet="toggleShowBottomSheet"
          />
        </transition>
      </component>
    </transition>
    <!-- <NavbarStyle v-if="showNavbar" class="open-sans" />
    <footer-navbar class="open-sans" v-if="showNavbarkayan && showFooter" />
    <footer-copyright class="open-sans" v-if="showNavbar && showFooter" /> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Navbar from './components/NewNavbar'
import FooterNavbar from './components/Footer'
import FooterCopyright from './components/FooterCopyright'
import scriptInjector from './functions/scriptInjector'
import deviceDetector from 'mobile-device-detect'
import MessagingService from './modules/messaging-service'
import desktopNotification from './functions/pushNotification/desktopNotification'
import SButton from '@/components/atoms/buttons/SButton.vue'
import SAlert from '@/components/atoms/alerts/SAlert.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import crazyEggMixin from '@/mixins/crazyEggMixin.js'
import featureFlag from '@/api/feature-flag'
import LoadingOverlay from '@/components/molecules/LoadingOverlay.vue'

export default {
  components: {
    Navbar,
    FooterNavbar,
    FooterCopyright,
    SAlert,
    SButton,
    FontAwesomeIcon,
    SnackbarWrapper: () => import('@/components/atoms/snackbar/SnackbarWrapper.vue'),
    MediaViewer: () => import('@/components/molecules/mediaViewer/MediaViewer.vue'),
    LoadingOverlay
  },
  mixins: [crazyEggMixin],
  data: () => ({
    showNavbar: true,
    showFooter: true,
    isFullyLoad: false,
    // isLogin: false,
    defaultLayout: 'full-blank',
    screenWidth: window.innerWidth,
    // msgService: null,
    isMobile: deviceDetector.isMobile,
    showAlert: 0,
    alertTimeout: null,
    alertText: ''
  }),
  methods: {
    sanitize (html) {
      return this.$XSSFilter(html)
    },
    hide (status) {
      this.showNavbar = status
    },
    footerShown (showState) {
      this.showFooter = showState ?? true
    },
    handleResize () {
      this.screenWidth = window.innerWidth
    },
    triggerLoginModal () {
      this.$refs.layoutComponent.triggerLoginModal()
    },
    triggerRegisterModal (payload) {
      this.$refs.layoutComponent.triggerRegisterModal(payload)
    },
    toggleModalAuthentication (path) {
      this.$refs.layoutComponent.toggleModalAuthentication(path)
    },
    triggerEmailVerificationModal () {
      this.$refs.layoutComponent.triggerEmailVerificationModal()
    },
    toggleSearch () {
      this.$refs.layoutComponent.openInputSearch()
    },
    setSearch (value) {
      this.$refs.layoutComponent.searchNavbar(value)
    },
    activateOverlay (value) {
      this.$refs.layoutComponent.activateOverlay(value)
    },
    defineTanyamuFloatingBtn (value) {
      this.$refs.layoutComponent.defineTanyamuFloatingBtn(value)
    },
    openModalRegisterOAuthForm2 () {
      this.$refs.layoutComponent.openModalRegisterOAuthForm2()
    },
    openModalRegisterForm2 () {
      this.$refs.layoutComponent.openModalRegisterForm2()
    },
    askNotificationPermission () {
      if (Notification?.permission === 'default') {
        Notification.requestPermission()
      }
    },
    toggleShowBottomSheet (value) {
      this.$refs.layoutComponent.toggleShowBottomSheet(value)
    },
    async setFbMessaging () {
      this.msgService = new MessagingService()
      // Request permission
      await this.msgService.requestPermissionAsync()
      // this.isTokenSentToServer = msgService.isTokenSentToServer();
      return this.msgService.getTokenAsync()
    },
    listenFirebaseNotif () {
      if (Notification?.permission === 'granted') {
        this.setFbMessaging()

        window.firebaseMessaging.onMessage((payload) => {
          this.$store.commit('notification/SET_NEW_NOTIF', true)
          desktopNotification(payload.data.title, {
            body: payload.data.body,
            image: payload.data.icon,
            icon: 'https://cdn.sekolah.mu/assets/home/footer_logo.svg'
          }, '/notifikasi')
        })
      }
    },
    checkIsVerificationFreeze () {
      if ((localStorage.getItem('freezing-verification-timestamp'))) {
        let countTime = new Date(Number(localStorage.getItem('freezing-verification-timestamp'))).getTime()
        let now = new Date().getTime()
        let gap = countTime - now

        if (gap > 0) {
          this.$store.commit('user/CHANGE_IS_TEMPORARILY_FREEZE_OTP_VERIFICATION', true)
          setTimeout(() => {
            this.$store.commit('user/CHANGE_UNFREEZE_VERIFICATION_SNACKBAR_TEXT_TYPE', 'wasDisabled')
            this.$store.commit('user/CHANGE_IS_TEMPORARILY_FREEZE_OTP_VERIFICATION', false)
            this.$store.commit('user/CHANGE_IS_SHOW_UNFREEZE_SNACKBAR', true)
          }, gap)
        }
      }
    },
    openAlert (alertText) {
      clearTimeout(this.alertTimeout)
      this.alertText = alertText
      this.showAlert = 6
      this.alertTimeout = setTimeout(() => {
        this.showAlert = 0
      }, 6000)
    },
    fetchAccountMenu (accessToken) {
      this.$store.dispatch('accountMenu/fetchAccountMenu', accessToken)
    },
    validateSubdomain () {
      const hostname = window.location.hostname
      const parts = hostname.split('.')
      const subdomainDepth = parts.length - 2 // minus TLD and domain
      const maxSubdomainDepth = this.isSandbox ? 2 : 1

      if (subdomainDepth > maxSubdomainDepth) {
        // prevent something like smm.x.y.z.sekolah.mu
        // and redirect to www.sekolah.mu

        window.location.href = 'https://www.sekolah.mu'
      }
    }
  },
  computed: {
    ...mapState(['envPlatinum', 'domainState']),
    ...mapGetters(['isSandbox', 'isLocalhost']),
    msgService: {
      get () {
        return this.$store.state.notification.msgService
      },
      set (value) {
        this.$store.commit('notification/SET_MSG_SERVICE', value)
      }
    },
    mediaViewerList: {
      get () {
        return this.$store.state.mediaViewer.mediaViewerList
      },
      set (value) {
        this.$store.commit('mediaViewer/SET_MEDIA_VIEWER_LIST', value)
      }
    },
    layout () {
      return (this.$route.meta.layout || this.defaultLayout) + '-layout'
    },
    hasBackButtonMobile () {
      return this.$route.meta.hasBackButtonMobile
    },
    paddingTopLogin () {
      if (
        !this.showNavbar ||
        this.$route.name === 'Sertifikat Regular' ||
        this.$route.name === 'Checkout' ||
        this.$route.name === 'Thankyou Page Payment' ||
        this.$route.name === 'Resi Program' ||
        this.$route.name === 'Prakerja Receipt' ||
        this.$route.name === 'GuideDocuments' ||
        this.$route.name === 'ChildProfile' ||
        this.$route.name === 'ParentProfile' ||
        this.$route.name === 'FatherProfileEdit' ||
        this.$route.name === 'MotherProfileEdit' ||
        this.$route.name === 'GuardianProfileEdit' ||
        this.$route.name === 'ChildDetail' ||
        this.$route.name === 'EditAdressData' ||
        this.$route.name === 'EditPeriodicData' ||
        this.$route.name === 'EditProsperityCard' ||
        this.$route.name === 'EditAchievementCard' ||
        this.$route.name === 'BillPaymentScheme' ||
        this.$route.name === 'Rapor Program' ||
        this.$route.name === 'Rapor Program Type' ||
        (
          this.hasBackButtonMobile && this.isMobile
        )
      ) {
        return '0'
      } else if (this.$route.name === 'activity-program') {
        return '72px'
      } else {
        return '64px'
      }
    },
    envBelajar () {
      return this.$store.state.envBelajar
    },
    isLogin () {
      return this.$store.state.user.isLogin
    },
    isNotificationSupported () {
      return this.$store.state.notification.isNotificationSupported
    },
    isShowUnfreezeVerificationSnackbar: {
      get () {
        return this.$store.state.user.isShowUnfreezeVerificationSnackbar
      },
      set (val) {
        this.$store.commit('user/CHANGE_IS_SHOW_UNFREEZE_SNACKBAR', val)
      }
    }
  },
  watch: {
    $route () {
      this.$store.commit('user/CHANGE_IS_VERIFICATION_MANDATORY', false)
      this.$store.commit('user/CHANGE_IS_HIDE_SKIP_VERIFICATION', false)
      this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_INVITATION', false)
      this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_OTP', false)
    },
    isShowUnfreezeVerificationSnackbar (value) {
      if (value) {
        setTimeout(() => {
          this.isShowUnfreezeVerificationSnackbar = false
        }, 10000)
      }
    }
  },
  async created () {
    /* global $cookies */
    /* eslint no-undef: "error" */
    if ($cookies.get('access-token')) {
      await this.$store.dispatch('storage/setStorageApp')
      if (window.firebase.messaging.isSupported() && this.isNotificationSupported) {
        this.listenFirebaseNotif()
      }
    }

    this.$store.commit('SET_HOST_DOMAIN')
    // setup env and web mode
    this.$store.commit('SET_ACTIVE_ENVIRONMENT')
    this.$store.commit('SET_ENV_BELAJAR')
    this.$store.commit('SET_ENV_PLATINUM')
    this.$store.commit('institution/INIT_PLATINUM_CONFIG_JSON')
    if (!this.isLocalhost) {
      this.validateSubdomain()
    }
    if (this.envPlatinum && this.domainState === 'loading') {
      await this.$store.dispatch('institution/getInstitutionPlatinumBySubdomain')
    } else {
      await this.$store.dispatch('institution/fetchConfigJSON')
    }

    // checking if notification supported
    const isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window
    this.$store.commit('notification/SET_IS_NOTIFICATION_SUPPORTED', isSupported())

    // ask permission for notification
    // this.askNotificationPermission()

    // this.$store.commit('SET_HOST_DOMAIN')
    if (this.$store.state.storage.accessToken) {
      this.$store.commit('user/CHANGE_IS_LOGIN', true)
      this.$store.dispatch('notification/getNotificationCounter')
      this.fetchAccountMenu(this.$store.state.storage.accessToken)
    }
    window.addEventListener('resize', this.handleResize)

    // const CLIENT_KEY = process.env.VUE_APP_CLIENT_KEY_MIDTRANS
    // const SOURCE_KEY = process.env.VUE_APP_SOURCE_SCRIPT
    // const script = document.createElement('script')
    // script.src = SOURCE_KEY
    // script.type = 'text/javascript'
    // script.setAttribute('data-client-key', CLIENT_KEY)
    // script.style.display = 'none'
    // document.head.append(script)

    // inject midtrans script
    const MIDTRANS_CLIENT_KEY = process.env.VUE_APP_CLIENT_KEY_MIDTRANS
    const TOKEN_SCRIPT = process.env.VUE_APP_MIDTRANS_TOKEN_SCRIPT
    const midtransScript = document.createElement('script')
    const DATA_ENVIRONMENT = this.$store.state.activeEnv === 'production' ? 'production' : 'sandbox'
    midtransScript.src = TOKEN_SCRIPT
    midtransScript.type = 'text/javascript'
    midtransScript.setAttribute('data-client-key', MIDTRANS_CLIENT_KEY)
    midtransScript.id = 'midtrans-script'
    midtransScript.setAttribute('data-environment', DATA_ENVIRONMENT)
    document.head.append(midtransScript)

    this.$store.dispatch('subnavbar/initMenuList')
    const currentUrlParams = window.location.search
    const urlParams = new URLSearchParams(currentUrlParams)
    const isFromBayar = (urlParams.get('from') === 'bayar')
    await this.$store.dispatch('user/fetchUserData', { isFromBayar })
    // const dataLogin = await this.$store.dispatch('user/fetchUserData')
    // if (dataLogin.status === 200) {
    //   this.$store.dispatch('tanyamu/loginQiscus')
    // }

    if (this.$store.state.activeEnv === 'production') {
      scriptInjector()
    }

    this.checkIsVerificationFreeze()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
    if (this.msgService) {
      this.msgService.deleteTokenAsync()
    }
  },
  async mounted () {
    const isMobileWebOrWeb = this.isMobile ? 'mweb_sekolahmu' : 'web_sekolahmu'
    document.addEventListener('click', () => {
      if (event.target.matches('.overlay')) {
        this.$refs.layoutComponent.activateOverlay(false)
      }
    })
    window.addEventListener('load', (event) => {
      this.isFullyLoad = true
    }, { once: true })
    this.$store.commit('analytics/SET_SOURCE', isMobileWebOrWeb)

    if (this.isLogin) {
      this.initCrazyEgg()
    }

    localStorage.setItem('isUsingQuizV2', await featureFlag.getQuizV2Flag())
  }
}
</script>

<style>
@import url('./styles/Tour.css');
@import url('./styles/Wysiwyg.css');
@import url('./styles/dist/FontTypes.css');
@import url('./styles/Variables.css');
@import url('./styles/ColorStyle.css');
@import url('./styles/Spacing.css');
@import url('./styles/ModalStyle.css');
@import url('./styles/EllipsisText.css');
@import url('./styles/WidthHelper.css');
@import url('./styles/FontHelper.css');
@import url('./styles/ButtonStyle.css');
@import url('./styles/FloatingVueStyle.css');
@import url('./styles/VueToastification.css');

body{
  height: 100vh;
}
#base-app{
  height: 100vh;
}
*{
  color: inherit;
}
a {
  /* color: inherit; */
  text-decoration: none;
}

a.dropdown-item.active, a.dropdown-item:active {
  background-color: var(--color-neutral-100) !important;
}

#image-resizer img {
  width: 100%;
  object-fit: contain;
}

.alert-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.alert-close-button {
  margin-left: 10px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  background: #BDBDBD;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

/* .modal-content {
  width: 340px !important;
  margin: auto;
} */

@font-face {
  font-family: "Gotham Bold";
  src: url("./assets/fonts/GothamSSm-Bold.ttf");
}

@font-face {
  font-family: "Gotham Book";
  src: url("./assets/fonts/GothamSSm-Medium.ttf");
}

@font-face {
  font-family: "Catalunya";
  src: url("./assets/fonts/Catalunya-demo.ttf");
}

@font-face {
  font-family: 'hind_gunturbold';
  src: url('./assets/fonts/Hind-Guntur/HindGuntur-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hind_gunturlight';
  src: url('./assets/fonts/Hind-Guntur/HindGuntur-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hind_gunturmedium';
  src: url('./assets/fonts/Hind-Guntur/HindGuntur-Medium.ttf');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'hind_gunturregular';
  src: url('./assets/fonts/Hind-Guntur/HindGuntur-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hind_guntursemibold';
  src: url('./assets/fonts/Hind-Guntur/HindGuntur-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}

.gotham-bold {
  font-family: "Gotham Bold";
}

.gotham-book {
  font-family: "Gotham Book";
}

.open-sans {
  font-family: "Open Sans", sans-serif;
}

.catalunya-font{
  font-family: "Catalunya";
}

.hind-guntur-bold{
  font-family: "hind_gunturbold";
}

.hind-guntur-light{
  font-family: "hind_gunturlight";
}

.hind-guntur-medium{
  font-family: "hind_gunturmedium";
}

.hind-guntur-regular{
  font-family: "hind_gunturregular";
}

.hind-guntur-semi-bold{
  font-family: "hind_guntursemibold";
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}

.component-fade-enter, .component-fade-leave-active {
  opacity: 0;
}

:root {
  scroll-behavior: smooth;
  font-family: "Open Sans", sans-serif;
}

*:focus {
  outline: none;
}

.fade-enter-active, .fade-leave-active {
  transition: .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* ::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: var(--color-persian-blue-500);
  border-radius: 15px;
  cursor: pointer
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-persian-blue-500);
  cursor: pointer
} */

/* ::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: var(--color-persian-blue-500);
  border-radius: 20px;
  cursor: pointer
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-persian-blue-500);
  cursor: pointer
} */

code {
  white-space: pre
}

/* Adding class for font weight */

.font-normal{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.font-semibold{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.font-bold{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}

.primary-font-color{
  color: var(--color-ui-text-primary);
}

.secondary-font-color{
  color: var(--color-neutral-800);
}

.highlight-font-color{
  color: var(--color-persian-blue-500);
}

.white-font-color{
  color: var(--color-neutral-100);
}

/* Adding CSS variable for coloring in Project */
:root{
  --todo-card-program-name-width: 375px;
  --todo-card-activity-name-width: 375px;
}

.unrotate-class {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.3s;
}

.rotate-class {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: 0.3s;
}

.cursor-pointer {
  cursor: pointer;
}

.cropper-crop-box, .cropper-view-box {
  border-radius: 50%;
  outline: 0 !important;
}

.cropper-view-box {
  box-shadow: 0 0 0 0px black;
  outline: 0;
  outline-color: #333333;
}
.cropper-point {
display: none;
}
.cropper-face {
background: none !important;
}
.cropper-line {
  display: none !important;
}
/* checkbox customs */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--color-persian-blue-500);
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px var(--color-neutral-100), 0 0 0 0.2rem var(--color-persian-blue-100)
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px var(--color-neutral-100), 0 0 0 0.2rem var(--color-persian-blue-100)
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: var(--color-persian-blue-300);
}
</style>

<style lang="scss">
@import '@/styles/datePicker.scss';
@import '@/styles/Margin.scss';
@import '@/styles/Padding.scss';
@import '@/styles/FontTypes.scss';
</style>
