import axiosServer from '@/configs/axiosServer.js'
import axiosServerGolang from '@/configs/axiosServerGolang'

export default {
  async fetchSubjectReferences ({ accessToken }) {
    const url = `/program/subject/list/`
    try {
      const result = await axiosServer({
        method: 'GET',
        url,
        headers: {
          Authorization: accessToken
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get subject references')
    }
  },
  async getProgramRecommendations ({ accessToken, lsId, page, perPage }) {
    try {
      return await axiosServerGolang({
        method: 'GET',
        url: `/discovery/addon-featured-by-lsid/${lsId}/${page}/${perPage}`,
        headers: {
          Authorization: accessToken
        }
      })
    } catch (error) {
      console.error(error, 'Failed to get subject references')
    }
  },
  async requestGroup ({ Authorization, data }) {
    try {
      return await axiosServer({
        method: 'POST',
        url: '/program/resource_group/request_group/',
        headers: {
          Authorization
        },
        data
      })
    } catch (error) {
      console.error(error, 'Failed to request group')
    }
  },
  async getFirstActivityMultiProgram ({ accessToken, program_id_list }) {
    try {
      const { data } = await axiosServer({
        method: 'POST',
        url: '/program/first-activity-multi-program/',
        headers: { Authorization: accessToken },
        data: { program_id_list }
      })
      return data
    } catch (error) {
      console.error(error, 'Failed to get First Activity Multi Program')
    }
  }
}
