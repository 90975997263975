<template>
  <div :class="['searchbar-container', { 'full-width': !isLogin || isShowSuggestion }]">
    <s-searchbar
      v-model="keyword"
      placeholder="Mau belajar apa hari ini?"
      class="searchbar-input-container font-paragraph-5"
      @focus="setShowSuggestion(true, { state :'focus' })"
      @keyup.enter="logEventTracker('SEKOLAHMU_BEGIN_SEARCH_ICON_CLICK', true, () => handleSearch(null, 'sekolahmu-search-bar'))"
      @click="logEventTracker('SEKOLAHMU_BEGIN_SEARCH_ICON_CLICK', true, () => handleSearch(null, 'sekolahmu-search-bar'))"
    />

    <b-collapse
      v-if="everShowingSuggestions && !isAllDataEmpty"
      v-model="isShowSuggestion"
      class="search-suggestion collapse-transition"
    >
      <search-suggestion-content
        ref="suggestion"
        :is-show-suggestion="isShowSuggestion"
        @clicktag="handleSearch"
        @clicked="setShowSuggestion(false)"
        @setHasSearchResult="(val) => {this.isAllDataEmpty = val}"
      ></search-suggestion-content>
    </b-collapse>
  </div>
</template>

<script>
import debounce from '@/functions/debounce'
import refHandler from '@/functions/refHandler'

export default {
  name: 'Searchbar',
  components: {
    SearchSuggestionContent: () => import('@/components/navbar/Search/SearchSuggestionContent.vue')
  },
  prop: {
    value: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      keyword: '',
      isShowSuggestion: false,
      everFocused: false,
      isAllDataEmpty: false
    }
  },
  computed: {
    isLogin () {
      return this.$store.state.user.isLogin
    },
    everShowingSuggestions () {
      return this.isShowSuggestion || this.everFocused
    }
  },
  methods: {
    logEventTracker (key, isTrack = true, cb, additionalField = {}) {
      this.$store.dispatch('logEventTracker', {
        isTrack,
        key,
        module: 'SEARCH',
        additionalField: {
          ...additionalField,
          page_referrer: `${window.location.origin}${this.$route.fullPath}`,
          search_keyword: this.keyword
        }
      })
      cb && cb()
    },
    closeSuggestionNative (e) {
      if (!this.$el.contains(e.target)) {
        this.setShowSuggestion(false, { invokeEvent: false })
      }
    },
    setShowSuggestion (isShow, config = {}) {
      const { state = '', invokeEvent = true } = config
      if (state === 'focus') this.everFocused = true
      const event = isShow ? 'show' : 'hide'
      this.isShowSuggestion = isShow
      if (invokeEvent) {
        this.logEventTracker('SEKOLAHMU_BEGIN_SEARCH_CLICK', isShow)
      }
      this.$emit(event)
    },
    handleSearch (value, pageRef) {
      this.keyword = value ?? this.keyword
      this.setShowSuggestion(false)
      document.activeElement.blur()
      this.$router.push({
        path: '/search',
        query: {
          ...this.$route.query,
          ref: refHandler(this.$route.query?.ref, pageRef),
          keyword: this.keyword
        }
      }).catch(() => {})
    }
  },
  watch: {
    keyword: {
      handler: debounce(function (value) {
        this.isAllDataEmpty = false
        this.$nextTick(() => {
          if ('suggestion' in this.$refs) {
            this.$refs.suggestion.fetchAllData(value)
          }
        })
      }, 500)
    },
    $route (to) {
      const searchRouteNames = ['general-search', 'search-result-by-category']
      if (!searchRouteNames.includes(to.name)) {
        this.keyword = ''
      }
    }
  },
  created () {
    this.keyword = this.$route?.query?.keyword ?? ''
  },
  mounted () {
    document.addEventListener('click', this.closeSuggestionNative)
  }
}
</script>

<style scoped>
.searchbar-container {
  position: relative;
  margin: 0;
  width: 100%;
}

.collapse-transition {
  transition: height 0.3s ease, opacity 0.5s ease, margin 0.5s ease;
}

.hide-suggestion-bar {
  display: none;
}
.searchbar-input-container {
  width: 100%;
}

::v-deep .s-searchbar-input {
  height: 44px
}

::v-deep .s-searchbar-icon {
  top: calc(50% - 14px);
}

::v-deep .s-searchbar-dropdown {
  display: none;
}

.search-suggestion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;
  position: absolute;
  height: calc(100vh - 54px);
  max-width: 100vw;
  right: -12px;
  background: var(--color-neutral-100);
  box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.1);
  border-radius: 10px;
  z-index: 1;
}

@media screen and (min-width: 921px) {
  .full-width {
    width: calc(100vw - 622px) !important;

  }
  .searchbar-container {
    width: calc(100vw - 720px);
  }

  .search-suggestion {
    left: 0;
    padding: 16px 0px;
    height: fit-content;
    width: 696px;
    max-height: 520px;
  }
}

@media screen and (min-width: 1366px) {
  .full-width {
    width: 696px !important;
  }

  .searchbar-container {
    width: 622px;
    /* prepare for next improvement */
    /* -webkit-transition: width .1s ease-in-out;
    -moz-transition: width .1s ease-in-out;
    -o-transition: width .1s ease-in-out;
    transition: width .1s ease-in-out; */
  }
}
</style>
