<template>
  <div>
    <div :id="`group-item-${item.id || 0}`" class="group-item" :class="{'is-disabled': isDisabled, 'is-selected': isSelected}" @click.prevent="handleSelect(item)">
      <div class="group-item-content">
        <h2 class="item-name mb-0" :class="{ 'font-label-2': isMobile, 'font-paragraph-4': !isMobile}">
          {{ item.name }}
        </h2>
        <h3 v-if="item.capacity !== -1" class="item-capacity mb-0" :class="{'low-quota': isLowQuota, 'font-label-5': isMobile, 'font-paragraph-5': !isMobile}">
          {{ item.current_quota && !isMinusQuota ? `Kuota: ${item.current_quota}` : 'Kuota Habis' }}
        </h3>
      </div>
      <div class="group-item-radio">
        <div class="radio-circle"></div>
      </div>
    </div>
    <hr class="item-divider m-0">
  </div>
</template>

<script>
export default ({
  name: 'GroupItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSelect (val) {
      if (!this.isDisabled) {
        this.$emit('select', val)
      }
    }
  },
  computed: {
    isLowQuota () {
      return this.item.current_quota < 4 && this.item.current_quota > 0
    },
    isMinusQuota () {
      return this.item.current_quota < 0
    },
    isDisabled () {
      return (this.item.current_quota === 0 && this.item.capacity !== -1) || this.item.status === 2 || this.isMinusQuota
    }
  }
})
</script>

<style lang="scss" scoped>
.group-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-neutral-300);
    .group-item-radio {
      .radio-circle {
        box-shadow: 0 0 0 2px rgba(35, 37, 179, 0.3);
      }
    }
  }

  &:hover.is-disabled {
    background-color: var(--color-neutral-100);
    .group-item-radio {
      .radio-circle {
        box-shadow: none
      }
    }
  }

  &-content {
    .item-name {
      color: var(--color-text-100);
    }

    .item-capacity {
      color: var(--color-text-200);
    }
    .low-quota {
      color: var(--color-crimson-500);
    }
  }

  &-radio {
    display: flex;
    align-items: center;
    justify-content: center;

    .radio-circle {
     width: 16px;
     height: 16px;
     border-radius: 50%;
     border: 2px solid var(--color-neutral-700);
    }
  }

  &.is-disabled {
    cursor: not-allowed;
    .group-item-content {
      .item-name {
        color: var(--color-neutral-600);
      }

      .item-capacity {
        color: var(--color-neutral-600);
      }
    }

    .group-item-radio {
      .radio-circle {
        background-color: var(--color-neutral-500);
      }
    }
  }

  &.is-selected {
    .group-item-radio {

      .radio-circle {
        border: 2px solid var(--color-persian-blue-500);
        display: grid;
        place-items: center;

        &::before {
          content: '';
          display: block;
          position: static;
          width: 0.5em;
          height: 0.5em;
          border-radius: 0.25em;
          background: var(--color-persian-blue-500);
        }
      }
    }
  }
}

@media screen and (min-width: 991px){

  .item-divider {
    display: none;
  }
  .group-item {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 16px;
    padding: 12px 16px;
    border-bottom: unset;
    border-radius: 10px;
    border: solid 1px var(--color-neutral-700);

    &.is-selected {
      border: solid 1px var(--color-persian-blue-500);
    }
  }

  .group-item:not(:last-child) {
    margin-bottom: 12px;
  }
}
</style>
