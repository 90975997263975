<template>
  <nav class="footer">
    <div class="container" id="footer-body">
      <div id="footer-images">
        <img src="https://cdn.sekolah.mu/assets/home/footer_logo.svg" alt="Sekolah.mu" class="sekolahmu-footer-logo" width="105" height="82"/>
        <a id="icon-store-device" href="https://play.google.com/store/apps/details?id=mu.sekolah.android&hl=in" rel="noopener" target="_blank">
          <img src="https://cdn.sekolah.mu/assets/home/googleplay.svg" alt="download-sekolahmu-app-on-google-playstore" width="105" height="30"/>
          <!-- <img src="../assets/icons/icon_appstore.png" alt /> -->
        </a>
      </div>
      <div id="footer-menu-right">
        <!-- Tentang Prakerja -->
        <div class="footer-menu">
          <div class="footer-menu-title font-subtitle-3">Tentang Prakerja</div>
          <div style="margin-top: 21px" class="footer-submenu">
            <router-link to="/mitra-kolaborasi">
              <div class="footer-menu-submenu font-subtitle-3">Mitra Kolaborasi</div>
            </router-link>
            <a href="https://panduan.sekolah.mu/help/prakerja/" target="_blank">
              <div class="footer-menu-submenu font-subtitle-3">Panduan Prakerja</div>
            </a>
          </div>
        </div>
        <!-- Tentang Kami -->
        <div class="footer-menu">
          <div class="footer-menu-title font-subtitle-3">Tentang Kami</div>
          <div style="margin-top: 21px" class="footer-submenu">
            <router-link to="/profil-sekolahmu">
              <div class="footer-menu-submenu font-subtitle-3">Tentang Sekolah.mu</div>
            </router-link>
            <router-link to="/dewan-sekolahmu">
              <div class="footer-menu-submenu font-subtitle-3">Dewan Sekolah.mu</div>
            </router-link>
            <a href="https://career.sekolah.mu/?utm_source=sekolahmu&utm_medium=footer&utm_campaign=karier" rel="noopener" target="_blank">
              <div class="footer-menu-submenu font-subtitle-3">Karier</div>
            </a>
            <!-- <div class="footer-menu-submenu" @click="toRouting('/mitra-sekolahmu')" >Mitra Sekolah.mu</div> -->
          </div>
        </div>
        <!-- Lainnya -->
        <div class="footer-menu">
          <div class="footer-menu-title font-subtitle-3">Lainnya</div>
          <div style="margin-top: 21px" class="footer-submenu">
            <a href="https://www.sekolah.mu/blog/" rel="noopener noreferrer">
              <div class="footer-menu-submenu font-subtitle-3">Blog</div>
            </a>
            <router-link to="/bantuan">
              <div class="footer-menu-submenu font-subtitle-3">Bantuan</div>
            </router-link>
            <router-link to="/syarat-ketentuan">
              <div class="footer-menu-submenu font-subtitle-3">Syarat Ketentuan</div>
            </router-link>
            <router-link to="/kebijakan-privasi">
              <div class="footer-menu-submenu font-subtitle-3">Kebijakan Privasi</div>
            </router-link>
          </div>
        </div>
        <div class="footer-menu">
          <div class="footer-menu-title font-subtitle-3">Ikuti Kami</div>
          <div style="margin-top: 21px; display: flex; flex-wrap: wrap">
            <a href="https://www.youtube.com/channel/UCKaJP90erDda-EvyWcsa9ng" rel="noopener noreferrer" target="_blank" style="margin-right: 10px; margin-bottom: 10px; color: #fff">
              <img src="../assets/icons/icon_youtube_grey.png" alt="Sekolah.mu-youtube"/>
            </a>
            <a href="https://www.instagram.com/sekolah.mu/" rel="noopener noreferrer" target="_blank" style="margin-right: 10px; margin-bottom: 10px; color: #fff">
              <img src="../assets/icons/icon_instagram_grey.png" alt="Sekolah.mu-instagram"/>
            </a>
            <a href="https://www.facebook.com/sekolahmuid/" rel="noopener noreferrer" target="_blank" style="margin-right: 10px; margin-bottom: 10px; color: #fff">
              <img src="../assets/icons/icon_facebook_grey.png" alt="Sekolah.mu-facebook"/>
            </a>
            <a href="https://twitter.com/sekolahmuid" rel="noopener noreferrer" target="_blank" style="margin-right: 10px; margin-bottom: 10px; color: #fff">
              <img src="../assets/icons/icon_twitter.png" alt="Sekolah.mu-twitter"/>
            </a>
            <a href="https://www.linkedin.com/company/sekolahmu" rel="noopener noreferrer" target="_blank" style="margin-right: 10px; margin-bottom: 10px; color: #fff">
              <img src="../assets/icons/icon_linkedin.png" alt="Sekolah.mu-linkedin"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    // toRouting (route) {
    //   this.$router.push(`${route}`)
    // }
  }
}
</script>

<style scoped>
.footer {
  background-color: #F2F2F2;;
  min-height: 290px;
  padding-bottom: 40px;
}

#footer-body {
  color: var(--color-neutral-100);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-wrap: wrap;
  padding-top: 30px
}

#footer-images {
  margin-right: 97px;
}

#icon-store-device {
  display: flex;
  align-items: center;
}

#footer-menu-right {
  display: flex;
  align-items: flex-start;
  height: 100%;
  margin-top: 50px;
}

#footer-menu-right a{
  text-decoration: none;
}

.footer-menu {
  margin-left: 35px;
  margin-right: 35px;
}

.footer-menu-title {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}

.footer-menu-submenu {
  color: rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
  cursor: pointer;
}

.footer-menu-submenu:hover {
  text-decoration: underline;
}

.sekolahmu-footer-logo{
  width: 100%;
  margin-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  #footer-body {
    flex-direction: column;
  }

  #footer-images {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
}

@media screen and (max-width: 990px) {
  .footer-menu-submenu {
    margin-bottom: 5px
  }
}

@media screen and (max-width: 770px) {
  #footer-menu-right {
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center;
    width: 100%;
  }

  .footer-menu {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }

  .footer-submenu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
