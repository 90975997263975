import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebook,
  faWhatsapp,
  faLinkedin,
  faTwitter,
  faLine
} from '@fortawesome/free-brands-svg-icons'

import {
  faStar as farStar,
  faCalendarCheck,
  faClock
} from '@fortawesome/free-regular-svg-icons'

import {
  faCheck,
  faHome,
  faPlayCircle,
  faSchool,
  faBuilding,
  faTimes,
  faChevronRight,
  faShare,
  faChevronDown,
  faStar,
  faStarHalfAlt,
  faArrowLeft,
  faPlus,
  faBars,
  faEye,
  faEyeSlash,
  faChevronUp,
  faSearch,
  faPhone,
  faEnvelope,
  faChevronLeft,
  faTrash,
  faTrashAlt,
  faDirections,
  faShareAlt,
  faEdit,
  faLink,
  faCog,
  faBusinessTime,
  faCalendarAlt,
  faPaperPlane,
  faInfoCircle,
  faEllipsisV,
  faCheckCircle,
  faMinus
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faCheck,
  faHome,
  faPlayCircle,
  faSchool,
  faBuilding,
  faTimes,
  faChevronRight,
  faShare,
  faChevronDown,
  faStar,
  farStar,
  faStarHalfAlt,
  faArrowLeft,
  faPlus,
  faBars,
  faEye,
  faEyeSlash,
  faChevronUp,
  faSearch,
  faPhone,
  faEnvelope,
  faChevronLeft,
  faTrash,
  faTrashAlt,
  faDirections,
  faFacebook,
  faLinkedin,
  faWhatsapp,
  faTwitter,
  faLine,
  faShareAlt,
  faEdit,
  faLink,
  faCog,
  faBusinessTime,
  faCalendarAlt,
  faPaperPlane,
  faInfoCircle,
  faCalendarCheck,
  faClock,
  faEllipsisV,
  faCheckCircle,
  faMinus
)
