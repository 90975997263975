import axiosServerGolang from '@/configs/axiosServerGolang'

export default {
  async setTranscriptPrivacy ({ Authorization, data }) {
    const url = `/program-activity/transcript-privacy`
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to set transcript privacy data')
    }
  }
}
