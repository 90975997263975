var firebaseConfig = {
  apiKey: process.env.VUE_APP_FBCONFIG_APIKEY,
  authDomain: process.env.VUE_APP_FBCONFIG_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FBCONFIG_DATABASEURL,
  projectId: process.env.VUE_APP_FBCONFIG_PROJECTID,
  storageBucket: process.env.VUE_APP_FBCONFIG_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FBCONFIG_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FBCONFIG_APPID,
  measurementId: process.env.VUE_APP_FBCONFIG_MEASUREMENTID
}

export default firebaseConfig
