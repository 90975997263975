import rupiah from '../functions/rupiah.js'

export const formatRupiahMixin = {
  methods: {
    formatRupiah (value, zeroWording) {
      if (zeroWording && +value === 0) {
        return zeroWording
      }
      return rupiah(+value)
    }
  }
}
