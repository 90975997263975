<template>
  <div id="navbar-login-collapse-mobile">
    <div>
      <div class="font-title-3 navbar-login-title">Selamat Datang di<br/>{{ appName }}</div>
      <div class="font-label-md-rg-lh16 navbar-login-subtitle">Mulai belajar dan tingkatkan metode belajarmu bersama {{ appName }}</div>
    </div>
    <b-img-lazy class="navbar-illustration" :src="imageSource" alt="selamat-datang"></b-img-lazy>
    <div>
      <router-link id="login-collapse-mobile-register-button" to="/register">
        <s-button variant="primary" class="font-subtitle-4 register-button" @onClick="saveLoginRedirect()">
          Daftar
        </s-button>
      </router-link>
      <router-link id="login-collapse-mobile-login-button" to="/login">
        <s-button variant="secondary" class="font-subtitle-4 login-button" @onClick="saveLoginRedirect()">
          Masuk
        </s-button>
      </router-link>
    </div>
  </div>
</template>
<script>
import SButton from '@/components/atoms/buttons/SButton.vue'

export default {
  name: 'NavbarLoginCollapseMobile',
  components: {
    SButton
  },
  computed: {
    platinumInstitutionInfo () {
      return this.$store.state.institution.platinumInstitutionInfo
    },
    isSMM () {
      return this.$store.state.platinumSubdomain === 'smm'
    },
    imageSource () {
      return this.isSMM
        ? 'https://cdn.sekolah.mu/assets/v2/smm-register-assets/selamat-datang-di-SMM.webp'
        : 'https://cdn.sekolah.mu/assets/home_v3/mobile-navigation/akun-dewasa-dan-anak-square.webp'
    },
    appName () {
      return this.isSMM ? this.platinumInstitutionInfo.name : 'Sekolah.mu'
    }
  },
  methods: {
    openRegisterModal () {
      this.$emit('toggleDaftar')
    },
    openLoginModal () {
      this.$emit('toggleLogin')
    },
    saveLoginRedirect () {
      this.$store.commit('SET_LOGIN_REDIRECTION', {
        path: this.$route.path,
        name: this.$route.name
      })
    }
  }
}
</script>
<style scoped>
a{
  text-decoration: none;
}
h5, p{
  margin: 0;
}
#navbar-login-collapse-mobile {
  min-height: calc(100vh - 50px);
  padding: 40px 16px 32px 16px;
  overflow-y: scroll;
  background: var(--color-neutral-100);

  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  background-image:
  url(https://cdn.sekolah.mu/assets/home_v3/mobile-navigation/bottom-left-cloud.webp),
  url(https://cdn.sekolah.mu/assets/home_v3/mobile-navigation/top-left-cloud.webp),
  url(https://cdn.sekolah.mu/assets/home_v3/mobile-navigation/right-cloud.webp),
  url(https://cdn.sekolah.mu/assets/home_v3/mobile-navigation/bottom-right-star.webp);
  background-position: bottom left, top left, right, bottom 30px right 25px;
  background-repeat: no-repeat;
  background-size: 50%, 25%, 45%, 15%;
}
#navbar-login-collapse-mobile::-webkit-scrollbar {
  display: none;
}

.navbar-login-title {
  margin-bottom: 16px;
}

.navbar-login-subtitle {
  padding-bottom: 34px;
}

.navbar-illustration {
  width: 100%;
  margin-bottom: 15px;
  max-width: 550px;
}

.register-button, .login-button {
  margin-bottom: 12px;
  width: 213px;
  display: block;
}

.img-background {
  position: absolute;
}

.circle-left {
  left: 0;
  bottom: 200px;
}

.cloud-bottom-left {
  bottom: 0;
  left: 0;
  z-index: 0;
}

.cloud-bottom {
  bottom: 0;
  right: 0;
}

.plus-right {
  right: 0;
  top: 80px;
}

@media screen and (max-width:400px) {
  .navbar-illustration {
    height: calc(100vh * 40 / 100);
    width: auto;
    object-fit: contain;
  }
}

</style>
