import axiosServer from '../../configs/axiosServer'

const prakerja = {
  namespaced: true,
  state: {
    invoice_code: '',
    transaction_code: '',
    url_invoice: ''
  },
  mutations: {
    CHANGE_TRANSACTION_CODE (state, payload) {
      state.transaction_code = payload
    },
    CHANGE_INVOICE_CODE (state, payload) {
      state.invoice_code = payload
    },
    CHANGE_URL_INVOICE (state, payload) {
      state.url_invoice = payload
    }
  },
  actions: {
    async requestOTP ({ commit, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: '/payment/pay/prakerja/send_otp/',
          method: 'POST',
          headers: {
            Authorization: rootState.storage.accessToken
          },
          data: payload
        })
        dispatch('storage/checkToken', data, { root: true })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async authorizeOTP ({ commit, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: '/payment/pay/prakerja/authorize/',
          method: 'POST',
          headers: {
            Authorization: rootState.storage.accessToken
          },
          data: payload,
          generateQueryParamSource: true
        })
        dispatch('storage/checkToken', data, { root: true })
        return data
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default prakerja
