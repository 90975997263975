<template>
  <div id="navbar-icon-programmu">
    <div class="icon-container">
      <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/kelasku.svg" alt="Sekolah.mu">
    </div>
    <p class="font-body-4 icon-wording">Kelasku</p>
  </div>
</template>
<script>
export default {
  name: 'NavbarIconProgrammu'
}
</script>
<style scoped>
h5, p{
  margin: 0;
}
#navbar-icon-programmu{
  text-align: center;
  cursor: pointer;
}
.icon-wording{
  line-height: 16px;
  white-space: nowrap;
  color: var(--color-neutral-800);
  margin-top: 2px;
}
.icon-container img {
  height: 24px;
  width: 24px;
}
</style>
