export default [
  'ebook',
  'webview',
  'freetext',
  'live_streaming',
  'toolkit',
  'html',
  'image',
  'document',
  'direct_chat',
  'discussion'
]
