import axiosServer from '../../configs/axiosServer'
import axiosServerGolang from '../../configs/axiosServerGolang'
import { evaluateChecklistCart, initStatusShopingCart } from '@/functions/payment/checkoutLogic'

// Cart V2
import cartAPI from '@/api/cart.js'
import { changeCartStatuses, divideAvailableAndUnavailableCart } from '@/functions/helper/cart/cartLogic.js'

const cart = {
  namespaced: true,
  state: {
    cartItems: [],
    cartChecklist: false,
    // V2 revamp
    items: [],
    unavailableItem: {},
    // multiple product cart
    totalBillCount: 0,
    totalBillAmount: 0,
    totalPackageCount: 0,
    totalPackageAmount: 0,
    totalAmount: 0,
    multipleCartErrors: []
  },
  mutations: {
    SET_MULTIPLE_CART (state, payload) {
      state.totalBillCount = payload.totalBillCount
      state.totalBillAmount = payload.totalBillAmount
      state.totalPackageCount = payload.totalPackageCount
      state.totalPackageAmount = payload.totalPackageAmount
      state.totalAmount = payload.totalAmount
    },
    SET_MULTIPLE_CART_ERRORS (state, payload) {
      state.multipleCartErrors = payload
    },
    INSERT_CART_ITEMS (state, payload) {
      state.cartItems = payload
    },
    TOGGLE_CART_CHECKLIST (state, payload) {
      state.cartChecklist = payload
    },
    ASSIGN_ITEMS (state, payload) {
      state.items = payload
    },
    ASSIGN_ITEMS_FROM_API (state, payload = []) {
      const [available, unavailable] = divideAvailableAndUnavailableCart(payload)
      const status = true
      const isInitCart = true
      const shoppingCart = changeCartStatuses(available, status, isInitCart)

      state.items = shoppingCart
      state.unavailableItem = unavailable
    }
  },
  actions: {
    async getCart ({ rootState, commit }) {
      const { data } = await axiosServerGolang({
        url: '/payment/cart',
        method: 'GET',
        headers: {
          Authorization: rootState.storage.accessToken
        }
      })
      if (data.status === 200) {
        let shoppingCart = initStatusShopingCart(data.data.items)
        let statusCartChecklist = evaluateChecklistCart(shoppingCart)
        commit('TOGGLE_CART_CHECKLIST', statusCartChecklist)
        commit('INSERT_CART_ITEMS', shoppingCart)
      }
      return data
    },
    async deleteCartItem ({ rootState, commit }, payload) {
      const { data } = await axiosServer({
        url: '/payment/v3/delete_cart_item/',
        method: 'POST',
        headers: {
          Authorization: rootState.storage.accessToken
        },
        data: payload
      })
      if (data.status === 200) {
        let shoppingCart = initStatusShopingCart(data.data.items)
        let statusCartChecklist = evaluateChecklistCart(shoppingCart)
        commit('TOGGLE_CART_CHECKLIST', statusCartChecklist)
        commit('INSERT_CART_ITEMS', shoppingCart)
      }
    },
    async getCartV2 ({ rootState, commit }) {
      const accessToken = rootState.storage.accessToken
      const { data } = await cartAPI.getCartLists({ accessToken })
      if (data?.status === 200) {
        commit('ASSIGN_ITEMS_FROM_API', data.data.items || [])
      }
      return data
    },
    async deleteCartV2 ({ rootState, commit }, payload) {
      const accessToken = rootState.storage.accessToken
      const { data } = await cartAPI.deleteCartItem({ accessToken, payload })
      if (data?.status === 200) {
        commit('ASSIGN_ITEMS_FROM_API', data.data.items || [])
        window.scrollTo(0, 0)
      }
    },
    async undoDeleteCartV2 ({ rootState, commit }, payload) {
      const accessToken = rootState.storage.accessToken
      const { data } = await cartAPI.undoDeleteCartItem({ accessToken, payload })
      if (data?.status === 200) {
        commit('ASSIGN_ITEMS_FROM_API', data.data.items || [])
        window.scrollTo(0, 0)
      }
    }
  },
  getters: {
    isAllChecked (state) {
      const { items } = state
      let isChecked = true

      for (let i = 0; i < items.length; i++) {
        const creator = items[i]
        if (!creator.isPicked) {
          isChecked = false
          break
        }

        for (let j = 0; j < creator.programs.length; j++) {
          const program = creator.programs[j]
          if (!program.isPicked) {
            isChecked = false
            break
          }

          for (let k = 0; k < program.addons.length; k++) {
            const addon = program.addons[k]
            if (!addon.isPicked) {
              isChecked = false
              break
            }
          }
        }
      }
      return isChecked
    },
    cartSummary (state) {
      const { items } = state
      const summaryProgram = {
        count: 0,
        price: 0
      }
      const summaryAddon = {
        count: 0,
        price: 0
      }
      let totalPrice = 0
      let totalItem = 0

      items.forEach(creator => {
        creator.programs.forEach(program => {
          if (program.isPicked) {
            summaryProgram.count++
            summaryProgram.price += program.amount

            program.addons.forEach(addon => {
              if (addon.isPicked) {
                summaryAddon.count++
                summaryAddon.price += addon.amount
              }
            })
          }
        })
      })

      totalPrice = summaryProgram.price + summaryAddon.price
      totalItem = summaryProgram.count + summaryAddon.count

      return {
        totalPrice,
        totalItem,
        summaryProgram,
        summaryAddon
      }
    }
  }
}

export default cart
