<template>
    <div id="navbar-icon-jadwal">
      <div class="icon-container">
        <img src="https://cdn.sekolah.mu/assets/v2/menu-icon/jadwal.svg" alt="icon-jadwal">
      </div>
      <p class="font-body-4 icon-wording">Jadwal</p>
    </div>
  </template>
<script>

export default {
  name: 'NavbarIconTanyamu'
}
</script>
  <style scoped>
  h5, p{
    margin: 0;
  }
  #navbar-icon-jadwal{
    text-align: center;
    cursor: pointer;
  }
  .icon-wording{
    line-height: 16px;
    white-space: nowrap;
    color: var(--color-neutral-800);
    margin-top: 2px;
  }
  .icon-container{
    position: relative;
  }
  .icon-container img {
    height: 24px;
    width: 24px;
  }
  .counter{
    position: absolute;
    top: 0px;
    left: 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    background: var(--color-crimson-500);
    color: var(--color-neutral-100);
    border-radius: 32px;
    padding: 0px 5px;
    border: solid 1px var(--color-neutral-100);
  }
  </style>
