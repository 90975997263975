const loadingState = {
  namespaced: true,
  state: {
    isLoadingOverlay: false,
    overlayText: 'Sedang memproses...',
    containerStyle: {},
    imageStyle: []
  },
  mutations: {
    SET_LOADING_OVERLAY (state, payload) {
      state.isLoadingOverlay = payload
    },
    SET_OVERLAY_TEXT (state, payload) {
      state.overlayText = payload
    },
    SET_CONTAINER_STYLE (state, payload) {
      state.containerStyle = payload
    },
    SET_IMAGE_STYLE (state, payload) {
      state.imageStyle = payload
    }
  },
  actions: {
    loadingDapodik ({ commit }) {
      commit('SET_IMAGE_STYLE', [{ width: '60px' }])
      commit('SET_OVERLAY_TEXT', 'Sedang diproses...')
      commit('SET_CONTAINER_STYLE', {
        'width': '80vw',
        'max-width': '288px',
        'border-radius': '10px',
        'padding': '16px'
      })
      commit('SET_LOADING_OVERLAY', true)
    },
    loadingDapodikDone ({ commit }) {
      commit('SET_LOADING_OVERLAY', false)
      commit('SET_CONTAINER_STYLE', {})
      commit('SET_IMAGE_STYLE', [])
      commit('SET_OVERLAY_TEXT', 'Sedang memproses...')
    }
  }
}

export default loadingState
