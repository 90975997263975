<template>
  <div id="chat-room-full-message">
    <div class="close-button">
      <img src="../../assets/icons/icon_close.svg" @click.prevent="closeModal"/>
    </div>
    <div class="error-message-content">
      <b-img-lazy
        :src="require('../../assets/images/ful_chat_room.png')"
        width="190"
        height="155"
        alt="chat-room-full"
      ></b-img-lazy>
      <h2 class="error-message-title">Ruang Diskusi Penuh</h2>
      <p class="error-message-body">Kamu dapat mencoba kembali secara berkala untuk masuk ke ruang diskusi ini.</p>
      <b-button class="footer-close-button" @click.prevent="closeModal">Kembali</b-button>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>
<style scoped>
h2, p{
  margin: 0;
}
.close-button{
  text-align: end;
}
.close-button img{
  cursor: pointer;
}
.error-message-content{
  text-align: center;
}
.error-message-title{
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 19px;
  color: var(--color-persian-blue-900);
  margin-top: 20px;
}
.error-message-body{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: var(--color-neutral-800);
  margin-top: 10px;
}
.footer-close-button{
  background: var(--color-persian-blue-500);
  border-radius: 10px;
  border: 1px solid var(--color-persian-blue-500);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding: 8px 70px;
  color: var(--color-neutral-100);
  margin-top: 26px;
}
</style>
