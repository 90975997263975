<template>
  <div v-if="isShowOverLay" class="loading-overlay">
    <div class="loading-content" :style="containerStyle">
      <lottie-player
        :src="JSON.stringify(loadingAnimation)"
        autoplay
        loop
        mode="normal"
        class="dots-animation"
        :style="imageStyle"
      />
      <span>
        {{ overlayText }}
      </span>
    </div>
  </div>
</template>

<script>
import LottiePlayer from '@lottiefiles/lottie-player'
import loadingAnimation from '@/assets/animations/coki-riding-plane.json'

export default ({
  name: 'LoadingOverlay',
  components: {
    LottiePlayer
  },
  data () {
    return {
      loadingAnimation
    }
  },
  computed: {
    isShowOverLay () {
      return this.$store.state.loadingState.isLoadingOverlay
    },
    overlayText () {
      return this.$store.state.loadingState.overlayText
    },
    containerStyle () {
      return this.$store.state.loadingState.containerStyle
    },
    imageStyle () {
      return this.$store.state.loadingState.imageStyle
    }
  }
})
</script>

<style lang="scss" scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999; // s-modal z-index 9998
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .loading-content {
    background-color: var(--color-neutral-100);
    border-radius: 20px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .dots-animation {
      width: 240px;
    }
  }
}
</style>
