import axiosServer from '../../configs/axiosServer'

const certificate = {
  namespaced: true,
  state: {
    currentCertificate: {}
  },
  mutations: {
    CHANGE_CURRENT_CERTIFICATE (state, payload) {
      state.currentCertificate = payload
    }
  },
  actions: {
    async getCertificateRegular ({ commit }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/certificate/regular/${payload}`,
          method: 'GET'
        })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async getCertificateAddon ({ commit }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/certificate/addon/${payload}`,
          method: 'GET'
        })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async getPublicCertificate ({ commit }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/certificate_preview/${payload}`,
          method: 'GET',
          generateQueryParamSource: true
        })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async generateSertifikat (_, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/certificate/generate_file/`,
          method: 'POST',
          data: payload,
          generateQueryParamSource: true
        })
        return data
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default certificate
