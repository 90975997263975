/* global CE2 */

export default {
  methods: {
    initCrazyEgg () {
      window.CE_READY = function () {
        console.log('crazy egg ready')
        CE2.set(1, this.$store.state.user.userInfo.id.toString())
        CE2.set(2, this.$store.state.user.userInfo.profession_name)
        CE2.set(3, this.$store.state.user.userInfo.year_level_name)
        CE2.set(4, this.$store.state.user.userInfo.role[0].label)
        CE2.set(
          5,
          this.$store.state.user.userInfo.prakerja_id
            ? 'Prakerja User'
            : 'Non-Prakerja User'
        )
      }
    }
  }
}
