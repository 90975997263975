import deviceDetector from 'mobile-device-detect'

export default {
  data () {
    return {
      isMobile: deviceDetector.isMobile
    }
  },
  methods: {
    sendClickEventTracker (eventName) {
      window.firebaseAnalytics.logEvent(eventName, {
        user_profession: this.$store.state.user.userInfo.profession_name,
        user_year_level: this.$store.state.user.userInfo.year_level_name,
        user_id: this.$store.state.user.userInfo.id,
        source: this.isMobile ? 'mobile-web' : 'web',
        platform: 'sekolahmu'
      })
    }
  }
}
