const routes = [
  {
    path: '/aktivitas-tambahan/tatap-muka/:add_on_slug',
    name: 'Offline Add On Discovery',
    meta: {
      title: 'Sekolah.mu | Aktivitas Tambahan - Tatap Muka'
    },
    component: () => import(/* webpackChunkName: 'addon-discovery-offline' */ '../views/addOnDiscovery/Offline.vue')
  },
  {
    path: '/aktivitas-tambahan/konsultasi/:add_on_slug',
    name: 'Konsultasi Add On Discovery',
    meta: {
      title: 'Sekolah.mu | Aktivitas Tambahan - Konsultasi'
    },
    component: () => import(/* webpackChunkName: 'addon-discovery-konsultasi' */ '../views/addOnDiscovery/Konsultasi.vue')
  },
  {
    path: '/aktivitas-tambahan/kelas-plus-daring/:add_on_slug',
    name: 'Kelas Plus Add On Discovery',
    meta: {
      title: 'Sekolah.mu | Aktivitas Tambahan - Kelas Plus Daring'
    },
    component: () => import(/* webpackChunkName: 'addon-discovery-kelas-plus' */ '../views/addOnDiscovery/KelasPlus.vue')
  },
  {
    path: '/aktivitas-tambahan/lainnya/:add_on_slug',
    name: 'Lainnya Add On Discovery',
    meta: {
      title: 'Sekolah.mu | Aktivitas Tambahan - Lainnya'
    },
    component: () => import(/* webpackChunkName: 'addon-discovery-lainnya' */ '../views/addOnDiscovery/Lainnya.vue')
  }
]

export default routes
