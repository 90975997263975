<template>
  <div class="text-center text-primary my-2">
    <b-spinner class="align-middle mr-2"></b-spinner>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
