<template>
  <div id="mobile-filter">
    <s-bottom-sheet
      class="mobile-filter-bottom-sheet"
      :class="isShowMobileSelector ? 'activity-selector' : 'first-selector'"
      v-model="filterData.isShowFilter"
    >
      <div class="mobile-filter-wrapper">
        <div v-if="!isShowMobileSelector">
          <div class="mobile-title-wrapper">
            <div class="inter-normal-bold">Filter Aktivitas</div>
            <s-button
              id="search-activity-reset-btn"
              class="inter-medium_small-bold"
              variant="ghost"
              @click="resetFilter"
            >
              Reset
            </s-button>
          </div>
          <div>
            <div class="inter-medium_small-bold s-mt-5">Kategori Aktivitas</div>
            <div>
              <div id="toggle-show-selector" @click="toggleShowSelector" class="checkbox-selector">
                {{ checkboxSelectorWording }}
                <img
                  src="@/assets/icons/chevron-right.png"
                  alt="chevron-right"
                />
              </div>
            </div>
          </div>
          <div>
            <s-button
              id="search-activity-apply-filter-btn"
              class="w-100 apply-filter-btn"
              variant="primary"
              @click="applyFilter"
            >
              Terapkan
            </s-button>
          </div>
        </div>
        <div v-else>
          <div class="mobile-title-wrapper">
            <s-button
              id="search-activity-back-btn"
              class="inter-medium_small-bold back-btn"
              variant="ghost"
              @click="backPage"
            >
              <div class="inter-normal-bold back-arrow-btn">
                <font-awesome-icon icon="arrow-left" />
                Kategori Aktivitas
              </div>
            </s-button>
            <s-button
              id="search-activity-reset-btn"
              class="inter-medium_small-bold"
              variant="ghost"
              @click="resetFilter"
              :disabled="isApplyBtnDisabled"
            >
              Reset
            </s-button>
          </div>
          <div>
            <b-form-checkbox-group
              id="search-activity-type-checkbox-group"
              v-model="optionSelected"
              :options="activityCategoryTypes"
              stacked
            />
          </div>
          <div>
            <s-button
              id="search-activity-apply-filter-btn"
              class="w-100 apply-filter-btn"
              variant="primary"
              @click="saveFilter"
              :disabled="isApplyBtnDisabled"
            >
              Simpan
            </s-button>
          </div>
        </div>
      </div>
    </s-bottom-sheet>
  </div>
</template>

<script>
import categoryType from '@/constants/activityCategoryTypes.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import activityFilterMixin from '@/mixins/activityV2/filterActivity.js'

export default {
  name: 'SearchActivityMobileFilter',
  components: {
    FontAwesomeIcon
  },
  props: {
    filterData: {
      type: Object,
      default: () => {
        return {
          selected: [],
          isShowFilter: false,
          currentSelectedTotal: 0
        }
      }
    }
  },
  mixins: [activityFilterMixin],
  data () {
    return {
      isShowMobileSelector: false,
      activityCategoryTypes: categoryType.activityCategoryTypes,
      optionSelected: []
    }
  },
  computed: {
    isApplyBtnDisabled () {
      return this.optionSelected.length === 0
    },
    checkboxSelectorWording () {
      if (this.filterData.currentSelectedTotal === 0) {
        return 'Pilih kategori aktivitas'
      }
      return `${this.filterData.currentSelectedTotal} kategori aktivitas dipilih`
    }
  },
  methods: {
    toggleShowSelector () {
      this.isShowMobileSelector = !this.isShowMobileSelector
    },
    backPage () {
      this.toggleShowSelector()
      this.optionSelected = this.filterData.selected
    },
    resetFilter () {
      this.sendClickEventTracker('hb_aktivitas_reset_filter_click')
      this.optionSelected = []
      this.filterData.selected = []
      this.isShowMobileSelector = false
      this.applyFilter()
    },
    saveFilter () {
      this.toggleShowSelector()
      this.filterData.selected = this.optionSelected
      this.filterData.currentSelectedTotal = this.filterData.selected.length
    },
    applyFilter () {
      this.sendClickEventTracker('hb_aktivitas_button_filter_click')
      this.$emit('applyFilter')
      this.filterData.isShowFilter = false
      this.filterData.currentSelectedTotal = this.filterData.selected.length
    }
  }
}
</script>

<style scoped>
.mobile-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.back-arrow-btn {
  display: flex;
  align-items: center;
  gap: 16px;
}
.back-btn {
  color: var(--color-text-100);
}
.mobile-filter-wrapper {
  position: relative;
}
.checkbox-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid var(--color-neutral-500);
  padding: 10px 16px;
  margin: 8px 0 16px;
}
.checkbox-selector img {
  width: 10px;
}
.first-selector ::v-deep .bottom-sheet__card, .activity-selector ::v-deep .bottom-sheet__card {
  padding-bottom: 14px;
  height: fit-content !important; /* need important to override the inline style in sheet */
}
.mobile-filter-wrapper ::v-deep .custom-control {
  border-bottom: solid 1px var(--color-neutral-500);
  padding: 0 0 12px 28px;
}
.mobile-filter-wrapper ::v-deep .custom-control-label {
  position: unset;
}
.mobile-filter-wrapper ::v-deep .custom-control-label::before {
  left: unset;
  right: 24px;
}
.mobile-filter-wrapper ::v-deep .custom-control-label::after {
  left: unset;
  right: 24px;
}
.mobile-filter-wrapper ::v-deep .apply-filter-btn {
  min-width: 100%;
}
::v-deep .bottom-sheet__content {
  height: fit-content !important;
}
</style>
