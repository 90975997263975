const routes = [
  {
    path: '/kebijakan-privasi',
    name: 'Kebijakan Privasi',
    meta: {
      title: 'Kebijakan Privasi'
    },
    component: () => import(/* webpackChunkName: 'docsPage' */ '../views/docs/KebijakanPrivasi.vue')
  },
  {
    path: '/syarat-ketentuan',
    name: 'Syarat Ketentuan',
    meta: {
      title: 'Syarat & Ketentuan'
    },
    component: () => import(/* webpackChunkName: 'docsPage' */ '../views/docs/SyaratKetentuan.vue')
  },
  {
    path: '/profil-sekolahmu',
    name: 'ProfilSekolahmu',
    meta: {
      title: 'Profil'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ProfilSekolahmu.vue')
  },
  {
    path: '/mitra-sekolahmu',
    name: 'MitraSekolahmu',
    meta: {
      title: 'Mitra'
    },
    component: () =>
      import(/* webpackChunkName: 'partner' */ '../views/MitraSekolahmu.vue')
  },
  {
    path: '/dewan-sekolahmu',
    name: 'DewanSekolahmu',
    meta: {
      title: 'Dewan'
    },
    component: () =>
      import(/* webpackChunkName: "board" */ '../views/DewanSekolahmu.vue')
  },
  {
    path: '/mitra-kolaborasi',
    name: 'mitra-kolaborasi',
    component: () => import('../views/MitraPrakerja.vue')
  },
  {
    path: '/kontak-sekolahmu',
    name: 'KontakSekolahmu',
    meta: {
      title: 'Kontak'
    },
    component: () =>
      import(/* webpackChunkName: 'contact' */ '../views/KontakSekolahmu.vue')
  },
  {
    path: '/bantuan',
    name: 'Bantuan',
    meta: {
      title: 'Bantuan'
    },
    component: () => import(/* webpackChunkName: 'help' */ '../views/Bantuan.vue'),
    beforeEnter: (to, from, next) => {
      window.open('https://panduan.sekolah.mu/', from.name ? '_blank' : '_self')
    }
  }
]

export default routes
