import QiscusCore from 'qiscus-sdk-core'
import desktopNotification from '@/functions/pushNotification/desktopNotification'
import setupPushNotifTanyamuBody from '@/functions/pushNotification/setupPushNotifTanyamuBody'
import crypto from '@/functions/crypto'

const tanyamu = {
  namespaced: true,
  state: {
    qiscus: null,
    qiscusReady: false,
    initLoading: false,
    rooms: [],
    fullRoomList: [],
    comments: [],
    commentsFloating: [],
    initMessage: '',
    dataParticipant: [],
    currentChatRoom: {
      name: ''
    },
    currentChatRoomFloating: {
      id: '',
      name: ''
    },
    loadingMessage: false,
    loadingRooms: false,
    totalUnreadCounter: 0,
    isLoggingInQiscus: false,
    setQiscusConfig: process.env.VUE_APP_SET_QISCUS_CONFIG === 'true'
  },
  mutations: {
    CHANGE_QISCUS (state, payload) {
      state.qiscus = payload
    },
    CHANGE_QISCUS_READY (state, payload) {
      state.qiscusReady = payload
    },
    CHANGE_INIT_LOADING (state, payload) {
      state.initLoading = payload
    },
    CHANGE_ROOM_LIST (state, payload) {
      state.rooms = payload
    },
    CHANGE_FULL_ROOM_LIST (state, payload) {
      state.fullRoomList = payload
    },
    CHANGE_COMMENTS (state, payload) {
      state.comments = payload
    },
    CHANGE_COMMENTS_FLOATING (state, payload) {
      state.commentsFloating = payload
    },
    CHANGE_INIT_MESSAGE (state, payload) {
      state.initMessage = payload
    },
    CHANGE_DATA_PARTICIPANT (state, payload) {
      state.dataParticipant = payload
    },
    CHANGE_CURRENT_CHAT_ROOM (state, payload) {
      state.currentChatRoom = payload
    },
    CHANGE_CURRENT_CHAT_ROOM_FLOATING (state, payload) {
      state.currentChatRoomFloating = payload
    },
    CHANGE_LOADING_MESSAGE (state, payload) {
      state.loadingMessage = payload
    },
    CHANGE_LOGGINGIN_QISCUS (state, payload) {
      state.isLoggingInQiscus = payload
    },
    CHANGE_LOADING_ROOMS (state, payload) {
      state.loadingRooms = payload
    },
    CHANGE_TOTAL_UNREAD_COUNTER (state, payload) {
      switch (payload.type) {
        case 'substract':
          if (payload.value <= state.totalUnreadCounter) {
            state.totalUnreadCounter -= payload.value
          } else {
            state.totalUnreadCounter = 0
          }
          break
        case 'add':
          state.totalUnreadCounter += payload.value
          break
        case 'change':
          state.totalUnreadCounter = payload.value
          break
        default:
          state.totalUnreadCounter = payload.value
          break
      }
    }
  },
  actions: {
    async loginQiscus ({ commit, state, rootState, dispatch }, payload) {
      try {
        commit('CHANGE_QISCUS', new QiscusCore())
        await state.qiscus.init({
          AppId: process.env.VUE_APP_QISCUS_SDK_APP_ID,
          options: {
            loginSuccessCallback () {
              commit('CHANGE_QISCUS_READY', true)
              dispatch('getTotalUnreadMessage')
              if (window.location.pathname === '/tanyamu') {
                dispatch('getRoomList')
              }
            },
            loginErrorCallback (data) {
              commit('CHANGE_INIT_MESSAGE', 'Terjadi Kesalahan Pada Login Chat.')
            },
            // commentDeletedCallback: function (data) {},
            // commentDeliveredCallback: function (data) {},
            // commentReadCallback: function (data) {},
            // presenceCallback: function (data, userId) {},
            // typingCallback: function (data) {},
            // onReconnectCallback: function (data) {},
            newMessagesCallback: function (messages) {
              if (messages[0].room_id === state.currentChatRoom.id) {
                dispatch('getRoomMessages', messages[0].room_id)
              }
              if (messages[0].room_id === state.currentChatRoomFloating.id) {
                commit('CHANGE_COMMENTS_FLOATING', [...state.commentsFloating, messages[0]])
              }
              dispatch('getRoomList')
              if (Number(messages[0].email) !== Number(rootState.storage.userId)) {
                commit('CHANGE_TOTAL_UNREAD_COUNTER', {
                  type: 'add',
                  value: messages.length
                })
                const { body, image } = setupPushNotifTanyamuBody(messages[0])
                desktopNotification(messages[0].username, {
                  body: body,
                  image: image,
                  icon: 'https://cdn.sekolah.mu/assets/home/footer_logo.svg'
                }, '/tanyamu')
              }
            }
            // roomClearedCallback: function (data) {},
            // roomChangedCallback: function (data) {},
          }
        })
        await dispatch('setUserQiscus')
      } catch (error) {
        console.log(error, 'failed init qiscus')
      }
    },
    async setUserQiscus ({ commit, state, rootState, dispatch }, payload) {
      try {
        let userKey
        if (state.setQiscusConfig) {
          userKey = crypto.md5Encrypt(`${rootState.storage.userId}${process.env.VUE_APP_APP_KEY}${rootState.user.userInfo.email}`)
        } else {
          userKey = rootState.storage.userId
        }
        await state.qiscus.setUser(
          rootState.storage.userId,
          userKey,
          rootState.storage.name,
          rootState.storage.avatar
        )
      } catch (error) {
        console.log(error, 'failed set user qiscus')
      }
    },
    async getRoomList ({ commit, state, rootState, dispatch }, payload) {
      commit('CHANGE_LOADING_ROOMS', true)
      try {
        const rooms = await state.qiscus.loadRoomList({
          show_empty: true,
          limit: 100
        })
        commit('CHANGE_ROOM_LIST', rooms)
        commit('CHANGE_FULL_ROOM_LIST', rooms)
      } catch (error) {
        console.log('failed get chat rooms')
      }
      commit('CHANGE_LOADING_ROOMS', false)
    },
    async getRoomMessages ({ commit, state, rootState, dispatch }, payload) {
      try {
        let comments = await state.qiscus.loadComments(payload, {
          last_comment_id: 12345678910
        })
        comments = comments.sort(function (a, b) {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        })
        commit('CHANGE_COMMENTS', comments)
        return comments
      } catch (error) {
        console.log('failed get chat messages')
      }
    },
    async getTotalUnreadMessage ({ commit, state, rootState, dispatch }, payload) {
      if (rootState.activityV2.isInStudyPageRevamp) {
        return
      }
      try {
        const dataUnread = await state.qiscus.getTotalUnreadCount()
        commit('CHANGE_TOTAL_UNREAD_COUNTER', {
          type: 'change',
          value: dataUnread
        })
      } catch (error) {
        console.log(error)
      }
    },
    logoutQiscus ({ commit, state }) {
      state.qiscus.disconnect()
      commit('CHANGE_QISCUS', null)
    }
  }
}

export default tanyamu
