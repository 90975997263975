<template>
  <div :class="blankLayoutClasses">
    <s-navbar v-if="usingMobileHeader && isMobile" class="soft-shadow">
      <template #left>
        <s-navbar-item class="py-2">
          <s-navbar-item v-if="hasBackButtonMobile" class="mr-4">
            <font-awesome-icon id="back-arrow" icon="arrow-left" @click="arrowClicked"/>
          </s-navbar-item>
          <s-navbar-item>
            <h2 class="inter-normal-bold mb-0">{{ headerTitle }}</h2>
          </s-navbar-item>
        </s-navbar-item>
      </template>
    </s-navbar>
    <slot />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'

import EventBus from '@/plugin/EventBus'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  components: {
    FontAwesomeIcon
  },
  data () {
    return {
      isMobile,
      headerProperties: {
        title: '',
        url: ''
      }
    }
  },
  created () {
    EventBus.$on('changeMobileHeader', (payload) => {
      this.$set(this.headerProperties, 'url', payload.url ? payload.url : this.headerProperties.url)
      this.$set(this.headerProperties, 'title', payload.title ? payload.title : this.headerProperties.title)
    })
  },
  async mounted () {
    // let hubSpotFrame = document.getElementById('hubspot-messages-iframe-container')
    // if(hubSpotFrame !== null || hubSpotFrame !== undefined){
    //   let newData = document.getElementById('hubspot-messages-iframe-container')
    //   newData.style.display = 'none'
    //   newData.style.width = '0px'
    //   newData.style.height = '0px'
    // }
    const hideSociomile = time => new Promise(resolve => setTimeout(() => {
      let sociomile = document.getElementsByClassName('custom_widget')[0]
      if (sociomile) {
        sociomile.style.display = 'none'
        resolve(true)
      } else {
        resolve(false)
      }
    }, time))

    this.$store.commit('SET_ENV_BELAJAR')
    this.$store.commit('SET_ACTIVE_ENVIRONMENT')
    this.$store.dispatch('subnavbar/initMenuList')
    for (let i = 0; i < 5; i++) {
      const sociomile = await hideSociomile(300)
      if (sociomile) {
        break
      }
    }
  },
  computed: {
    metaModule () {
      return this.$route.meta.module
    },
    blankLayoutClasses () {
      return {
        'neutral-background': this.metaModule === 'profile' || this.metaModule === 'payment-scheme'
      }
    },
    hasBackButtonMobile () {
      return this.$route.meta.hasBackButtonMobile
    },
    headerTitle () {
      return this.headerProperties.title || this.$route.meta.headerTitle
    },
    usingMobileHeader () {
      return this.$route.meta.usingMobileHeader
    },
    backButtonMobileLink () {
      return this.headerProperties.url || this.$route.meta.backButtonMobileLink || '/'
    }
  },
  methods: {
    async arrowClicked () {
      const prevBackButtonMobileLink = this.backButtonMobileLink
      this.headerProperties.title = ''
      this.headerProperties.url = ''

      await this.$router.push(prevBackButtonMobileLink)
    }
  }
}
</script>

<style>
.neutral-background {
  background: var(--color-neutral-400);
}
</style>
