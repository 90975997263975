import axiosServerGolang from '@/configs/axiosServerGolang'
import axios from 'axios'

const PaymentHistoryAPI = {
  getV2: async (url, payload) => {
    try {
      const { data } = await axiosServerGolang({
        url: `/payment${url}`,
        method: 'GET',
        headers: {
          Authorization: payload.token
        }
      })
      return data
    } catch (error) {
      return error
    }
  },
  postV2: async (url, payload) => {
    try {
      const { data } = await axiosServerGolang({
        url: `/payment${url}`,
        method: 'POST',
        data: payload.data,
        headers: {
          Authorization: payload.token
        }
      })
      return data
    } catch (error) {
      return error
    }
  },
  loadMore: async (url, payload) => {
    try {
      const { data } = await axios({
        url: url,
        method: 'POST',
        data: payload.data,
        headers: {
          Authorization: payload.token
        }
      })
      return data
    } catch (error) {
      return error
    }
  }
}

export default PaymentHistoryAPI
