<template>
  <div class="topic-regular mb-1 position-relative cursor-pointer border-0 ff-primary">
    <div class="mb-2">
      <s-tag
        bg-color="--color-eucalyptus-100"
        text-color="--color-eucalyptus-700"
        v-if="data.is_addon && hasEnrolled"
      >
        Topik Tambahan
      </s-tag>
    </div>
    <div class="text-left pr-5">
      <span
        class="d-block topic-title fs-16 fw-600"
        :class="{ 'primary-font-color': hasEnrolled, 'text-eucalyptus-700': data.is_addon && !hasEnrolled }"
      >
        {{data.title}}
      </span>
      <span class="fs-12 fw-400 meta-info text-gargoyle">{{data.total_resource}} Aktivitas</span>
      <span v-if="data.duration"> - <span class="fs-12 fw-400 meta-info text-gargoyle">{{formattedDuration}}</span></span>
    </div>
    <img
      src="@/assets/icons/activity-v2/chevron-down.svg"
      class="img-fluid toggle-indicator"
      v-if="hasActivities"
    />
  </div>
</template>
<script>
import STag from '@/components/atoms/Tags/STag'
import moment from 'moment'

export default {
  name: 'TopicRegular',
  components: {
    STag
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    hasEnrolled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedDuration () {
      return moment(moment().startOf('day').minutes(this.data.duration)).format('HH:mm:ss')
    },
    hasActivities () {
      return this.data.total_resource > 0
    }
  }
}
</script>
<style scoped>
.topic-regular {
  background-color: transparent;
}

.topic-regular:focus,
.topic-regular:active {
  outline: none;
}

.toggle-indicator {
  filter: invert(66%) sepia(0%) saturate(1752%) hue-rotate(140deg) brightness(92%) contrast(84%);
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%) rotate(0deg);
  -ms-transform: translateY(-50%) rotate(0deg);
  transform: translateY(-50%) rotate(0deg);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.not-collapsed .toggle-indicator {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
</style>
