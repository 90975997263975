import { SEARCH_TYPE } from '../constants/gtmEventNames'

export default function createSearchTypeData (searchPayload, result) {
  const data = {
    'event': SEARCH_TYPE,
    'keyword': `${searchPayload.search}`,
    'number_of_search_results': result.length,
    'source': `${searchPayload.source}`,
    'user_id': searchPayload.user_id
  }

  return data
}
