<template>
  <div role="tablist">
    <section-search-activity />
    <div v-for="(topic, index) in topicData" :key="index">
      <section-enrolled-topic
        :data="topic"
        :topic-index="index"
        :is-linier="isLinier"
        v-if="topic.enrolled"
      />
      <section-addon-topic
        :data="topic"
        :topic-index="index"
        :is-linier="isLinier"
        v-else-if="topic.is_addon && !topic.enrolled"
      />
    </div>
  </div>
</template>
<script>
import SectionEnrolledTopic from '@/components/activityV2/Sidebar/Tabs/TabActivity/SectionEnrolledTopic'
import SectionAddonTopic from '@/components/activityV2/Sidebar/Tabs/TabActivity/SectionAddonTopic'
import activityV2GeneralMixin from '@/mixins/activityV2/general'
import activityV2SidebarTabActivityMixin from '@/mixins/activityV2/sidebarTabActivity'
import ProgramActivityAPI from '@/api/program-activity'
import SectionSearchActivity from '@/components/activityV2/Sidebar/Tabs/TabActivity/SectionSearchActivity'

export default {
  name: 'TabActivity',
  mixins: [activityV2GeneralMixin, activityV2SidebarTabActivityMixin],
  components: {
    SectionEnrolledTopic,
    SectionAddonTopic,
    SectionSearchActivity
  },
  data () {
    return {
      topicData: [],
      isLinier: false
    }
  },
  computed: {
    enrolledTopic () {
      return this.topicData.filter(topic => topic.enrolled)
    },
    toggleQuizV2Text () {
      return this.isQuizV2 ? 'Hide' : 'Show'
    }
  },
  methods: {
    async getTabActivityItems () {
      try {
        const { program_id: id, user_id, program_user_id, linier } = this.activityValidate
        const result = await ProgramActivityAPI.getTabActivityItems({
          Authorization: this.authorization,
          data: {
            user_id,
            program_user_id,
            id
          }
        })
        this.topicData = result.data.data.chapter_list.filter(topic => topic.chapters.length) // Return non empty topic
        this.setOfTopicResources = this.topicData.map(topic => { // Get each topic's total actvities
          if (topic.enrolled) {
            return topic.chapters[0].total_resource
          } else {
            return 0
          }
        })
        this.isLinier = linier
      } catch (error) {
        console.error(error)
      }
    },
    toggleQuizV2 () {
      const quizVersion = this.isQuizV2 ? 'quiz' : 'quiz-v2'
      this.$store.commit('activityV2/TOGGLE_ACTIVITY_TYPE', quizVersion)
    }
  },
  mounted () {
    this.getTabActivityItems()
  }
}
</script>
