function kariermuUrl () {
  let hostname = window.location.hostname
  if (hostname.includes('dev') || hostname.includes('localhost')) {
    return 'https://www.dev.karier.mu'
  } else if (hostname.includes('staging')) {
    return 'https://www.staging.karier.mu'
  } else {
    return 'https://www.karier.mu'
  }
}

export default {
  kariermuUrl
}
