import axios from 'axios'
import deviceDetector from 'mobile-device-detect'
import generateQueryParamSource from '@/functions/generateQueryParamSource'

const axiosServer = axios.create({
  baseURL: process.env.VUE_APP_URL_SERVER
})

axiosServer.interceptors.request.use(
  function (req) {
    let traceSource = ''
    if (req.generateQueryParamSource) {
      const isMobile = deviceDetector.isMobile
      const sourceString = req.source || 'sekolah.mu'
      traceSource = generateQueryParamSource(sourceString, isMobile)
    }

    const version = req.version || 'v2'
    req.baseURL = req.baseURL + '/' + version

    req.url = req.url.includes('?')
      ? req.url + '&' + traceSource
      : req.url + '?' + traceSource
    return req
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default axiosServer
