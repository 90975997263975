<template>
  <div id="modal-confirmation-register">
    <div id="close-icon">
      <img src="../../assets/icons/icon_close.svg" @click="toggleConfirmationRegister()"/>
    </div>
    <div id="modal-pic">
      <img src="../../assets/icons/icon_confirmation_password.svg" />
    </div>
    <div id="modal-title" class="gotham-bold">
      Verifikasi Email
    </div>
    <div id="modal-description">
      Cek kotak masuk/spam kamu ya, email aktivasi akun sudah dikirim ke <span>{{ email }}</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toggleConfirmationRegister () {
      this.$emit('toggleConfirmationRegister')
    }
  },
  computed: {
    email () {
      return this.$store.state.login.email
    }
  }
}
</script>

<style>
#modal-confirmation-register {
  padding: 0px 13px
}

#close-icon {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#close-icon img {
  cursor: pointer
}

#modal-pic {
  text-align: center;
}

#modal-title {
  text-align: center;
  color: #172D5C;
  margin-top: 25px;
  font-size: 20px;
}

#modal-description {
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  margin-top: 15px;
}

#modal-description span {
  font-weight: 600;
}
</style>
