<template>
  <div id="group-selection" class="group-selection ff-primary">
    <s-modal
      v-if="!isMobile && !isError"
      cluster="s"
      title="Pilih Kelompok Belajar Kelas"
      class="modal-selection-desktop"
      :value="isShow"
      :show-close-icon="false"
    >
      <template #default>
        <div class="content-wrapper">
          <group-selection-content
            :is-loading="isLoading"
            :group-list="groupList"
            :selected-item="selectedItem"
            @search="handleSearch"
            @select="handleSelect"
          />
        </div>
      </template>
      <template #footer>
        <div class="footer-wrapper">
          <s-button
            id="cancel-button"
            variant="secondary"
            pill
            @click.prevent="handleCancel"
          >
            <span class="font-label-1">
              Batalkan
            </span>
          </s-button>
          <s-button
            id="submit-button"
            variant="primary"
            pill
            :disabled="!selectedItem.id"
            @click.prevent="handleSubmit"
          >
            <span class="font-label-1">
              Pilih Kelompok
            </span>
          </s-button>
        </div>
      </template>
    </s-modal>
    <s-bottom-sheet
      v-else-if="isMobile && !isError"
      :value="isShow"
      :expanded="true"
      :show-toggler="true"
    >
      <template #header>
        <div class="bottom-sheet-header-wrapper">
          <s-button
            id="cancel-button-mobile"
            variant="ghost"
            @click.prevent="handleCancel"
          >
            <span class="font-label-1">
              <img src="@/assets/icons/close-icon.svg" alt="x">
            </span>
          </s-button>
          <h1 class="font-body-2-lg mb-0">Pilih Kelompok Belajar Kelas</h1>
        </div>
      </template>
      <template #default>
        <div class="bottom-sheet-content-wrapper">
          <group-selection-content
            :is-loading="isLoading"
            :group-list="groupList"
            :selected-item="selectedItem"
            @search="handleSearch"
            @select="handleSelect"
          />
        </div>
      </template>
      <template #footer>
        <div class="footer-wrapper-mobile">
          <s-button
            id="submit-button"
            class="w-100"
            variant="primary"
            pill
            :disabled="!selectedItem.id"
            @click.prevent="handleSubmit"
          >
            <span class="font-label-1">
              Pilih Kelompok
            </span>
          </s-button>
        </div>
      </template>
    </s-bottom-sheet>
    <s-pop-up
      cluster="s"
      :value="isError"
      title="Tidak Dapat Memilih Kelompok Belajar Kelas"
      description="Terjadi kendala dalam memilih kelompok belajar kelas."
      :showCloseIcon="false"
    >
      <template #image>
        <img
          class="image-size"
          :src="imageUrl"
          alt="image"
        />
      </template>
      <template #footer>
        <s-button class="w-100" @click.prevent="submitHandler" cluster="s">
          Pilih Ulang Kelompok
        </s-button>
      </template>
    </s-pop-up>
  </div>
</template>

<script>
import deviceDetector from 'mobile-device-detect'

import GroupSelectionContent from '@/components/programmu/GroupSelection/GroupSelectionContent.vue'
import ProgramActivityAPI from '@/api/program-activity.js'
import { mapState } from 'vuex'

export default ({
  name: 'GroupSelection',
  components: {
    GroupSelectionContent
  },
  data () {
    return {
      isMobile: deviceDetector.isMobile,
      isShow: true,
      debounceState: null,
      groupList: [],
      isLoading: false,
      submitLoading: false,
      selectedItem: {},
      imageUrl: 'https://cdn.sekolah.mu/assets/illustration/tidak-dapat-diproses.svg',
      isError: false
    }
  },
  methods: {
    handleSearch (val) {
      this.selectedItem = {}
      this.debounceSearch(val)
    },
    handleSelect (val) {
      this.selectedItem = val
    },
    debounceSearch (val) {
      clearInterval(this.debounceState)
      this.debounceState = setTimeout(() => {
        this.fetchGroupList(val)
      }, 500)
    },
    async fetchGroupList (val = '') {
      try {
        this.isLoading = true
        const result = await ProgramActivityAPI.getGroupActivityList({
          accessToken: this.accessToken,
          name: val,
          programId: this.programId
        })
        if (result.status === 200) {
          this.groupList = result.data
          this.isLoading = false
        }
      } catch (error) {
        this.isLoading = false
      }
    },
    async handleSubmit () {
      try {
        this.$store.commit('loadingState/SET_LOADING_OVERLAY', true)
        const result = await ProgramActivityAPI.submitGroupActivity({
          accessToken: this.accessToken,
          payload: {
            program_id: Number(this.selectedItem.program_id),
            group_id: Number(this.selectedItem.id)
          }
        })
        if (result.data.status === 200) {
          setTimeout(() => {
            this.reValidateActivity()
          }, 4000)
        } else {
          this.$store.commit('loadingState/SET_LOADING_OVERLAY', false)
          this.isShow = false
          this.isError = true
        }
        if (this.isLogin) {
          window.firebaseAnalytics.logEvent('kelompok_belajar_pilih_click', this.firebaseAnalyticsObj)
        }
      } catch (error) {
        this.$store.commit('loadingState/SET_LOADING_OVERLAY', false)
      }
    },
    handleCancel () {
      if (this.isLogin) {
        window.firebaseAnalytics.logEvent('kelompok_belajar_batalkan_click', this.firebaseAnalyticsObj)
      }
      this.$router.push('/kelasku')
    },
    async reValidateActivity () {
      const apiResult = await ProgramActivityAPI.validateActivity({
        data: {
          type_access: 'activity',
          user_id: this.$store.state.storage.userId,
          slug: this.$route.params.id
        },
        Authorization: this.accessToken
      })
      const apiData = apiResult.data.data ? apiResult.data.data : apiResult.data
      if (!apiData.on_process_study_group) {
        const content = {
          props: { content: 'Berhasil memilih kelompok belajar kelas.' }
        }
        const option = {
          type: 'success',
          position: this.isMobile ? 'bottom-center' : 'top-center',
          timeout: 3000
        }
        this.$toast(content, option)
      }
      this.$store.commit('activityV2/SET_ACTIVITY_VALIDATE', apiData)
      this.$store.commit('loadingState/SET_LOADING_OVERLAY', false)
    },
    submitHandler () {
      this.selectedItem = {}
      this.isError = false
      this.isShow = true
      this.fetchGroupList()
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'isLogin']),
    accessToken () {
      return this.$store.state.storage.accessToken
    },
    programId () {
      return this.$store.state.activityV2.validate.program_id
    },
    firebaseAnalyticsObj () {
      return {
        user_id: this.userInfo.id,
        source: this.isMobile ? 'sekolahmu-webview' : 'sekolahmu-web'
      }
    }
  },
  mounted () {
    this.fetchGroupList()

    const root = document.querySelector(':root')
    root.style.setProperty('--height-bottom-sheet-mobile', `${window.innerHeight - 150}px`)
    root.style.setProperty('--height-bottom-sheet-mobile-expanded', `${window.innerHeight}px`)
  }
})
</script>

<style lang="scss" scoped>
::v-deep .s-modal-container {
  width: 720px;
}

::v-deep .s-modal-content {
  overflow-y: hidden;
}

.footer-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
}

.footer-wrapper-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-sheet-content-wrapper {
  padding: 16px 0;
}

.bottom-sheet-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: var(--color-text-100);
}

.content-wrapper {
  margin: 16px 0;
}

::v-deep .s-button {
  padding: 6px 16px !important;
}

::v-deep .s-bottom-sheet-container.is-expanded {
  height: 100dvh;
  max-height: 100dvh;
}

::v-deep .s-bottom-sheet-footer {
  position: sticky;
  bottom: 0;
}

::v-deep .s-pop-up-container {
  width: 304px;
}

.image-size {
  width: 100%;
  height: 144px;
}
</style>

<style>
.s-dropdown-options-wrapper-mobile > .s-bottom-sheet-wrapper > .s-bottom-sheet-container {
  max-height: calc(var(--height-bottom-sheet-mobile) - var(--navbar-height-desktop));
}

.s-dropdown-options-wrapper-mobile > .s-bottom-sheet-wrapper > .is-expanded {
  max-height: calc(var(--height-bottom-sheet-mobile-expanded) - var(--navbar-height-desktop));
}
</style>
