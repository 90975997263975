<template>
  <b-container
    fluid
    class="apps-container"
    :class="{'py-2': !isPopUp && !isIOSPlatform}"
  >
    <b-row
      class="justify-content-center"
      v-if="!isPopUp && !isIOSPlatform"
    >
      <b-col
        class="d-flex m-auto"
        :class="activeClass"
      >
        <b-button
          variant="link"
          class="p-0"
          @click.prevent="$emit('closeRecommendation')"
        >
          <b-icon-x
            scale="1.3"
            variant="light"
          ></b-icon-x>
        </b-button>
        <div class="gotham-book caption ml-1">Nikmati kemudahan belajar di aplikasi Sekolah.mu</div>
      </b-col>
      <b-col
        :cols="dynamicCols"
        class="px-1 m-auto"
      >
        <!-- <b-button
          class="download-btn gotham-bold"
          href="https://play.google.com/store/apps/details?id=mu.sekolah.android&referrer=utm_source%3Dmobileweb-sekolahmu%26utm_medium%3Dtop-navbar%26utm_campaign%3Dboost-download"
          target="_blank"
        >Buka Aplikasi</b-button> -->
        <b-button
          class="download-btn gotham-bold"
          @click.prevent="checkForRelatedApps('ribbon')"
          :disabled="openAppsLoading"
        >
          <b-spinner
            small
            label="Small Spinner"
            v-if="openAppsLoading"
          ></b-spinner>
          Buka Aplikasi
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      id="modal-download-apps"
      v-model="isPopUp"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
      size="sm"
    >
      <div class="text-center">
        <div class="text-right p-0 m-0">
          <b-button
            variant="link"
            @click="$bvModal.hide('modal-download-apps')"
            name="button-close"
          >
            <img src="../../assets/icons/icon_close.svg" alt="Sekolah.mu"/>
          </b-button>
        </div>
        <div style="max-width: 202px; max-height: 174px; margin: 0 auto;">
          <b-img-lazy :src="require('../../assets/icons/download-app-popup-v11032022.svg')" style="width: 100%;" alt="Sekolah.mu"/>
        </div>
        <div class="gotham-bold download-title my-3">Unduh Aplikasi Sekolah.mu</div>
        <div class="open-sans download-desc">Ayo unduh aplikasi android<br>Sekolah.mu untuk belajar yang lebih<br>mudah dan menyenangkan</div>
        <!-- <b-button
          variant="primary"
          pill
          class="gotham-bold popup-download-btn my-2"
          href="https://play.google.com/store/apps/details?id=mu.sekolah.android&referrer=utm_source%3Dmobileweb-sekolahmu%26utm_medium%3Dbutton-popup%26utm_campaign%3Dboost-download"
          target="_blank"
        >Unduh Sekarang</b-button> -->
        <b-button
          variant="primary"
          pill
          class="gotham-bold popup-download-btn my-2"
          @click.prevent="checkForRelatedApps('modal')"
          :disabled="openAppsLoading"
        >
          <b-spinner
            small
            label="Small Spinner"
            v-if="openAppsLoading"
          ></b-spinner>
          Unduh Sekarang
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { BIconX } from 'bootstrap-vue'
import axiosServer from '../../configs/axiosServer'

export default {
  components: { BIconX },
  data () {
    return {
      windowWidth: window.innerWidth,
      isPopUp: true,
      openAppsLoading: false,
      mitra: []
    }
  },
  methods: {
    async fetchMitraConfig () {
      const mitra = await axiosServer({
        url: 'https://sekolahmu-config.s3-ap-southeast-1.amazonaws.com/web/landing-page-mitra/data-landing-page.json'
      })
      let temp = []
      mitra.data.forEach(element => {
        temp.push(element.route)
      })
      this.mitra = temp
    },
    getPathName () {
      let pathname = window.location.pathname
      let isMitraPath = this.mitra.find(item => item === pathname)
      if (isMitraPath) {
        return `/project${pathname}`
      }
      if (pathname === '/') {
        return '/home'
      } else if (
        (pathname.includes('aktivitas') ||
          pathname.includes('program') ||
          pathname.includes('pencarian') ||
          pathname.includes('mitra')) && !pathname.includes('programmu')
      ) {
        return pathname
      } else {
        return '/home'
      }
    },
    checkForRelatedApps (_from) {
      const pathname = this.getPathName()
      this.openAppsLoading = true
      let referrer = {
        ribbon: 'utm_source%3Dmobileweb-sekolahmu%26utm_medium%3Dtop-navbar%26utm_campaign%3Dboost-download',
        modal: 'utm_source%3Dmobileweb-sekolahmu%26utm_medium%3Dbutton-popup%26utm_campaign%3Dboost-download'
      }
      window.location.replace(`sekolahmu:/${pathname}?referrer=${referrer[_from]}`)
      setTimeout(() => {
        if (!document.hidden) {
          // window.open(`https://play.google.com/store/apps/details?id=mu.sekolah.android&referrer=${referrer[_from]}`, '_blank')
          window.open(`https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=mu.sekolah.android&referrer=${referrer[_from]}`, '_blank')
          // window.location.href = `https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=mu.sekolah.android&referrer=${referrer[_from]}`;
        }
        this.openAppsLoading = false
      }, 1000)
    }
    // checkForRelatedApps(_from) {
    //   if('getInstalledRelatedApps' in navigator){
    //     // let tempPath = window.location.pathname
    //     navigator.getInstalledRelatedApps()
    //     .then((relatedApps) => {
    //       // const pathname = this.getPathName(tempPath)
    //       let referrer = {
    //         ribbon: 'utm_source%3Dmobileweb-sekolahmu%26utm_medium%3Dtop-navbar%26utm_campaign%3Dboost-download',
    //         modal: 'utm_source%3Dmobileweb-sekolahmu%26utm_medium%3Dbutton-popup%26utm_campaign%3Dboost-download'
    //       }
    //       const pathname = this.getPathName()
    //       if(Boolean(Array.isArray(relatedApps) && relatedApps.length)){
    //         window.location.replace(`sekolahmu:/${pathname}?referrer=${referrer[_from]}`)
    //       }else{
    //         window.open(`https://play.google.com/store/apps/details?id=mu.sekolah.android&referrer=${referrer[_from]}`, '_blank')
    //       }
    //     })
    //     .catch((err) => {
    //       window.open(`https://play.google.com/store/apps/details?id=mu.sekolah.android&referrer=${referrer[_from]}`, '_blank')
    //     })
    //   }else{
    //     window.open(`https://play.google.com/store/apps/details?id=mu.sekolah.android&referrer=${referrer[_from]}`, '_blank')
    //   }
    // }
  },
  computed: {
    dynamicCols () {
      switch (true) {
        case this.windowWidth >= 1024:
        case this.windowWidth <= 320:
          return 5
        case this.windowWidth <= 375:
          return 4
        case this.windowWidth >= 768:
          return 2
        default:
          return 4
      }
    },
    activeClass () {
      switch (true) {
        case this.windowWidth >= 1024:
          return { 'px-1': true, 'justify-content-end': true }
        case this.windowWidth <= 375:
          return { 'px-1': true }
        case this.windowWidth >= 768:
          return { 'px-2': true }
        default:
          return { 'px-1': true }
      }
    },
    isIOSPlatform () {
      var userAgent = navigator.userAgent
      let iPhoneUser = userAgent.includes('iPhone')
      let iPadUser = userAgent.includes('iPad')
      let iPodUser = userAgent.includes('iPod')
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (iPhoneUser || iPadUser || iPodUser) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    if (this.isPopUp && !this.isIOSPlatform) {
      this.$cookies.set('download-recommendation', true)
    } else {
      this.$cookies.set('download-recommendation', false)
    }
  },
  async created () {
    if (!this.$cookies.get('download-recommendation') && !this.isIOSPlatform) {
      this.isPopUp = true
    } else {
      this.isPopUp = false
    }
    // this.isPopUp = !Boolean(this.$cookies.get('download-recommendation'))
    await this.fetchMitraConfig()
  }
}
</script>

<style scoped>
.apps-container {
  background-color: var(--color-persian-blue-500);
  max-height: 54px;
  /* justify-content: space-between; */
}

.icon-close {
  width: 10px;
  height: 10px;
}

.download-btn {
  /* max-height: 24px; */
  max-width: 113px;
  border: none;
  background: var(--color-deep-lemon-500);
  border-radius: 10px;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--color-neutral-100);
}

.caption {
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: var(--color-neutral-100);
}

.download-title {
  font-family: Inter, sans-serif;
  font-size: 18px;
  line-height: 17px;
  text-align: center;
  font-weight: 600;
  color: var(--color-persian-blue-700);
}

.download-desc {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: var(--color-ui-text-primary);
  margin-bottom: 5px;
}

.popup-download-btn {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--color-persian-blue-500);
  width: 210px;
  border: none;
  outline: none;
  box-shadow: none;
}

.btn.popup-download-btn:hover,
.btn.popup-download-btn:active,
.btn.popup-download-btn:focus{
  background-color: var(--color-persian-blue-500);
  border: none;
  outline: none;
  box-shadow: none;
}

@media screen and (max-width: 320px) {
  .caption {
    font-size: 10px;
  }
}
</style>
