import {
  evaluateChecklistCart,
  creatorChecklist,
  programChecklist,
  addonChecklist,
  cartChecklist,
  initStatusShopingCart } from '@/functions/payment/checkoutLogic'
import {
  parsePaymentMethodList,
  inputAvatarLabel,
  inputLabelCategory,
  changeStatusPaymentType,
  changeVisiblePaymentCategory } from '@/functions/payment/paymentMethod'
import PaymentAPI from '@/configs/api/payment/index'

const payment = {
  namespaced: true,
  state: {
    shoppingCartList: [],
    allProgramCheck: false,
    loadingCart: false,
    checkoutData: null,
    paymentMethods: [],
    paymentTypeName: '',
    paymentSlugName: '',
    paymentTypeCategory: '',
    servicePaymentFee: 0,
    paymentTypeChoosen: null,
    phoneNumber: '',
    phoneNumberValid: false,
    hostedPaymentData: {},
    creditCardValidationStatus: false,
    creditCardPayload: {},
    freeOfCharge: false
  },
  mutations: {
    CHANGE_CART_CHECKLIST (state, payload) {
      state.allProgramCheck = payload
    },
    CHANGE_SHOPPING_CART_LIST (state, payload) {
      state.shoppingCartList = payload
    },
    CHANGE_LOADING_CART (state, payload) {
      state.loadingCart = payload
    },
    CHANGE_CHECKOUT_LIST_ITEM (state, payload) {
      // ? temporary comment logic
      // if (this.state.activeEnv === 'localhost') {
      //  this.state.checkout.checkoutData = payload
      // } else {
      state.checkoutData = payload
      // }
    },
    CHANGE_PAYMENT_METHODS (state, payload) {
      state.paymentMethods = payload
    },
    CHANGE_PAYMENT_TYPE_NAME (state, payload) {
      state.paymentTypeName = payload
    },
    CHANGE_PAYMENT_TYPE_CATEGORY (state, payload) {
      state.paymentTypeCategory = payload
    },
    CHANGE_SERVICE_PAYMENT_FEE (state, payload) {
      state.servicePaymentFee = payload
    },
    CHANGE_PAYMENT_TYPE_CHOOSEN (state, payload) {
      state.paymentTypeChoosen = payload
    },
    CHANGE_PHONE_NUMBER (state, payload) {
      state.phoneNumber = payload
    },
    CHANGE_PHONE_NUMBER_VALID (state, payload) {
      state.phoneNumberValid = payload
    },
    CHANGE_HOSTED_PAYMENT_DATA (state, payload) {
      state.hostedPaymentData = payload
    },
    CHANGE_CREDITCARD_VALIDATION_STATUS (state, payload) {
      state.creditCardValidationStatus = payload
    },
    CHANGE_CREDITCARD_PAYLOAD (state, payload) {
      state.creditCardPayload = payload
    },
    CHANGE_FREE_OF_CHARGE (state, payload) {
      // ? temporary comment logic
      // if (this.state.activeEnv === 'localhost') {
      //  this.state.checkout.freeOfCharge = payload
      // } else {
      state.freeOfCharge = payload
      // }
    },
    CHANGE_PAYMENT_SLUG_NAME (state, payload) {
      state.paymentSlugName = payload
    }
  },
  actions: {
    async addToCart ({ rootState, dispatch }, payload) {
      const result = await PaymentAPI.post('/add_to_cart/', { token: rootState.storage.accessToken, data: payload }, true)
      if (result.status === 200 || result.message === 'OK') {
        return result
      } else {
        dispatch('storage/checkToken', result, { root: true })
        return result
      }
    },
    async getCartList ({ rootState, commit }) {
      commit('CHANGE_LOADING_CART', true)
      const result = await PaymentAPI.get('/v3/get_cart/', { token: rootState.storage.accessToken })
      if (result.status === 200 || result.message === 'OK') {
        let shoppingCart = initStatusShopingCart(result.data.items)
        let statusChecklistCart = evaluateChecklistCart(shoppingCart)
        commit('CHANGE_CART_CHECKLIST', statusChecklistCart)
        commit('CHANGE_SHOPPING_CART_LIST', shoppingCart)
      }
      commit('CHANGE_LOADING_CART', false)
      return result
    },
    async deleteCartItem ({ rootState, commit }, payload) {
      commit('CHANGE_LOADING_CART', true)
      const result = await PaymentAPI.post('/v3/delete_cart_item/', { token: rootState.storage.accessToken, data: payload })
      if (result.status === 200 || result.message === 'OK') {
        let shoppingCart = initStatusShopingCart(result.data.items)
        let statusChecklistCart = evaluateChecklistCart(shoppingCart)
        commit('CHANGE_CART_CHECKLIST', statusChecklistCart)
        commit('CHANGE_SHOPPING_CART_LIST', shoppingCart)
      }
      commit('CHANGE_LOADING_CART', false)
    },
    async checkoutCartItem ({ rootState, commit }, payload) {
      const result = await PaymentAPI.post('/v3/checkout/', { token: rootState.storage.accessToken, data: payload })
      if (result.status === 200 || result.message === 'OK') {
        commit('CHANGE_FREE_OF_CHARGE', !result.data.is_pay)
      }
      return result
    },
    async applyVoucher ({ rootState, commit }, payload) {
      const result = await PaymentAPI.post('/v3/voucher/', { token: rootState.storage.accessToken, data: payload })
      if (result.status === 200 || result.message === 'OK') {
        commit('CHANGE_FREE_OF_CHARGE', !result.data.is_pay)
      }
      return result
    },
    async deleteVoucher ({ rootState, commit }, payload) {
      const result = await PaymentAPI.delete('/v3/voucher/', { token: rootState.storage.accessToken, data: payload })
      if (result.status === 200 || result.message === 'OK') {
        // ini danang ngerubah check dari real_total
        if (Number(result.data.real_total) >= 10000) {
          commit('CHANGE_FREE_OF_CHARGE', false)
        } else {
          commit('CHANGE_FREE_OF_CHARGE', true)
        }
      }
      return result
    },
    async getPaymentMethods ({ rootState }) {
      const result = await PaymentAPI.get('/v3/method/', { token: rootState.storage.accessToken })
      return result
    },
    async submitPayment ({ rootState }, payload) {
      const result = await PaymentAPI.post('/v3/pay/', { token: rootState.storage.accessToken, data: payload }, true)
      return result
    },
    // Invoice Detail
    async getDetailInvoice ({ rootState }, payload) {
      const result = await PaymentAPI.get(`/v3/detail/${payload}`, { token: rootState.storage.accessToken })
      return result
    },
    async repurchaseItems ({ rootState }, payload) {
      const result = await PaymentAPI.post('/add_list_to_cart/', { token: rootState.storage.accessToken, data: payload }, true)
      return result
    },
    async checkCartItem ({ rootState }, { addon_id = [], addon_resource_id = [] }) {
      const result = await PaymentAPI.post(
        '/check_cart_items/',
        {
          token: rootState.storage.accessToken,
          data: {
            list_addon_id: addon_id,
            list_addon_resource_id: addon_resource_id
          }
        })
      return result
    },
    async freeEnrollment ({ rootState }, payload) {
      const result = await PaymentAPI.post('/free_enrollment/', { token: rootState.storage.accessToken, data: payload }, true)
      return result
    },
    // action logic
    setListPaymentMethods ({ commit }, payload) {
      let parsedData = parsePaymentMethodList(payload.result.data.payment_methods)
      let inputAvatar = inputAvatarLabel(parsedData, payload.isMobile)
      commit('CHANGE_PAYMENT_METHODS', inputLabelCategory(inputAvatar))
    },
    changeAddonChecklist ({ state, commit }, payload) {
      let newData = addonChecklist(state.shoppingCartList, payload)
      let statusChecklistCart = evaluateChecklistCart(newData)
      commit('CHANGE_CART_CHECKLIST', statusChecklistCart)
      commit('CHANGE_SHOPPING_CART_LIST', newData)
    },
    changeProgramChecklist ({ state, commit }, payload) {
      let newData = programChecklist(state.shoppingCartList, payload)
      let statusChecklistCart = evaluateChecklistCart(newData)
      commit('CHANGE_CART_CHECKLIST', statusChecklistCart)
      commit('CHANGE_SHOPPING_CART_LIST', newData)
    },
    changeCreatorChecklist ({ state, commit }, payload) {
      let newData = creatorChecklist(state.shoppingCartList, payload)
      let statusChecklistCart = evaluateChecklistCart(newData)
      commit('CHANGE_CART_CHECKLIST', statusChecklistCart)
      commit('CHANGE_SHOPPING_CART_LIST', newData)
    },
    changeCartChecklist ({ state, commit }, payload) {
      let newData = cartChecklist(state.shoppingCartList, payload)
      commit('CHANGE_SHOPPING_CART_LIST', newData)
      commit('CHANGE_CART_CHECKLIST', payload.status)
    },
    changeStatusPayment ({ state, commit }, payload) {
      let newListPayment = changeStatusPaymentType(state.paymentMethods, payload)
      commit('CHANGE_PAYMENT_SLUG_NAME', payload.slug_name)
      commit('CHANGE_PAYMENT_TYPE_NAME', payload.payment_name)
      commit('CHANGE_PAYMENT_TYPE_CATEGORY', payload.category_name)
      commit('CHANGE_PAYMENT_METHODS', newListPayment)
    },
    configPaymentTypeFee ({ state, commit }, payload) {
      let totalAmount = Number(state.checkoutData.real_total)
      let totalFee = 0
      if (payload.pct && payload.fixed) {
        totalFee = Math.round((Number(totalAmount) * payload.pct) / 100) + Number(payload.fixed)
      } else if (payload.pct) {
        totalFee = Math.round((Number(totalAmount) * payload.pct) / 100)
      } else {
        totalFee = Number(payload.fixed)
      }
      commit('CHANGE_SERVICE_PAYMENT_FEE', totalFee)
    },
    openPaymentType ({ state, commit }, payload) {
      let newListPayment = changeVisiblePaymentCategory(state.paymentMethods, payload)
      commit('CHANGE_PAYMENT_METHODS', newListPayment)
    },
    async changePaymentMethod ({ rootState }, payload) {
      const result = await PaymentAPI.post('/invoice/cancel/', { token: rootState.storage.accessToken, data: payload }, true)
      return result
    }
  }
}

export default payment
