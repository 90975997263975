const snackbar = {
  namespaced: true,
  state: {
    lists: [],
    id: 0, // unique id for snackbar
    option: {
      text: 'This is snackbar', // support html string
      variant: 'success', // success | error | warning | info
      hasCloseBtn: true,
      hasTextBtn: false,
      textBtn: '',
      textBtnFunc: null,
      timer: 5 // second
    }
  },
  mutations: {
    REMOVE_SNACKBAR (state, payload) {
      const newLists = state.lists.filter(item => item.id !== payload)
      state.lists = newLists
    },
    ADD_SNACKBAR (state, payload) {
      state.lists.push(payload)
    },
    REPLACE_SNACKBAR (state, payload) {
      state.lists = [payload]
    },
    INCREMENT_ID (state) {
      state.id++
    }
  },
  actions: {
    showSnackbar ({ commit, state }, { allowMultiple = true, ...payload }) {
      const newSnackbar = {
        ...state.option,
        ...payload,
        id: state.id // on last to keep from overwritten
      }

      if (allowMultiple) {
        commit('ADD_SNACKBAR', newSnackbar)
      } else {
        commit('REPLACE_SNACKBAR', newSnackbar)
      }

      // to keep the id unique
      commit('INCREMENT_ID')
    }
  }
}

export default snackbar
