const login = {
  namespaced: true,
  state: {
    menuBelajar: [
      {
        name: 'PAUD',
        childs: [
          { name: 'PAUD < 1 tahun', route: '/jenjang/paud/0-1tahun' },
          { name: 'PAUD 1 & 2 tahun', route: '/jenjang/paud/1-2tahun' },
          { name: 'PAUD 2 & 3 tahun', route: '/jenjang/paud/2-3tahun' },
          { name: 'PAUD 3 & 4 tahun', route: '/jenjang/paud/3-4tahun' },
          { name: 'PAUD TK A', route: '/jenjang/paud/tk-a' },
          { name: 'PAUD TK B', route: '/jenjang/paud/tk-b' }
        ]
      },
      {
        name: 'SD',
        childs: [
          { name: 'Kelas 1 atau 7 tahun', route: '/jenjang/sd/kelas1' },
          { name: 'Kelas 2 atau 8 tahun', route: '/jenjang/sd/kelas2' },
          { name: 'Kelas 3 atau 9 tahun', route: '/jenjang/sd/kelas3' },
          { name: 'Kelas 4 atau 10 tahun', route: '/jenjang/sd/kelas4' },
          { name: 'Kelas 5 atau 11 tahun', route: '/jenjang/sd/kelas5' },
          { name: 'Kelas 6 atau 12 tahun', route: '/jenjang/sd/kelas6' }
        ]
      },
      {
        name: 'SMP',
        childs: [
          { name: 'Kelas 7 atau 13 tahun', route: '/jenjang/smp/kelas7' },
          { name: 'Kelas 8 atau 14 tahun', route: '/jenjang/smp/kelas8' },
          { name: 'Kelas 9 atau 15 tahun', route: '/jenjang/smp/kelas9' }
        ]
      },
      {
        name: 'SMA/SMK',
        childs: [
          { name: 'Kelas 10 atau 16 tahun', route: '/jenjang/sma/kelas10' },
          { name: 'Kelas 11 atau 17 tahun', route: '/jenjang/sma/kelas11' },
          { name: 'Kelas 12 atau 18 tahun', route: '/jenjang/sma/kelas12' }
        ]
      },
      { name: 'Mahasiswa', route: '/jenjang/mahasiswa' },
      { name: 'Orangtua', route: '/jenjang/orangtua' },
      { name: 'Guru', route: '/jenjang/guru' },
      { name: 'Profesional', route: '/jenjang/profesional', param: 'professional' },
      {
        name: 'Lembaga',
        childs: [
          { name: 'Sekolah', route: '/mitra/sekolah' },
          { name: 'Perguruan Tinggi', route: '/mitra/perguruan-tinggi' },
          { name: 'Industri', route: '/mitra/industri' }
        ]
      }
    ],
    menuPrakerja: [
      { name: 'Kartu Prakerja', route: '/kartuprakerja', newBadge: true },
      { name: 'Mitra Kolaborasi', route: '/mitra-kolaborasi' },
      { name: 'Panduan Prakerja', route: '/panduan-prakerja' }
    ]
  },
  mutations: {
    SET_MENU (state, payload) {
      state.menuList = payload
    }
  },
  actions: {
    initMenuList ({ commit, rootState }) {
      let menuList
      if (rootState.envBelajar) {
        menuList = [
          {
            name: 'PAUD',
            childs: [
              { name: 'PAUD < 1 tahun', route: '/jenjang/paud/0-1tahun' },
              { name: 'PAUD 1 & 2 tahun', route: '/jenjang/paud/1-2tahun' },
              { name: 'PAUD 2 & 3 tahun', route: '/jenjang/paud/2-3tahun' },
              { name: 'PAUD 3 & 4 tahun', route: '/jenjang/paud/3-4tahun' },
              { name: 'PAUD TK A', route: '/jenjang/paud/tk-a' },
              { name: 'PAUD TK B', route: '/jenjang/paud/tk-b' }
            ]
          },
          {
            name: 'SD',
            childs: [
              { name: 'Kelas 1 atau 7 tahun', route: '/jenjang/sd/kelas1' },
              { name: 'Kelas 2 atau 8 tahun', route: '/jenjang/sd/kelas2' },
              { name: 'Kelas 3 atau 9 tahun', route: '/jenjang/sd/kelas3' },
              { name: 'Kelas 4 atau 10 tahun', route: '/jenjang/sd/kelas4' },
              { name: 'Kelas 5 atau 11 tahun', route: '/jenjang/sd/kelas5' },
              { name: 'Kelas 6 atau 12 tahun', route: '/jenjang/sd/kelas6' }
            ]
          },
          {
            name: 'SMP',
            childs: [
              { name: 'Kelas 7 atau 13 tahun', route: '/jenjang/smp/kelas7' },
              { name: 'Kelas 8 atau 14 tahun', route: '/jenjang/smp/kelas8' },
              { name: 'Kelas 9 atau 15 tahun', route: '/jenjang/smp/kelas9' }
            ]
          },
          {
            name: 'SMA/SMK',
            childs: [
              { name: 'Kelas 10 atau 16 tahun', route: '/jenjang/sma/kelas10' },
              { name: 'Kelas 11 atau 17 tahun', route: '/jenjang/sma/kelas11' },
              { name: 'Kelas 12 atau 18 tahun', route: '/jenjang/sma/kelas12' }
            ]
          },
          { name: 'Mahasiswa', route: '/jenjang/mahasiswa' },
          { name: 'Orangtua', route: '/jenjang/orangtua' },
          { name: 'Guru', route: '/jenjang/guru' },
          { name: 'Profesional', route: '/jenjang/profesional', param: 'professional' }
          // {
          //   name: 'Lembaga',
          //   childs: [
          //     { name: 'Sekolah', route: '/jenjang/sekolah' },
          //     { name: 'Perguruan Tinggi', route: '/jenjang/perguruan-tinggi' },
          //     { name: 'Industri', route: '/jenjang/industri' }
          //   ]
          // }
        ]
      } else {
        menuList = [
          { name: 'Kartu Prakerja', route: '/kartuprakerja', newBadge: true },
          { name: 'Mitra Kolaborasi', route: '/mitra-kolaborasi' },
          { name: 'Panduan Prakerja', route: '/panduan-prakerja' }
        ]
      }
      commit('SET_MENU', menuList)
    }
  }
}

export default login
