const voucherV5 = {
  namespaced: true,
  state: {
    voucherCode: '',
    isVoucherApplied: false,
    voucherData: {},
    isApplyError: false,
    voucherErrorMessage: '',
    originVoucher: '', // [list, detail, input]
    activeVoucher: {},
    isVoucherLoading: false,
    errorVoucherCode: ''
  },
  mutations: {
    SET_VOUCHER_CODE (state, payload) {
      state.voucherCode = payload
    },
    SET_IS_VOUCHER_APPLIED (state, payload) {
      state.isVoucherApplied = payload
    },
    SET_VOUCHER_DATA (state, payload) {
      state.voucherData = payload
    },
    SET_IS_APPLY_ERROR (state, payload) {
      state.isApplyError = payload
    },
    SET_VOUCHER_ERROR_MESSAGE (state, payload) {
      state.voucherErrorMessage = payload
    },
    SET_ORIGIN_VOUCHER (state, payload) {
      state.originVoucher = payload
    },
    SET_ACTIVE_VOUCHER (state, payload) {
      state.activeVoucher = payload
    },
    SET_IS_VOUCHER_LOADING (state, payload) {
      state.isVoucherLoading = payload
    },
    SET_ERROR_VOUCHER_CODE (state, payload = '') {
      state.errorVoucherCode = state.activeVoucher ? state.activeVoucher.code : payload
    }
  },
  actions: {
    resetVoucherStore ({ state }) {
      state.voucherCode = ''
      state.isVoucherApplied = false
      state.voucherData = {}
      state.isApplyError = false
      state.voucherErrorMessage = ''
      state.originVoucher = ''
      state.activeVoucher = {}
      state.isVoucherLoading = false
      state.errorVoucherCode = ''
    }
  }
}

export default voucherV5
