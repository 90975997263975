<template>
  <div id="default-layout">
    <Navbar />
    <div class="overlay" v-if=overlayStatus>
    </div>
    <div>
        <slot id="view-router" @activate="changeOverlay"/>
        <Footer />
        <FooterCopyright />
    </div>
    <!-- <float-action-button></float-action-button> -->
  </div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar'
import Footer from '@/components/FooterV3'
import FooterCopyright from '@/components/FooterCopyright'
// import FloatActionButton from '../components/tanyamu/FloatActionButton'

export default {
  components: {
    Navbar,
    Footer,
    FooterCopyright
    // FloatActionButton
  },
  data: () => ({
    overlayStatus: true
  }),
  methods: {
    changeOverlay (status) {
      console.log(status)
      this.overlayStatus = status
    }
  },
  created () {
    this.$store.commit('SET_ENV_BELAJAR')
    this.$store.commit('SET_ACTIVE_ENVIRONMENT')
    this.$store.dispatch('subnavbar/initMenuList')
  }
}
</script>

<style scoped>
#default-layout {
  font-family: "Open Sans", sans-serif;
}

.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,0.5); /*dim the background*/
}
</style>
