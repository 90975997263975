<template>
    <div>
      <s-skeleton class="mb-4 mb-md-5 navbar-skeleton" height="64px" width="100%" variant="rectangle" />

      <b-container fluid>
        <b-row class="mt-4">
          <b-col cols="12" md="9">
            <div class="content-skeleton mx-auto">
              <div class="mb-4 d-flex">
                <s-skeleton class="mr-4" height="44px" width="120px" variant="btn" />
                <s-skeleton class="mr-4" height="44px" width="120px" variant="btn" />
              </div>
              <s-skeleton class="mb-4" height="400px" variant="rectangle" />
              <div class="d-none justify-content-end mb-5 d-md-flex">
                <s-skeleton class="mr-4" height="44px"  width="200px" variant="btn" />
              </div>
            </div>
          </b-col>
          <b-col class="d-none d-md-block">
            <div class="mb-4">
              <s-skeleton class="mb-2" height="36px" variant="rectangle" />
            </div>
            <div class="p-4">
              <div class="mb-4">
                <s-skeleton class="mb-1" height="20px" width="100%" variant="text" />
                <s-skeleton class="mb-3" height="20px" width="100%" variant="text" />
                <div class="d-flex justify-content-end">
                  <s-skeleton height="44px" width="80px" variant="btn" />
                </div>
              </div>
              <div class="mb-4">
                <s-skeleton class="mb-1" height="20px" width="100%" variant="text" />
                <s-skeleton class="mb-3" height="20px" width="100%" variant="text" />
                <div class="d-flex justify-content-end">
                  <s-skeleton height="44px" width="80px" variant="btn" />
                </div>
              </div>
              <div class="mb-4">
                <s-skeleton class="mb-1" height="20px" width="100%" variant="text" />
                <s-skeleton class="mb-3" height="20px" width="100%" variant="text" />
                <div class="d-flex justify-content-end">
                  <s-skeleton height="44px" width="80px" variant="btn" />
                </div>
              </div>
            </div>
            <div class="mb-4">
              <s-skeleton class="mb-2" height="36px" variant="rectangle" />
            </div>
            <div class="p-4">
              <div class="mb-4">
                <s-skeleton class="mb-1" height="20px" width="100%" variant="text" />
                <s-skeleton class="mb-3" height="20px" width="100%" variant="text" />
                <div class="d-flex justify-content-end">
                  <s-skeleton height="44px" width="80px" variant="btn" />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="bottom-toolbar d-block d-md-none">
        <b-skeleton type="button" width="90%" class="mx-auto round-border"></b-skeleton>
      </div>
    </div>
    </template>

<script>
export default {
  name: 'ActivityV2PageSkeleton'
}
</script>

<style scoped>
.content-skeleton {
  max-width: 95%;
}

.bottom-toolbar {
  position: fixed;
  bottom: 16px;
  width: 100%;
}

.round-border {
  border-radius: 30px;
}

::v-deep .s-skeleton:after {
  animation: none;
}
</style>
