const routes = [
  {
    path: '/mitra/all',
    name: 'Lembaga All',
    meta: {
      title: 'Lembaga'
    },
    component: () => import(/* webpackChunkName: 'PageLembaga' */ '../views/lembaga/ListLembaga.vue')
  },
  {
    path: '/mitra/sekolah',
    name: 'Lembaga Sekolah',
    meta: {
      title: 'Lembaga Sekolah'
    },
    component: () => import(/* webpackChunkName: 'PageLembaga' */ '../views/lembaga/ListLembaga.vue')
  },
  {
    path: '/mitra/perguruan-tinggi',
    name: 'Lembaga Perguruan Tinggi',
    meta: {
      title: 'Lembaga Perguruan Tinggi'
    },
    component: () => import(/* webpackChunkName: 'PageLembaga' */ '../views/lembaga/ListLembaga.vue')
  },
  {
    path: '/mitra/industri',
    name: 'Lembaga Industri',
    meta: {
      title: 'Lembaga Industri'
    },
    component: () => import(/* webpackChunkName: 'PageLembaga' */ '../views/lembaga/ListLembaga.vue')
  },
  {
    path: '/mitra/:institution_slug',
    name: 'Lembaga',
    meta: {
      title: 'Mitra'
    },
    component: () => import(/* webpackChunkName: 'PageLembaga' */ '../views/lembaga/BaseLembaga.vue')
  },
  {
    path: '/mitra-group/:institution_slug',
    name: 'Group Lembaga',
    meta: {
      title: 'Mitra Group'
    },
    component: () => import(/* webpackChunkName: 'PageGroupLembaga' */ '../views/lembaga/BaseGroupLembaga.vue')
  }
]

export default routes
