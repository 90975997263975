<template>
  <button id="button-primary-solid" class="inter-medium_small-bold" @click="onClick" :disabled="isDisabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'button-primary-solid-box',
  props: {
    isDisabled: Boolean
  },
  methods: {
    onClick () {
      this.$emit('onClick')
    }
  }
}
</script>

<style scoped>
#button-primary-solid {
  background: var(--color-persian-blue-500);
  border: 1px solid var(--color-persian-blue-500);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px 20px;
  color: var(--color-neutral-100);
  transition: 0.3s;
}

#button-primary-solid:disabled {
  background-color: var(--color-neutral-500);
  border: 1px solid var(--color-neutral-500);
}

#button-primary-solid:hover:enabled {
  background: var(--color-neutral-100);
  color: var(--color-persian-blue-500);
}
</style>
