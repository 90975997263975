import axiosServerGolang from '@/configs/axiosServerGolang'

export default {
  async setReportPrivacy ({ Authorization, data }) {
    const url = `/program-activity/user-report-privacy`
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to set report privacy data')
    }
  },
  async getProficiencyScale ({ accessToken }) {
    try {
      const { data } = await axiosServerGolang({
        method: 'get',
        url: '/program-activity/proficiency-scale?version=1',
        headers: {
          Authorization: accessToken
        }
      })
      return data
    } catch (error) {
      console.error(error, 'Failed to get proficiency scale')
    }
  }
}
