const regex = {
  ALPHANUMERICS_DASH_AND_UNDERSCORE: /^[A-Za-z0-9-_\s]+$/,
  ALPHANUMERICS: /^[A-Za-z0-9]+$/,
  NON_ALPHANUMERICS: /[^A-Za-z0-9]/gi,
  ALPHANUMERIC_QUESTION_EQUAL_DOTS: /^[A-Za-z0-9?=.\s]+$/,
  NUMERIC: /^[0-9\s]+$/,
  HTML_TAG_REMOVER: /(<([^>]+)>)/ig,
  NUMERIC_AND_SPACE: /^[0-9 ]+$/,
  VALID_URL: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
}

export default regex
