import axiosServerGolang from '@/configs/axiosServerGolang'
import axiosServer from '@/configs/axiosServer.js'
import objectToQueryParams from '@/functions/objectToQueryParams'

export default {
  async validateActivity ({ Authorization, data }) {
    const url = 'program-activity/v3/program-detail/validate'
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to validate activity')
    }
  },
  async getActivityDashboard ({ Authorization, data }) {
    const url = 'program-activity/v3/program-detail/get/activity-dashboard'
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get activity dashboard')
    }
  },
  async getTabAnnouncement ({ Authorization, data }) {
    const url = 'program-activity/v3/program-detail/get/tab-announcement'
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get tab announcement')
    }
  },
  async getReport ({ Authorization, data }) {
    const url = 'program-activity/v3/program-detail/get/report'
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get report')
    }
  },
  async getProgramRecommendation ({ Authorization, program_slug }) {
    const url = `program_activity/program_recommendation/${program_slug}/`
    try {
      const result = await axiosServer({
        method: 'GET',
        url,
        headers: {
          Authorization
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get program_recommendation')
    }
  },
  async recalculate ({ Authorization, data }) {
    const url = 'program_activity/score/'
    try {
      const result = await axiosServer({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get report')
    }
  },
  async getTabActivityItems ({ Authorization, data }) {
    const url = 'program-activity/v3/program-detail/get/tab-activity'
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get data for tab activity')
    }
  },
  async getChapterDetail ({ Authorization, data }) {
    const url = 'program-activity/v3/chapter-detail/get'
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get data activities for desired chapter')
    }
  },
  async getActivityDetail ({ Authorization, data }) {
    const url = 'program-activity/v3/activity-detail/get'
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get activity detail data')
    }
  },
  async getActivityFacilFeedback ({ Authorization, activityId }) {
    const url = `program_activity/product_activity/view_feedback/${activityId}`
    try {
      const result = await axiosServer({
        method: 'GET',
        url,
        headers: {
          Authorization
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get facil feedback data')
    }
  },
  async getParentComment ({ Authorization, activity_id, page, per_page }) {
    const url = `program_activity/resource/user_comment/${activity_id}/${page}/${per_page}`
    try {
      const result = await axiosServer({
        method: 'GET',
        url,
        headers: {
          Authorization
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get parent comment')
    }
  },
  async getChildComment ({
    Authorization,
    activity_id,
    page,
    per_page,
    parent_id
  }) {
    const url = `program_activity/resource/reply_comment/${activity_id}/${parent_id}/${page}/${per_page}`
    try {
      const result = await axiosServer({
        method: 'GET',
        url,
        headers: {
          Authorization
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get child comment')
    }
  },
  async submitComment ({ Authorization, data }) {
    const url = `program_activity/resource/comment/`
    try {
      const result = await axiosServer({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to submit comment')
    }
  },
  async setActivityComplete ({ Authorization, data }) {
    const url = `program_activity/v2/activity/`
    try {
      const result = await axiosServer({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data,
        generateQueryParamSource: true
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to set activity complete')
    }
  },
  async sendReview ({ Authorization, payload }) {
    try {
      const { data } = await axiosServer({
        url: '/program_activity/rating/',
        method: 'post',
        headers: { Authorization },
        data: payload
      })
      return data
    } catch (error) {
      return error
    }
  },
  async getPersonalTanyamuRoom ({ Authorization, data }) {
    const url = '/program_activity/personal_chat/'
    try {
      const result = await axiosServer({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get tanyamu room id')
    }
  },
  async getProgramGroupActivity ({ Authorization, data }) {
    const url = `/program-activity/my-study-group/${data.programId}/${data.page}/${data.itemsPerPage}`
    try {
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: {
          Authorization
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get Program Group Activity')
    }
  },
  async saveSelectedGroupActivity ({ Authorization, data }) {
    const url = `program/resource_group/select_group/?refresh=1` // Need refresh query to trigger update elastic almost instantly
    try {
      const result = await axiosServer({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to save selected group activity')
    }
  },
  async getMyActivity ({ accessToken, pagination, body }) {
    try {
      const { data } = await axiosServer({
        method: 'POST',
        url: `/program_activity/v2/my_activity/${pagination}`,
        headers: {
          Authorization: accessToken
        },
        data: body
      })
      return data
    } catch (error) {
      console.error(error, 'Failed to get activity list')
    }
  },
  async getLatestActivityProgram ({ accessToken, count = 10 }) {
    try {
      const { data } = await axiosServerGolang({
        method: 'GET',
        url: `/program-activity/last-activity/${count}`,
        headers: { Authorization: accessToken }
      })
      return data
    } catch (error) {
      console.log(error, 'Failed to get last activity list')
    }
  },
  async getZoomSignature (payload) {
    try {
      const { data } = await axiosServerGolang({
        method: 'POST',
        url: `/program-activity/zoom/signature`,
        data: payload,
        headers: { Authorization: process.env.VUE_APP_APP_KEY }
      })
      return data
    } catch (error) {
      console.log(error, 'Failed to get last activity list')
    }
  },
  async newRecalculate ({ Authorization, data }) {
    const url = 'program-activity/score'
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get report')
    }
  },
  async getGroupActivityList ({ accessToken, name = '', programId }) {
    try {
      const { data } = await axiosServerGolang({
        method: 'GET',
        url: `/program-activity/study-group/list/${programId}?name=${name}&status=1&platform=sekolahmu&refresh=1`,
        headers: { Authorization: accessToken }
      })
      return data
    } catch (error) {
      console.log(error, 'Failed to get group activity list')
    }
  },
  async submitGroupActivity ({ accessToken, payload }) {
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url: '/program-activity/study-group/enrollment',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return result
    } catch (error) {
      console.log(error, 'Failed to submit group activity')
    }
  },
  async fetchGamePlay ({ accessToken, payload }) {
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url: '/program-activity/game/play',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return result
    } catch (error) {
      console.log(error, 'Failed to submit group activity')
    }
  },
  async fetchGameProgress ({ accessToken, payload }) {
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url: '/program-activity/game/progress',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return result
    } catch (error) {
      console.log(error, 'Failed to submit group activity')
    }
  },
  async fetchTranscriptList ({ accessToken, payload }) {
    try {
      const { page = 1, limit = 8, queries } = payload
      const queryUrl = await objectToQueryParams(queries)
      const result = await axiosServerGolang({
        method: 'GET',
        url: `/program-activity/transcript/list/${page}/${limit}${queryUrl}`,
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return result
    } catch (error) {
      console.log(error, 'Failed to fetch transcript list')
    }
  }
}
