<template>
  <footer id="sekolahmu-footer-default">
    <div class="footer" :class="{ 'has-top-margin' :hasTopMargin}">
      <div  class="logo-apps-section">
        <div class="logo-container">
          <b-img-lazy class="sekolahmu-logo-wrapper" src=" https://cdn.sekolah.mu/assets/home/sekolahmu_logo.svg" alt="Sekolah.mu"></b-img-lazy>
          <div class="contact-wrapper">
            <div class="email-wrapper">
              <img src="https://cdn.sekolah.mu/assets/icon/mail-bw.svg" />
              <div class="font-contact fc-secondary">{{ email }}</div>
            </div>
            <div class="phone-wrapper">
              <img src="https://cdn.sekolah.mu/assets/icon/whatsapp-bw.svg" />
              <div class="font-contact fc-secondary">{{ contactPhone }}</div>
            </div>
          </div>
        </div>
        <div class="app-container">
          <div class="font-body-1-new footer-link-title app-title-container"><span>Download&nbsp;</span><span>Aplikasi Kami</span></div>
          <a
            href="https://play.google.com/store/apps/details?id=mu.sekolah.android&utm_source=sekolahmu&utm_medium=footer&utm_campaign=download-google-play"
            rel="noopener"
            target="_blank"
            id="footer-apps-google-play-button"
          >
            <b-img-lazy class="google-play-img" src="https://cdn.sekolah.mu/assets/home/googleplay.svg" alt="Sekolah.mu"></b-img-lazy>
          </a>
        </div>
      </div>
      <div class="product-guide-section-wrapper">
        <div class="product-section">
          <div class="footer-link-container" v-for="(item_i, index_i) in productLinks" :key="index_i">
            <h5 class="font-body-1-new footer-link-title">{{ item_i.title }}</h5>
            <template v-for="(item_j, index_j) in item_i.links">
              <router-link v-if="item_j.type === 'route'" :to="appendParam(item_j.path, 'ref', $route.query.ref)" :key="index_j" class="font-paragraph-2 footer-link" @click.native="handleLogEvent(item_j.eventName)">
                {{ item_j.name }}
              </router-link>
              <a v-if="item_j.type === 'ext-link'" :href="appendParam(item_j.path, 'ref', $route.query.ref)" :key="index_j" class="font-paragraph-2 footer-link" rel="noopener" target="_blank">
                {{ item_j.name }}
              </a>
            </template>
          </div>
        </div>
        <div class="guide-section">
          <div class="footer-link-container" v-for="(item_i, index_i) in guideLinks" :key="index_i">
            <h5 class="font-body-1-new footer-link-title">{{ item_i.title }}</h5>
            <template v-for="(item_j, index_j) in item_i.links">
              <router-link v-if="item_j.type === 'route'" :to="appendParam(item_j.path, 'ref', $route.query.ref)" :key="index_j" class="font-paragraph-2 footer-link" @click.native="handleLogEvent(item_j.eventName)">
                {{ item_j.name }}
              </router-link>
              <a v-if="item_j.type === 'ext-link'" :href="appendParam(item_j.path, 'ref', $route.query.ref)" :key="index_j" class="font-paragraph-2 footer-link" rel="noopener" target="_blank">
                {{ item_j.name }}
              </a>
            </template>
          </div>
          <div class="footer-link-container social-container">
            <h5 class="font-body-1-new footer-link-title">Ikuti Kami</h5>
            <div class="social-wrapper">
              <a
                class="footer-sekolahmu-social"
                v-for="(item, index) in socialLinks"
                :key="index"
                :href="item.path"
                rel="noopener"
                target="_blank"
              >
                <b-img-lazy :src="item.icon" alt="Sekolah.mu" class="footer-sekolahmu-social-image"></b-img-lazy>
              </a>
            </div>
          </div>
          <div class="footer-link-container security-container">
            <h5 class="font-body-1-new footer-link-title">Keamanan</h5>
            <b-img-lazy src="https://cdn.sekolah.mu/assets/home_v3/footer/iso-certified.webp" alt="Sekolah.mu" class="footer-sekolahmu-iso-certified"></b-img-lazy>
          </div>
        </div>
        <div class="security-section-mobile">
          <div class="footer-link-container security-container">
            <h5 class="font-body-1-new footer-link-title">Keamanan</h5>
            <b-img-lazy src="https://cdn.sekolah.mu/assets/home_v3/footer/iso-certified.webp" alt="Sekolah.mu" class="footer-sekolahmu-iso-certified"></b-img-lazy>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-section mt-3">
      <div class="ground-bg-section">
        <img class="ground-bg-img-mobile" src="https://cdn.sekolah.mu/assets/background/homepage/v4/footer/footer-ground-mobile.svg" loading="lazy">
        <img class="ground-bg-img-tablet" src="https://cdn.sekolah.mu/assets/background/homepage/v4/footer/footer-ground-tablet.svg" loading="lazy">
        <img class="ground-bg-img" src="https://cdn.sekolah.mu/assets/background/homepage/v4/footer/footer-ground-desktop.svg" loading="lazy">
      </div>
      <div class="copyright-text text-300">© {{ currentYear }} Sekolah.mu PT Semesta Integrasi Digital. All Rights Reserved</div>
    </div>
  </footer>
</template>
<script>
import deviceDetector from 'mobile-device-detect'
import handleParam from '@/mixins/handleParam'
import contact, { livingEnglishContact } from '@/constants/contact.js'
export default {
  name: 'SekolahmuFooterDefault',
  mixins: [handleParam],
  data () {
    return {
      email: contact.email,
      productLinks: [
        {
          title: 'Produk Sekolah.mu',
          links: [
            { name: 'Living English', type: 'ext-link', path: 'https://livingenglish.id/?utm_source=sekolahmu&utm_medium=footer', eventName: 'living_english_footer_click' },
            { name: 'Siap Kuliah', type: 'ext-link', path: 'https://sekolah.mu/siapkuliah/?utm_source=sekolahmu&utm_medium=footer&utm_campaign=siap-kuliah' }
          ]
        },
        {
          title: 'Produk Lainnya',
          links: [
            { name: 'Sekolah Murid Merdeka', type: 'ext-link', path: 'https://sekolahmuridmerdeka.id/?utm_source=sekolahmu&utm_medium=footer&utm_campaign=sekolah-murid-merdeka' },
            { name: 'Karier.mu & Prakerja', type: 'ext-link', path: 'https://www.karier.mu/?utm_source=sekolahmu&utm_medium=footer&utm_campaign=kariermu' },
            { name: 'Playkit Sekolah.mu', type: 'ext-link', path: 'https://www.tokopedia.com/sekolahmu?utm_source=sekolahmu&utm_medium=footer&utm_campaign=produk-kami&utm_campaign=tokopedia-playkit' }
          ]
        },
        {
          title: 'Tentang Kami',
          links: [
            { name: 'Tentang Sekolah.mu', type: 'route', path: '/profil-sekolahmu?utm_source=sekolahmu&utm_medium=footer' },
            { name: 'Karir', type: 'ext-link', path: 'https://jobs.talentics.id/pt-semesta-integrasi-digital?tab=jobs' },
            { name: 'Kontak Kami', type: 'route', path: '/kontak-sekolahmu?utm_source=sekolahmu&utm_medium=footer' }
          ]
        }
      ],
      guideLinks: [
        {
          title: 'Bantuan dan Panduan',
          links: [
            { name: 'Bantuan', type: 'ext-link', path: 'https://panduan.sekolah.mu?utm_source=sekolahmu&utm_medium=footer' },
            { name: 'Kebijakan Privasi', type: 'route', path: '/kebijakan-privasi?utm_source=sekolahmu&utm_medium=footer' },
            { name: 'Syarat Ketentuan', type: 'route', path: '/syarat-ketentuan?utm_source=sekolahmu&utm_medium=footer' }
          ]
        },
        {
          title: 'Lainnya',
          links: [
            { name: 'Blog', type: 'ext-link', path: 'https://www.sekolah.mu/blog/?utm_source=sekolahmu&utm_medium=footer' }
          ]
        }
      ],
      socialLinks: [
        { icon: 'https://cdn.sekolah.mu/assets/v3/icon/youtube.svg', path: 'https://www.youtube.com/channel/UCKaJP90erDda-EvyWcsa9ng?utm_source=sekolahmu&utm_medium=footer&utm_campaign=ikuti-kami' },
        { icon: 'https://cdn.sekolah.mu/assets/v3/icon/instagram.svg', path: 'https://www.instagram.com/sekolah.mu/?utm_source=sekolahmu&utm_medium=footer&utm_campaign=ikuti-kami' },
        { icon: 'https://cdn.sekolah.mu/assets/v3/icon/facebook.svg', path: 'https://www.facebook.com/sekolahmuid?utm_source=sekolahmu&utm_medium=footer&utm_campaign=ikuti-kami' },
        { icon: 'https://cdn.sekolah.mu/assets/v3/icon/x.svg', path: 'https://twitter.com/sekolahmuid?utm_source=sekolahmu&utm_medium=footer&utm_campaign=ikuti-kami' },
        { icon: 'https://cdn.sekolah.mu/assets/v3/icon/linkedin.svg', path: 'https://www.linkedin.com/company/sekolahmu/?utm_source=sekolahmu&utm_medium=footer&utm_campaign=ikuti-kami' }
      ]
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    },
    contactPhone () {
      if (this.$route.path === '/living-english') {
        return livingEnglishContact.phone
      } else {
        return contact.phone
      }
    },
    currentYear () {
      return new Date().getFullYear()
    },
    // ignore margin top cases
    hasTopMargin () {
      switch (true) {
        // living english page
        case this.$route.path === '/living-english':
          return false
        // platinum home page
        case this.envPlatinum && this.$route.path === '/':
          return false
        default:
          return true
      }
    },
    envPlatinum () {
      return this.$store.state.envPlatinum
    }
  },
  methods: {
    handleLogEvent (eventName) {
      if (eventName) {
        const { id, profession_name, year_level_name, is_admin = false } = this.userInfo
        window.firebaseAnalytics.logEvent(eventName, {
          page_referrer: `${window.location.origin}${this.$route.fullPath}`,
          user_id: id || '',
          user_profession: profession_name || '',
          user_year_level: year_level_name || '',
          user_is_admin: is_admin,
          source: deviceDetector.isMobile ? 'mweb_sekolahmu' : 'web_sekolahmu'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
a {
  text-decoration: none !important;
  display: block;
}
h5, p{
  margin: 0;
}

.has-top-margin {
  margin-top: 176px;
}

.footer {
  position: relative;
  display: flex;
  background-color: var(--color-neutral-200);
  width: 100%;
  justify-content: center;
  align-items: start;
  gap: 115px;
  padding: 54px 0 54px 0;
  background-image: url('https://cdn.sekolah.mu/assets/background/homepage/v4/footer/footer-cloud.svg');
  background-repeat: no-repeat;
  background-position: top right 80px;
}
/* logo apps section */
.logo-apps-section  {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-4, 32px);
    .contact-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-15, 12px);
    .email-wrapper, .phone-wrapper {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
  }
}
.app-container {
  display: flex;
  width: 227px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2, 16px);
}
.app-title-container {
  white-space: nowrap;
}
.sekolahmu-logo-wrapper {
  width: 200px
}
/* wrapper */
.product-guide-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
/* product section */
.product-section {
  display: flex;
  gap: 115px;
  .footer-link-container {
    width: 172px;
    max-width: 172px;
    white-space: nowrap;
  }
  .footer-link-container:last-child{
    margin-right: 0;
  }
}
/* guide section */
.guide-section {
  display: flex;
  gap: 115px;
  .footer-link-container {
    width: 172px;
    max-width: 172px;
    white-space: nowrap;
  }
  .footer-link-container:last-child{
    margin-right: 0;
  }
  .social-container {
    .social-wrapper {
      display: flex;
      width: 100%;
      gap: 12px;
      flex-wrap: wrap;
    }
  }
}
.footer-link-title{
  line-height: 24px !important;
  font-weight: 600 !important;
  color: var(--color-text-100);
  margin-bottom: 16px;
}
.footer-link{
  line-height: 17px;
  color: var(--color-text-200);
  margin-bottom: 11px;
  text-decoration: none;
}
.footer-link-container.security-container {
  width: 92px !important;
}
.security-section-mobile {
  display: none;
}
.footer-sekolahmu-iso-certified {
    width: 54px;
    height: 54px;
}
.font-contact {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25px !important;
}
.copyright-section {
  position: relative;
  background-color: var(--color-neutral-100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
  z-index: 1;
}
.copyright-text {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: var(--color-text-300);
  align-self: stretch;
}
/* gaps small desktop */
@media screen and (max-width: 1366px) {
  .guide-section, .product-section {
    gap: 80px !important;
  }
}
@media screen and (max-width: 1281px) {
  .guide-section, .product-section {
    gap: 64px !important;
  }
}

@media screen and (max-width: 1279px) {
  .has-top-margin {
    margin-top: 176px;
  }
  .footer {
    flex-direction: column;
    gap: 24px;
    padding: 48px 100px 100px 100px;
    background-image: url('https://cdn.sekolah.mu/assets/background/homepage/v4/footer/footer-cloud.svg');
    background-repeat: no-repeat;
    background-position: top 68px right -20px;
    background-size: 136px 76px;
  }
  .product-guide-section-wrapper {
    width: 100%;
  }
  .logo-apps-section  {
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
  .app-container {
    width: 147px;
    margin-right: 25px;
    margin-top: 64px;
  }
  .app-title-container {
    display: flex;
    flex-direction: column;
    white-space: normal;
  }
  .guide-section, .product-section {
    width: 100%;
    justify-content: space-between;
    gap: 32px !important;
  }
  .guide-section {
    .security-container {
      display: none;
    }
  }
  .social-container {
    .social-wrapper {
      max-width: 114px;
    }
  }
  .security-section-mobile {
    display: flex;
  }
  .font-paragraph-2 {
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
  .font-body-1-new {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}
@media screen and (max-width: 767px) {
  .has-top-margin {
    margin-top: 88px;
  }

  .footer {
    justify-content: center;
    align-items: center;
    padding: 48px;
    background:
    url('https://cdn.sekolah.mu/assets/background/homepage/v4/footer/footer-cloud.svg') top 172px right -20px no-repeat,
    url('https://cdn.sekolah.mu/assets/background/homepage/v4/footer/footer-cloud.svg') bottom 300px left 0px no-repeat;
    background-size: 136px 76px;
    background-color: var(--color-neutral-200);
  }
  .app-container {
    align-items: center;
  }
  .app-title-container {
    display: block;
    white-space: normal;
  }
  .logo-apps-section  {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    .logo-container {
      align-items: center;
      .contact-wrapper {
        align-items: center;
      }
    }
    .app-container {
      width: max-content;
      align-items: center;
      margin-right: 0;
      margin-top: 0;
    }
  }
  .footer-link-container {
    width: 100%;
    white-space: normal;
    text-align: center;
    .social-container  {
      .social-wrapper {
        justify-content: center;
        align-items: center;
      }
    }
  }
  .guide-section, .product-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .product-section .footer-link-container {
    width: 180px;
    max-width: 180px;
  }
  .google-play-img {
    width: 105px;
  }
  .social-wrapper {
    margin: auto;
  }
  .security-section-mobile {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .copyright-section {
    padding: 8px 16px;
  }
}

/* ground only */
@media screen and (min-width: 1025px) {
  .ground-bg-section {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 48px;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ground-bg-img-mobile {
    display: none;
  }
  .ground-bg-img-tablet {
    display: none;
  }
  .ground-bg-img {
    display: block;
    width: 100%;
    max-width: 100vw;
  }
}

@media screen and (max-width: 1024px) {
  .ground-bg-section {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 48px;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ground-bg-img-mobile {
    display: none;
  }
  .ground-bg-img-tablet {
    display: block;
    width: 100%;
    max-width: 100vw;
  }
  .ground-bg-img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .copyright-section {
    height: 64px;
  }
  .ground-bg-section {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 64px;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .copyright-text {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .ground-bg-img-mobile {
    width: 100%;
    max-width: 100vw;
    z-index: 0;
    display: block;
  }
  .ground-bg-img-tablet {
    display: none;
  }

  .ground-bg-img {
    display: none;
  }

  .footer-link-container.social-container {
    width: unset;
    max-width: unset;
    .social-wrapper {
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      gap: 16px;
    }
  }
}
</style>
