function divideAvailableAndUnavailableCart (items) {
  const newItems = JSON.parse(JSON.stringify(items))

  // check is last index is unavailable section
  if (items.length && items[items.length - 1].creator_id === 0) {
    const unavailable = newItems.pop()
    const unavailableItem = combineUnavailableAddon(unavailable)
    return [newItems, unavailableItem]
  }
  return [newItems, {}]
}

function combineUnavailableAddon (obj) {
  const newObj = JSON.parse(JSON.stringify(obj))

  newObj.programs.forEach(program => {
    program.addons = program.addons.concat(program.addons_resource)
  })

  return newObj
}

function changeCartStatuses (items, status, isInitCart) {
  const newItems = JSON.parse(JSON.stringify(items))

  newItems.forEach(creator => {
    // assign the flag isPicked to all creator
    creator.isPicked = status
    creator.programs.forEach(program => {
      // assign the flag isPicked to program
      program.isPicked = status
      if (isInitCart) {
        // combine addon topic and addon resource
        program.addons = program.addons.concat(program.addons_resource)
      }
      program.addons.forEach(addon => {
        addon.isPicked = status
      })
    })
  })

  return newItems
}

function changePicked (items, { status, level, creatorId, programId, addonId }) {
  const newItems = JSON.parse(JSON.stringify(items))

  if (level === 'creator') {
    return changeCreatorLevel(newItems, { status, creatorId })
  } else if (level === 'program') {
    return changeProgramLevel(newItems, { status, creatorId, programId })
  } else if (level === 'addon') {
    return changeAddonLevel(newItems, { status, creatorId, programId, addonId })
  }
}

function changeCreatorLevel (items, { status, creatorId }) {
  items.forEach(creator => {
    if (creator.creator_id === creatorId) {
      creator.isPicked = status
      creator.programs.forEach(program => {
        program.isPicked = status
        program.addons.forEach(addon => {
          addon.isPicked = status
        })
      })
    }
  })

  return items
}

function changeProgramLevel (items, { status, creatorId, programId }) {
  items.forEach(creator => {
    if (creator.creator_id === creatorId) {
      creator.programs.forEach(program => {
        if (program.id === programId) {
          program.isPicked = status
          program.addons.forEach(addon => {
            addon.isPicked = status
          })
        }
      })
      // creator status is true if every of program true
      creator.isPicked = creator.programs.every(e => e.isPicked)
    }
  })

  return items
}

function changeAddonLevel (items, { status, creatorId, programId, addonId }) {
  items.forEach(creator => {
    if (creator.creator_id === creatorId) {
      creator.isPicked = true
      creator.programs.forEach(program => {
        if (program.id === programId) {
          program.isPicked = true
          program.addons.forEach(addon => {
            if (addon.id === addonId) {
              addon.isPicked = status
            }
          })
        }
      })
    }
  })

  return items
}

export {
  divideAvailableAndUnavailableCart,
  changeCartStatuses,
  changePicked
}
