<template>
  <b-modal
    v-model="isOpen"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    centered
    size="sm"
    content-class="modal-content-semi_round"
  >
    <div class="modal-wrapper">
      <div class="icon-close w-100">
        <img
          src="../../assets/icons/icon_close.svg"
          @click.prevent="toggleModalAuthentication()"
        />
      </div>
      <img
        src="https://cdn.sekolah.mu/assets/v2/halaman-akun/selamat-datang.webp"
        alt="illustration-error"
        class="modal-illustration"
        width="163px"
      >
      <div class="modal-auth-content-wrapper">
        <div class="modal-auth-title font-label-xl-sb">Selamat Datang di <br v-if="platinumInstitutionInfo.name"/> {{ appName }}</div>
        <div class="modal-auth-description font-label-md-rg-lh20">Mulai Belajar dan Tingkatkan Metode Belajarmu Bersama {{ appName }}</div>
      </div>
      <router-link to="/register" @click.native="logEventAnalytics('sign_up_mandatory_click')">
        <s-button id="button-register" block @onClick="saveLoginRedirect()">Daftar</s-button>
      </router-link>
      <router-link to="/login" @click.native="logEventAnalytics('login_mandatory_click')">
        <s-button variant="link" block @onClick="saveLoginRedirect()">Masuk</s-button>
      </router-link>
    </div>
  </b-modal>
</template>

<script>
import SButton from '../atoms/buttons/SButton.vue'
import deviceDetector from 'mobile-device-detect'

export default {
  components: { SButton },
  props: {
    isOpen: Boolean,
    redirectPath: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isMobile: deviceDetector.isMobile
    }
  },
  computed: {
    platinumInstitutionInfo () {
      return this.$store.state.institution.platinumInstitutionInfo
    },
    appName () {
      return this.platinumInstitutionInfo.name || 'Sekolah.mu'
    }
  },
  methods: {
    saveLoginRedirect () {
      this.$store.commit('SET_LOGIN_REDIRECTION', {
        path: this.redirectPath?.path || this.$route.path,
        name: this.redirectPath?.name || this.$route.name,
        isRedirected: 1
      })
    },
    toggleModalAuthentication () {
      this.$emit('toggleModalAuthentication')
    },
    logEventAnalytics (event) {
      window.firebaseAnalytics.logEvent(event, {
        screen_url: window.location.href,
        user_profession: '',
        user_year_level: '',
        user_is_admin: '',
        user_id: '',
        source: this.isMobile ? 'web_mobile_sekolahmu' : 'web_sekolahmu'
      })
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  padding: 5px;
  text-align: center;
  gap: 16px;
}

.icon-close {
  text-align: right;
  cursor: pointer;
}

.modal-auth-title {
  color: var(--color-ui-text-main-title);
  margin-bottom: 8px;
}

.modal-illustration {
  margin-bottom: 20px;
}

.modal-auth-description {
  margin-bottom: 18px;
}

#button-register {
  margin-bottom: 10px;
}
</style>
