import APIPayment from '@/api/payment'
import AxiosServer from '@/configs/axiosServer.js'

const getCurrentChecklistStatus = (currentState, id) => {
  const selectedFilter = currentState.find(item => item.id === id)
  return selectedFilter !== undefined ? selectedFilter.checklist_status : false
}

const setChecklistFilter = (state, selectedFilter) => {
  return state.length ? state.map(item => {
    for (let i = 0; i < selectedFilter.length; i++) {
      if (item.id === selectedFilter[i].identifier) {
        item.checklist_status = selectedFilter[i].value
        break
      }
    }
    return item
  }) : state.map(item => {
    item.checklist_status = false
    return item
  })
}

const educationPayment = {
  namespaced: true,
  state: {
    initialFetch: false,
    billTypeFilter: [],
    pickedSortId: 'due_date',
    sortOption: [
      { id: 'due_date', label: 'Jatuh Tempo Terdekat' },
      { id: 'created', label: 'Tagihan Terbaru' }
    ],
    nextUrl: '',
    currentBill: {},
    billPaymentScheme: {}
  },
  mutations: {
    SET_BILL_TYPE_FILTER (state, payload) {
      state.billTypeFilter = payload
    },
    SET_INITIAL_FETCH_STATUS (state, payload) {
      state.initialFetch = payload
    },
    SET_CHECKLIST_STATUS_FILTER (state, payload) {
      const newFilter = setChecklistFilter(state.billTypeFilter, payload)
      state.billTypeFilter = newFilter
    },
    SET_SORT_TYPE (state, payload) {
      state.pickedSortId = payload
    },
    SET_NEXT_URL (state, payload) {
      state.nextUrl = payload
    },
    SET_CURRENT_BILL (state, payload) {
      state.currentBill = payload
    },
    SET_BILL_PAYMENT_SCHEME (state, payload) {
      state.billPaymentScheme = payload
    }
  },
  actions: {
    async getBillList ({ rootState, commit, state }, payload) {
      const { data } = await APIPayment.getBillList({ Authorization: rootState.storage.accessToken, data: payload })
      if (data.status === 200) {
        if (!state.initialFetch) {
          const newFilter = data.filter.bill_type.map(item => {
            item.checklist_status = false
            return item
          })
          commit('SET_BILL_TYPE_FILTER', newFilter)
        } else {
          const newFilter = data.filter.bill_type.map(item => {
            item.checklist_status = getCurrentChecklistStatus(state.billTypeFilter, item.id)
            return item
          })
          commit('SET_BILL_TYPE_FILTER', newFilter)
        }
      }
      return data
    },
    async getNextUrl ({ rootState, commit, state }, payload) {
      const { data } = await AxiosServer({
        url: state.nextUrl,
        method: 'POST',
        headers: {
          Authorization: rootState.storage.accessToken
        },
        data: payload
      })
      if (!state.initialFetch) {
        const newFilter = data.filter.bill_type.map(item => {
          item.checklist_status = false
          return item
        })
        commit('SET_BILL_TYPE_FILTER', newFilter)
      } else {
        const newFilter = data.filter.bill_type.map(item => {
          item.checklist_status = getCurrentChecklistStatus(state.billTypeFilter, item.id)
          return item
        })
        commit('SET_BILL_TYPE_FILTER', newFilter)
      }
      return data
    }
  }
}

export default educationPayment
