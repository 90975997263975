const routes = [
  {
    path: '/paud',
    name: 'Detail PAUD',
    meta: {
      title: 'Jenjang PAUD | Sekolah.mu',
      metaTags: [
        {
          name: 'description',
          content: 'Belajar online serta offline semakin mudah dan terjangkau untuk jenjang PAUD. Dapatkan beragam paket kelas yang menyenangkan untuk menunjang perkembangan anak sedari dini.'
        }
      ]
    },
    component: () => import(/* webpackChunkName: 'detailPaud' */ '../views/yearLevel/DetailPaud.vue')
  },
  {
    path: '/sd',
    name: 'Detail SD',
    meta: {
      title: 'Jenjang SD | Sekolah.mu',
      metaTags: [
        {
          name: 'description',
          content: 'Bimbingan belajar online dan offline untuk jenjang SD dengan dukungan pengajar terbaik. Nikmati pengalaman belajar terbaik melalui paket kelas pilihan. Mudah, cepat, terjangkau, akses tanpa batas.'
        }
      ]
    },
    component: () => import(/* webpackChunkName: 'detailSD' */ '../views/yearLevel/DetailSD.vue')
  },
  {
    path: '/smp',
    name: 'Detail SMP',
    meta: {
      title: 'Jenjang SMP | Sekolah.mu',
      metaTags: [
        {
          name: 'description',
          content: 'Dapatkan pengalaman belajar terbaik melalui materi dan video pembelajaran yang menarik. Pilih paket kelas online dan offline sesuai minat dan kebutuhanmu.'
        }
      ]
    },
    component: () => import(/* webpackChunkName: 'detailSMP' */ '../views/yearLevel/DetailSMP.vue')
  },
  {
    path: '/sma-dan-smk',
    name: 'Detail SMA',
    meta: {
      title: 'Jenjang SMA/SMK | Sekolah.mu',
      metaTags: [
        {
          name: 'description',
          content: 'Solusi belajar online dan offline jenjang SMA/SMK dengan dukungan pengajar terbaik. Asah potensi, minat, dan bakat melalui pilihan paket kelas terbaik.'
        }
      ]
    },
    component: () => import(/* webpackChunkName: 'detailSMA' */ '../views/yearLevel/DetailSMA.vue')
  }
]

export default routes
