import axiosServerGolang from '@/configs/axiosServerGolang'

const baseUrl = '/product'

export default {
  async getBillScheme ({ id: relationId, project, accessToken, payoutId }) {
    /* relationId are refer to learning_package_id or bill_payment_id */
    const url = `${baseUrl}/v2/payment-scheme/${relationId}?type=${project}&payout_id=${payoutId}`
    try {
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: { Authorization: accessToken }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get payment scheme')
    }
  },
  async getPaymentScheme ({ id, accessToken, data }) {
    const url = `${baseUrl}/v2/payment-scheme/${id}`
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: { Authorization: accessToken },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get payment scheme')
    }
  },
  async fetchShipmentStatus ({ id, accessToken }) {
    const url = `${baseUrl}/shipment/detail/${id}?getFromElastic=1`
    try {
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: { Authorization: accessToken }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get shipment status')
    }
  },
  async recordLeadsquaredEvent ({ accessToken, payload }) {
    try {
      const result = axiosServerGolang({
        url: `${baseUrl}/lead-squared`,
        method: 'POST',
        headers: {
          Authorization: accessToken
        },
        data: payload
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to submit leadsquared event')
    }
  },
  async getMandatoryOptionalBillScheme ({ id, project, accessToken, payoutId, productIds }) {
    try {
      const url = `${baseUrl}/v2/payment-scheme/${id}`
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: { Authorization: accessToken },
        data: {
          type: project,
          payout_id: payoutId,
          product_ids: productIds
        }
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get payment scheme')
    }
  }
}
