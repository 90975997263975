const analytics = {
  namespaced: 'true',
  state: {
    source: ''
  },
  mutations: {
    SET_SOURCE (state, payload) {
      state.source = payload
    }
  }
}

export default analytics
