import { recalculateStatus } from '@/constants/recalculateStatus.js'

export default {
  computed: {
    recalculateInfo () {
      return this.$store.state.activityV2.report.recalculate_info
    },
    neverRecalculate () {
      return this.recalculateInfo.status === recalculateStatus.neverRecalculate
    },
    recalculating () {
      return this.recalculateInfo.status === recalculateStatus.recalculating
    },
    recalculated () {
      return this.recalculateInfo.status === recalculateStatus.recalculated
    }
  }
}
