var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reply-comment-container",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"modal-close-button",staticStyle:{"position":"absolute","top":"0px","right":"10px"}},[_c('img',{staticStyle:{"cursor":"pointer","width":"10px"},attrs:{"src":require("../../assets/icons/icon_close.svg")},on:{"click":function($event){$event.preventDefault();return _vm.closeReplyMessage.apply(null, arguments)}}})]),_c('div',{staticClass:"reply-box-left"}),_c('div',{staticClass:"reply-box-right"},[_c('div',{staticClass:"replied-name"},[_vm._v(_vm._s(_vm.replyComment.username))]),_c('div',{staticClass:"reply-content"},[(_vm.replyComment.payload.url)?_c('span',{staticClass:"text-right",staticStyle:{"max-width":"100px"}},[(_vm.replyComment.payload.url.toLowerCase().match(/\.(svg)$/))?[_c('div',{staticClass:"inter-medium_small-normal unsupported-svg"},[_vm._v("File svg tidak ditampilkan.")])]:[_c('a',{attrs:{"href":_vm.replyComment.payload.url,"target":"_blank","rel":"noopener noreferrer"}},[(
                _vm.replyComment.payload.url &&
                  _vm.replyComment.payload.url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/)
              )?_c('b-img',{style:(_vm.imageStyle),attrs:{"src":_vm.replyComment.payload.url}}):(
                _vm.replyComment.payload.url &&
                  _vm.replyComment.payload.url.match(/\.(m4a|mp3|ogg})$/)
              )?_c('audio',{style:(_vm.audioStyle),attrs:{"controls":"","src":_vm.replyComment.payload.url}}):(
                _vm.replyComment.payload.url &&
                  _vm.replyComment.payload.url.match(/\.(mp4|avi|3gp})$/)
              )?_c('video',{style:(_vm.videoStyle),attrs:{"controls":"","src":_vm.replyComment.payload.url}}):_c('span',[_vm._v(" "+_vm._s(_vm.replyComment.payload.file_name)+" "),_c('b-icon-download',{staticClass:"ml-2"})],1)],1)],_c('div',{style:(_vm.captionStyle)},[_vm._v(_vm._s(_vm.replyComment.payload.caption))])],2):_c('span',{style:(_vm.textStyle),domProps:{"innerHTML":_vm._s(_vm.urlify(_vm.replyComment.message))}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }