const funReport = {
  namespaced: true,
  state: {
    isOpenModalFunReportInvitation: false,
    isOpenModalFunReportInvitationQR: false
  },
  mutations: {
    CHANGE_IS_OPEN_MODAL_INVITATION (state, payload) {
      state.isOpenModalFunReportInvitation = payload
    },
    CHANGE_IS_OPEN_MODAL_INVITATION_QR (state, payload) {
      state.isOpenModalFunReportInvitationQR = payload
    }
  }
}

export default funReport
