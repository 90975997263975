const OTP_REFERENCE = {
  SMS: 'Log-in via SMS',
  CAPTCHA_LOGIN: 'captcha_login',
  CAPTCHA_FORGOT_PASSWORD: 'captcha_forget_password',
  VERIFY_USER: 'verify_user'
}

function getOTPReferenceId (lists, reference) {
  let id = -1
  lists.forEach(list => {
    if (list.name === reference) {
      id = list.id
    }
  })
  return id
}

export {
  OTP_REFERENCE,
  getOTPReferenceId
}
