<template>
  <div id="modal-register">
    <div id="close-icon">
      <img
        src="../../assets/icons/icon_close.svg"
        @click="toggleRegister()"
      />
    </div>
    <div
      id="modal-title"
      class="font-title-4"
    >
      Pendaftaran Akun
    </div>

    <div class="font-subtitle-4 login-container">
      <p>Sudah punya akun? <span @click="toggleMasuk()">Masuk di sini</span></p>
    </div>
    <div class="error-password general-message">{{ generalMessage }}</div>
    <form @submit.prevent="register()">
      <select v-model="roleUser" class="font-body-3" :class="{'input-field-select': !roleUser, 'input-field-select-selected': roleUser}">
        <option disabled value="">Profil Pengguna</option>
        <option value="Murid">Murid</option>
        <template v-for="(item, index) in yearLevelNonSekolah">
          <option :value="item.id" :key="index">{{ item.display_name || item.name }}</option>
        </template>
      </select>
      <select v-if="roleUser == 'Murid'" v-model="roleUserSekolah" class="font-body-3" :class="{'input-field-select': !roleUserSekolah, 'input-field-select-selected': roleUserSekolah}">
        <option disabled value="">Pilih jenjang/Usia</option>
        <template v-for="(item, index) in yearLevelSekolah">
          <option :value="item.id" :key="index">{{ item.name }}</option>
        </template>
      </select>
      <email-auto-complete
        v-model="email"
        :domains="domains"
        :default-domains="defaultDomains"
        :max-suggestions="5"
        :input-classes="['font-body-3', errorRegister? 'input-error': '', 'register-input', 'form-control']"
        :placeholder="emailPlaceholder"
      />
      <div
        class="error-password"
        v-if="errorRegister"
        style="margin-left: 5px; margin-top: 5px;"
      >{{messageRegister}}</div>
      <b-input
        :placeholder="namePlaceholder"
        type="text"
        name="full-name"
        class="font-body-3 register-input"
        v-model="fullName"
        :formatter="fullNameFormatter"
        :maxlength="30"
      ></b-input>
      <div
        class="pass-info"
      >
        Nama akan tercantum di sertifikat
      </div>
      <!-- <input
        :placeholder="`Nomor Ponsel ${isKid?'(Opsional)':''}`"
        type="number"
        name="phone"
        class="input-field"
        @input="phone = phone.length > 13 ? phone.slice(0, 13) : phone"
        v-model="phone"
        :class="{'input-error': errorPhone}"
      /> -->
      <b-input
        :placeholder="`Nomor ponsel${isPaud?' orangtua':''}`"
        type="text"
        name="phone"
        class="font-body-3 register-input"
        v-model="phone"
        :class="{'input-error': errorPhone}"
        :formatter="phoneNumberFormatter"
      ></b-input>
      <div
        class="pass-info error-password"
        v-if="errorPhone"
      >{{messagePhone}}</div>
      <div class="input-relative">
        <input
          placeholder="Kata sandi"
          :type="typePassword"
          name="password"
          class="font-body-3 input-field"
          v-model="password"
          :class="{'input-error': errorPassword}"
        />
        <div class="icon-wrapper">
          <font-awesome-icon
            icon="eye"
            @click="typePassword = 'text'"
            v-if="typePassword === 'password'"
          />
          <font-awesome-icon
            icon="eye-slash"
            @click="typePassword = 'password'"
            v-else
          />
        </div>
        <div
          style="margin-bottom: 20px;"
          class="pass-info"
          :class="{'error-password': errorPassword}"
        >Minimal 8 karakter kombinasi huruf besar, huruf kecil, dan angka</div>
      </div>
      <div id="input-checkbox" class="font-body-4">
        Dengan daftar di Sekolah.mu, saya menyetujui
        <router-link
          id="form-register-condition"
          to="/syarat-ketentuan"
          target="_blank"
          class="terms-condition-link"
        >
          Syarat &amp; Ketentuan
        </router-link>
        serta
        <router-link
          id="form-register-terms"
          to="/kebijakan-privasi"
          target="_blank"
          class="terms-condition-link"
        >
          Kebijakan Privasi
        </router-link>
        yang berlaku
      </div>
      <button
        class="button-register-disabled"
        disabled
        v-if="!isFilled"
      >Daftar Sekarang</button>
      <button
        class="button-register-disabled"
        disabled
        v-else-if="isLoading"
      >
        <b-spinner small></b-spinner> Daftar Sekarang
      </button>
      <button
        class="button-register"
        v-else
      >Daftar Sekarang</button>
    </form>
    <div id="atau-register">
      <hr>
      <div>atau</div>
      <hr>
    </div>
    <button
      id="google-oauth"
      @click="googleOauth()"
      :disabled="isLoadingOauth"
    >
      <img src="../../assets/icons/google_oauth.svg" v-if="!isLoadingOauth"/>
      <b-spinner style="margin-right: 40px; width: 18px; height: 18px;" variant="primary" v-else></b-spinner>
      <span>Daftar dengan Google</span>
    </button>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axiosServer from '../../configs/axiosServer'
import Toast from '../../configs/Toast'
import crypto from '@/functions/crypto'
import deviceDetector from 'mobile-device-detect'
// firebase messaging
import MessagingService from '../../modules/messaging-service'
import desktopNotification from '../../functions/pushNotification/desktopNotification'
// email autocomplete
import EmailAutoComplete from '@/components/atoms/inputs/EmailAutoComplete'
import setAuthPayload from '@/mixins/setAuthPayload.js'

import validateEmailWithRegex from '@/regex/email'
import sourceEncoderMixin from '@/mixins/sourceEncoderMixin.js'

export default {
  props: {
    isFromProgramDetail: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FontAwesomeIcon,
    EmailAutoComplete
  },
  mixins: [setAuthPayload, sourceEncoderMixin],
  data () {
    return {
      typePassword: 'password',
      typeConfirm: 'password',
      fullName: '',
      roleUser: '',
      roleUserSekolah: '',
      yearLevelSekolah: [],
      yearLevelNonSekolah: [],
      password: '',
      confirmation: '',
      phone: '',
      yearLevelId: null,
      isLoading: false,
      errorConfirmPassword: false,
      errorPassword: false,
      errorRegister: false,
      errorPhone: false,
      messagePhone: '',
      messageRegister: '',
      generalMessage: '',
      isOpenForm2: false,
      isMobile: deviceDetector.isMobile,
      isLoadingOauth: false,
      // email autocomplete
      domains: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com'
      ],
      defaultDomains: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com'
      ]
    }
  },
  methods: {
    fullNameFormatter (value) {
      return value.replace(/[^\w'. -]/g, '').replace(/[0-9]/g, '')
    },
    phoneNumberFormatter (value) {
      return value.replace(/[^\d+]/g, '')
    },
    phoneNumberValidation () {
      if (this.phone.length >= 9 && this.phone.length <= 15 && (this.phone.match(/[^\d+]/g) || []).length === 0) {
        if (this.phone.includes('+')) {
          if (this.phone.split('+').length === 2 && this.phone.split('+')[0] === '') {
            return true
          } else {
            this.messagePhone = 'Nomor Ponsel Tidak Valid'
            this.errorPhone = true
            return false
          }
        } else {
          return true
        }
      } else {
        this.messagePhone = 'Nomor Ponsel Tidak Valid'
        this.errorPhone = true
        return false
      }
    },
    async googleOauth () {
      this.isLoadingOauth = true
      try {
        const authCode = await this.$gAuth.getAuthCode()
        const result = await this.$axiosServerGolang({
          url: '/auth/external',
          data: {
            auth_code: authCode,
            agent: this.browserName,
            operation_system: this.OSName,
            longitude: this.longitude,
            latitude: this.latitude,
            source: this.isMobile ? btoa('sekolahmu-webview-google') : btoa('sekolahmu-web-google')
          },
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          },
          method: 'post'
        })

        if (result.data.status === 200) {
          Toast.fire({
            icon: 'success',
            title: 'Berhasil Mendaftar'
          })

          window.firebaseAnalytics.setUserProperties({
            user_id: result.data.data.id,
            user_profession: result.data.data.profession_name,
            user_year_level: result.data.data.year_level_name,
            user_registration_method: 'google',
            source: this.isMobile ? 'mobile-web' : 'web',
            is_platinum_user: false,
            is_prakerja_user: Boolean(result.data.data.prakerja_id),
            user_institution_id: result.data.data.institution_id
          })

          this.$store.dispatch('storage/loginSetStorage', result.data)

          localStorage.setItem('routing-before', this.$route.path)

          await this.$store.dispatch('user/fetchUserData')
          // if (dataLogin.status === 200) {
          //   this.$store.dispatch('tanyamu/loginQiscus')
          // }

          this.toggleRegister()

          if (window.firebase.messaging.isSupported() && this.isNotificationSupported) {
            this.listenFirebaseNotif()
          }

          this.$store.commit('user/CHANGE_IS_LOGIN', true)
          this.$store.dispatch('notification/getNotificationCounter')
          this.$cookies.set('name', crypto.encrypt(result.data.data.name), '', '', this.domain)
          this.$cookies.set('profession', crypto.encrypt(result.data.data.year_level_name), '', '', this.domain)
          this.$store.commit('storage/SET_NAME', result.data.data.name)
          this.$store.commit('storage/SET_PROFESSION', result.data.data.year_level_name)

          if (!result.data.data.year_level_id) {
            this.$emit('openRegistrationOAuthForm2')
          } else {
            if (this.isFromProgramDetail && this.$route.name === 'ProgramDetail') {
              this.$store.commit('user/CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL', true)
            } else {
              this.$store.commit('user/CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL', false)
            }
            this.$emit('closeAllModal')
          }
        }
        this.isLoadingOauth = false
      } catch (error) {
        this.isLoadingOauth = false
        console.log(error)
      }
    },
    toggleMasuk () {
      this.$emit('toggleMasuk')
    },
    toggleRegister () {
      this.$emit('toggleDaftar')
    },
    async fetchYearLevel () {
      try {
        const { data } = await axiosServer({
          url: '/user/profession/',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          },
          method: 'get'
        })
        if (data.status === 200) {
          this.yearLevelSekolah = data.data.filter(item => item.name === 'Murid')[0].year_level
          let temp = []
          data.data.forEach(element => {
            if (element.name !== 'Murid') {
              temp.push({ ...element.year_level[0], display_name: element.display_profession_name })
            }
          })
          this.yearLevelNonSekolah = temp
          this.roleUser = this.envBelajar ? 'Murid' : 17
        }
      } catch (err) {
        console.error(err)
      }
    },
    async register () {
      try {
        if (this.verificationPassword() && this.phoneNumberValidation() && this.emailValidation()) {
          this.isLoading = true

          const result = await this.$axiosServerGolang({
            url: '/auth/register',
            data: {
              name: this.fullName,
              email: this.email,
              password: this.password,
              phone: this.phone,
              year_level: this.yearLevelId,
              agent: this.browserName,
              operation_system: this.OSName,
              longitude: this.longitude,
              latitude: this.latitude,
              source: this.encodeSource()
            },
            headers: {
              Authorization: process.env.VUE_APP_APP_KEY
            },
            method: 'post'
          })

          this.isLoading = false
          this.errorPassword = false
          this.errorConfirmPassword = false
          if (result.data.status === 200) {
            Toast.fire({
              icon: 'success',
              title: 'Register berhasil'
            })
            window.firebaseAnalytics.setUserProperties({
              user_id: result.data.data.id,
              user_profession: result.data.data.profession_name,
              user_year_level: result.data.data.year_level_name,
              user_registration_method: 'regular',
              source: this.isMobile ? 'mobile-web' : 'web',
              is_platinum_user: Boolean(result.data.data.is_platinum_user),
              is_prakerja_user: Boolean(result.data.data.prakerja_id),
              user_institution_id: result.data.data.institution_id
            })

            this.$store.dispatch('storage/loginSetStorage', result.data)

            localStorage.setItem('routing-before', this.$route.path)

            await this.$store.dispatch('user/fetchUserData')
            // if (dataLogin.status === 200) {
            //   this.$store.dispatch('tanyamu/loginQiscus')
            // }
            if (this.isFromProgramDetail && this.$route.name === 'ProgramDetail') {
              this.$store.commit('user/CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL', true)
            } else {
              this.$store.commit('user/CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL', false)
            }
            this.$store.commit('user/CHANGE_IS_LOGIN', true)

            this.$cookies.set('name', crypto.encrypt(result.data.data.name), '', '', this.domain)
            this.$cookies.set('profession', crypto.encrypt(result.data.data.year_level_name), '', '', this.domain)
            this.$store.commit('storage/SET_NAME', result.data.data.name)
            this.$store.commit('storage/SET_PROFESSION', result.data.data.year_level_name)

            if (window.firebase.messaging.isSupported() && this.isNotificationSupported) {
              this.listenFirebaseNotif()
            }
            this.$emit('closeAllModal')
            // this.$router.push('/daftar')
            // this.$emit('toggleConfirmationRegister')
          } else {
            if (result.data.message.toLowerCase().includes('email')) {
              this.errorPhone = false
              this.messagePhone = ''
              this.errorRegister = true
              this.messageRegister = result.data.message
              this.generalMessage = ''
            } else if (result.data.message.toLowerCase().includes('telepon')) {
              this.errorPhone = true
              this.messagePhone = result.data.message
              this.errorRegister = false
              this.messageRegister = ''
              this.generalMessage = ''
            } else {
              this.errorPhone = false
              this.messagePhone = ''
              this.errorRegister = false
              this.messageRegister = ''
              this.generalMessage = result.data.message
            }
          }
        }
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    },
    verificationPassword () {
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/.test(this.password)) {
        this.errorRegister = false
        this.errorPhone = false
        this.errorConfirmPassword = false
        this.errorPassword = true
        return false
      } else {
        return true
      }
    },
    async setFbMessaging () {
      let msgService = new MessagingService()
      // Request permission
      await msgService.requestPermissionAsync()
      // this.isTokenSentToServer = msgService.isTokenSentToServer();
      return msgService.getTokenAsync()
    },
    listenFirebaseNotif () {
      if (Notification?.permission === 'granted') {
        this.setFbMessaging()

        window.firebaseMessaging.onMessage((payload) => {
          this.$store.commit('notification/SET_NEW_NOTIF', true)
          desktopNotification(payload.data.title, {
            body: payload.data.body,
            image: payload.data.icon,
            icon: 'https://cdn.sekolah.mu/assets/home/footer_logo.svg'
          }, '/notifikasi')
        })
      }
    },
    placeholderFormatter (prefix) {
      let rolePlaceholder
      if (this.roleUser !== 'Murid') {
        const user = this.yearLevelNonSekolah.filter(user => user.id === this.roleUser)[0]
        if (user.display_name === 'Orangtua' || user.display_name === 'Mahasiswa') {
          rolePlaceholder = `${prefix} ${user.display_name.toLowerCase()}`
        } else {
          rolePlaceholder = `${prefix} pengguna`
        }
      } else {
        rolePlaceholder = `${prefix} murid`
      }
      return rolePlaceholder
    },
    emailValidation () {
      const isProduction = (this.$store.state.activeEnv === 'production')
      const isEmailValid = validateEmailWithRegex(this.email, isProduction)

      return isEmailValid
    }
  },
  watch: {
    roleUser () {
      if (!(this.roleUser === 'Murid')) {
        this.roleUserSekolah = ''
        this.yearLevelId = parseInt(this.roleUser)
      }
    },
    roleUserSekolah () {
      if (this.roleUser === 'Murid') {
        this.yearLevelId = parseInt(this.roleUserSekolah)
      }
    },
    phone () {
      if (this.errorPhone) {
        this.messagePhone = ''
        this.errorPhone = false
      }
    }
  },
  computed: {
    isKid () {
      if (this.roleUserSekolah) {
        let getYearLevelObj = this.yearLevelSekolah.filter(item => item.id === this.roleUserSekolah)[0]
        if (getYearLevelObj.name.includes('PAUD')) {
          return true
        } else if (getYearLevelObj.name.split(' ')[3] < 10) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isPaud () {
      if (this.roleUserSekolah) {
        const getYearLevelObj = this.yearLevelSekolah.filter(item => item.id === this.roleUserSekolah)[0]
        return getYearLevelObj.name.includes('PAUD')
      } else {
        return false
      }
    },
    isFilled () {
      if (this.roleUser === 'Murid') {
        if (!this.roleUserSekolah) {
          return false
        }
      } else {
        if (!this.roleUser) {
          return false
        }
      }
      if (!this.isKid) {
        if (this.email &&
          this.password &&
          this.password.length >= 8 &&
          this.fullName.length > 2 &&
          // /^[a-zA-Z ]*$/.test(this.fullName) &&
          this.phone &&
          this.phone.length >= 9 &&
          this.phone.length <= 15
        ) {
          return true
        } else return false
      } else {
        if (this.email &&
          this.password &&
          this.password.length >= 8 &&
          this.fullName.length > 2 &&
          // /^[a-zA-Z ]*$/.test(this.fullName)
          this.phone &&
          this.phone.length >= 9 &&
          this.phone.length <= 15
        ) {
          return true
        } else return false
      }
    },
    email: {
      get () {
        return this.$store.state.login.email
      },
      set (value) {
        this.$store.commit('login/SET_EMAIL', value)
      }
    },
    domain () {
      return this.$store.state.hostDomain
    },
    envBelajar () {
      return this.$store.state.envBelajar
    },
    emailPlaceholder () {
      if (this.yearLevelNonSekolah.length > 0) {
        return this.placeholderFormatter('Email')
      }
      return 'Alamat email'
    },
    namePlaceholder () {
      if (this.yearLevelNonSekolah.length > 0) {
        return this.placeholderFormatter('Nama lengkap')
      }
      return 'Nama lengkap'
    },
    isNotificationSupported () {
      return this.$store.state.notification.isNotificationSupported
    }
  },
  created () {
    this.fetchYearLevel()
  }
}
</script>

<style scoped>
#modal-register {
  margin-bottom: 24px;
}

#close-icon {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#close-icon img {
  cursor: pointer;
}

#modal-title {
  text-align: center;
  color: var(--color-ui-text-main-title);
  margin-top: 10px;
  margin-bottom: 12px;
}

.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: var(--color-ui-text-primary);
  margin-bottom: 10px;
}

.login-container p {
  font-weight: 600;
  margin: unset;
}

.login-container span {
  color: var(--color-persian-blue-500);
  font-weight: 600;
  cursor: pointer;
}

.login-container span:hover {
  text-decoration: underline;
}

.input-field {
  margin-top: 10px;
  width: 100%;
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 9px 15px;
  color: var(--color-ui-text-primary);
}

.input-field-select {
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;

  margin-top: 10px;
  width: 100%;
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 7px 15px;
  color: var(--color-ui-text-placeholder);

  background-image: url('https://cdn.sekolah.mu/assets/home/chevron_3.svg');
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 50%;
}

.input-field-select-selected {
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;

  margin-top: 10px;
  width: 100%;
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 7px 15px;
  color: var(--color-ui-text-primary);

  background-image: url('https://cdn.sekolah.mu/assets/home/chevron_3.svg');
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 50%;
}

input.input-error {
  border: 1px solid var(--color-ui-text-error);
}

::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.input-relative {
  position: relative;
}

.icon-wrapper {
  position: absolute;
  color: #e0e0e0;
  top: 14px;
  right: 15px;
  font-size: 22px;
  cursor: pointer;
}

#input-checkbox {
  margin-top: 10px;
  color: var(--color-ui-text-primary);
  text-align: center;
}

.terms-condition-link {
  color: var(--color-persian-blue-500);
}

.button-register-disabled {
  background: var(--color-neutral-500);
  border-radius: 10px;
  padding: 7px 0px;
  width: 100%;
  font-weight: 600;
  border: 1px solid var(--color-neutral-500);
  color: var(--color-ui-text-placeholder);
  margin-top: 14px;
  transition: 0.3s;
}

.button-register {
  background: var(--color-persian-blue-500);
  border-radius: 10px;
  padding: 7px 0px;
  width: 100%;
  font-weight: 600;
  border: 1px solid var(--color-persian-blue-500);
  color: var(--color-neutral-100);
  margin-top: 14px;
  transition: 0.3s;
}

.button-register:hover {
  background-color: var(--color-persian-blue-400);
  border: 1px solid var(--color-persian-blue-400);
}

#atau-register {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
}

#atau-register hr {
  flex-grow: 1;
}

#atau-register div {
  padding: 10px;
}

#google-oauth {
  height: 40px;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, .54);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  border: transparent;
  background: var(--color-neutral-100);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  outline : none;
}

#google-oauth img {
  width: 18px;
  height: 18px;
  margin-right: 24px;
}

.error-password {
  color: var(--color-ui-text-error) !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.pass-info {
  color: var(--color-ui-text-placeholder);;
  font-size: 11px;
  text-align: left;
  margin-top: 4px;
  padding-left: 8px;
}

.register-input{
  margin-top: 10px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 19px 15px;
  color: var(--color-ui-text-primary);
}

.register-input::placeholder,
.input-field::placeholder {
  color: var(--color-ui-text-placeholder);
}

.general-message{
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
