<template>
  <div class="activity-v2-layout h-100" :class="{ 'sidebar-open': isSidebarVisible }" @contextmenu.prevent>
    <activity-v2-page-skeleton v-if="isShowingSkeleton"/>
    <template v-else>
      <div class="main h-100">
        <activity-header />
        <slot></slot>
      </div>
      <sidebar />
    </template>
    <group-selection v-if="needStudyGroup && !isStudyGroupProcess"/>
    <on-process-modal v-if="isStudyGroupProcess"/>
  </div>
</template>

<script>
import ActivityHeader from '@/components/activityV2/ActivityHeader'
import Sidebar from '@/components/activityV2/Sidebar'
import activityV2SidebarMixin from '@/mixins/activityV2SidebarMixin'
import ActivityV2PageSkeleton from '@/components/activityV2/Skeleton/ActivityV2PageSkeleton.vue'
import GroupSelection from '@/components/programmu/GroupSelection/GroupSelection.vue'
import OnProcessModal from '@/components/programmu/GroupSelection/OnProcessModal.vue'

export default {
  name: 'ActivityV2Layout',
  components: {
    ActivityHeader,
    Sidebar,
    ActivityV2PageSkeleton,
    GroupSelection,
    OnProcessModal
  },
  mixins: [activityV2SidebarMixin],
  created () {
    this.$store.commit('activityV2/SET_IN_STUDY_PAGE_REVAMP', true)
  },
  beforeDestroy () {
    this.$store.commit('activityV2/SET_IN_STUDY_PAGE_REVAMP', false)
  },
  computed: {
    needStudyGroup () {
      return this.$store.state.activityV2.validate.need_study_group && this.$store.state.activityV2.validate.study_group_type === 'manual_by_user'
    },
    isStudyGroupProcess () {
      return this.$store.state.activityV2.validate.on_process_study_group
    },
    isShowingSkeleton () {
      return this.needStudyGroup || this.isStudyGroupProcess
    }
  }
}
</script>

<style scoped>
.activity-v2-layout {
  background-color: var(--color-neutral-400);
}

.main {
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  width: 100%;
}

>>> .btn-toggle-activity-v2-sidebar {
  box-shadow: 0 2px 8px var(--color-medium-shadow);
  border-radius: 10px 0 0px 10px;
  padding: 0 16px;
  height: 40px;
  margin-top: 0;
}

>>> .btn-toggle-activity-v2-sidebar:hover {
  text-decoration: none;
}

.sidebar-open >>> .activity-v2-sidebar {
  right: 0;
}

@media (min-width: 992px) {
  .sidebar-open .main {
    width: calc(100% - 378px);
  }

  .sidebar-open >>> .activity-v2-navbar {
    width: calc(100% - 378px);
  }
}
</style>
