export const SEKOLAHMU_HOMEPAGE_VIEW = 'sekolahmu_homepage_view'
export const HEADLINE_CLICK = 'headline_click'
export const PILIHAN_KATEGORI_JENJANG_CLICK = 'kategori_jenjang_click'
export const PAUD_JENJANG_VIEW = 'PAUD_jenjang_view'
export const SD_JENJANG_VIEW = 'SD_jenjang_view'
export const SMP_JENJANG_VIEW = 'SMP_jenjang_view'
export const SMA_JENJANG_VIEW = 'SMA_jenjang_view'
export const LOGIN_VIEW = 'login_view'
export const LOGIN_CLICK = 'login'
export const SIGN_UP_VIEW = 'sign_up_view'
export const SIGN_UP_CLICK = 'registrasi_sukses'
export const VERIFICATION_PAGE_VIEW = 'verifikasi_page_view'
export const SEARCH_TYPE = 'search_type'
export const PROGRAM_DETAIL_PAGE_VIEW = 'product_detail_page_view'
export const ADD_TO_CART = 'add_to_cart'
export const CONTINUE_TO_CHECKOUT = 'lanjut_ke_pembayaran'
export const ACTIVITY_VIEW = 'activity_view'
