import contentWithCompletionTimer from '@/constants/completionTimer'
import { ACTIVITY_VIEW } from '@/constants/gtmEventNames'
import sendDataToGtm from '@/functions/sendDataToGtm'

export default {
  computed: {
    authorization () {
      return this.$store.state.storage.accessToken
    },
    activityValidate () {
      return this.$store.state.activityV2.validate
    },
    activityFocus () {
      return this.$store.state.activityV2.activityFocus
    },
    activityResourceDetail () {
      return this.$store.state.activityV2.activityResourceDetail
    },
    isGroupActivity () {
      return this.$store.state.activityV2.activityResourceDetail.is_group_activity === 1
    },
    isGroupActivityManualByUser () {
      return this.$store.state.activityV2.activityResourceDetail.group_activity_type === 'manual_by_user'
    },
    isUserJoinAGroup () {
      return this.$store.state.activityV2.activityResourceDetail.is_join_group
    },
    isQuiz () {
      const quiz = ['refleksi', 'quiz']
      return quiz.includes(this.activityResourceDetail.type)
    },
    isQuizV2 () {
      // Temporary logic to show quiz v2 components
      return this.activityResourceDetail.type === 'quiz-v2'
    },
    isContentCompletionTimerAllowed () {
      return contentWithCompletionTimer.includes(this.activityResourceDetail.type)
    },
    isDevEnv () {
      return process.env.VUE_APP_URL_SERVER.includes('dev')
    },
    isCurrentActivityComplete () {
      return this.$store.state.activityV2.activityResourceDetail.is_complete
    },
    isExperimental () {
      return this.$store.state.activityV2.isExperimental
    },
    hasCompletionTimer () {
      return this.$store.state.activityV2.activityResourceDetail.completion_timer >= 0
    },
    program () {
      return this.$store.state.activityV2.activityDashboard
    },
    selectedGroupActivity () {
      return this.$store.state.activityV2.activityResourceDetail.content_group.find(group => group.selected_group)
    },
    source () {
      return this.$store.state.analytics.source
    },
    userId () {
      return this.$store.state.storage.userId
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    userLandingTimestamp () {
      return this.$store.state.activityV2.userLandingTimestamp
    },
    isLoadingSetLastState () {
      return this.$store.state.activityV2.isLoadingSetLastState
    }
  },
  methods: {
    setActivityComplete (hasCompletionTimer = this.hasCompletionTimer, showWarning = true) {
      if (this.isContentCompletionTimerAllowed && hasCompletionTimer) {
        return
      }
      const activityId = this.activityResourceDetail.id
      const number_of_play = this.activityFocus.user_activity.number_of_play + 1 || undefined
      this.$store.dispatch('activityV2/setActivityComplete', { activityId, showWarning, number_of_play })
    },
    setActivityCompleteWithLastState (last_state) {
      const activityId = this.activityResourceDetail.id
      const number_of_play = this.activityFocus.user_activity.number_of_play + 1 || undefined
      this.$store.dispatch('activityV2/setActivityCompleteWithLastState', { activityId, last_state, number_of_play })
    },
    checkpointActivityDuration (last_state) {
      const activityId = this.activityResourceDetail.id
      const number_of_play = this.activityFocus.user_activity.number_of_play
      this.$store.dispatch('activityV2/checkpointActivityDuration', { activityId, last_state, number_of_play })
    },
    emitDataToFirebaseAnalyticsAndGtm () {
      const endTime = Date.now()
      const engagementTime = Math.round((endTime - this.userLandingTimestamp) / 1000)
      const categoryName = this.program.category_name.toLowerCase()
      const categoryProgramString = `${categoryName}_program_${Number(this.program.price_normal) ? 'paid' : 'free'}`
      const currentRouteFullPath = this.$route.fullPath
      const payload = {
        is_platinum_user: this.userInfo.is_platinum_user,
        is_prakerja_user: !!this.userInfo.prakerja_id,
        screen_engagement_time: engagementTime,
        screen_url: `https://www.sekolah.mu${currentRouteFullPath}`,
        program_id: this.program.id,
        source: this.source,
        user_id: this.userInfo.id,
        user_institution_id: this.userInfo.institution_id,
        user_profession: this.userInfo.profession_name,
        user_year_level: this.userInfo.year_level_name,
        smm_user: categoryName === 'smm' ? categoryProgramString : null,
        sekolahmu_user: categoryName === 'sekolahmu' ? categoryProgramString : null,
        kariermu_user: categoryName === 'kariermu' ? categoryProgramString : null
      }
      window.firebaseAnalytics.logEvent('activity_view', payload)
      sendDataToGtm({ event: ACTIVITY_VIEW, ...payload })
    }
  }
}
