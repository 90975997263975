<template>
  <div class="text-center" v-if="isOpen || isLoading">
    <div v-if="isLoading">
      <b-spinner variant="primary"></b-spinner>
      <div class="gotham-bold oops-text my-2">Verifikasi Akun</div>
      <div class="open-sans detail-text">Akun kamu sedang diverifikasi.</div>
      <div class="open-sans detail-text">Mohon tunggu beberapa saat.</div>
    </div>
    <div v-else>
      <div class="text-right p-0 m-0" v-if="$route.name !== 'ProgramDetail'">
        <b-button variant="link" @click="$store.commit('program/CHANGE_OPEN_PROGRAM_TERBATAS', { isOpen: false })">
          <img src="../../assets/icons/icon_close.svg"  />
        </b-button>
      </div>
      <img src="../../assets/images/blue-thumb.png" alt="image-alert" width="198px"/>
      <div class="gotham-bold oops-text my-2">Program Terbatas</div>
      <div class="open-sans detail-text">
        Program ini hanya dapat diakses oleh pengguna tertentu. Info lebih lanjut, hubungi penyelenggara program atau <span style="color: #96C9EC">suaramu@sekolahmu.co.id</span>
      </div>
      <b-button
        v-if="$route.name === 'ProgramDetail' || !isLogin"
        pill
        class="gotham-book confirmation-btn mt-4 mb-3"
        :to="redirectUrl"
        @click.prevent="action()"
      >
        {{isLogin || ($route.name === 'ProgramDetail' && isLogin) ? 'Kembali ke Beranda' : 'Masuk Sekarang'}}
      </b-button>
    </div>
  </div>
</template>

<script>
import toggleInspectElement from '@/functions/toggleInspectElement'

export default {
  computed: {
    isLogin () {
      return this.$store.state.user.isLogin
    },
    redirectUrl () {
      if (this.isLogin || (this.$route.name === 'ProgramDetail' && this.isLogin)) {
        return '/'
      } else {
        return ''
      }
    },
    isLoading () {
      return this.$store.state.program.isLoadingProgramTerbatas
    },
    isOpen () {
      return this.$store.state.program.isOpeningProgramTerbatas
    }
  },
  methods: {
    action () {
      if (!this.isLogin) {
        this.$store.commit('program/CHANGE_OPEN_PROGRAM_TERBATAS', { isOpen: true, isLoading: true })
        this.saveLoginRedirect()
        this.$router.push('/login')
      } else {
        this.$store.commit('program/CHANGE_OPEN_PROGRAM_TERBATAS', { isOpen: false })
      }
    },
    saveLoginRedirect () {
      this.$store.commit('SET_LOGIN_REDIRECTION', {
        path: this.$route.path,
        name: this.$route.name,
        isRedirected: 1
      })
    }
  },
  watch: {
    $route (to) {
      if (to.name !== 'ProgramDetail') {
        this.$store.commit('program/CHANGE_OPEN_PROGRAM_TERBATAS', { isOpen: false })
      }
    }
  },
  mounted () {
    toggleInspectElement(false)
  },
  destroyed () {
    toggleInspectElement(true)
  }
}
</script>

<style scoped>
.oops-text {
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #172D5C;
}

.detail-text {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.confirmation-btn {
  border: none;
  min-width: 230px;
  font-size: 14px;
  color: var(--color-neutral-100);
  background-color: var(--color-persian-blue-500);
}

.confirmation-btn:hover {
  background-color: var(--color-persian-blue-400);
}
</style>
