<template>
  <div id="default-layout">
    <Navbar />
    <slot />
    <float-action-button v-if="isShowFloatingBtn"></float-action-button>
    <FooterCopyright />
  </div>
</template>

<script>
import Navbar from '../components/navbar/Navbar'
import FooterCopyright from '../components/FooterCopyright'
// tambahan fab untuk tanyamu by Harfi
import FloatActionButton from '@/components/tanyamu/FloatActionButton'

export default {
  data () {
    return {
      isShowFloatingBtn: true
    }
  },
  components: {
    Navbar,
    FooterCopyright,
    FloatActionButton
  },
  methods: {
    defineTanyamuFloatingBtn (value) {
      this.isShowFloatingBtn = value
    }
  },
  mounted () {
    // let sociomile = document.getElementsByClassName('custom_widget')[0]
    // sociomile.style.display = 'none'
  },
  async created () {
    // let sociomile = document.getElementsByClassName('custom_widget_container')
    // console.log(sociomile[0]);
    // sociomile.style.display = 'none'
    this.$store.commit('activity/SET_IN_STUDY_PAGE', true)
    this.$store.commit('SET_ENV_BELAJAR')
    this.$store.commit('SET_ACTIVE_ENVIRONMENT')
    this.$store.dispatch('subnavbar/initMenuList')
    const hideSociomile = time => new Promise(resolve => setTimeout(() => {
      let sociomile = document.getElementsByClassName('custom_widget')[0]
      if (sociomile) {
        sociomile.style.display = 'none'
        resolve(true)
      } else {
        resolve(false)
      }
    }, time))

    for (let i = 0; i < 5; i++) {
      const sociomile = await hideSociomile(300)
      if (sociomile) {
        break
      }
    }
  },
  beforeDestroy () {
    this.$store.commit('activity/SET_IN_STUDY_PAGE', false)
  }
}
</script>

<style scoped>
#default-layout {
  font-family: "Open Sans", sans-serif;
}
</style>
