import axiosServer from '../../configs/axiosServer'
import voucherAPI from '@/api/voucher.js'

const voucher = {
  namespaced: true,
  state: {
    isVoucherApplied: false,
    message: '',
    isVoucherLoading: false,
    code: '',
    nextPage: 1,
    voucherLists: [],
    isVoucherListError: false,
    voucherCodeId: 0
  },
  mutations: {
    UPDATE_IS_VOUCHER_APPLIED (state, payload) {
      state.isVoucherApplied = payload
    },
    UPDATE_MESSAGE (state, payload) {
      state.message = payload
    },
    UPDATE_CODE (state, payload) {
      state.code = payload
    },
    UPDATE_VOUCHER_LOADING (state, payload) {
      state.isVoucherLoading = payload
    },
    UPDATE_VOUCHER_LIST (state, payload) {
      state.voucherLists = payload
    },
    UPDATE_NEXT_PAGE (state, payload) {
      state.nextPage = payload
    },
    UPDATE_IS_VOUCHER_LIST_ERROR (state, payload) {
      state.isVoucherListError = payload
    },
    UPDATE_VOUCHER_CODE_ID (state, payload) {
      state.voucherCodeId = payload
    }
  },
  actions: {
    async redeemVoucher ({ rootState }, payloadData) {
      try {
        const { data } = await axiosServer({
          url: '/payment/voucher_redeem/',
          method: 'POST',
          headers: {
            Authorization: rootState.storage.accessToken
          },
          data: payloadData,
          generateQueryParamSource: true
        })
        return data
      } catch (error) {
        return {
          status: 500,
          message: error.response.data.message
        }
      }
    },
    async getVoucherList ({ commit, state, rootState }, payload) {
      const { data } = await voucherAPI.getVoucherList({
        ...payload,
        accessToken: rootState.storage.accessToken
      })
      if (data?.status === 200) {
        commit('UPDATE_VOUCHER_LIST', [...state.voucherLists, ...data.data])
        const splittedNextUrl = data.next_url ? data.next_url.split('/') : [1]
        const nextPage = splittedNextUrl[splittedNextUrl.length - 2]
        commit('UPDATE_NEXT_PAGE', nextPage)
      } else {
        commit('UPDATE_IS_VOUCHER_LIST_ERROR', true)
      }
    }
  }
}

export default voucher
