<template>
  <form
    class="modal-new-password-container"
    @submit.prevent="validateUser()"
  >
    <div class="title-wrapper">
      <div
        class="title-modal inter-medium_small-bold fl-24"
      >
        Perbarui Kata Sandi
      </div>
      <div class="close-icon">
        <img
          id="new-password-close-btn"
          src="@/assets/icons/icon_close.svg"
          @click="toggleNewPassword()"
        />
      </div>
    </div>
    <div class="modal-description inter-medium_small-normal">
      Masukkan email yang sudah terdaftar. Instruksi pembaruan kata sandi akan dikirim ke email tersebut.
    </div>
    <div class="input-wrapper">
      <s-input-text
        id="input-email-reset-password"
        v-model="email"
        :is-error="Boolean(errorEmail)"
        type="text"
        name="email"
        class="register-text-input register-input-email"
        placeholder="Alamat email"
      />
      <div v-if="errorEmail" class="error-alert">
        <img src="https://cdn.sekolah.mu/assets/icon/toast/error.svg" alt="error-icon" class="error-icon">
        <span class="font-body-4">{{ errorEmail }}</span>
      </div>
    </div>
    <s-button
      variant="primary"
      id="button-submit"
      type="submit"
      block
      pill
      :is-loading="isLoading"
      @onClick="logEventAnalytics('lupa_sandi_submit_email_click')"
    >
      Kirim Instruksi
    </s-button>

  </form>
</template>

<script>
import deviceDetector from 'mobile-device-detect'
import SButton from '@/components/atoms/buttons/SButton.vue'
import SInputText from '@/components/atoms/inputs/SInputText.vue'
import validateEmailWithRegex from '@/regex/email'

import { ACCOUNT_REQUIRE_OTP, CAPTCHA_ERROR_BACKEND, FORGOT_PASSWORD_LIMIT_ATTEMPT } from '@/constants/errorStatus.js'
import AUTH_API from '@/api/auth.js'

export default {
  components: {
    SButton,
    SInputText
  },
  data () {
    return {
      isLoading: false,
      errorEmail: '',
      isMobile: deviceDetector.isMobile
    }
  },
  methods: {
    toggleNewPassword () {
      this.$emit('toggleNewPassword')
    },
    async validateUser () {
      this.errorEmail = ''
      if (!this.validateEmail()) {
        this.errorEmail = 'Email tidak valid'
        return
      }
      this.isLoading = true
      await this.sendForgetPassword()
    },
    async sendForgetPassword () {
      try {
        const payload = {
          email: this.email,
          source: this.isMobile ? window.btoa('sekolahmu-webview') : window.btoa('sekolahmu-web')
        }
        const data = await AUTH_API.sendForgotPassword({
          authorization: process.env.VUE_APP_APP_KEY,
          payload
        })
        this.errorEmail = ''
        if (data.status === ACCOUNT_REQUIRE_OTP || data.status === CAPTCHA_ERROR_BACKEND) {
          this.toggleNewPassword()
          const isForgotPassword = true
          this.$emit('toggleOTPState', this.email, isForgotPassword)
        } else if (data.status === 200) {
          this.$emit('toggleConfirmationPassword')
        } else if (data.status === FORGOT_PASSWORD_LIMIT_ATTEMPT) {
          this.$emit('showModalLimitAttempt', this.email)
          this.toggleNewPassword()
        } else {
          this.errorEmail = data.message ? data.message : 'Email tidak terdaftar'
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    logEventAnalytics (event) {
      window.firebaseAnalytics.logEvent(event, {
        screen_url: window.location.href,
        user_profession: '',
        user_year_level: '',
        user_is_admin: false,
        user_id: '',
        source: this.isMobile ? 'web_mobile_sekolahmu' : 'web_sekolahmu'
      })
    },
    validateEmail () {
      const isProduction = (this.$store.state.activeEnv === 'production')
      const isEmailValid = validateEmailWithRegex(this.email, isProduction)

      return isEmailValid
    }
  },
  computed: {
    emailActive () {
      if (this.email) {
        return true
      } else {
        return false
      }
    },
    email: {
      get () {
        return this.$store.state.login.email
      },
      set (value) {
        this.$store.commit('login/SET_EMAIL', value)
      }
    },
    isLogin () {
      return this.$store.state.user.isLogin
    },
    userInfo () {
      return this.$store.state.user.userInfo
    }
  }
}
</script>

<style scoped>
.title-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-neutral-500);
}

.input-wrapper {
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-neutral-500);
  padding-bottom: 16px;
}

.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  margin: 16px 29px 0 0;
  cursor: pointer;
}

.modal-new-password-container {
  padding: 0px 13px;
}

.title-modal {
  color: var(--color-persian-blue-900);
  text-align: center;
}

.modal-description {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.error-alert {
  display: inline-block;
  text-align: left;
  align-items: flex-start;
  color: var(--color-crimson-500);
}

.error-icon {
  height: 13.33px;
  width: 13.33px;
  margin-right: 4px;
}
</style>
