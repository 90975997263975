// <meta name="ahrefs-site-verification" content="321734df00461c02eb17db2d432a8225da179e65d76c996302059e91bfec7fb3">
// <meta name="google-site-verification" content="FJwQq5_4o71zKkgd2dl_xfuL00EWpEPrrSkkFsGP8_w" />

// <!-- Hub spot -->
// <script type="text/javascript" async defer src="//js.hs-scripts.com/6801130.js"></script>

const noScriptGoogleTag = () => {
  // <!-- Google Tag Manager (noscript) -->
  const noScript = document.createElement('noscript')
  const googleTag = document.createElement('iframe')
  const currentDomain = window.location.host.split('.')[0]
  const GTM_ID = currentDomain === 'smm' ? 'GTM-NNT9DBX' : 'GTM-PH5WQ8H'
  googleTag.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`)
  googleTag.setAttribute('height', 0)
  googleTag.setAttribute('width', 0)
  googleTag.setAttribute('style', 'display:none;visibility:hidden')
  noScript.appendChild(googleTag)
  document.body.appendChild(noScript)
  // <!-- End Google Tag Manager (noscript) -->
}

// const facebookPixelCode = () => {
//   document.write(`
//     <script>
//       !function(f,b,e,v,n,t,s)
//       {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
//       n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//       if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
//       n.queue=[];t=b.createElement(e);t.async=!0;
//       t.src=v;s=b.getElementsByTagName(e)[0];
//       s.parentNode.insertBefore(t,s)}(window, document,'script',
//       'https://connect.facebook.net/en_US/fbevents.js');
//       fbq('init', '1006262153204761');
//       fbq('track', 'PageView');
//     </script>
//   `)
//   const noScript = document.createElement('noscript')
//   const facebookPixelImg = document.createElement('img')
//   facebookPixelImg.setAttribute('src', 'https://www.facebook.com/tr?id=1006262153204761&ev=PageView&noscript=1')
//   facebookPixelImg.setAttribute('height', 1)
//   facebookPixelImg.setAttribute('width', 1)
//   facebookPixelImg.setAttribute('style', 'display:none')
//   noScript.appendChild(facebookPixelImg)
//   document.body.appendChild(noScript)
// }

// const googleSiteTag = () => {
//   // <!-- Global site tag (gtag.js) - Google Analytics -->
//   const scripted = document.createElement('script')
//   scripted.setAttribute('async', '')
//   scripted.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-6XVLZFB6PE')
//   document.body.appendChild(scripted)
//   document.write(`
//     <script>
//       window.dataLayer = window.dataLayer || [];
//       function gtag(){dataLayer.push(arguments);}
//       gtag('js', new Date());
//       gtag('config', 'G-6XVLZFB6PE');
//     </script>
//   `)
//   // <!-- End Global site tag (gtag.js) - Google Analytics -->
// }

const scriptInjector = () => {
  noScriptGoogleTag()
  // facebookPixelCode()
  // googleSiteTag()
}

export default scriptInjector
