import ObjectToQueryParam from '../functions/objectToQueryParams'
import axiosServerGolang from '@/configs/axiosServerGolang'

export default {
  async getPaymentMethod ({ accessToken, query }) {
    try {
      const queryURL = await ObjectToQueryParam(query)
      const response = await axiosServerGolang({
        url: `/payment/method${queryURL}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return response.data
    } catch (error) {
      return error
    }
  }
}
