import axiosServer from '../../configs/axiosServer'

const project = {
  namespaced: true,
  state: {
    filter: {},
    programs: [],
    nextUrl: ''
  },
  mutations: {
    INITIALIZE_STATES (state) {
      state.filter = {}
      state.programs = []
      state.nextUrl = ''
    },
    CHANGE_PROGRAM (state, programs) {
      state.programs = programs
    },
    CHANGE_FILTER (state, filters) {
      for (const key in filters) {
        switch (key.toLowerCase()) {
          case 'subject':
            state.filter[key] = [{ key: 'Semua Bidang Studi/Industri' }].concat(filters[key])
            break
          case 'year_level':
            state.filter[key] = [{ key: 'Semua Jenjang/Usia', name: 'Semua Jenjang/Usia' }].concat(filters[key])
            break
          default:
            state.filter[key] = [{ key: 'Semua' }].concat(filters[key])
            break
        }
      }
    },
    CHANGE_NEXT_URL (state, nextUrl) {
      state.nextUrl = nextUrl
    },
    APPEND_PROGRAM (state, programs) {
      state.programs = [...state.programs, ...programs]
    }
  },
  actions: {
    async fetchAllProgram ({ commit, dispatch, rootState }, { params, itemPerPage, isCustomized, nextUrl }) {
      try {
        let response, headers
        // let headers = { Authorization: rootState.storage.accessToken || process.env.VUE_APP_APP_KEY }
        if (isCustomized) {
          headers = { Authorization: process.env.VUE_APP_APP_KEY }
        } else {
          headers = { Authorization: rootState.storage.accessToken || process.env.VUE_APP_APP_KEY }
        }

        if (nextUrl) {
          response = await axiosServer({
            method: 'GET',
            url: nextUrl,
            headers
          })
        } else {
          const url = `/program_activity/v2/list/1/${itemPerPage}`
          response = await axiosServer({
            method: 'GET',
            url,
            headers,
            params
          })
        }

        const { data } = response
        if (data.status === 200 || data.message === 'OK') {
          commit(nextUrl ? 'APPEND_PROGRAM' : 'CHANGE_PROGRAM', data.data)
          commit('CHANGE_FILTER', data.filter)
          commit('CHANGE_NEXT_URL', data['next-url'])
        } else {
          commit('INITIALIZE_STATES')
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        commit('INITIALIZE_STATES')
      }
    }
  }
}

export default project
