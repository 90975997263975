<template>
  <div id="navbar-progress-detail-v2" class="navbar-progress-detail-wrapper">
    <b-row>
      <b-col class="inter-medium_small-bold progress-bar-title">
        Progress Bar
      </b-col>
      <b-col class="inter-small-bold progress-bar-total">
        {{ currentProgress }}/{{ totalProgress }} Aktivitas
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-progress :value="currentProgress" :max="totalProgress"></b-progress>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="inter-small-normal progress-bar-info">
        Ayo selesaikan seluruh aktivitas, untuk kembangkan kemampuan sesuai
        dengan kompetensi terbaikmu.
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ProgressDetail',
  props: {
    currentProgress: {
      type: Number,
      default: 0
    },
    totalProgress: {
      type: Number,
      default: 100
    }
  }
}
</script>

<style scoped>
.navbar-progress-detail-wrapper {
  color: var(--color-ui-text-primary);
  width: 288px;
  padding: 5px;
}
.progress-bar-title {
  text-align: left;
  margin: 10px 0;
}
.progress-bar-total {
  text-align: right;
  margin: 11px 3px;
  color: var(--color-persian-blue-500);
}
.progress-bar-info {
  text-align: left;
  margin: 12px 0;
}
.navbar-progress-detail-wrapper >>> .progress-bar {
  background-color: var(--color-persian-blue-500);
}
.navbar-progress-detail-wrapper >>> .progress {
  background-color: var(--color-neutral-300);
}
</style>
