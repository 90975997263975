export default {
  bill: {
    all: 'https://cdn.sekolah.mu/assets/images/bill/all.svg',
    allActive: 'https://cdn.sekolah.mu/assets/images/bill/all-active.svg',
    pending: 'https://cdn.sekolah.mu/assets/images/bill/belum-ada-pembayaran.svg',
    pendingActive: 'https://cdn.sekolah.mu/assets/images/bill/belum-ada-pembayaran-active.svg',
    activeDefault: 'https://cdn.sekolah.mu/assets/images/bill/active-default.svg',
    active: 'https://cdn.sekolah.mu/assets/images/bill/active.svg',
    paidoff: 'https://cdn.sekolah.mu/assets/images/bill/paidoff.svg',
    paidoffActive: 'https://cdn.sekolah.mu/assets/images/bill/paidoff-active.svg',
    failed: 'https://cdn.sekolah.mu/assets/images/bill/tidak-melakukan-pembayaran.svg',
    failedActive: 'https://cdn.sekolah.mu/assets/images/bill/tidak-melakukan-pembayaran-active.svg'
  },
  purchase: {
    all: 'https://cdn.sekolah.mu/assets/images/purchase/all.svg',
    allActive: 'https://cdn.sekolah.mu/assets/images/purchase/all-active.svg',
    pending: 'https://cdn.sekolah.mu/assets/images/purchase/pending.svg',
    pendingActive: 'https://cdn.sekolah.mu/assets/images/purchase/pending-active.svg',
    paid: 'https://cdn.sekolah.mu/assets/images/purchase/paid.svg',
    paidActive: 'https://cdn.sekolah.mu/assets/images/purchase/paid-active.svg',
    success: 'https://cdn.sekolah.mu/assets/images/purchase/success.svg',
    successActive: 'https://cdn.sekolah.mu/assets/images/purchase/success-active.svg',
    canceled: 'https://cdn.sekolah.mu/assets/images/purchase/cancel.svg',
    canceledActive: 'https://cdn.sekolah.mu/assets/images/purchase/cancel-active.svg',
    failed: 'https://cdn.sekolah.mu/assets/images/purchase/failed.svg',
    failedActive: 'https://cdn.sekolah.mu/assets/images/purchase/failed-active.svg',
    reject: 'https://cdn.sekolah.mu/assets/images/purchase/reject.svg',
    rejectActive: 'https://cdn.sekolah.mu/assets/images/purchase/reject-active.svg',
    expired: 'https://cdn.sekolah.mu/assets/images/purchase/expired.svg',
    expiredActive: 'https://cdn.sekolah.mu/assets/images/purchase/expired-active.svg',
    refund: 'https://cdn.sekolah.mu/assets/images/purchase/refund.svg',
    refundActive: 'https://cdn.sekolah.mu/assets/images/purchase/refund-active.svg'
  }
}
