<template>
  <s-pop-up
    cluster="s"
    :value="true"
    title="Pemilihan Kelompok Belajar Kelas Sedang Diproses"
    description="Proses pemilihan kelompok belajar kelas membutuhkan waktu beberapa saat. Silakan cek kembali halaman kelas ini secara berkala."
    :showCloseIcon="false"
  >
    <template #image>
      <img
        class="image-size"
        :src="imageUrl"
        alt="image"
      />
    </template>
    <template #footer>
      <s-button class="w-100" @click.prevent="submitHandler" cluster="s">
          Mengerti
      </s-button>
    </template>
  </s-pop-up>
</template>

<script>
export default ({
  name: 'OnProcessModal',
  components: {},
  data () {
    return {
      imageUrl: 'https://cdn.sekolah.mu/assets/illustration/tunggu-masuk-kelompok.svg',
      modalMessage: 'Proses pemilihan kelompok belajar kelas membutuhkan waktu beberapa saat. Silakan cek kembali halaman kelas ini secara berkala.'
    }
  },
  methods: {
    submitHandler () {
      this.$router.push('/kelasku')
    }
  }
})
</script>

<style lang="scss" scoped>
::v-deep .s-pop-up-container {
  width: 304px;
}

.image-size {
  width: 100%;
  height: 144px;
}
</style>
