const ADDON_ACTIVITY = {
  AT_TAB_CLICK: 'at_tab_click',
  AT_FILTER_CLICK: 'at_filter_click',
  AT_FILTER1_JENIS_CLICK: 'at_filter1_jenis_click',
  AT_FILTER1_KONSUL_FASIL_CLICK: 'at_filter1_konsul_fasil_click',
  AT_FILTER1_KELASPLUS_FASIL_CLICK: 'at_filter1_kelasplus_fasil_click',
  AT_FILTER1_DATE_MIN_CLICK: 'at_filter1_date_min_click',
  AT_FILTER1_DATE_MAX_CLICK: 'at_filter1_date_max_click',
  AT_FILTER1_TERAPKAN_CLICK: 'at_filter1_terapkan_click',
  AT_ADD_TO_CART_CLICK: 'at_add_to_cart_click',
  AT_JADWAL_CLICK: 'at_jadwal_click',
  AT_LIHAT_LOKASI_CLICK: 'at_lihat_lokasi_click',
  AT_FILTER2_OFFLINE_HUB_CLICK: 'at_filter2_offline_hub_click',
  AT_FILTER2_OFFLINE_DATE_CLICK: 'at_filter2_offline_date_click',
  AT_FILTER2_OFFLINE_TIME_CLICK: 'at_filter2_offline_time_click',
  AT_FILTER2_OFFLINE_TERAPKAN_CLICK: 'at_filter2_offline_terapkan_click',
  AT_FILTER2_KELASPLUS_FASIL_CLICK: 'at_filter2_kelasplus_fasil_click',
  AT_FILTER2_KELASPLUS_DATE_CLICK: 'at_filter2_kelasplus_date_click',
  AT_FILTER2_KELASPLUS_TIME_CLICK: 'at_filter2_kelasplus_time_click',
  AT_FILTER2_KELASPLUS_TERAPKAN_CLICK: 'at_filter2_kelasplus_terapkan_click',
  AT_FILTER2_KONSUL_FASIL_CLICK: 'at_filter2_konsul_fasil_click',
  AT_FILTER2_KONSUL_DATE_CLICK: 'at_filter2_konsul_date_click',
  AT_FILTER2_KONSUL_TIME_CLICK: 'at_filter2_konsul_time_click',
  AT_FILTER2_KONSUL_TERAPKAN_CLICK: 'at_filter2_konsul_terapkan_click',
  AT_FILTER2_LAINNYA_KELOMPOK_CLICK: 'at_filter2_lainnya_kelompok_click',
  AT_FILTER2_LAINNYA_TERAPKAN_CLICK: 'at_filter2_lainnya_terapkan_click',
  DELETE_CART: 'at_delete_cart_click',
  PILIH: 'at_pilih_click',
  RESET: 'at_filter1_reset_click',
  GRADES: 'at_kelas_click',
  TIMES: 'at_filter1_time_click',
  DISTRICTS: 'at_filter1_kota_kab_click',
  LOCATIONS: 'at_filter1_hub_click'
}

const TOPIC_EXPLORATION = {
  TT_TAB_CLICK: 'tt_tab_click',
  TT_KELAS_CLICK: 'tt_kelas_click',
  TT_KELAS_SIMPAN_CLICK: 'tt_kelas_simpan_click',
  TT_RESET_FILTER_CLICK: 'tt_reset_filter_click',
  DELETE_CART: 'tt_delete_cart_click',
  PILIH: 'tt_pilih_click',
  GRADES: 'tt_kelas_click',
  RESET: 'tt_reset_filter_click'
}

const ACTIVITY_V2 = {
  HALAMAN_BELAJAR_TANYAMU_CLICK: 'halaman_belajar_tanyamu_click',
  PENGUMUMAN_CLICK: 'pengumuman_click',
  HALAMAN_BELAJAR_KEBAB_MENU_CLICK: 'halaman_belajar_kebab_menu_click',
  CATATANFASIL_CLICK: 'catatanfasil_click',
  INSTRUKSI_CLICK: 'instruksi_click',
  TAB_ALIAS_CLICK: 'tab_alias_click',
  DISKUSI_CLICK: 'diskusi_click',
  HALAMAN_BELAJAR_KELOMPOK_CLICK: 'halaman_belajar_kelompok_click',
  SELANJUTNYA_CLICK: 'selanjutnya_click',
  SEBELUMNYA_CLICK: 'sebelumnya_click',
  ADDON_CLICK: 'addon_click',
  ONBOARDING_CLICK: 'onboarding_click',
  HALAMAN_BELAJAR_PANDUAN_CLICK: 'halaman_belajar_panduan_click',
  PENILAIAN_CLICK: 'penilaian_click',
  HALAMAN_BELAJAR_SHARE_CLICK: 'halaman_belajar_share_click',
  PENGUMUMAN_VIEW: 'pengumuman_view',
  SEARCH_TYPE: 'search_type',
  FITUR_CHANGE: 'fitur_change',
  KELOMPOK_CLICK: 'kelompok_click',
  HALAMAN_BELAJAR_EXIT_BUTTON_CLICK: 'halaman_belajar_exit_button_click',
  DAFTAR_AKTIVITAS_CLICK: 'daftar_aktivitas_click',
  HALAMAN_BELAJAR_LOGO_CLICK: 'halaman_belajar_logo_click',
  HALAMAN_BELAJAR_JUDUL_PROGRAM_CLICK: 'halaman_belajar_judul_program_click',
  HALAMAN_BELAJAR_BACK_BUTTON_CLICK: 'halaman_belajar_back_button_click',
  AKTIVITAS_CLICK: 'aktivitas_click',
  HITUNG_NILAI_SEMENTARA_CLICK: 'hitung_nilai_sementara_click'
}

const SEARCH = {
  SEKOLAHMU_BEGIN_SEARCH_CLICK: 'sekolahmu_begin_search_click',
  SEKOLAHMU_BEGIN_SEARCH_ICON_CLICK: 'sekolahmu_begin_search_icon_click',
  SEKOLAHMU_TERAKHIR_DILIHAT_CLICK: 'sekolahmu_terakhir_dilihat_click',
  SEKOLAHMU_TERAKHIR_DICARI_CLICK: 'sekolahmu_terakhir_dicari_click',
  SEKOLAHMU_TERAKHIR_DICARI_HAPUS_CLICK: 'sekolahmu_terakhir_dicari_hapus_click',
  SEKOLAHMU_PENCARIAN_TERPOPULER_CLICK: 'sekolahmu_pencarian_terpopuler_click',
  SEKOLAHMU_REKOMENDASI_PAKET_CLICK: 'sekolahmu_rekomendasi_paket_click',
  SEKOLAHMU_REKOMENDASI_PAKET_SEMUA_CLICK: 'sekolahmu_rekomendasi_paket_semua_click',
  SEKOLAHMU_REKOMENDASI_KELAS_CLICK: 'sekolahmu_rekomendasi_kelas_click',
  SEKOLAHMU_REKOMENDASI_KELAS_SEMUA_CLICK: 'sekolahmu_rekomendasi_kelas_semua_click'
}

export default {
  ADDON_ACTIVITY,
  TOPIC_EXPLORATION,
  ACTIVITY_V2,
  SEARCH
}
