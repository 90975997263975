const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Masuk/Login | Sekolah.mu',
      layout: 'blank',
      metaTags: [
        {
          name: 'description',
          content: 'Login ke akun Sekolah.mu sekarang dan dapatkan pengalaman belajar yang praktis dan menyenangkan!'
        }
      ],
      authForm: 'login',
      isRouteWithoutLogin: true,
      retainFormId: true
    },
    component: () => import(/* webpackChunkName: 'registration' */ '../views/authentication/AuthPageV3.vue')
  },
  {
    path: '/register-google',
    name: 'google-register',
    meta: {
      title: 'Daftar/Register | Sekolah.mu',
      layout: 'blank',
      metaTags: [
        {
          name: 'description',
          content: 'Sudah punya akun di Sekolah.mu? Mulai gunakan sekarang dan akses ragam kelas terbaik sesuai kebutuhan. Tersedia untuk PAUD, SD, SMP, SMA dan SMK.'
        }
      ],
      authForm: 'google',
      retainFormId: true
    },
    component: () => import(/* webpackChunkName: 'verification' */ '../views/authentication/AuthPageV3.vue')
  },
  // development only page
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: 'Daftar/Register | Sekolah.mu',
      layout: 'blank',
      metaTags: [
        {
          name: 'description',
          content: 'Sudah punya akun di Sekolah.mu? Mulai gunakan sekarang dan akses ragam kelas terbaik sesuai kebutuhan. Tersedia untuk PAUD, SD, SMP, SMA dan SMK.'
        }
      ],
      authForm: 'registration',
      isRouteWithoutLogin: true,
      retainFormId: true
    },
    component: () => import(/* webpackChunkName: 'registration' */ '../views/authentication/AuthPageV3.vue')
  },
  {
    path: '/verification',
    name: 'verification',
    meta: {
      title: 'Verifikasi | Sekolah.mu',
      layout: 'blank',
      metaTags: [
        {
          name: 'description',
          content: 'Login ke akun Sekolah.mu sekarang dan dapatkan pengalaman belajar yang praktis dan menyenangkan!'
        }
      ],
      authForm: 'verification',
      retainFormId: true
    },
    component: () => import(/* webpackChunkName: 'verification' */ '../views/authentication/AuthPageV3.vue')
  },
  {
    path: '/register-children',
    name: 'RegisterChildren',
    meta: {
      module: 'register-children',
      title: 'Daftar/Register | Sekolah.mu',
      layout: 'blank',
      metaTags: [
        {
          name: 'description',
          content: 'Sudah punya akun di Sekolah.mu? Mulai gunakan sekarang dan akses ragam kelas terbaik sesuai kebutuhan. Tersedia untuk PAUD, SD, SMP, SMA dan SMK.'
        }
      ],
      authForm: 'register-children',
      retainFormId: true,
      usingMobileHeader: true,
      hasBackButtonMobile: false,
      headerTitle: 'Daftarkan Akun Anak'
    },
    component: () => import(/* webpackChunkName: 'verification' */ '../views/authentication/AuthPageV3.vue')
  },
  {
    path: '/register-children-profile',
    name: 'RegisterChildrenProfile',
    meta: {
      module: 'register-children',
      title: 'Daftar/Register | Sekolah.mu',
      layout: 'no-footer',
      metaTags: [
        {
          name: 'description',
          content: 'Sudah punya akun di Sekolah.mu? Mulai gunakan sekarang dan akses ragam kelas terbaik sesuai kebutuhan. Tersedia untuk PAUD, SD, SMP, SMA dan SMK.'
        }
      ],
      authForm: 'register-children',
      retainFormId: true
    },
    component: () => import(/* webpackChunkName: 'verification' */ '../views/authentication/AuthPageV3.vue')
  },
  {
    path: '/login-relation',
    name: 'LoginRelation',
    meta: {
      module: 'login-relation',
      title: 'Relasi Akun | Sekolah.mu',
      authForm: 'login-relation'
    },
    component: () => import(/* webpackChunkName: 'loginrelation' */ '../views/authentication/LoginRelation.vue')
  },
  {
    path: '/switch-account-redirection',
    name: 'SwitchAccountRedirection',
    meta: {
      title: 'Switch Account | Sekolah.mu'
    },
    component: () => import(/* webpackChunkName: 'loginrelation' */ '../views/redirection/SwitchAccountRedirection.vue')
  }
]

export default routes
