export default {
  alfamart: require('./alfamart.png'),
  bca: require('./bca.png'),
  bni: require('./bni_new.png'),
  bri: require('./bri.png'),
  dana: require('./dana.png'),
  gopay: require('./gopay.png'),
  indomaret: require('./indomaret.png'),
  link_aja: require('./link_aja.png'),
  mandiri: require('./mandiri.png'),
  master_card: require('./master_card.png'),
  ovo: require('./ovo.png'),
  permata: require('./permata.png'),
  visa: require('./visa.png'),
  akulaku: require('./akulaku.png'),
  jcb: require('./jcb.png'),
  cc_complete: require('./cc_complete.svg'),
  prakerja: 'https://cdn.sekolah.mu/kariermu/assets/logo/prakerja-2023.webp',
  shopeepay: require('./shopeepay.svg'),
  cc_complete_midtrans: require('./cc_complete_midtrans.svg'),
  amex: 'https://cdn.sekolah.mu/assets/payment_method/amex.png'
}
