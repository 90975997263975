export default {
  beforeRouteLeave (to, from, next) {
    const tourOverlay = document.getElementsByClassName('shepherd-modal-is-visible')[0]
    const tourStep = document.getElementsByClassName('shepherd-enabled')[0]

    if (tourOverlay && tourStep) {
      tourOverlay.remove()
      tourStep.remove()
    }

    next()
  }
}
