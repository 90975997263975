const checkoutV5 = {
  namespaced: true,
  state: {
    checkoutData: null,
    shipmentData: {
      id: 0,
      recipient_name: '',
      address: '',
      phone: '',
      addressDetail: '',
      province_id: 0,
      province_name: '',
      district_id: 0,
      district_name: '',
      subdistrict_id: 0,
      subdistrict_name: '',
      village_name: '',
      village_id: 0,
      postal_code: 0,
      region_id: 0
    },
    tempShipmentData: {
      id: 0,
      recipient_name: '',
      address: '',
      phone: '',
      addressDetail: '',
      province_id: 0,
      province_name: '',
      district_id: 0,
      district_name: '',
      subdistrict_id: 0,
      subdistrict_name: '',
      village_name: '',
      village_id: 0,
      postal_code: 0,
      region_id: 0
    },
    isEditShipmentProcess: false,
    choosenPaymentMethod: null,
    previousURL: null,
    userPhoneNumber: null,
    phoneNumberValid: true,
    hasShipment: false,
    paymentMethodFee: 0,
    adminFee: 0,
    summaryTotalAmount: 0,
    creditCardPayload: {},
    creditCardValidationStatus: false,
    hostedPaymentData: {},
    selectedCCInstallment: null,
    ccBankCode: '',
    ccBrand: '',
    ccBankName: ''
  },
  mutations: {
    SET_CHECKOUT_DATA (state, payload) {
      state.checkoutData = payload
    },
    SET_SHIPMENT_DATA (state, payload) {
      state.shipmentData = payload
      state.tempShipmentData = payload
    },
    SET_TEMP_SHIPMENT_DATA (state, payload) {
      state.tempShipmentData = payload
    },
    SET_EDIT_SHIPMENT_PROCESS (state, payload) {
      state.isEditShipmentProcess = payload
    },
    SET_CHOOSEN_PAYMENT_METHOD (state, payload) {
      state.userPhoneNumber = null
      state.choosenPaymentMethod = payload
    },
    SET_PREVIOUS_URL (state, payload) {
      state.previousURL = payload
    },
    SET_USER_PHONE_NUMBER (state, payload) {
      state.userPhoneNumber = payload
    },
    CHANGE_PHONE_NUMBER_VALID (state, payload) {
      state.phoneNumberValid = payload
    },
    CHANGE_HAS_SHIPMENT (state, payload) {
      state.hasShipment = payload
    },
    SET_PAYMENT_METHOD_FEE (state, payload) {
      state.paymentMethodFee = payload
    },
    SET_ADMIN_FEE (state, payload) {
      state.adminFee = payload
    },
    SET_SUMMARY_TOTAL_AMOUNT (state, payload) {
      state.summaryTotalAmount = payload
    },
    CHANGE_CREDITCARD_PAYLOAD (state, payload) {
      state.creditCardPayload = payload
    },
    CHANGE_CREDITCARD_VALIDATION_STATUS (state, payload) {
      state.creditCardValidationStatus = payload
    },
    SET_HOSTED_PAYMENT_DATA (state, payload) {
      state.hostedPaymentData = payload
    },
    SET_CC_INSTALLMENT (state, payload) {
      state.selectedCCInstallment = payload
    },
    SET_CC_CARD_TYPE (state, payload) {
      state.ccBankCode = payload.ccBankCode
      state.ccBrand = payload.ccBrand
      state.ccBankName = payload.ccBankName
    }
  }
}

export default checkoutV5
