<template>
  <div
    :id="`focus-${data.slug}-button`"
    :class="activityWrapperClasses"
    @click="toggleActivityFocus"
    v-show="isShow"
  >
    <div class="activity-tags-wrapper">
      <div class="mb-2 activity-category-tags">
        <template v-if="data.activity_category">
          <s-tag
            :bg-color="data.activity_category_color"
            :text-color="data.activity_category_wording_color"
          >
            {{activityCategory[data.activity_category]}}
          </s-tag>
        </template>
      </div>
      <div class="mb-2 activity-unit-tags">
        <template v-if="data.is_assessment">
          <s-tag
            bg-color="--color-crimson-100"
            text-color="--color-crimson-700"
          >
            Asesmen
          </s-tag>
        </template>
        <template v-if="data.is_addon_resource">
          <s-tag
            bg-color="--color-eucalyptus-100"
            text-color="--color-eucalyptus-700"
          >
            Aktivitas Tambahan
          </s-tag>
        </template>
        <template v-else-if="data.mandatory">
          <s-tag
            bg-color="--color-gamboge-100"
            text-color="--color-gamboge-900"
          >
            Wajib
          </s-tag>
        </template>
      </div>
    </div>
    <div
      class="d-flex align-items-start fw-600 fs-14 activity-unit-content"
      :class="{ 'text-eucalyptus-700': data.is_addon_resource && !data.enrolled, 'text-gargoyle': data.enrolled && !isFromTopicAddon, 'text-placeholder':  isFromTopicAddon }"
    >
      <img
        src="@/assets/icons/activity-v2/icon-check.svg"
        class="activity-complete-icon mr-2"
        :class="{ 'invisible': !isActivityComplete }"
      />
      <div class="mr-2 activity-unit-number">
        {{number}}.
      </div>
      <div class="activity-unit-detail">
        <p class="mb-1 three-line-ellipsis activity-unit-title" :class="{ 'text-eucalyptus-700': isFromTopicAddon }">
          {{data.title}}
        </p>
        <div class="d-flex mb-2">
          <span class="mr-2 fw-400 fs-12 meta-info text-gargoyle">
            <b-img-lazy :src="data.activity_type_icon" class="img-fluid meta-info-icon" :class="{ 'from-topic-addon-icon': isFromTopicAddon }" />
            {{data.activity_type_label}}
          </span>
          <span class="fw-400 fs-12 meta-info text-gargoyle" v-if="data.duration">
            <img src="@/assets/icons/activity-v2/icon-jam.svg" class="img-fluid meta-info-icon" :class="{ 'from-topic-addon-icon': isFromTopicAddon }" />
            {{data.duration}} Menit
          </span>
        </div>
        <div class="mb-3">
          <p
            class="fw-400 fs-14 mb-2 activity-addon-description text-ui-primary"
            v-if="data.is_addon_resource && data.addon_resource_detail.description.length && !data.enrolled"
            :class="{ 'two-line-ellipsis': !isFullDescriptionVisible }"
            v-html="$XSSFilter(data.addon_resource_detail.description)"
          >
          </p>
          <div
            class="mb-3 text-persian-blue-500 cursor-pointer"
            @click.stop="toggleFullDescripton"
            v-if="data.is_addon_resource && data.addon_resource_detail.description.length > 80 && !data.enrolled"
            :id="`toggleFullDescription-${number}`"
          >
            {{toggleDescriptionText}}
            <img
              src="@/assets/icons/activity-v2/chevron-down.svg"
              class="img-fluid toggle-description-icon unrotate-class"
              :class="{ 'rotate-class': isFullDescriptionVisible }"
            />
          </div>
          <span
            class="d-block mb-3 fw-400"
            :class="{
              'text-crimson-500': isThresholdQuota,
              'text-gargoyle': !isThresholdQuota
            }"
            v-if="data.is_addon_resource && !data.is_group_activity && !data.enrolled"
          >
            {{data.addon_resource_detail.current_quota}} Kuota Tersisa
          </span>
          <div class="d-flex justify-content-between" v-if="data.is_addon_resource && !data.enrolled">
            <div>
              <span v-if="data.is_group_activity" class="d-block text-gargoyle fs-12 fw-400">
                Harga Mulai Dari
              </span>
              <span v-else class="d-block text-gargoyle fs-12 fw-400">
                Harga
              </span>
              <span class="primary-font-color">
                +{{formatRupiah(data.addon_resource_detail.price)}}
              </span>
            </div>
            <div>
              <s-button
                pill
                variant="secondary"
                class="btn-enroll-addon"
                :disabled="!data.addon_resource_detail.current_quota"
                @onClick="openBuyAddonModal"
                id="enroll-activity-addon"
              >
                {{joinActivityText}}
              </s-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import deviceDetector from 'mobile-device-detect'
import getAddOnActivityTypeSlug from '@/functions/getAddOnActivityTypeSlug.js'
import STag from '@/components/atoms/Tags/STag'
import SButton from '@/components/atoms/buttons/SButton'
import { formatRupiahMixin } from '@/mixins/formatRupiahMixin'
import ProgramActivityAPI from '@/api/program-activity'
import activityV2GeneralMixin from '@/mixins/activityV2/general'
import activityV2SidebarMixin from '@/mixins/activityV2SidebarMixin'
import categoryType from '@/constants/activityCategoryTypes.js'

export default {
  name: 'ActivityRegular',
  mixins: [formatRupiahMixin, activityV2GeneralMixin, activityV2SidebarMixin],
  components: {
    STag,
    SButton
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    number: {
      type: Number,
      default: 0
    },
    isLinier: {
      type: Boolean,
      default: false
    },
    isFromTopicAddon: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isFullDescriptionVisible: false,
      isActivityComplete: false,
      activityCategory: categoryType.activityCategory
    }
  },
  computed: {
    isActive () {
      return this.$route.params.id === this.data.slug
    },
    activityWrapperClasses () {
      return [
        'pb-3', 'pt-3', 'position-relative', 'activity-unit', 'ff-primary', 'cursor-pointer',
        {
          'current-activity': this.isActiveRegularActivity || this.isActiveAddonActivity,
          'd-none': this.data.is_addon_resource && !this.data.enrolled && !this.data.addon_resource_detail.is_available
        }
      ]
    },
    isThresholdQuota () {
      return this.data.addon_resource_detail.current_quota <= 5
    },
    toggleDescriptionText () {
      return this.isFullDescriptionVisible ? 'Tutup Penjelasan' : 'Selengkapnya'
    },
    joinActivityText () {
      return this.data.addon_resource_detail.current_quota ? 'Ikut Aktivitas' : 'Kuota Habis'
    },
    isDisabled () {
      return !this.data.is_complete && this.isLinier
    },
    tagDisabledText () {
      if (this.data.is_addon_resource) {
        return 'Aktivitas Tambahan'
      } else if (this.data.mandatory) {
        return 'Aktivitas Wajib'
      }
    },
    isActiveRegularActivity () {
      return this.isActive && !this.isFromTopicAddon && !this.data.is_addon_resource
    },
    isActiveAddonActivity () {
      return this.isActive && this.data.is_addon_resource && this.data.enrolled
    },
    isChatBoxOpen () {
      return this.$store.state.activityV2.isChatBoxOpen
    }
    // navigateId () {
    //   return this.$route.query.navigate_id
    // }
  },
  methods: {
    toggleFullDescripton () {
      this.isFullDescriptionVisible = !this.isFullDescriptionVisible
    },
    openBuyAddonModal () {
      const { group_activity_type, addon_resource_detail, slug } = this.data
      if (group_activity_type === 'manual_by_user') {
        const addonActivityTypeSlug = getAddOnActivityTypeSlug(addon_resource_detail.type)
        this.$router.push(`/aktivitas-tambahan/${addonActivityTypeSlug}/${slug}`)
      } else {
        this.$store.commit('activityV2/SET_ADDON_BUY_MODAL_VISIBILITY', true)
      }
    },
    async getActivityDetail (id) {
      try {
        const { user_id, program_id } = this.activityValidate
        const result = await ProgramActivityAPI.getActivityDetail({
          Authorization: this.authorization,
          data: {
            user_id,
            program_id,
            activity_id: Number(id) || this.data.id,
            group_id: 0
          }
        })
        if (result.data.status === 200) {
          this.setActivityFocus(result.data.data)
        } // Todo: Handle content error state in main content
      } catch (error) {
        console.error(error)
      }
    },
    setActivityFocus (activity) {
      this.$store.commit('activityV2/SET_ACTIVITY_FOCUS', activity)
      this.$store.commit('activityV2/SET_ACTIVITY_RESOURCE_DETAIL', activity.resource_detail)
      this.$store.commit('activityV2/SET_CURRENT_ACTIVITY_ID', activity.resource_detail.id)
      this.$store.commit('activityV2/SET_CURRENT_ACTIVITY_NUMBER', this.number)
      this.$store.commit('activity/CHANGE_ACTIVITY_FOCUS', activity.resource_detail)
      this.$store.commit('activityV2/SET_QUIZ_HAS_CONTENT', true)

      if (activity.resource_detail.type === 'quiz' || activity.resource_detail.type === 'refleksi') {
        if (JSON.parse(localStorage.getItem('isUsingQuizV2'))) {
          this.$store.commit('activityV2/TOGGLE_ACTIVITY_TYPE', 'quiz-v2')
        }
        this.$store.commit('quizV2/SET_QUIZ_DETAIL', activity.resource_detail.quiz_detail)
        this.$store.commit('quizV2/SET_IS_REFLECTION', activity.resource_detail.quiz_detail.is_refleksi)
        this.$store.commit('quizV2/SET_QUIZ_LOADING', true)
      }

      if (this.isChatBoxOpen) {
        this.$store.commit('activityV2/SET_CHAT_BOX', false)
      }
    },
    setActivityFocusRoute () {
      // prevent error duplicate navigation
      this.emitDataToFirebaseAnalyticsAndGtm()
      const path = `/aktivitas/${this.data.slug}`
      if (this.$route.path !== path) this.$router.push(path)
    },
    toggleActivityFocus () {
      // this.getActivityDetail(this.navigateId)  Todo: handle caching to speedup performance
      this.getActivityDetail()
      this.setActivityFocusRoute()
      deviceDetector.isMobile && this.toggleSidebar()
    }
  },
  watch: {
    'activityResourceDetail.is_complete' (value, prevValue) {
      if (this.isActive) { // Todo: handle missing active activity
        if (value !== prevValue) {
          this.isActivityComplete = value
        }
      }
    }
    // navigateId (nextValue, prevValue) {
    //   if (nextValue !== prevValue) {
    //     if (this.$route.params.id === this.data.slug) {
    //       this.getActivityDetail(nextValue)
    //     }
    //   }
    // }
  },
  mounted () {
    this.isActivityComplete = this.data.is_complete
    if (this.isActive) {
      // this.getActivityDetail(this.navigateId)
      this.getActivityDetail()
    }
  }
}
</script>
<style scoped>
.activity-unit::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -16px;
  height: 100%;
  width: calc(100% + 32px);
  transition: background 0.4s ease-in-out;
  z-index: -1;
}

.activity-unit:hover::before {
  background-color: var(--color-neutral-200);
}
.activity-tags-wrapper {
  display: flex;
  align-items: center;
}

.activity-category-tags {
  padding-left: 42px;
}

.activity-unit-content {
  /* color: var(--color-ui-text-secondary); */
  line-height: 20px;
}

.activity-addon-description {
  line-height: 20px;
  word-break: break-all;
}

.activity-unit-detail {
  flex-grow: 1;
}

.meta-info-icon {
  width: 16px;
  filter: var(--filter-color-ui-text-secondary);
}

.current-activity {
  pointer-events: none;
}

.current-activity::before,
.current-activity::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -16px;
  height: 100%;
}

.current-activity::before {
  width: calc(100% + 32px);
  background-color: var(--color-neutral-200);
  z-index: -1;
}

.current-activity::after {
  width: 8px;
  background-color: var(--color-persian-blue-500);
  z-index: 1;
}

.current-activity .meta-info,
.current-activity .activity-unit-title,
.current-activity .activity-unit-number {
  color: var(--color-persian-blue-500);
}

.current-activity .meta-info-icon {
  filter: var(--filter-color-persian-blue-500);
}

.from-topic-addon-icon {
  filter: var(--filter-color-ui-text-placeholder);
}

.tags-atom:first-of-type {
  margin-right: 8px;
}
</style>
