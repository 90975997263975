import axiosServerGolang from '@/configs/axiosServerGolang'

const baseUrl = '/payment'

export default {
  async getBillDetail ({ id, accessToken }) {
    const url = `${baseUrl}/v2/billing/detail/${id}`
    try {
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: {
          Authorization: accessToken
        }
      })
      if (result.status === 200) {
        return result.data
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async getBillList ({ Authorization, data }) {
    const url = `${baseUrl}/v2/billing/list/1/${data.perPage}`
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: { Authorization },
        data
      })
      return result
    } catch (error) {
      console.error(error, 'Failed to get bill list user')
    }
  },
  async getTransactionList ({ Authorization, page, limit, data }) {
    const url = `${baseUrl}/v5/list/${page}/${limit}`
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: { Authorization },
        data
      })
      return result.data
    } catch {
      console.error('Failed to get transaction list')
    }
  },
  async loadMore ({ Authorization, nextUrl = '', data }) {
    const url = nextUrl.split('v2')[1]
    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: { Authorization },
        data
      })
      return result.data
    } catch {
      console.error('Failed to get transaction list')
    }
  },
  async getTransactionDetail ({ Authorization, id }) {
    const url = `${baseUrl}/v5/detail/${id}`
    try {
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: { Authorization }
      })
      return result.data
    } catch {
      console.error('Failed to get transaction detail')
    }
  },
  async downloadReceipt ({ Authorization, id }) {
    const url = `${baseUrl}/v5/detail/${id}?download=1`
    try {
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: { Authorization }
      })
      return result.data
    } catch (error) {
      console.error(error)
    }
  },
  async getBillHistoryDetail ({ Authorization, payoutId }) {
    const url = `/payment/v2/billing/history-detail/${payoutId}`
    try {
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: { Authorization }
      })
      return result.data
    } catch (error) {
      console.error(error)
    }
  },
  async getInvoiceAttemptSnapshot ({ Authorization, payoutId, termin }) {
    const url = `/payment/snapshot/${payoutId}/${termin}`
    try {
      const result = await axiosServerGolang({
        method: 'GET',
        url,
        headers: { Authorization }
      })
      return result.data
    } catch (error) {
      console.error(error)
    }
  },
  async getPaymentInstruction ({ accessToken, slugName, providerName }) {
    try {
      const response = await axiosServerGolang({
        url: `${baseUrl}/payment-step/sekolahmu/${slugName}/${providerName}`,
        method: 'GET',
        headers: {
          Authorization: accessToken
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  // PAYMENT V5
  async checkoutV5 (accessToken, { type, checkoutPayload }) {
    try {
      const response = await axiosServerGolang({
        version: 'v5',
        url: `/payment/checkout/${type}`,
        method: 'POST',
        headers: { Authorization: accessToken },
        data: checkoutPayload
      })
      return response.data
    } catch (error) {
      return error
    }
  },
  async checkoutBulkV5 (accessToken, payload) {
    try {
      const response = await axiosServerGolang({
        method: 'POST',
        version: 'v5',
        url: '/payment/checkout-bulk',
        headers: { Authorization: accessToken },
        data: payload
      })
      return response.data
    } catch (error) {
      return error
    }
  },
  async createCartBulk (accessToken, payload) {
    try {
      const response = await axiosServerGolang({
        method: 'POST',
        version: 'v5',
        url: '/payment/checkout-cart',
        headers: { Authorization: accessToken },
        data: payload
      })
      return response.data
    } catch (error) {
      return error
    }
  },
  async payV5 (accessToken, payload) {
    const { type, body, platform, source } = payload
    const { isBulk } = body
    const url = isBulk
      ? `/payment/pay-multiple?platform=${platform}`
      : `/payment/pay/${type}?platform=${platform}`
    delete body.isBulk
    const requestBody = {
      url,
      method: 'POST',
      headers: { Authorization: accessToken },
      data: body,
      generateQueryParamSource: true,
      source,
      version: isBulk ? 'v5' : 'v2'
    }
    try {
      const response = await axiosServerGolang(requestBody)
      return response.data
    } catch (error) {
      return error
    }
  },
  // INVOICE
  async getReceipt ({ accessToken, id, isDownload }) {
    try {
      const url = '/payment/receipt/' + id + (isDownload ? '?download=1' : '')
      const response = await axiosServerGolang({
        method: 'GET',
        version: 'v5',
        url,
        headers: { Authorization: accessToken }
      })
      return response.data
    } catch (error) {
      return error
    }
  },
  // INSTALLMENT
  async checkBins ({ accessToken, binNumber }) {
    try {
      const url = `payment/bin-check/${binNumber}`
      const response = await axiosServerGolang({
        method: 'GET',
        version: 'v2',
        url,
        headers: { Authorization: accessToken }
      })
      return response.data
    } catch (error) {
      return error
    }
  },
  async getInstallmentFee ({ accessToken, bankCode, brand, amount, platform }) {
    try {
      const url = `payment/installment-credit-fees?bank_code=${bankCode}&brand=${brand}&amount=${amount}&platform=${platform}`
      const response = await axiosServerGolang({
        method: 'GET',
        version: 'v2',
        url,
        headers: { Authorization: accessToken }
      })
      return response.data
    } catch (error) {
      return error
    }
  }
}
