<template>
  <div id="error-message-modal">
    <b-modal
      v-model="isOpenModal"
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      size="error-message"
      content-class="modal-content-semi_round"
    >
      <div class="modal-error-message-container">
        <div class="illustration-container">
          <img :src="illustration" alt="Sekolah.mu" class="illustration">
        </div>
        <div class="header-container">
          <h5 class="font-label-xl-sb title">{{ title }}</h5>
        </div>
        <div class="description-container">
          <p class="font-label-md-rg-lh16 description">{{ description }}</p>
        </div>
        <s-button
          :variant="'primary'"
          pill
          class="close-button"
          @onClick="$emit('onCloseModal')"
          id="error-message-modal-close-button"
        >{{ buttonText }}</s-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import SButton from '@/components/atoms/buttons/SButton.vue'

export default {
  name: 'ErrorMessageModal',
  props: {
    isOpenModal: Boolean,
    illustration: String,
    title: String,
    description: String,
    buttonText: {
      type: String,
      default: 'Mengerti'
    }
  },
  components: {
    SButton
  }
}
</script>
<style scoped>
h5, p{
  margin: 0;
}
.modal-error-message-container{
  text-align: center;
  padding: 8px;
}
.illustration{
  width: 163px;
  height: 163px;
  object-fit: contain;
}
.title{
  color: var(--color-ui-text-main-title);
  margin-bottom: 8px;
}
.description{
  margin-bottom: 16px;
}
.close-button{
  width: 100%;
}
</style>
