const routes = [
  {
    path: '/receipt/program/:id',
    name: 'Resi Program',
    meta: {
      title: 'Resi Program',
      layout: 'blank'
    },
    component: () => import(/* webpackChunkName: 'regular receipt' */ '../views/receipt/RegularReceipt.vue')
  },
  {
    path: '/invoice/program/:id',
    name: 'Invoice Program',
    meta: {
      title: 'Resi Program',
      layout: 'blank'
    },
    component: () => import(/* webpackChunkName: 'regular receipt' */ '../views/receipt/RegularReceipt.vue')
  },
  {
    path: '/receipt/paket-belajar/:id',
    name: `Resi Paket Kelas`,
    meta: {
      title: `Resi Paket Kelas`,
      layout: 'blank'
    },
    component: () => import(/* webpackChunkName: 'learning package receipt' */ '../views/receipt/LearningPackageReceipt.vue')
  },
  {
    path: '/receipt/bill-payment/:id',
    name: 'BillPaymentReceiptV2',
    meta: {
      title: 'Resi Pembayaran Pendidikan',
      layout: 'blank'
    },
    component: () => import(/* webpackChunkName: 'BillPaymentReceiptV2' */ '../views/receipt/BillPaymentReceiptV2.vue')
  },
  {
    path: '/receipt/paket-belajar-v2/:id',
    name: 'LearningPackageReceiptV2',
    meta: {
      title: 'Resi Paket Belajar',
      layout: 'blank'
    },
    component: () => import(/* webpackChunkName: 'LearningPackageReceiptV2' */ '../views/receipt/BillPaymentReceiptV2.vue')
  },
  {
    path: '/receipt/:id',
    name: 'Prakerja Receipt',
    meta: {
      title: 'Prakerja Receipt',
      layout: 'blank'
    },
    component: () => import(/* webpackChunkName: 'prakerja receipt' */ '../views/receipt/PrakerjaReceipt.vue')
  },
  {
    path: '/invoice/:id',
    name: 'Prakerja Invoice',
    meta: {
      title: 'Prakerja Receipt',
      layout: 'blank'
    },
    component: () => import(/* webpackChunkName: 'prakereja receipt' */ '../views/receipt/PrakerjaReceipt.vue')
  }
]

export default routes
