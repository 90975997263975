<template>
  <div id="modal-otp">
    <div id="close-icon">
      <img
        src="../../assets/icons/icon_close.svg"
        @click="toggleOtp()"
      />
    </div>
    <form
      v-if="!isSend"
      @submit.prevent="submitNumber()"
    >
      <div class="title-modal gotham-bold">Masuk dengan Nomor Ponsel</div>
      <div
        class="error-message"
        v-if="errorMessage"
      >{{errorMessage}}</div>
      <input
        id="input-phone"
        type="number"
        v-model="phone"
        min="0"
        placeholder="Nomor Ponsel"
      />
      <div id="button-submit">
        <button
          v-if="!buttonActive"
          disabled
          class="button-disabled"
        >Lanjut</button>
        <button
          v-else-if="isLoading"
          disabled
          class="button-loading"
        >
          <b-spinner small></b-spinner> Lanjut
        </button>
        <button
          v-else
          class="button-active"
        >Lanjut</button>
      </div>
      <div id="to-register">
        <span>Belum punya akun?</span> <span
          id="register-user"
          @click="toggleDaftar()"
        >Daftar di sini</span>
      </div>
      <div id="help-report">
        Lapor kendala login ke <span>suaramu@sekolahmu.co.id</span>
      </div>
    </form>
    <form
      v-else
      @submit.prevent="submitOtp()"
    >
      <div id="image-modal">
        <img src="../../assets/icons/icon_otp_modal.svg" />
      </div>
      <div class="title-modal gotham-bold mt-3">
        Konfirmasi Nomor Ponsel
      </div>
      <div id="modal-description">Masukkan 4 digit kode yang kami kirim melalui SMS ke nomor <span>{{phone}}</span></div>
      <div id="otp-input">
        <input
          type="number"
          ref="first"
          v-model="first"
          @keyup.right="rightKey('first')"
          @keyup.delete="deleteKey('first')"
        />
        <input
          type="number"
          ref="second"
          v-model="second"
          @keyup.right="rightKey('second')"
          @keyup.left="leftKey('second')"
          @keyup.delete="deleteKey('second')"
        />
        <input
          type="number"
          ref="third"
          v-model="third"
          @keyup.right="rightKey('third')"
          @keyup.left="leftKey('third')"
          @keyup.delete="deleteKey('third')"
        />
        <input
          type="number"
          ref="fourth"
          v-model="fourth"
          @keyup.left="leftKey('fourth')"
          @keyup.delete="deleteKey('fourth')"
        />
      </div>
      <div
        class="error-message"
        v-if="errorOtp"
      >
        {{errorOtp}}
      </div>
      <div id="button-submit">
        <button
          v-if="!otpActive"
          disabled
          class="button-disabled"
        >Lanjut</button>
        <button
          v-else-if="isLoading"
          disabled
          class="button-loading"
        >
          <b-spinner small></b-spinner> Lanjut
        </button>
        <button
          v-else
          class="button-active"
        >Lanjut</button>
      </div>
      <div id="modal-footer">
        <div>Belum dapat email?</div>
        <div v-if="time > 0">Coba <span>Kirim Ulang</span> ({{time}}s)</div>
        <div v-else-if="isLoading"><span class="send-again">
            <b-spinner small></b-spinner>
          </span></div>
        <div v-else>Coba <span
            class="send-again"
            @click="submitNumber()"
          >Kirim Ulang</span></div>
      </div>
    </form>
  </div>
</template>

<script>
import axiosServer from '../../configs/axiosServer'
import Toast from '../../configs/Toast'

export default {
  data () {
    return {
      isSend: false,
      errorMessage: '',
      phone: '',
      isLoading: false,
      first: '',
      second: '',
      third: '',
      fourth: '',
      errorOtp: '',
      time: 60,
      timerInterval: '',
      otp_request_id: ''
    }
  },
  methods: {
    toggleIsSend () {
      this.isSend = !this.isSend
    },
    toggleDaftar () {
      this.$emit('toggleDaftar')
    },
    toggleOtp () {
      this.$emit('toggleOtp')
    },
    async submitNumber () {
      this.isLoading = true
      try {
        const { data } = await axiosServer({
          url: '/user/login/request_otp/',
          method: 'post',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          },
          data: {
            method: 'sms-otp',
            phone: this.phone
          }
        })
        this.isLoading = false
        console.log(data)
        if (data.status === 200) {
          this.isSend = true
          this.isLoading = false
          this.time = 60
          this.otp_request_id = data.data.otp_request_id
          this.startTimer()
        } else {
          if (data.message === 'multiple use bounded to phone, please contact admin to resolve this issue') {
            this.errorMessage = 'Nomor digunakan lebih dari 1 akun, mohon hubungi admin untuk menyelesaikan masalah ini.'
          } else if (data.message === 'retry attempt limit has reached') {
            this.errorMessage = 'OTP sudah dikirim sebelumnya, mohon tunggu beberapa saat'
          } else if (data.message === 'Fail OTP attempt has reached limit. Please wait until OTP expired and request for new OTP') {
            this.errorMessage = data.message
          } else {
            this.errorMessage = 'Nomor belum terdaftar'
          }
        }
      } catch (err) {
        this.isLoading = false
        console.log(err)
      }
    },
    async submitOtp () {
      this.isLoading = true
      try {
        console.log({
          method: 'sms-otp',
          phone: this.phone,
          otp_request_id: this.otp_request_id,
          otp_code: this.allOtp
        })
        const { data } = await axiosServer({
          url: '/user/login/',
          method: 'post',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          },
          data: {
            method: 'sms-otp',
            phone: this.phone,
            otp_request_id: this.otp_request_id,
            otp_code: this.allOtp
          }
        })
        this.isLoading = false
        if (data.status === 200) {
          Toast.fire({
            icon: 'success',
            title: 'Login berhasil'
          })
          this.$store.dispatch('storage/loginSetStorage', data)

          this.$store.dispatch('user/fetchUserData')

          this.errorMessage = ''
          this.errorOtp = ''

          this.toggleOtp()

          this.$store.commit('user/CHANGE_IS_LOGIN', true)
          if (data.data.year_level_id) {
            this.$router.push('/programmu')
          } else {
            this.$router.push('/daftar')
          }
        } else {
          this.errorOtp = data.message
        }
      } catch (err) {
        this.isLoading = false
        console.log(err)
      }
    },
    rightKey (place) {
      if (place === 'first') {
        this.$refs.second.focus()
      }
      if (place === 'second') {
        this.$refs.third.focus()
      }
      if (place === 'third') {
        this.$refs.fourth.focus()
      }
    },
    leftKey (place) {
      if (place === 'second') {
        this.$refs.first.focus()
      }
      if (place === 'third') {
        this.$refs.second.focus()
      }
      if (place === 'fourth') {
        this.$refs.third.focus()
      }
    },
    deleteKey (place) {
      this[place] = ''
      this.leftKey(place)
    },
    startTimer () {
      this.timerInterval = setInterval(() => {
        if (this.time === 0) {
          clearInterval(this.timerInterval)
        }
        this.time -= 1
      }, 1000)
    }
  },
  computed: {
    buttonActive () {
      const phone = this.phone
      const stringE = this.phone.indexOf('e')
      if (phone < 0) {
        return false
      }
      if (stringE > -1) {
        return false
      }
      if (phone.length > 13) {
        return false
      }
      if (phone.length < 9) {
        return false
      }

      return true
    },
    otpActive () {
      if (this.first && this.second && this.third && this.fourth) {
        return true
      }
      return false
    },
    allOtp () {
      return `${this.first}${this.second}${this.third}${this.fourth}`
    }
  },
  watch: {
    first (newValue) {
      if (newValue.length > 1) {
        this.first = newValue[newValue.length - 1]
      } else if (newValue) {
        this.rightKey('first')
      }
    },
    second (newValue) {
      if (newValue.length > 1) {
        this.second = newValue[newValue.length - 1]
      } else if (newValue) {
        this.rightKey('second')
      }
    },
    third (newValue) {
      if (newValue.length > 1) {
        this.third = newValue[newValue.length - 1]
      } else if (newValue) {
        this.rightKey('third')
      }
    },
    fourth (newValue) {
      if (newValue.length > 1) {
        this.fourth = newValue[newValue.length - 1]
      } else if (newValue) {
        this.rightKey('fourth')
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timerInterval)
  }
}
</script>

<style scoped>
#modal-otp {
  padding: 0px 13px;
  color: rgba(0, 0, 0, 0.7);
}

#close-icon {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

#close-icon img {
  cursor: pointer;
}

.title-modal {
  color: #172d5c;
  font-size: 20px;
  text-align: center;
  margin-bottom: 13px;
}

#modal-description {
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 15px;
}

#modal-description span {
  font-weight: 600;
}

.error-message {
  color: #eb5757;
  text-align: center;
}

#input-phone {
  background: var(--color-neutral-100);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 15px;
}

::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

#button-submit {
  margin-top: 15px;
}

#button-submit .button-disabled {
  padding: 10px 15px;
  background: #e0e0e0;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}

.button-loading {
  padding: 10px 15px;
  background: var(--color-persian-blue-400);
  border-radius: 10px;
  width: 100%;
  border: 1px solid var(--color-persian-blue-400);
  color: var(--color-neutral-100);
  font-weight: 600;
  outline: none;
}

.button-active {
  padding: 10px 15px;
  background: var(--color-persian-blue-500);
  border-radius: 10px;
  width: 100%;
  border: 1px solid var(--color-persian-blue-500);
  color: var(--color-neutral-100);
  font-weight: 600;
  outline: none;
}

.button-active:hover {
  background-color: var(--color-persian-blue-400);
  border: 1px solid var(--color-persian-blue-400);
}

#to-register {
  text-align: center;
  margin-top: 20px;
}

#register-user {
  text-decoration-line: underline;
  color: var(--color-deep-lemon-500);
  font-weight: 600;
  cursor: pointer;
}

#help-report {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 15px;
}

#help-report span {
  font-weight: 600;
}

#image-modal {
  text-align: center;
}

#otp-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

#otp-input input {
  width: 37px;
  font-size: 37px;
  text-align: center;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.9);
  margin: 10px 10px;
  color: var(--color-persian-blue-500);
}

#modal-footer {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}

#modal-footer span {
  font-weight: 600;
}

.send-again {
  color: var(--color-deep-lemon-500);
  font-weight: 600;
  cursor: pointer;
}

.send-again:hover {
  text-decoration: underline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
