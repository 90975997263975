<template>
  <div class="text-center">
    <div class="text-right p-0 m-0">
      <b-button variant="link" @click="$emit('closeEmailNotYetVerified')">
        <img src="../../assets/icons/icon_close.svg"  />
      </b-button>
    </div>
    <img src="../../assets/images/email_not_verified.svg" alt="gambar" width="170px">
    <div class="gotham-bold oops-text">Email Belum Terverifikasi</div>
    <div class="open-sans detail-text">
      Segera cek kotak masuk/spam di
      <br> <span class="font-weight-bold">{{ userEmail }}</span> <br>
      dan klik tombol verifikasi <br> di email tersebut.
    </div>
    <div>
      <b-button class="back-button" @click="$emit('toggleEmailNotYetVerified')">
        Kembali
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  computed: {
    userEmail () {
      return this.$store.state.user.userInfo.email
    }
  }
}
</script>

<style scoped>
.oops-text {
  margin: 20px 0;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #172D5C;
}

.detail-text {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 22px;
}

.back-button{
  margin-top: 35px;
  margin-bottom: 10px;
  width: 110px;
  background: var(--color-persian-blue-500);
  border: 1px solid var(--color-persian-blue-500);
  box-sizing: border-box;
  border-radius: 10px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}
</style>
