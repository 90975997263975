import CryptoJS from 'crypto-js'
const PRIVATE_KEY = process.env.VUE_APP_PRIVATE_KEY

export default {
  decrypt: (value) => {
    let bytes = CryptoJS.AES.decrypt(value, PRIVATE_KEY)
    let decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptData
  },
  encrypt: (value) => {
    let enrcyptData = CryptoJS.AES.encrypt(JSON.stringify(value), PRIVATE_KEY).toString()
    return enrcyptData
  },
  md5Encrypt: (value) => {
    return CryptoJS.MD5(value).toString()
  },
  base64Encrypt (value) {
    // https://github.com/brix/crypto-js/issues/271#issuecomment-691443875
    const encJson = this.encrypt(value)
    const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
    return encData
  },
  base64Decrypt (value) {
    // https://github.com/brix/crypto-js/issues/271#issuecomment-691443875
    const decJSON = CryptoJS.enc.Base64.parse(value).toString(CryptoJS.enc.Utf8)
    const decData = this.decrypt(decJSON)
    return decData
  }
}
