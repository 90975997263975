import axiosServer from '../../configs/axiosServer'

const reflection = {
  namespaced: true,
  state: {
    regular: {},
    facilitator: {},
    sekolahmu: {},
    role: ''
  },
  mutations: {
    SET_REGULAR_REPORT (state, payload) {
      state.regular = payload
    },
    SET_FACILITATOR_REPORT (state, payload) {
      state.facilitator = payload
    },
    SET_INFO_SEKOLAHMU (state, payload) {
      state.sekolahmu = payload
    },
    SET_ROLE (state, payload) {
      state.role = payload
    }
  },
  actions: {
    async fetchIdentifyUser ({ commit }, { year, username }) {
      try {
        const { data } = await axiosServer({
          url: `/user/report/fun/role/${year}/${username}`,
          method: 'GET',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('SET_ROLE', data.data.role.toLowerCase())
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchRegularReflection ({ commit }, { year, username }) {
      try {
        const { data } = await axiosServer({
          url: `/user/report/fun/regular/${year}/${username}`,
          method: 'GET',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('SET_REGULAR_REPORT', data.data)
        } else {
          commit('SET_REGULAR_REPORT', {})
        }
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async fetchFacilitatorReflection ({ commit }, { year, username }) {
      try {
        const { data } = await axiosServer({
          url: `/user/report/fun/facilitator/${year}/${username}`,
          method: 'GET',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('SET_FACILITATOR_REPORT', data.data)
        } else {
          commit('SET_FACILITATOR_REPORT', {})
        }
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async fetchInfoSekolahmu ({ commit }, { year }) {
      try {
        const { data } = await axiosServer({
          url: `/user/report/fun/sekolahmu/${year}`,
          method: 'GET',
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('SET_INFO_SEKOLAHMU', data.data)
        } else {
          commit('SET_INFO_SEKOLAHMU', {})
        }
        return data
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default reflection
