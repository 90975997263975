import PaymentAPI from '@/configs/api/payment/index'
import CheckoutAPI from '@/api/checkout'

const checkout = {
  namespaced: true,
  state: {
    checkoutData: null,
    paymentTypeChoosen: null,
    servicePaymentFee: 0,
    paymentTypeCategory: '',
    paymentTypeName: '',
    freeOfCharge: false,
    phoneNumberValid: false,
    creditCardValidationStatus: false,
    phoneNumber: '',
    creditCardPayload: {},
    paymentSlugName: ''
  },
  mutations: {
    CHANGE_CHECKOUT_LIST_ITEM (state, payload) {
      state.checkoutData = payload
    },
    CHANGE_PAYMENT_TYPE_CHOOSEN (state, payload) {
      state.paymentTypeChoosen = payload
    },
    CHANGE_SERVICE_PAYMENT_FEE (state, payload) {
      state.servicePaymentFee = payload
    },
    CHANGE_PAYMENT_TYPE_CATEGORY (state, payload) {
      state.paymentTypeCategory = payload
    },
    CHANGE_PAYMENT_TYPE_NAME (state, payload) {
      state.paymentTypeName = payload
    },
    CHANGE_FREE_OF_CHARGE (state, payload) {
      state.freeOfCharge = payload
    },
    CHANGE_PHONE_NUMBER_VALID (state, payload) {
      state.phoneNumberValid = payload
    },
    CHANGE_PHONE_NUMBER (state, payload) {
      state.phoneNumber = payload
    },
    CHANGE_CREDITCARD_VALIDATION_STATUS (state, payload) {
      state.creditCardValidationStatus = payload
    },
    CHANGE_CREDITCARD_PAYLOAD (state, payload) {
      state.creditCardPayload = payload
    },
    CHANGE_PAYMENT_SLUG_NAME (state, payload) {
      state.paymentSlugName = payload
    }
  },
  actions: {
    async applyVoucher ({ rootState, commit }, payload) {
      const result = await PaymentAPI.post('/v3/voucher/', { token: rootState.storage.accessToken, data: payload })
      if (result.status === 200 || result.message === 'OK') {
        commit('CHANGE_FREE_OF_CHARGE', !result.data.is_pay)
      }
      return result
    },
    async deleteVoucher ({ rootState, commit }, payload) {
      const result = await PaymentAPI.delete('/v3/voucher/', { token: rootState.storage.accessToken, data: payload })
      if (result.status === 200 || result.message === 'OK') {
        if (Number(result.data.real_total) >= 10000) {
          commit('CHANGE_FREE_OF_CHARGE', false)
        } else {
          commit('CHANGE_FREE_OF_CHARGE', true)
        }
      }
      return result
    },
    async submitPayment ({ rootState }, payload) {
      const result = await PaymentAPI.post('/v3/pay/', { token: rootState.storage.accessToken, data: payload }, true)
      return result
    },
    async getPaymentMethods ({ rootState }, { query }) {
      try {
        const response = await CheckoutAPI.getPaymentMethod({
          accessToken: rootState.storage.accessToken,
          query
        })
        if (response.status === 200) {
          return response
        } else {
          throw new Error(response)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async checkoutCartItem ({ rootState, commit }, payload) {
      const result = await PaymentAPI.post('/v3/checkout/', { token: rootState.storage.accessToken, data: payload })
      if (result.status === 200 || result.message === 'OK') {
        commit('CHANGE_FREE_OF_CHARGE', !result.data.is_pay)
      }
      return result
    }
  }
}

export default checkout
