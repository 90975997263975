<template>
  <topic-wrapper>
    <topic-regular
      :data="data"
      :has-enrolled="true"
      v-b-toggle="`accordion-${data.id}`"
      role="tab"
    />
    <b-collapse
      :id="`accordion-${data.id}`"
      role="tabpanel"
      @input="isVisible => getChapterDetail(data.id, isVisible)"
    >
      <activity
        v-for="(activity, activityIndex) in activityData"
        :number="setNumbering(topicIndex, activityIndex)"
        :is-linier="isLinier"
        :data="activity"
        :key="activity.id"
        :is-show="activity.isShow"
      />
    </b-collapse>
  </topic-wrapper>
</template>
<script>
import TopicWrapper from '@/components/activityV2/Sidebar/TopicWrapper'
import TopicRegular from '@/components/activityV2/Sidebar/TopicRegular'
import Activity from '@/components/activityV2/Sidebar/Activity'
import activityV2GeneralMixin from '@/mixins/activityV2/general'
import activityV2SidebarTabActivityMixin from '@/mixins/activityV2/sidebarTabActivity'

export default {
  name: 'TabActivitySectionEnrolledTopic',
  mixins: [activityV2GeneralMixin, activityV2SidebarTabActivityMixin],
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    topicIndex: Number,
    isLinier: Boolean
  },
  components: {
    TopicWrapper,
    TopicRegular,
    Activity
  }
}
</script>
