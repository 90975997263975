export default {
  option: {
    useModalOverlay: true,
    exitOnEsc: false
  },
  parseTourPayload ({ tour, payload }) {
    const defaultOpt = {
      element: '', // target element selector
      position: 'bottom', // top, bottom, left, right
      image: '', // image url
      title: 'Title', // title
      description: 'Description', // description
      scrollTo: false, // Boolean or Object  i.e. {behavior: 'smooth', block: 'center'}
      when: {}
    }

    const lists = []
    const totalStep = payload.length
    for (let i = 0; i < payload.length; i++) {
      const element = payload[i]
      const option = {
        attachTo: { element: '', on: '' },
        buttons: [],
        text: '',
        modalOverlayOpeningRadius: 8,
        modalOverlayOpeningPadding: 8,
        cancelIcon: { enabled: true, label: 'close-coachmark' },
        canClickTarget: false,
        scrollTo: element.scrollTo || defaultOpt.scrollTo,
        when: element.when || defaultOpt.when
      }

      option.attachTo.element = element.element || defaultOpt.element
      option.attachTo.on = element.position || defaultOpt.position

      option.beforeShowPromise = function () {
        return new Promise(async function (resolve) {
          const selector = element.element
          if (element.usingCustomFunction) {
            element.customFunction()
          }

          await waitForElementToDisplay(selector).then(() => {
            resolve()
          })
        })
      }

      if (i !== 0) {
        option.buttons.push({
          text: '<img src="https://cdn.sekolah.mu/assets/v2/coachmark/button-circle.webp" alt="back" class="coachmark-sekolahmu-old__back-img" />',
          action: tour.back,
          classes: 'coachmark-sekolahmu-old__back'
        })
      }
      if (i !== totalStep - 1) {
        option.buttons.push({
          text: 'Selanjutnya',
          action: tour.next,
          classes: 'coachmark-sekolahmu-old__next'
        })
      }
      if (i === totalStep - 1) {
        option.buttons.push({
          text: element.finishButton || 'Mengerti',
          classes: 'coachmark-sekolahmu-old__next',
          action: tour.complete
        })
      }
      option.text = `
          <div class="d-flex coachmark-sekolahmu-old__wrapper">
            <img src="${element.image}" class="coachmark-sekolahmu-old__icon" alt="${element.title || defaultOpt.title}">
            <div class="coachmark-sekolahmu-old-content">
              <h4 class="font-label-xl-sb">${element.title || defaultOpt.title}</h4>
              <p class="mb-0 font-body-2-sm font-body-2 text-gargoyle">${element.description || defaultOpt.description}</p>
            </div>
          </div>
          <p class="font-label-md-sb-lh16 coachmark-sekolahmu-old__steps">${i + 1} dari ${totalStep}</p>`
      lists.push(option)
    }

    // overriding cancel event to be complete the event
    tour.on('cancel', () => {
      tour.complete()
    })

    return lists
  }
}

function waitForElementToDisplay (selector) {
  return new Promise(function (resolve) {
    (function checkIfElementExists () {
      if (document.querySelector(selector) !== null) {
        resolve()
      } else {
        setTimeout(checkIfElementExists, 500)
      }
    })()
  })
}
