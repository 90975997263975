import axiosServer from '@/configs/axiosServer'

const PaymentAPI = {
  get: async (url, payload, paramSource = false) => {
    try {
      const { data } = await axiosServer({
        url: `/payment${url}`,
        method: 'GET',
        headers: {
          Authorization: payload.token
        },
        generateQueryParamSource: paramSource
      })
      return data
    } catch (error) {
      return error
    }
  },
  post: async (url, payload, paramSource = false) => {
    try {
      const { data } = await axiosServer({
        url: `/payment${url}`,
        method: 'POST',
        headers: {
          Authorization: payload.token
        },
        data: payload.data,
        generateQueryParamSource: paramSource
      })
      return data
    } catch (error) {
      return error
    }
  },
  delete: async (url, payload, paramSource = false) => {
    try {
      const { data } = await axiosServer({
        url: `/payment${url}`,
        method: 'DELETE',
        headers: {
          Authorization: payload.token
        },
        data: payload.data,
        generateQueryParamSource: paramSource
      })
      return data
    } catch (error) {
      return error
    }
  }
}

export default PaymentAPI
