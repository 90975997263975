const router = [
  {
    path: '/search',
    name: 'general-search',
    component: () => import(/* webpackChunkName: "general-search" */ '../views/search/General.vue'),
    meta: {
      module: 'discovery'
    }
  },
  {
    path: '/search/:category',
    name: 'search-result-by-category',
    component: () => import(/* webpackChunkName: "search-result-by-category" */ '../views/search/SearchCategory.vue'),
    meta: {
      module: 'discovery'
    }
  }
]

export default router
