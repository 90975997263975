<template>
  <b-modal
    id="share-modal"
    v-model="isShow"
    hide-footer
    hide-header
    centered
    size="login"
  >
    <div class="text-right">
      <s-button id="share-modal-close" @onClick="isShow = false" variant="link">
        <font-awesome-icon icon="times" />
      </s-button>
    </div>
    <div class="text-center">
      <h3 class="title inter-medium-bold">Bagikan Aktivitas</h3>
    </div>
    <div class="text-center">
      <s-button
        id="share-modal-btn-copy"
        @onClick="copyUrl"
        variant="link"
        class="share-btn inter-small-normal"
      >
        <img
          src="https://cdn.sekolah.mu/assets/v2/icon-bagikan/icon-salin-tautan.svg"
          alt="link"
          class="socmed-icon-style"
        />
        <span class="d-block">Salin Tautan</span>
      </s-button>
      <s-button
        id="share-modal-btn-wa"
        @onClick="shareUrl('whatsapp')"
        variant="link"
        class="share-btn inter-small-normal"
      >
        <img
          src="https://cdn.sekolah.mu/assets/v2/icon-bagikan/icon-whatsapp.svg"
          alt="whatsapp"
          class="socmed-icon-style"
        />
        <span class="d-block">Share</span>
      </s-button>
      <s-button
        id="share-modal-btn-line"
        @onClick="shareUrl('line')"
        variant="link"
        class="share-btn inter-small-normal"
      >
        <img
          src="https://cdn.sekolah.mu/assets/v2/icon-bagikan/icon-line.svg"
          alt="line"
          class="socmed-icon-style"
        />
        <span class="d-block">Share</span>
      </s-button>
    </div>
    <div class="text-center">
      <s-button
        id="share-modal-btn-linkedin"
        @onClick="shareUrl('linkedin')"
        variant="link"
        class="share-btn inter-small-normal"
      >
        <img
          src="https://cdn.sekolah.mu/assets/v2/icon-bagikan/icon-linkedin.svg"
          alt="linkedin"
          class="socmed-icon-style"
        />
        <span class="d-block">Post</span>
      </s-button>
      <s-button
        id="share-modal-btn-fb"
        @onClick="shareUrl('facebook')"
        variant="link"
        class="share-btn inter-small-normal"
      >
        <img
          src="https://cdn.sekolah.mu/assets/v2/icon-bagikan/icon-facebook.svg"
          alt="link"
          class="socmed-icon-style"
        />
        <span class="d-block">Post</span>
      </s-button>
      <s-button
        id="share-modal-btn-twitter"
        variant="link"
        @onClick="shareUrl('twitter')"
        class="share-btn inter-small-normal"
      >
        <img
          src="https://cdn.sekolah.mu/assets/v2/icon-bagikan/icon-twitter.svg"
          alt="twitter"
          class="socmed-icon-style"
        />
        <span class="d-block">Tweet</span>
      </s-button>
    </div>
  </b-modal>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SButton from '@/components/atoms/buttons/SButton.vue'
import Toast from '@/configs/Toast'
export default {
  name: 'ShareModal',
  components: {
    FontAwesomeIcon,
    SButton
  },
  data () {
    return {
      isShow: false
    }
  },
  computed: {
    appHostUrl () {
      return process.env.VUE_APP_HOST_URL
    },
    activityDashboard () {
      return this.$store.state.activityV2.activityDashboard
    }
  },
  methods: {
    copyUrl () {
      let url = window.location.href
      navigator.clipboard.writeText(url).then(data => {
        Toast.fire({
          icon: 'success',
          title: 'Berhasil menyalin link program',
          position: 'top'
        })
      })
    },
    shareUrl (platform) {
      let url = window.location.href
      switch (platform) {
        case 'facebook':
          return window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${url}`,
            '_blank'
          )
        case 'whatsapp':
          return window.open(
            `https://api.whatsapp.com/send?text=${url}`,
            '_blank'
          )
        case 'twitter':
          return window.open(`http://twitter.com/share?text=${url}`, '_blank')
        case 'line':
          return window.open(
            `https://social-plugins.line.me/lineit/share?url=&text=${url}&from=line_scheme`,
            '_blank'
          )
        case 'linkedin':
          return window.open(
            `https://www.linkedin.com/shareArticle?mini=true&title=Aktivitas_sekolahmu&url=${url}`,
            '_blank'
          )
        default:
          return ''
      }
    }
  }
}
</script>
<style scoped>
.title {
  text-align: center;
  margin-bottom: 32px;
  color: var(--color-persian-blue-700);
}
.socmed-icon-style {
  width: 55px;
  border-radius: 50px;
}
.share-btn {
  margin: 0 10px;
  width: 70px;
  color: var(--color-ui-text-primary);
}
</style>
