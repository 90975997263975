<template>
  <div id="verification-invitation" class="verification-invitation">
    <div class="verification-header">
      <p class="text-persian-blue-900 font-label-1">Verifikasi Akunmu</p>
      <button
        class="verification-header__close naked-button"
        @click="closeModal"
        id="verification-invitation-close-button"
      >
        <img src="@/assets/icons/icon_close.svg" />
      </button>
    </div>
    <div class="invitation-content">
      <img src="https://cdn.sekolah.mu/assets/v2/halaman-akun/masukkan-kode-otp.webp" alt="OTP" class="invitation-illustration">
      <p class="font-subtitle-rg-4 text-ui-primary" v-if="isVerificationMandatory">Hai, {{ userInfo.name }}! Silakan verifikasi terlebih dahulu untuk mengakses halaman ini.</p>
      <p class="font-subtitle-rg-4 email-info-text">
        Email verifikasi akan dikirim ke
        <strong>{{ userInfo.email }}</strong> atau ubah emailmu <span class="change-email-entry" @click="isOpenChangeEmailModal = true; logEventAnalytics('verifikasi_mandatory_ubah_email_click');">di sini.</span>
      </p>
    </div>
    <div class="otp-verification-entry">
      <s-button
        :variant="'primary'"
        pill
        class="otp-verification-entry-button"
        id="otp-verification-entry-button"
        @onClick="openOtpModal()"
      >Verifikasi Sekarang</s-button>
    </div>

    <b-modal
      v-model="isOpenChangeEmailModal"
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      size="modal-304"
      content-class="modal-content-semi_round"
    >
      <change-email
        @toggleEmailModal="toggleEmailModal"
        @afterChangeEmail="afterChangeEmail"
      />
    </b-modal>

    <error-message-modal
      :isOpenModal="isOpenModalError"
      :illustration="'https://cdn.sekolah.mu/avatar/154925/5054ab2a3a854fc19cee75fd11fb1674.png'"
      :title="otpSubmitErrorTitle"
      :description="otpSubmitErrorMessage"
      @onCloseModal="closeModalErrorMessage"
    ></error-message-modal>
  </div>
</template>
<script>
import SButton from '@/components/atoms/buttons/SButton.vue'
import ErrorMessageModal from '@/components/molecules/modals/ErrorMessageModal.vue'
import deviceDetector from 'mobile-device-detect'

import authAPI from '@/api/auth.js'
import { OTP_REFERENCE, getOTPReferenceId } from '@/constants/otpReference.js'

export default {
  name: 'VerificationInvitation',
  components: {
    SButton,
    ErrorMessageModal,
    ChangeEmail: () => import('@/components/otpUserVerification/ChangeEmail.vue')
  },
  data () {
    return {
      sendOTPdelay: 90,
      // change email
      isOpenChangeEmailModal: false,

      // error message modal
      isOpenModalError: false,
      otpSubmitErrorTitle: '',
      otpSubmitErrorMessage: '',
      isMobile: deviceDetector.isMobile
    }
  },
  computed: {
    isFromBayar () {
      return this.$route.query.from === 'bayar'
    },
    userId () {
      return this.$store.state.user.userInfo.id
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    accessToken () {
      return this.$store.state.storage.accessToken
    },
    isVerificationMandatory () {
      return this.$store.state.user.isVerificationMandatory
    },
    isTemporarilyFreezeOtpVerification: {
      get () {
        return this.$store.state.user.isTemporarilyFreezeOtpVerification
      },
      set (val) {
        this.$store.commit('user/CHANGE_IS_TEMPORARILY_FREEZE_OTP_VERIFICATION', val)
      }
    },
    pageSource () {
      let source
      switch (this.$route.path) {
        case '/verification':
          source = 'sekolahmu-web-register'
          break
        case '/profile':
          source = 'sekolahmu-web-profil'
          break
        case '/daftar-pilihan':
          source = 'sekolahmu-web-pembayaran'
          break
        case '/portofolio/profil':
          source = 'sekolahmu-web-portofolio'
          break
        case '/rapor-murid':
          source = 'sekolahmu-web-transkrip'
          break
        default:
          source = 'sekolahmu-web-aktivitas belajar'
          break
      }

      return source
    },
    isLogin () {
      return this.$store.state.user.isLogin
    }
  },
  methods: {
    afterChangeEmail () {
      setTimeout(() => {
        this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_INVITATION', false)
        this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_OTP', true)
      }, 500)
    },
    toggleEmailModal (payload) {
      this.isOpenChangeEmailModal = payload
    },
    closeModalErrorMessage () {
      this.isOpenModalError = false
      if (this.isFromBayar) {
        window.close()
      }
      if (this.isVerificationMandatory) {
        if (window.history.length <= 1) {
          this.$router.push('/')
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$store.commit('user/CHANGE_IS_VERIFICATION_MANDATORY', false)
        this.$store.commit('user/CHANGE_IS_HIDE_SKIP_VERIFICATION', false)
        this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_INVITATION', false)
        this.$store.commit('user/CHANGE_IS_OPEN_VERIFICATION_OTP', false)
        this.$router.push('/')
      }
    },
    closeModal () {
      this.logEventAnalytics('verifikasi_mandatory_close')
      this.$emit('closeModal')
      if (this.isVerificationMandatory) {
        if (window.history.length <= 1) {
          this.$router.push('/')
        } else {
          this.$router.go(-1)
        }
      }
    },
    openOtpModal () {
      this.logEventAnalytics('verifikasi_mandatory_click')
      if (this.isTemporarilyFreezeOtpVerification) {
        this.otpSubmitErrorTitle = 'Verifikasi Akun Diberhentikan Sementara.'
        this.otpSubmitErrorMessage = 'Proses verifikasi diberhentikan sementara karena telah 5 kali salah memasukkan kode. Silakan lakukan verifikasi kembali setelah 10 menit. '
        this.isOpenModalError = true
      } else {
        this.requestOTP()
        this.$emit('openOtpModal')
      }
    },
    emailFormatter (value) {
      return value.toLowerCase()
    },
    async requestOTP () {
      this.canSendOTP = false
      this.setCountdownOTP()
      try {
        const otpReference = await authAPI.referenceOTP({
          authorization: process.env.VUE_APP_APP_KEY
        })
        const otp_purpose_id = getOTPReferenceId(otpReference.data, OTP_REFERENCE.VERIFY_USER)
        const { data, status } = await authAPI.resendOTP({
          authorization: this.accessToken,
          payload: {
            source: this.isMobile ? window.btoa('sekolahmu-webview') : window.btoa('sekolahmu-web'),
            email: this.userInfo.email,
            otp_purpose_id
          }
        })
        if (status !== 200) {
          throw new Error(data)
        }
      } catch (error) {
        console.error(error)
      }
    },
    setCountdownOTP () {
      let timeNow = new Date()
      let timeOtp = new Date()
      timeOtp.setTime(timeNow.getTime() + (this.sendOTPdelay * 1000))
      let otpTimestamp = Date.parse(timeOtp)
      localStorage.setItem('OTP-timestamp', otpTimestamp)
    },
    logEventAnalytics (event) {
      window.firebaseAnalytics.logEvent(event, {
        screen_url: window.location.href,
        user_profession: this.userInfo.profession_name,
        user_year_level: this.userInfo.year_level_name,
        user_is_admin: this.userInfo.is_admin,
        user_id: this.userInfo.id,
        source: this.isMobile ? 'web_mobile_sekolahmu' : 'web_sekolahmu'
      })
    }
  }
}
</script>
<style scoped>
h5, p {
  margin: 0;
}
.verification-invitation{
  width: 100%;
}
.verification-header {
  position: relative;
  text-align: center;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-neutral-500);
}

.verification-header__close {
  position: absolute;
  right: -4px;
  top: 4px;
}
.invitation-content{
  text-align: center;
  border-bottom: 1px solid var(--color-neutral-500);
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.invitation-illustration{
  width: 192px;
  height: auto;
  margin-bottom: 16px;
}
.invitation-content .title{
  color: var(--color-ui-text-main-title);
}
.invitation-content {
  margin-top: 8px;
  color: var(--color-ui-text-primary);
}
.invitation-content .email-info-text {
  margin-top: 16px;
  color: var(--color-ui-text-primary);
}
.email-info-text strong{
  word-break: break-word;
}
.email-info-text .change-email-entry{
  color: var(--color-persian-blue-500);
  cursor: pointer;
  font-weight: 700;
}
.otp-verification-entry{
  padding: 0 calc(28px - 1rem);
}
.otp-verification-entry-button{
  width: 100%;
}
</style>
