export default {
  PAYMENT_CREATED: 1, // Belum Ada Pembayaran
  PAYMENT_REQUESTED: 2, // Menunggu Pembayaran
  PAYMENT_PENDING: 3, // Dibayar
  SUCCESS: 10, // Berhasil
  FAILED: 20, // Tidak Berhasil
  CANCELED: 21, // Dibatalkan
  EXPIRED: 22, // Kedaluwarsa
  REJECTED: 23, // Ditolak
  REFUND: 24, // Dikembalikan
  NON_PAYMENT: 25, // Tidak Melakukan Pembayaran
  PRAKERJA_ERROR: 101 // Prakerja Error
}
