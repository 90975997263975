export default {
  methods: {
    setFireBasePayloadMixin () {
      this.timeClosePage = new Date().getTime()
      const duration = (this.timeClosePage - this.timeOpenPage) / 1000
      if (this.isLogin) {
        this.$set(this.firebaseAnalyticsPayload, 'user_profession', this.userInfo.profession_name)
        this.$set(this.firebaseAnalyticsPayload, 'user_year_level', this.userInfo.year_level_name)
        this.$set(this.firebaseAnalyticsPayload, 'user_is_admin', this.userInfo.is_admin)
        this.$set(this.firebaseAnalyticsPayload, 'user_id', this.userInfo.id)
      }
      this.$set(this.firebaseAnalyticsPayload, 'duration', duration)
    },
    sendNewTrackerData (event) {
      // for new tracker so it will not disrupt the old tracker
      const payload = {
        screen_url: window.location.href,
        user_professsion: this.userInfo.profession_name ?? '',
        user_year_level: this.userInfo.year_level_name ?? '',
        source: this.trackerSource,
        user_id: this.userInfo.id ?? '',
        parent_id: this.userInfo.parent ? this.userInfo.parent[0]?.parent_user_id : ''
      }
      window.firebaseAnalytics.logEvent(event, payload)
    }
  }
}
