export function validateActivityAccess (data) {
  const result = {
    toNotFound: false,
    toProgramDetail: false,
    toKelasku: false
  }
  const {
    status,
    enrolled,
    activity_id,
    is_external,
    stage,
    is_admin,
    is_joined,
    has_user_group
  } = data
  if (status === 400 || status === 114 || status === 122) {
    result.toNotFound = true
  } else {
    if (
      !enrolled ||
      (!enrolled && !is_joined && has_user_group) ||
      is_external ||
      (stage.toLowerCase() === 'registration' && !is_admin)
    ) {
      result.toProgramDetail = true
    } else if (activity_id === 0) {
      result.toKelasku = true
    }
  }
  return result
}
