import axiosServer from '@/configs/axiosServer'

export default {
  async deleteMessage (token, chat_id) {
    try {
      const { data } = await axiosServer({
        url: '/chat/messages/',
        method: 'DELETE',
        headers: {
          Authorization: token
        },
        data: {
          unique_ids: [chat_id]
        }
      })
      return data
    } catch (error) {
      console.log(error)
    }
  },
  async askProgramFacilsFloatingChatBox (facilRoomId, token) {
    try {
      const { data } = await axiosServer({
        url: '/chat/participant/',
        method: 'post',
        headers: {
          Authorization: token
        },
        data: {
          room_id: `${facilRoomId}`
        }
      })
      return data
    } catch (err) {
      console.log(err)
    }
  },
  async initProgramFacils (url, token) {
    try {
      const { data } = await axiosServer({
        url: url,
        method: 'get',
        headers: {
          Authorization: token
        }
      })
      return data
    } catch (err) {
      console.log(err)
    }
  },
  async checkTanyamuParticipant ({ Authorization, data }) {
    try {
      const result = await axiosServer({
        url: '/chat/participant/',
        method: 'POST',
        headers: {
          Authorization
        },
        data
      })
      return result
    } catch (error) {
      console.log(error)
    }
  }
}
