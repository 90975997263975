<template>
  <b-alert
    :show="showAlert"
    fade
    :variant="variant"
    :class="['save-alert', `bg-${variant}-alert`]"
  >
    <slot></slot>
  </b-alert>
</template>

<script>
export default {
  name: 'SAlertAtom',
  props: {
    showAlert: {
      type: Number,
      default: 0
    },
    variant: {
      type: String,
      default: 'success'
    }
  }
}
</script>

<style scoped>
.save-alert {
  margin: auto;
  text-align: center;
  position: fixed;
  z-index: 50;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 10px;
}

.alert-success {
  background: #E0FFE9;
}

.alert-secondary {
  background: #F2F2F2;
}

@media screen and (max-width: 530px) {
  .save-alert {
    width: 250px;
  }
}
</style>
