<template>
  <nav id="footer-copyright">
    <div :class="{'completion-timer-space':addCompletionBtnSpace}" class="container font-subtitle-3" id="copyright">
      © {{ fullYear }} Sekolah.mu All Rights Reserved
    </div>
  </nav>
</template>

<script>
import deviceDetector from 'mobile-device-detect'

export default {
  data () {
    return {
      isMobile: deviceDetector.isMobile
    }
  },
  computed: {
    fullYear () {
      var date = new Date()
      var year = date.getFullYear()
      return year
    },
    activityCompletionTimer () {
      return this.$store.state.activity.activityFocus?.completion_timer
    },
    hasCompletionTimer () {
      if (this.activityCompletionTimer >= 0) {
        return true
      } else if (this.activityCompletionTimer === -1) {
        return false
      }
    },
    currentRouteName () {
      return this.$route.name
    },
    addCompletionBtnSpace () {
      return this.isMobile && this.hasCompletionTimer && this.currentRouteName === 'activity-program'
    }
  }
}
</script>

<style scoped>
#footer-copyright {
  min-height: 30px;
  background: #E0E0E0;
  text-align: center;
}

#copyright {
  color: rgba(0, 0, 0, 0.3);
  padding-top: 5px;
  padding-bottom: 5px
}

.completion-timer-space {
  padding-bottom: 60px;
}

@media (max-width: 770px) {
  #footer-copyright {
    font-size: 14px;
  }
}
</style>
