<template>
  <div
    class="notification-list"
    :style="getNotifBgColor(notificationData)"
  >
    <div class="notif-type-info">
      <img :src="getNotifCategory(notificationData.object_type, 'icon')" alt="Sekolah.mu" width="16">
      <span class="notif-category">{{ getNotifCategory(notificationData.object_type) }}</span> <span class="separator">|</span> <span class="notif-date">{{ getNotifDate(notificationData.sent_at) }}</span>
    </div>
    <div class="notif-title">
      <h5 class="inter-normal-bold fl-24 fc-primary">{{ notificationData.title }}</h5>
    </div>
    <div class="notif-description fc-primary" v-html="setMessageOneLine(notificationData.message)"></div>
  </div>
</template>
<script>
import moment from 'moment'
import Image from '@/assets/images/notification'

export default {
  props: ['notificationData'],
  data () {
    return {}
  },
  methods: {
    setMessageOneLine (textHTML) {
      // remove break
      let br = textHTML.replaceAll('<br>', '')

      // remove headings & paragraph
      let h1 = br.replaceAll('<h1>', '').replaceAll('</h1>', '')
      let h2 = h1.replaceAll('<h2>', '').replaceAll('</h2>', '')
      let h3 = h2.replaceAll('<h3>', '').replaceAll('</h3>', '')
      let h4 = h3.replaceAll('<h4>', '').replaceAll('</h4>', '')
      let h5 = h4.replaceAll('<h5>', '').replaceAll('</h5>', '')
      let p = h5.replaceAll('<p>', '').replaceAll('</p>', '')

      // remove image
      let hideImage = p.replaceAll('<img', '<img style="display: none;"')
      let removeFigure = hideImage.replaceAll('<figure', '<figure style="display: none;"')

      return this.$XSSFilter(removeFigure)
    },
    getNotifCategory (type, expect) {
      switch (type) {
        case 'program':
          if (expect === 'icon') {
            return 'https://cdn.sekolah.mu/assets/notifications/icons/kelas.svg'
          }
          return 'Kelas'
        case 'activity':
          if (expect === 'icon') {
            return 'https://cdn.sekolah.mu/assets/notifications/icons/aktivitas-tambahan.svg'
          }
          return 'Aktivitas Tambahan'
        case 'promo':
          if (expect === 'icon') {
            return 'https://cdn.sekolah.mu/assets/notifications/icons/promo.svg'
          }
          return 'Promo'
        case 'event':
          if (expect === 'icon') {
            return 'https://cdn.sekolah.mu/assets/notifications/icons/event.svg'
          }
          return 'Event'
        case 'learning_package':
          if (expect === 'icon') {
            return 'https://cdn.sekolah.mu/assets/notifications/icons/kelas.svg'
          }
          return 'Paket Kelas'
        case 'school_payment':
          if (expect === 'icon') {
            return 'https://cdn.sekolah.mu/assets/notifications/icons/kelas.svg'
          }
          return 'Pembayaran Pendidikan'
        default:
          if (expect === 'icon') {
            return 'https://cdn.sekolah.mu/assets/notifications/icons/pembayaran.svg'
          }
          return type
      }
    },
    getNotifDate (date_input) {
      moment.locale('id')
      return moment(date_input).format('LL')
    },
    getNotifBgColor (notif) {
      if (notif.is_active || !notif.has_read) {
        return {
          background: 'var(--color-persian-blue-100)'
        }
      } else {
        return {
          background: 'var(--color-neutral-100)'
        }
      }
    }
  },
  computed: {
    notificationIcon () {
      switch (this.notificationData.object_type) {
        case 'program':
          return Image.program
        case 'payment':
          return Image.payment
        case 'activity':
          return Image.addon
        case 'event':
          return Image.event
        case 'promo':
          return Image.promo
        case 'learning_package':
          return Image.learning_package
        default:
          return Image.payment
      }
    },
    urlServerImage () {
      return process.env.VUE_APP_URL_SERVER_IMAGE
    }
  }
}
</script>
<style scoped>
.notification-list{
  max-height: 101px;
  cursor: pointer;
  padding: 12px;
}
.notification-list:hover{
  background: var(--color-neutral-300) !important;
}
.notif-type-info img{
  margin-right: 10px;
}
.separator{
  margin: 0 5px;
}
.notif-type-info .notif-category, .notif-type-info .notif-date, .separator{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #7D7D7D;
}
.notif-title{
  margin-top: 10px;
  margin-bottom: 5px;
}
.notif-title h5{
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.notif-description{
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
