<template>
  <div id="checkout-layout">
    <div id="top-nav-checkout">
      <b-container>
        <img :src="headerIconUrl" alt="logo-sekolahmu" id="logo-sekolahmu-layout"
          @click="moveHomepage"
          :style="{ cursor: $route.path === '/checkout' || $route.path === '/checkout/v5' ? 'pointer': 'default' }">
      </b-container>
    </div>
    <div id="main-wrapper-checkout" :class="wrapperClass()">
      <slot />
    </div>
    <footer class="checkout-footer">
      <div class="footer-wrapper">
        <div class="wrapper-section copyright-section">
          <b-img-lazy
            src="https://cdn.sekolah.mu/assets/home/sekolahmu_logo.svg"
            alt="sekolah.mu logo"
            class="logo-sekolahmu-footer s-mb-5 test-class"
          />
          <p class="mb-0 font-label-4 text-300">&copy; {{ fullYear }} Sekolah.mu PT Semesta Integrasi Digital. All Rights Reserved.</p>
        </div>
        <div class="wrapper-section contact-section">
          <p class="font-paragraph-2 text-200 s-mb-4">
            <b-img-lazy src="https://cdn.sekolah.mu/assets/icon/mail-bw.svg" alt="email" class="s-mr-4" />
            <span class="mb-0">{{ contact.email }}</span>
          </p>
          <p class="font-paragraph-2 text-200 mb-0">
            <b-img-lazy src="https://cdn.sekolah.mu/assets/icon/whatsapp-bw.svg" alt="phone" class="s-mr-4" />
            <span class="mb-0">{{ contact.phone }}</span>
          </p>
        </div>
        <div class="wrapper-section security-section">
          <p class="font-body-1-new s-mb-5 text-ui-primary">Keamanan</p>
          <b-img-lazy src="https://cdn.sekolah.mu/assets/home_v3/footer/iso-certified.webp" alt="Sekolah.mu" class="logo-iso-footer" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import contact from '@/constants/contact.js'

export default {
  data () {
    return {
      contact
    }
  },
  methods: {
    moveHomepage () {
      if (this.$route.path === '/checkout' || this.$route.path === '/checkout/v5') {
        this.$router.push('/')
      }
    },
    wrapperClass () {
      let classes = []

      if (this.isSummaryV5) {
        classes.push('pt-0')
      }

      return classes.join('')
    }
  },
  computed: {
    fullYear () {
      var date = new Date()
      var year = date.getFullYear()
      return year
    },
    isSummaryV5 () {
      return this.$route.path === '/checkout/v5'
    },
    platinumInstitutionInfo () {
      return this.$store.state.institution.platinumInstitutionInfo
    },
    envPlatinum () {
      return this.$store.state.envPlatinum
    },
    headerIconUrl () {
      let url = 'https://cdn.sekolah.mu/assets/home/sekolahmu_logo.svg'
      if (this.envPlatinum) {
        url = this.platinumInstitutionInfo.logo_2 || this.platinumInstitutionInfo.logo || url
      }
      return url
    }
  }
}
</script>

<style scoped lang="scss">
/* VARIABLES */
$main-width: 744px;
$sidebar-width: 360px;
$gap-width: 14px;

#checkout-layout {
  font-family: Inter, sans-serif;
}

#top-nav-checkout{
  display: flex;
  padding: 18px 0px;
}

#logo-sekolahmu-layout{
  height: 24px;
}

#main-wrapper-checkout{
  /* min-height: calc(100vh - 40px - 170px); */
  padding-bottom: 240px;
  background-color: var(--color-neutral-400);
  padding-top: 32px;
}

.checkout-footer {
  display: flex;
  align-items: center;
  height: 178px;
  width: 100%;
  background-color: var(--color-neutral-100);

  @media screen and (max-width: calc($main-width + $sidebar-width + $gap-width)) {
    display: none;
  }

  .footer-wrapper {
    width: calc($main-width + $sidebar-width + $gap-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .wrapper-section {
      display: flex;
      flex-direction: column;

      .logo-sekolahmu-footer {
        width: 200px;
        height: 32px;
      }

      .logo-iso-footer {
        width: 54px;
        height: auto;
      }
    }
  }
}
</style>
