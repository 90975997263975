const routes = [
  {
    path: '/daftar-tagihan',
    name: 'BillListV2',
    meta: {
      title: 'Daftar Tagihan',
      module: 'payment'
    },
    component: () => {
      return import(/* webpackChunkName: "BillListV2" */ '../views/bill/BillList.vue')
    }
  }
]

export default routes
