/**
 * at least one non white space characters before and after '@'
 * contains '@'
 * by passing '.'
 * at least one non white space characters before and after '.'
 */
const REGEX_EMAIL_NON_STRICT = /\S+@\S+\.\S+/
/**
 * Begin with any character on alphabet
 * first char set ( anycharacter on alphabet, escape '.', contains '-')
 * contains '@'
 * second group char set ( anycharacter on alphabet, escape '.', contains '-')
 * thrid char set (any character on alhapbet, escape '.')
 */
const REGEX_EMAIL_STRICT = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

function validateEmailWithRegex (email, isStrict) {
  const currentUrlParams = window.location.search
  const urlParams = new URLSearchParams(currentUrlParams)
  const enableStrictRegex = (urlParams.get('lock') === '1')
  const ALLOWED_DOMAIN = 'sekolahmu.co.id'
  const emailDomain = email.split('@')[1]
  const isDomainMatch = (emailDomain === ALLOWED_DOMAIN)

  if (!isDomainMatch && (isStrict || enableStrictRegex)) {
    return REGEX_EMAIL_STRICT.test(email)
  } else {
    return REGEX_EMAIL_NON_STRICT.test(email)
  }
}

export default validateEmailWithRegex
