import auth from '@/api/auth.js'
import cart from '@/api/cart.js'
import chat from '@/api/chat.js'
import payment from '@/api/payment.js'
import product from '@/api/product.js'
import programActivity from '@/api/program-activity.js'
import program from '@/api/program.js'
import quiz from '@/api/quiz.js'
import report from '@/api/report.js'
import search from '@/api/search.js'
import transcript from '@/api/transcript.js'
import user from '@/api/user.js'
import voucher from '@/api/voucher.js'
import coachmark from '@/api/coachmark'

export default {
  coachmark,
  auth,
  cart,
  chat,
  payment,
  product,
  programActivity,
  program,
  quiz,
  report,
  search,
  transcript,
  user,
  voucher
}
