<template>
  <div
    class="text-center"
    v-if="!isOpenEditEmail"
  >
    <div class="text-right p-0 m-0">
      <b-button
        variant="link"
        @click="toggleEmailVerification"
      >
        <img src="../../assets/icons/icon_close.svg" />
      </b-button>
    </div>
    <b-img-lazy
      :src="require('../../assets/images/email-sent.png')"
      style="width: 125.11px; height: 109.54px;"
    />
    <div class="gotham-book email-title my-4">Verifikasi Email</div>
    <div class="open-sans email-desc" style="margin-bottom: 17px;">
      Yuk, Verifikasi email kamu <br>
      sekarang agar lebih mudah <br>
      mengakses fitur-fitur <strong>Sekolah.mu</strong>
    </div>
    <div class="open-sans email-desc" style="margin-bottom: 38px;">
      Email verifikasi kamu dikirim ke <br>
      <span class="open-sans email-desc font-weight-bold text-break">{{userEmail}}</span> <br>
      Kamu bisa mengubah email <strong style="color: var(--color-persian-blue-500); cursor: pointer;" @click.prevent="isOpenEditEmail = true">di sini</strong>
    </div>
    <div class="button-verification" v-if="!isLoading">
      <b-button
        class="button-verification-btn"
        @click.prevent="resendEmail"
        v-if="timerCount <= 0"
      >
        Kirim Verifikasi
      </b-button>
      <div class="skip-verification-btn" v-else>
        Kirim Verifikasi {{ `(${timerCount}s)` }}
      </div>
      <!-- <div class="skip-verification-btn" @click.prevent="toggleEmailVerification">
        Lewati
      </div> -->
    </div>
    <!-- <div class="open-sans resend mt-4">Belum dapat email?</div> -->
    <!-- <div
      class="action-email-button"
      v-if="!isLoading"
    >
      <b-button
        variant="link"
        class="open-sans resend"
        :style="{margin: '0 20px', color: !!timerCount ? 'rgba(0, 0, 0, 0.5)' : 'var(--color-persian-blue-500)'}"
        :disabled="!!timerCount"
        @click.prevent="resendEmail"
      >
        Kirim Ulang <span v-if="!!timerCount">({{timerCount}}s)</span>
      </b-button>

      <b-button
        variant="link"
        class="open-sans resend"
        style="margin: 0 20px;"
        @click.prevent="isOpenEditEmail = true"
      >
        Ubah Email
      </b-button>
    </div> -->
    <!-- <div
      class="check-email-verification-btn"
      v-if="!isLoading"
    >
      <b-button
        variant="primary"
        class="open-sans confirmation-btn my-2"
        @click.prevent="confirmVerification"
      >
        Sudah Verifikasi
      </b-button>
    </div> -->
    <div
      class="loading-resend-email"
      v-if="isLoading"
    >
      <b-spinner
        small
        type="grow"
        variant="warning"
      ></b-spinner>
      <b-spinner
        small
        type="grow"
        variant="dark"
        class="mx-1"
      ></b-spinner>
      <b-spinner
        small
        type="grow"
        variant="info"
      ></b-spinner>
      <div class="open-sans resend">Sedang Mengirimkan Pesan</div>
    </div>
    <!-- <b-button
      variant="link"
      class="open-sans resend"
      :class="{'font-weight-bold': !!timerCount}"
      :disabled="!!timerCount || isLoading"
      :style="{color: !timerCount ? '#FFC429' : 'rgba(0, 0, 0, 0.5)'}"
      @click.prevent="resendEmail"
    >
      <div v-if="isLoading">
        <b-spinner small type="grow" variant="warning"></b-spinner>
        <b-spinner small type="grow" variant="dark" class="mx-1"></b-spinner>
        <b-spinner small type="grow" variant="info"></b-spinner>
        <div>Sedang Mengirimkan Pesan</div>
      </div>
      <div v-else>
        Kirim Ulang <span v-if="!!timerCount">({{timerCount}}s)</span>
      </div>
    </b-button> -->
    <div
      class="open-sans resend text-danger"
      v-if="errorMessage"
    >Error: {{errorMessage}}</div>
  </div>
  <div
    class="text-center"
    v-else
  >
    <div class="text-right p-0 m-0">
      <b-button
        variant="link"
        @click="toggleEmailVerification"
      >
        <img src="../../assets/icons/icon_close.svg" />
      </b-button>
    </div>
    <div class="title-ubah-email open-sans">
      Ubah Email
    </div>
    <div style="display: flex; justify-content: center; margin-top: 15px;">
      <b-form-input
        type="email"
        :placeholder="userEmail"
        style="width: 320px; color: #3d3d3d;"
        v-model="newEmailText"
        :formatter="emailFormatter"
      ></b-form-input>
    </div>
    <div
      class="open-sans resend text-danger"
      style="text-align: left; margin-top: 5px; margin-left: 10px;"
      v-if="editEmailErrorMessage !== ''"
    >{{ editEmailErrorMessage }}</div>
    <button
      class="button-ubah-email"
      :style="{background: isLoadingEditEmail?'#4baff1':'var(--color-persian-blue-500)'}"
      :disabled="isLoadingEditEmail"
      @click.prevent="submitEditEmail"
      v-if="!editEmailSuccess"
    >
      <b-spinner
        small
        label="Small Spinner"
        v-if="isLoadingEditEmail"
      ></b-spinner>
      Kirim
    </button>
    <div
      class="edit-email-success open-sans"
      v-if="editEmailSuccess"
    >
      Email Verifikasi Telah Dikirim
    </div>
  </div>
</template>

<script>
import axiosServer from '@/configs/axiosServer.js'
import crypto from '../../functions/crypto'

export default {
  data () {
    return {
      isLoading: false,
      errorMessage: '',
      isOpenEditEmail: false,
      isLoadingEditEmail: false,
      newEmailText: '',
      editEmailErrorMessage: '',
      editEmailSuccess: false
    }
  },
  computed: {
    userId () {
      return this.$store.state.user.userInfo.id
    },
    userEmail () {
      return this.$store.state.user.userInfo.email
    },
    timerCount () {
      return this.$store.state.user.resendEmailCounter
      // if (window.localStorage.getItem('resend-email-counter')) {
      //   return Number(window.localStorage.getItem('resend-email-counter'))
      // } else {
      //   return 0
      // }
    },
    userToken () {
      return this.$store.state.storage.accessToken
    },
    isEmailVerified () {
      return this.$store.state.user.userInfo.is_email_verified
    },
    isCreatedWithoutEmail () {
      return this.$store.state.user.userInfo.is_created_without_email
    }
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.$store.commit('user/SET_RESEND_COUNTER', this.timerCount - 1)
          }, 1000)
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  methods: {
    emailFormatter (value) {
      return value.toLowerCase()
    },
    toggleEmailVerification () {
      this.$emit('toggleEmailVerification')
    },
    validateEmail (email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    async submitEditEmail () {
      this.editEmailErrorMessage = this.validateEmail(this.newEmailText) ? '' : 'Email Baru Tidak Sesuai Format Email.'

      if (this.validateEmail(this.newEmailText)) {
        this.isLoadingEditEmail = true
        try {
          const { data } = await axiosServer.post('/user/manage/', { id: Number(this.userId), email: this.newEmailText }, {
            headers: {
              Authorization: this.userToken
            }
          })

          if (data.status === 200) {
            await this.$store.dispatch('user/fetchUserData')
            this.editEmailSuccess = true
          } else {
            this.editEmailErrorMessage = data.message
          }
          this.isLoadingEditEmail = false
        } catch (error) {
          this.isLoadingEditEmail = false
          this.editEmailErrorMessage = `Terjadi kendala saat mengirim, silakan ulangi beberapa saat lagi.`
          console.log(error, 'error')
        }
      }
    },
    async resendEmail () {
      this.isLoading = true
      this.errorMessage = false
      try {
        const { data } = await axiosServer({
          url: `/user/v2/validate/`,
          method: 'POST',
          headers: {
            Authorization: this.userToken
          },
          data: { current_url: `${location.origin}${location.pathname}` }
        })
        if (data.status === 200) {
          this.$store.commit('user/SET_RESEND_COUNTER', 90)
          this.$emit('toggleEmailVerification')
          // window.localStorage.setItem('resend-email-counter', 60)
        } else if (data.message) {
          this.$store.dispatch('storage/checkToken', data)
          this.errorMessage = data.message
        }
      } catch (error) {
        this.errorMessage = error
      }
      this.isLoading = false
    },
    async confirmVerification () {
      await this.$store.dispatch('user/fetchUserData', {
        token: crypto.decrypt(window.$cookies.get('access-token'))
      })
      if (this.isEmailVerified || this.isCreatedWithoutEmail) {
        this.$emit('toggleEmailVerification')
      } else {
        this.$emit('notYetVerified')
      }
    }
  }
  // created () {
  //   if (this.$route.name.includes('profile')) {
  //     if (!this.timerCount) {
  //       this.resendEmail()
  //     }
  //   }
  // }
}
</script>

<style scoped>
.email-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  text-align: center;
  color: #172d5c;
}

.confirmation-btn {
  min-width: 230px;
  background: var(--color-persian-blue-500);
  border: 1px solid var(--color-persian-blue-500);
  border-radius: 10px;
  font-weight: bold;
}

.confirmation-btn:hover {
  background-color: #2980b9;
}

.email-desc {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.resend {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.action-email-button,
.loading-resend-email {
  margin-bottom: 5px;
}

.action-email-button button {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  text-align: center;

  color: var(--color-persian-blue-500);
}

.title-ubah-email {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;

  text-align: center;
  letter-spacing: 0.01em;

  color: #172d5c;
}

.button-ubah-email {
  margin-top: 20px;
  width: 100%;
  height: 39px;
  border: 1px solid var(--color-persian-blue-500);
  border-radius: 10px;

  color: var(--color-neutral-100);
  font-weight: bold;
}

.edit-email-success {
  margin-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.01em;

  color: #27ae60;
}
.button-verification{
  margin: 0 auto;
}
.button-verification-btn{
  background: var(--color-persian-blue-500);
  border: 1px solid var(--color-persian-blue-500);
  border-radius: 17.5px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: var(--color-neutral-100);
  padding: 5px 50px;
  margin-bottom: 15px;
}
.skip-verification-btn{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: #BDBDBD;
  margin-bottom: 15px;
}
</style>
