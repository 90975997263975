export default function getAddOnActivityTypeSlug (id) {
  const addOnActivityTypeSlug = {
    1: 'lainnya',
    2: 'tatap-muka',
    3: 'kelas-plus-daring',
    4: 'konsultasi',
    5: 'learning-product'
  }

  if (id === 0) {
    return addOnActivityTypeSlug[1] // Default Value
  }
  return addOnActivityTypeSlug[id]
}
