import deviceDetector from 'mobile-device-detect'
import crypto from '@/functions/crypto'

export default {
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    },
    parameters () {
      return {
        screen_url: window.location.href,
        user_profession: this.userInfo.profession_name ?? '',
        user_year_level: this.userInfo.year_level_name ?? '',
        source: this.sourceParameter,
        user_id: this.userInfo.id ?? '',
        parent_id: this.userInfo.parent ? this.userInfo.parent[0]?.parent_user_id : '',
        page_referrer: `${window.location.origin}${this.$route.fullPath}`,
        user_is_admin: this.userInfo.is_admin
      }
    },
    sourceParameter () {
      if (deviceDetector.isMobile) {
        return 'sekolahmu-webview'
      }

      return 'sekolahmu-web'
    },
    getSourceParamNewFormat () {
      const device = deviceDetector.isMobile ? 'mweb' : 'web'
      const host = this.$store.state.isSMM ? 'smm.sekolah.mu' : 'sekolah.mu'
      return `${device}_${host}`
    },
    defaultParam () {
      const cookieUserId = this.$cookies.get('user-id')
      let currentUserId = ''
      if (cookieUserId) {
        try {
          currentUserId = crypto.decrypt(this.$cookies.get('user-id'))
        } catch (error) {
          console.error(error)
        }
      }
      return {
        user_id: this.userInfo.id || currentUserId,
        screen_url: window.location.href,
        source: this.getSourceParamNewFormat
      }
    }
  },
  methods: {
    logEvent (event, arrParameters = []) {
      let payload = {}

      arrParameters.forEach(parameter => {
        switch (typeof parameter) {
          case 'object':
            payload = { ...payload, ...parameter }
            break
        }
      })

      payload = { ...this.parameters, ...payload }
      window.firebaseAnalytics.logEvent(event, payload)
    },
    logEventV2 (eventName, selectedDefaultParam = [], customParam = {}) {
      let payload = {}
      if (!selectedDefaultParam.length) {
        payload = this.defaultParam
      } else {
        for (const el of selectedDefaultParam) {
          payload[el] = this.defaultParam[el]
        }

        if (Object.keys(customParam).length > 0) {
          payload = {
            ...payload,
            ...customParam
          }
        }
      }
      try {
        window.firebaseAnalytics.logEvent(eventName, payload)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
