<template>
  <div class="topic-wrapper p-3 mb-3">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'TopicWrapper'
}
</script>
<style scoped>
.topic-wrapper {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
</style>
