const routes = [
  {
    path: '/refleksi2020',
    name: 'Refleksi2020',
    meta: {
      title: 'Refleksi 2020'
    },
    component: () => import(/* webpackChunkName: 'refleksi2020' */ '../views/reflection/MainMenu.vue')
  },
  {
    path: '/refleksi2020/belajar/:username',
    name: 'RegularPage',
    meta: {
      title: 'Refleksi 2020 - Perjalanan Belajar'
    },
    component: () => import(/* webpackChunkName: 'regularPage' */ '../views/reflection/RegularPage.vue')
  },
  {
    path: '/refleksi2020/mengajar/:username',
    name: 'FasilitatorPage',
    meta: {
      tite: 'Refleksi 2020 - Perjalanan Mengajar'
    },
    component: () => import(/* webpackChunkName: 'facilitatorPage' */ '../views/reflection/FacilitatorPage.vue')
  },
  {
    path: '/refleksi2020/timsekolahmu',
    name: 'InfoSekolahmu',
    meta: {
      title: 'Refleksi 2020 - Tim Sekolah.mu'
    },
    component: () => import(/* webpackChunkName: 'infoSekolahmu' */ '../views/reflection/InfoSekolahmu.vue')
  }
]

export default routes
