import axiosServer from '../../configs/axiosServer'

const activity = {
  namespaced: true,
  state: {
    activityFocus: {
      completion_timer: -1,
      isTimeUp: false
    },
    dataProgram: {},
    quizQuestion: {},
    quizSubmit: {
      activity_id: 0,
      activity_question_id: 0,
      question_position: 0,
      type: '',
      answer_essay: '',
      answer_file: [],
      is_next: false,
      is_preview: false
    },
    quizAnswer: [],
    isQuizStart: false,
    questionPerBatch: 0,
    isQuizIntro: true,
    activityTitle: 'Deskripsi Kelas',
    checkAnswer: false,
    quizIntro: {
      quizMasterData: {},
      quizTransaction: []
    },
    timer: 3605,
    intervalCounter: '',
    isCheckAnswer: false,
    dataProgramActivity: {},
    dataProgramDescription: {},
    dataProgramReport: {},
    isQuizGuideShown: false,
    isInstructionIncluded: false,
    isLoadingReport: false,
    isLoadingDescription: false,
    isReportNeedUpdate: false,
    isRatingButtonActive: false,
    activeMenu: 'activity',
    programRekomendasiList: [],
    isLoadingProgramRekomendasi: false,
    isFinishedBeforeCompletion: false,
    isLoadingUploadQuizFile: false,
    uploadFileQuizCancellationToken: null,
    folderId: null,
    isInStudyPage: false
  },
  getters: {
    answerFile (state) {
      if (!state.quizSubmit.answer_file) {
        return ['upload btn']
      } else {
        if (state.quizSubmit.answer_file.includes('upload btn')) {
          return state.quizSubmit.answer_file
        } else {
          return state.quizSubmit.answer_file.concat(['upload btn'])
        }
      }
    }
  },
  mutations: {
    CHANGE_LIST_PROGRAM_REKOMENDASI (state, payload) {
      state.programRekomendasiList = payload
    },
    CHANGE_LOADING_PROGRAM_REKOMENDASI (state, payload) {
      state.isLoadingProgramRekomendasi = payload
    },
    CHANGE_ACTIVITY_FOCUS (state, payload) {
      state.activityFocus = payload
    },
    CHANGE_JOINED_GROUP (state, { selectedGroup, previousGroup = { group_id: 0 }, user }) {
      const contentGroup = state.activityFocus.content_group.map(group => {
        // Add in view Quota
        if (group.group_id === previousGroup.group_id) {
          group.current_member -= 1
          group.selected_group = false
        }
        // Remove current user from all previous selected groups
        group.group_member = group.group_member.filter(g => g.user_name !== user.user_name)
        // Reduce in view Quota
        if (group.group_id === selectedGroup.group_id) {
          group.current_member += 1
          group.selected_group = true
          group.group_member.push(user)
        }
        return group
      })
      state.activityFocus = {
        ...state.activityFocus,
        content_group: contentGroup
      }
    },
    CHANGE_DATA_PROGRAM (state, payload) {
      state.dataProgram = payload
    },
    CHANGE_QUIZ_QUESTION (state, payload) {
      state.quizQuestion = payload
    },
    SET_IS_QUIZ_START (state, payload) {
      if (payload) {
        state.isQuizGuideShown = true
      }
      state.isQuizStart = payload
    },
    SET_QUIZ_SUBMIT (state, payload) {
      state.quizSubmit = payload
    },
    SET_QUIZ_ANSWER (state, payload) {
      state.quizAnswer = payload
    },
    SET_QUESTION_PER_BATCH (state, payload) {
      state.questionPerBatch = payload
    },
    SET_IS_QUIZ_INTRO (state, payload) {
      if (payload) {
        state.isQuizGuideShown = false
      }
      state.isQuizIntro = payload
    },
    SET_QUIZ_ANSWER_ESSAY (state, payload) {
      payload = payload.replace(/<p>/g, '')
      payload = payload.replace(/<\/p>/g, '')
      payload = `<p>${payload}</p>`
      payload = payload.replace(/(?:\r\n|\r|\n)/g, '<br />')
      state.quizSubmit.answer_essay = payload
    },
    SET_QUIZ_ANSWER_FILE (state, payload) {
      state.quizSubmit.answer_file = payload
    },
    PUSH_QUIZ_ANSWER_FILE (state, payload) {
      if (Array.isArray(payload)) {
        state.quizSubmit.answer_file = payload
      } else {
        let tempFiles
        if (Array.isArray(state.quizSubmit.answer_file)) {
          tempFiles = state.quizSubmit.answer_file
        } else {
          tempFiles = []
        }
        tempFiles.push(payload)
        const index = tempFiles.indexOf('upload btn')
        if (index > -1) {
          tempFiles.splice(index, 1)
        }
        state.quizSubmit.answer_file = tempFiles
      }
    },
    SET_ACTIVITY_TITLE (state, payload) {
      state.activityTitle = payload
    },
    SET_CHECK_ANSWER (state, payload) {
      state.checkAnswer = payload
    },
    SET_QUIZ_INTRO (state, payload) {
      let data = {}
      if (payload) {
        // data.quizMasterData = { ...payload.quiz_master_data }
        // data.quizTransaction = [...payload.quiz_transaction]
        const { quiz_master_data, quiz_transaction, ...rest } = payload
        data = {
          quizMasterData: quiz_master_data,
          quizTransaction: quiz_transaction,
          ...rest
        }
      } else {
        data.quizMasterData = {}
        data.quizTransaction = []
      }
      if (data.quizMasterData.instruction) {
        state.isInstructionIncluded = true
      } else {
        state.isInstructionIncluded = false
      }
      state.quizIntro = { ...data }
    },
    SET_TIMER (state, payload) {
      state.timer = payload
    },
    SET_INTERVAL_COUNTER (state, payload) {
      state.intervalCounter = payload
    },
    SET_IS_CHECK_ANSWER (state, payload) {
      state.isCheckAnswer = payload
    },
    SET_CAN_VIEW_CERTIFICATE (state, payload) {
      state.dataProgramReport.can_view_certificate = payload
    },
    SET_PROGRAM_REVIEWERS (state, payload) {
      state.dataProgramDescription.reviewers = payload.reviewers
      state.dataProgramDescription.reviewers_five_star = payload.five
      state.dataProgramDescription.reviewers_four_star = payload.four
      state.dataProgramDescription.reviewers_three_star = payload.three
      state.dataProgramDescription.reviewers_two_star = payload.two
      state.dataProgramDescription.reviewers_one_star = payload.one
      state.dataProgramDescription.rating = payload.rating
      state.dataProgramDescription.is_rated = payload.is_rated
      state.dataProgramDescription.can_view_certificate = payload.can_view_certificate
      state.dataProgramActivity.percentage_progress = payload.percentage_progress
    },
    SET_PROGRAM_REPORT (state, payload) {
      state.dataProgramReport.program_report = payload
    },
    CHANGE_DATA_PROGRAM_ACTIVITY (state, payload) {
      state.dataProgramActivity = payload
    },
    CHANGE_DATA_PROGRAM_DESCRIPTION (state, payload) {
      state.dataProgramDescription = payload
    },
    CHANGE_DATA_PROGRAM_REPORT (state, payload) {
      state.dataProgramReport = payload
    },
    SET_IS_LOADING_REPORT (state, payload) {
      state.isLoadingReport = payload
    },
    SET_IS_LOADING_DESCRIPTION (state, payload) {
      state.isLoadingDescription = payload
    },
    SET_IS_REPORT_NEED_UPDATE (state, payload) {
      state.isReportNeedUpdate = payload
    },
    SET_IS_RATING_BUTTON_ACTIVE (state, payload) {
      state.isRatingButtonActive = payload
    },
    SET_ACTIVE_MENU (state, payload) {
      state.activeMenu = payload
    },
    SET_TIME_UP (state, isTimeUp) {
      state.activityFocus = {
        ...state.activityFocus,
        isTimeUp
      }
    },
    SET_IS_FINISHED_BEFORE_COMPLETION (state, payload) {
      state.isFinishedBeforeCompletion = payload
    },
    SET_IS_LOADING_UPLOAD_QUIZ_FILE (state, payload) {
      state.isLoadingUploadQuizFile = payload
    },
    SET_UPLOAD_FILE_QUIZ_CANCELLATION_TOKEN (state, payload) {
      state.uploadFileQuizCancellationToken = payload
    },
    SET_FOLDER_ID (state, payload) {
      state.folderId = payload
    },
    SET_IN_STUDY_PAGE (state, payload) {
      state.isInStudyPage = payload
    }
  },
  actions: {
    async fetchAfterComplete ({ dispatch, commit }, payload) {
      commit('SET_IS_REPORT_NEED_UPDATE', true)
      dispatch('fetchProgramDescription', payload)
      // dispatch('fetchProgramReport', payload)
    },
    async fetchProgramReport ({ commit, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/v2/product_by_activity/${payload}/report`,
          method: 'get',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200) {
          commit('SET_CAN_VIEW_CERTIFICATE', data.data.can_view_certificate)
          commit('SET_PROGRAM_REPORT', data.data.program_report)
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async fetchProgramDescription ({ commit, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/v2/product_by_activity/${payload}/description`,
          method: 'get',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200) {
          commit('SET_PROGRAM_REVIEWERS', {
            reviewers: data.data.reviewers,
            five: data.data.reviewers_five_star,
            four: data.data.reviewers_four_star,
            three: data.data.reviewers_three_star,
            two: data.data.reviewers_two_star,
            one: data.data.reviewers_one_star,
            rating: data.data.rating,
            percentage_progress: data.data.percentage_progress,
            is_rated: data.data.is_rated,
            can_view_certificate: data.data.can_view_certificate
          })
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async activityComplete ({ commit, state, dispatch, rootState }) {
      try {
        const activity = state.activityFocus
        const program = state.dataProgramActivity
        if (activity.is_complete) {
          return false
        }
        if (program.subscription_lock || activity.is_archived) {
          return false
        }
        const { data } = await axiosServer({
          url: `/program_activity/v2/activity/`,
          method: 'post',
          headers: {
            Authorization: rootState.storage.accessToken
          },
          data: {
            program_user_id: program.program_user_id,
            resource_id: activity.id,
            is_finish: true
          },
          generateQueryParamSource: true
        })
        if (data.status === 200 && data.data.message?.length > 0) {
          commit('SET_IS_FINISHED_BEFORE_COMPLETION', true)
        } else if (data.status === 200) {
          dispatch('finishedActivity', { activityId: activity.id })
          dispatch('fetchAfterComplete', activity.slug)
        }
      } catch (err) {
        console.log(err)
      }
    },
    finishedActivity ({ state, commit }, payload) {
      const dataProgram = [...state.dataProgramActivity.chapter_list]
      const program = state.dataProgramActivity
      dataProgram.forEach(addon => {
        if (addon.is_addon) {
          addon.chapters.forEach(topic => {
            topic.resource_detail.forEach(activity => {
              if (activity.id === payload.activityId) {
                activity.is_complete = true
              }
            })
          })
        } else {
          addon.chapters[0].resource_detail.forEach(activity => {
            if (activity.id === payload.activityId) {
              activity.is_complete = true
            }
          })
        }
      })
      const allProgram = { ...program }
      allProgram.chapter_list = [...dataProgram]
      commit('CHANGE_DATA_PROGRAM', allProgram)
    },
    async videoAudioActivityStart ({ state, rootState }) {
      try {
        const activity = state.activityFocus
        const program = state.dataProgramActivity
        await axiosServer({
          url: '/program_activity/v2/activity/',
          method: 'post',
          headers: {
            Authorization: rootState.storage.accessToken
          },
          data: {
            program_user_id: program.program_user_id,
            resource_id: activity.id,
            is_finish: false
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }
}

export default activity
