<template>
  <b-modal
    v-model="isShow"
    :id="id"
    content-class="modal-content-semi_round"
    centered
    :hide-footer="!footer"
    :hide-header="!header"
    :no-close-on-backdrop="noBackdrop"
    :no-close-on-esc="noEsc"
    :size="size"
  >
    <div class="text-center pt-3 modal-wrapper" :class="{...containerClass}" >
      <div v-if="hasCloseIcon" class="close-icon">
        <img @click.prevent="closeModal()" id="icon-close" src="@/assets/icons/icon_close.svg" alt="Close"/>
      </div>
      <b-img-lazy
        v-if="modalImage"
        :src="modalImage"
        class="image-modal"
      ></b-img-lazy>

      <h2 class="font-paragraph-1 modal-title" :class="{...titleClass}" v-html="sanitizeXSS(modalTitle)"></h2>

      <p class="font-body-3 modal-text" v-html="sanitizeXSS(modalText)"></p>

      <s-button
        :is-loading="isLoading"
        variant="primary"
        pill
        @onClick="submitModal()"
        class="w-100 mb-2"
        :class="{...primaryButtonClass}"
        id="modal-submit-button"
      >{{submitButtonText}}</s-button>

      <s-button
        v-if="isHaveSecondaryBtn"
        variant="link"
        pill
        @onClick="closeModal()"
        class="w-100 mb-2"
        id="modal-close-button"
      >{{closeButtonText}}</s-button>
    </div>
  </b-modal>
</template>

<script>
import SButton from '@/components/atoms/buttons/SButton.vue'

export default {
  name: 'SModal',
  components: {
    SButton
  },
  props: {
    isShow: Boolean,
    isLoading: Boolean,
    variant: {
      type: String,
      default: 'custom'
    },
    modalTitle: String,
    modalText: String,
    submitButtonText: {
      type: String,
      default: 'Ya'
    },
    closeButtonText: {
      type: String,
      default: 'Batal'
    },
    isHaveSecondaryBtn: {
      type: Boolean,
      default: false
    },
    modalImage: String,
    size: {
      type: String,
      validator (value) {
        return ['sm', 'lg', 'xl'].includes(value)
      },
      default: 'sm'
    },
    id: {
      type: String,
      default: ''
    },
    footer: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    },
    noBackdrop: {
      type: Boolean,
      default: true
    },
    noEsc: {
      type: Boolean,
      default: true
    },
    titleClass: {
      type: Object,
      default: () => {}
    },
    containerClass: {
      type: Object,
      default: () => {}
    },
    primaryButtonClass: {
      type: Object,
      default: () => {}
    },
    hasCloseIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    submitModal () {
      this.$emit('submitModal')
    },
    sanitizeXSS (htmlEmbed) {
      return this.$XSSFilter(htmlEmbed)
    }
  },
  computed: {
    imgSrc () {
      switch (this.variant) {
        case 'custom':
          return this.modalImage
      }
    }
  }
}
</script>

<style scoped>
@import '../../../styles/ModalStyle.css';

.modal-wrapper {
  padding: 8px;
  padding-bottom: 0px;
}

.image-modal {
  width: 192px;
  height: 144px;
  object-fit: contain;
  margin-bottom: 10px;
}

.modal-title {
  color: var(--color-persian-blue-900);
  margin-bottom: 12px;
}

.modal-text {
  color: var(--color-ui-text-primary);
  margin-bottom: 16px;
  word-break: break-word;
}
.close-icon {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
</style>
