import axiosServer from '../../configs/axiosServer'
import axios from 'axios'
import deviceDetector from 'mobile-device-detect'
import sendDataToGtm from '../../functions/sendDataToGtm'
import createSearchTypeData from '../../gtmData/createSearchTypeData'

const program = {
  namespaced: true,
  state: {
    program: {},
    programPart2: {},
    search: '',
    searchList: [],
    nextUrl: '',
    loadMore: false,
    myProgram: [],
    allMyProgram: [],
    allMyProgramFolder: [],
    folderPrograms: {},
    folderProgramFilters: {},
    length: {
      semua: 0,
      kelasmu: 0,
      ujianmu: 0,
      karirmu: 0,
      prakerja: 0
    },
    searchProses: false,
    searchIkutProgram: '',
    searchCategory: '',
    filterList: {
      year_level: [],
      category: [],
      subject: [],
      duration: [],
      institution: []
    },
    filterOptions: [],
    sortList: [],
    loadingProgram: false,
    currentActivityProgram: {
      can_access: true
    },
    programId: 0,
    isOpeningProgramTerbatas: false,
    isLoadingProgramTerbatas: false,
    selectedProgramTerbatas: null,
    isOpenChildProgram: false,
    pageProgrammu: 1,
    programsFirstActivity: [],
    isAlreadyFetchRating: false,
    ratingProgram: [],
    ratingNextUrl: '',
    ratingPage: 1,
    ratingActive: 0,
    myReview: {},
    isDeleted: false,
    lastSearchQuery: '',
    isProgrammuEmpty: false,
    unreadAnnouncementCount: 0
  },
  mutations: {
    CHANGE_LAST_SEARCH_QUERY (state, payload) {
      state.lastSearchQuery = payload
    },
    CHANGE_OPEN_PROGRAM_TERBATAS (state, { isOpen, isLoading, selected, initSelected }) {
      state.isOpeningProgramTerbatas = !!isOpen
      state.isLoadingProgramTerbatas = isLoading || false
      state.selectedProgramTerbatas = selected || state.selectedProgramTerbatas
      if (initSelected) {
        state.selectedProgramTerbatas = null
      }
    },
    CHANGE_PROGRAM (state, payload) {
      state.program = payload
    },
    CHANGE_PROGRAM_CHAPTERLIST (state, payload) {
      state.program.chapter_list = payload
    },
    CHANGE_SEARCH (state, payload) {
      state.search = payload
    },
    CHANGE_SEARCH_CATEGORY (state, payload) {
      state.searchCategory = payload
    },
    CHANGE_FILTER_LIST (state, payload) {
      state.filterList = payload
    },
    CHANGE_SORT_LIST (state, payload) {
      state.sortList = payload
    },
    ALL_SEARCH_LIST (state, payload) {
      state.searchList = payload
    },
    CHANGE_LOAD_MORE_STATUS (state, payload) {
      state.loadMore = payload
    },
    CHANGE_NEXT_URL (state, payload) {
      state.nextUrl = payload
    },
    ADD_DATA_SEARCH (state, payload) {
      state.searchList = state.searchList.concat(payload)
    },
    PROGRAM_PART_TWO (state, payload) {
      state.programPart2 = payload
    },
    CHANGE_MY_PROGRAM (state, payload) {
      state.myProgram = payload
    },
    CHANGE_LEN_SEMUA (state, payload) {
      state.length.semua = payload
    },
    CHANGE_LEN_KELASMU (state, payload) {
      state.length.kelasmu = payload
    },
    CHANGE_LEN_UJIANMU (state, payload) {
      state.length.ujianmu = payload
    },
    CHANGE_LEN_KARIRMU (state, payload) {
      state.length.karirmu = payload
    },
    CHANGE_LEN_PRAKERJA (state, payload) {
      state.length.prakerja = payload
    },
    CHANGE_ALL_MYPROGRAM (state, payload) {
      state.allMyProgram = payload
    },
    CHANGE_ALL_MYPROGRAM_FOLDER (state, payload) {
      state.allMyProgramFolder = payload
    },
    CHANGE_FOLDER_PROGRAMS (state, payload) {
      state.folderPrograms = payload
    },
    CHANGE_FOLDER_PROGRAM_FILTERS (state, payload) {
      state.folderProgramFilters = payload
    },
    CHANGE_SEARCH_PROCESS (state, payload) {
      state.searchProses = payload
    },
    CHANGE_SEARCH_IKUTPROGRAM (state, payload) {
      state.searchIkutProgram = payload
    },
    CHANGE_LOADING_PROGRAM (state, payload) {
      state.loadingProgram = payload
    },
    CHANGE_CURRENT_ACTIVITY_PROGRAM (state, payload) {
      state.currentActivityProgram = payload
    },
    SET_PROGRAM_ID (state, payload) {
      state.programId = payload
    },
    SET_IS_OPEN_CHILD_PROGRAM (state, payload) {
      state.isOpenChildProgram = payload
    },
    SET_PAGE_PROGRAMMU (state, payload) {
      state.pageProgrammu = payload
    },
    SET_PROGRAMS_FIRST_ACTIVITY (state, payload) {
      state.programsFirstActivity = payload
    },
    SET_RATING_PROGRAM (state, payload) {
      state.ratingProgram = payload
    },
    SET_IS_ALREADY_FETCH_RATING (state, payload) {
      state.isAlreadyFetchRating = payload
    },
    SET_RATING_NEXT_URL (state, payload) {
      state.ratingNextUrl = payload
    },
    SET_RATING_PAGE (state, payload) {
      state.ratingPage = payload
    },
    SET_RATING_ACTIVE (state, payload) {
      state.ratingActive = payload
    },
    SET_MY_REVIEW (state, payload) {
      state.myReview = payload
    },
    SET_IS_DELETED (state, payload) {
      state.isDeleted = payload
    },
    SET_IS_PROGRAMMU_EMPTY (state, payload) {
      state.isProgrammuEmpty = payload
    },
    SET_UNREAD_ANNOUNCEMENT_COUNT (state, payload) {
      state.unreadAnnouncementCount = payload
    }
  },
  actions: {
    async fetchUnreadAnnouncementCount ({ commit, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          method: 'GET',
          url: `user/notification/program_announcement/count/?program_ids=${payload.programId}`,
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200) {
          commit('SET_UNREAD_ANNOUNCEMENT_COUNT', data.data.unread_count)
        } else {
          throw data
        }
      } catch (error) {
        commit('SET_UNREAD_ANNOUNCEMENT_COUNT', 0)
        console.error(error)
      }
    },
    async getProductPrakerja ({ commit, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program/get/${payload.id}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('CHANGE_PROGRAM', data.data)
        } else {
          dispatch('storage/checkToken', data, { root: true })
          let router = payload.router
          router.push(`/program/${payload.id}`)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getOneProgram ({ commit, rootState, dispatch }, id) {
      try {
        const { data } = await axiosServer({
          url: `/program/manage/get/${id}`,
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200) {
          commit('PROGRAM_PART_TWO', data.data)
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async fetchProgramOnly ({ commit, dispatch, rootState }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/v2/product_detail/${payload.id}/description`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('CHANGE_PROGRAM', data.data)
        } else {
          dispatch('storage/checkToken', data, { root: true })
          let router = payload.router
          if (
            (data.status === 400 && data.message === 'Not found slug') ||
            (data.status === 404)
          ) {
            router.push('/404')
          }
          if (data.status === 114 && data.message === 'program not available') {
            commit('SET_IS_DELETED', true)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchOneProgram ({ commit, rootState, dispatch }, id) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/v2/product_detail/${id}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('CHANGE_PROGRAM', data.data)
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (err) {
        console.log(err)
      }
    },
    async fetchAllProgram ({ commit, rootState, dispatch }, payload) {
      commit('CHANGE_LOADING_PROGRAM', true)
      let newUrl = payload.tag ? `/program/list/${payload.category}/1/${payload.limit || 30}/${payload.tag}` : `/program/list/${payload.category}/1/${payload.limit || 30}`
      try {
        const { data } = await axiosServer({
          url: newUrl,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('ALL_SEARCH_LIST', data.data)
          if (data['next-url'] !== '') {
            commit('CHANGE_LOAD_MORE_STATUS', true)
            let newUrl = data['next-url']
            commit('CHANGE_NEXT_URL', newUrl)
          } else {
            commit('CHANGE_LOAD_MORE_STATUS', false)
          }
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
        commit('CHANGE_LOADING_PROGRAM', false)
      } catch (error) {
        console.log(error)
      }
    },
    async searchProgram ({ commit, rootState, dispatch }, payload) {
      commit('CHANGE_LOADING_PROGRAM', true)
      try {
        const { data } = await axiosServer({
          url: `/program_activity/search-list/${payload.category}/${payload.title}/1?url=${window.location.href}&source=${deviceDetector.isMobile ? 'web-mobile' : 'web'}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('ALL_SEARCH_LIST', data.data)
          if (data['next-url'] !== '') {
            commit('CHANGE_LOAD_MORE_STATUS', true)
            let newUrl = data['next-url']
            commit('CHANGE_NEXT_URL', newUrl)
          } else {
            commit('CHANGE_LOAD_MORE_STATUS', false)
          }
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
        commit('CHANGE_LOADING_PROGRAM', false)
      } catch (error) {
        console.log(error)
      }
    },
    async loadMoreProgram ({ commit, state, rootState, dispatch }, payload) {
      try {
        const { data } = await axios({
          url: state.nextUrl,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          let newArray = state.searchList.concat(data.data)
          commit('ALL_SEARCH_LIST', newArray)
          if (data['next-url'] !== '') {
            commit('CHANGE_LOAD_MORE_STATUS', true)
            let newUrl = data['next-url']
            commit('CHANGE_NEXT_URL', newUrl)
          } else {
            commit('CHANGE_LOAD_MORE_STATUS', false)
          }
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getAllMyProgram ({ commit, rootState, dispatch }) {
      try {
        const { data } = await axiosServer({
          url: '/program_activity/my/all/',
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          commit('CHANGE_ALL_MYPROGRAM', data.data)
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getAllMyProgramFolder ({ commit, rootState, dispatch }, userId) {
      try {
        const { data } = await axiosServer({
          url: '/program_activity/enrolled/folder/' + userId,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200) {
          commit('CHANGE_ALL_MYPROGRAM_FOLDER', data.data)
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getFolderPrograms ({ commit, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: `program_activity/enrolled/folder/detail/${payload.userId}/${payload.folderId}?search=${payload.search}&subject=${payload.filterQuery}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200) {
          commit('CHANGE_FOLDER_PROGRAMS', data.data)
          if (!payload.search && !payload.filterQuery) {
            commit('CHANGE_FOLDER_PROGRAM_FILTERS', data.filter)
          }
          const arrProgramsId = data.data.non_grouped_data.map(program => {
            return program.id
          })
          const programsFirstActivity = await dispatch('getPorgramFirstActivityBulk', arrProgramsId)
          commit('SET_PROGRAMS_FIRST_ACTIVITY', [...programsFirstActivity])
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getMyProgram ({ commit, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: '/program_activity/my/all/1',
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          if (payload.isInit) {
            if (!data.data.length) {
              commit('SET_IS_PROGRAMMU_EMPTY', true)
            } else {
              commit('SET_IS_PROGRAMMU_EMPTY', false)
            }
          }
          commit('CHANGE_ALL_MYPROGRAM', data.data)
          commit('CHANGE_LEN_SEMUA', data.data.length)
          let totalKelasmu = data.data.filter(item => item.category_name === 'KELASMU')
          commit('CHANGE_LEN_KELASMU', totalKelasmu.length)
          let totalUjianmu = data.data.filter(item => item.category_name === 'UJIANMU')
          commit('CHANGE_LEN_UJIANMU', totalUjianmu.length)
          let totalKarirmu = data.data.filter(item => item.category_name === 'KARIERMU')
          commit('CHANGE_LEN_KARIRMU', totalKarirmu.length)
          let totalPrakerja = data.data.filter(item => item.category_name === 'PRAKERJA')
          commit('CHANGE_LEN_PRAKERJA', totalPrakerja.length)
          commit('CHANGE_MY_PROGRAM', data.data)
          if (data['next-url'] !== '') {
            commit('CHANGE_LOAD_MORE_STATUS', true)
            let newUrl = data['next-url']
            commit('CHANGE_NEXT_URL', newUrl)
          } else {
            commit('CHANGE_LOAD_MORE_STATUS', false)
            commit('CHANGE_NEXT_URL', '')
          }
          return data
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async loadMoreMyProgram ({ commit, state, rootState, dispatch }) {
      try {
        const { data } = await axios({
          url: state.nextUrl,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          let newArray = state.myProgram.concat(data.data)
          commit('CHANGE_LEN_SEMUA', newArray.length)
          let totalKelasmu = newArray.filter(item => item.category_name === 'KELASMU')
          commit('CHANGE_LEN_KELASMU', totalKelasmu.length)
          let totalUjianmu = newArray.filter(item => item.category_name === 'UJIANMU')
          commit('CHANGE_LEN_UJIANMU', totalUjianmu.length)
          let totalKarirmu = newArray.filter(item => item.category_name === 'KARIERMU')
          commit('CHANGE_LEN_KARIRMU', totalKarirmu.length)
          let totalPrakerja = newArray.filter(item => item.category_name === 'PRAKERJA')
          commit('CHANGE_LEN_PRAKERJA', totalPrakerja.length)
          commit('CHANGE_MY_PROGRAM', newArray)
          if (data['next-url'] !== '') {
            commit('CHANGE_LOAD_MORE_STATUS', true)
            let newUrl = data['next-url']
            commit('CHANGE_NEXT_URL', newUrl)
          } else {
            commit('CHANGE_LOAD_MORE_STATUS', false)
          }
        } else {
          dispatch('storage/checkToken', data, { root: true })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchAllProgramBeforeLogin ({ commit, dispatch, rootState }, payload) {
      commit('CHANGE_LOADING_PROGRAM', true)
      let newUrl = payload.tag ? `/program/list/${payload.category}/1/${payload.limit || 30}/${payload.tag}` : `/program/list/${payload.category}/1/${payload.limit || 30}`
      if (!rootState.storage.accessToken || payload.isCustomized) {
        try {
          const { data } = await axiosServer({
            url: newUrl,
            method: 'GET',
            headers: {
              Authorization: process.env.VUE_APP_APP_KEY
            }
          })
          if (data.status === 200 || data.message === 'OK') {
            commit('ALL_SEARCH_LIST', data.data)
            if (data['next-url'] !== '') {
              commit('CHANGE_LOAD_MORE_STATUS', true)
              let newUrl = data['next-url']
              commit('CHANGE_NEXT_URL', newUrl)
            } else {
              commit('CHANGE_LOAD_MORE_STATUS', false)
            }
          }
          commit('CHANGE_LOADING_PROGRAM', false)
        } catch (error) {
          console.log(error)
        }
      } else {
        dispatch('fetchAllProgram', payload)
      }
    },
    async searchProgramBeforeLogin ({ commit, dispatch, rootState }, payload) {
      commit('CHANGE_LOADING_PROGRAM', true)
      commit('CHANGE_SEARCH_CATEGORY', payload.category)
      if (!rootState.storage.accessToken) {
        try {
          const { data } = await axiosServer({
            url: `/program_activity/search-list/${payload.category}/${payload.title}/1?url=${window.location.href}&source=${deviceDetector.isMobile ? 'web-mobile' : 'web'}`,
            method: 'GET',
            headers: {
              Authorization: process.env.VUE_APP_APP_KEY
            }
          })
          if (data.status === 200 || data.message === 'OK') {
            commit('ALL_SEARCH_LIST', data.data)
            if (data['next-url'] !== '') {
              commit('CHANGE_LOAD_MORE_STATUS', true)
              let newUrl = data['next-url']
              commit('CHANGE_NEXT_URL', newUrl)
            } else {
              commit('CHANGE_LOAD_MORE_STATUS', false)
            }
          }
          commit('CHANGE_LOADING_PROGRAM', false)
        } catch (error) {
          console.log(error)
        }
      } else {
        dispatch('searchProgram', payload)
      }
    },
    async searchProgramV2 ({ commit, rootState, state }, payload) {
      commit('CHANGE_LOADING_PROGRAM', true)
      commit('CHANGE_SEARCH_CATEGORY', payload.data.category)
      if (!payload.data.category) {
        payload.data.category = 'sekolahmu-web'
      }
      let Authorization = rootState.storage.accessToken ? rootState.storage.accessToken : process.env.VUE_APP_APP_KEY
      let query = ''
      let queryBefore = ''
      for (const prop in payload.data) {
        if (payload.data[prop] && prop !== 'url' && prop !== 'source') {
          queryBefore += `${prop}=${payload.data[prop]}&`
        }
        if (payload.data[prop]) {
          query += `${prop}=${payload.data[prop]}&`
        }
      }
      if (query) {
        query = query.slice(0, query.length - 1)
        queryBefore = queryBefore.slice(0, queryBefore.length - 1)
      }
      if (!state.lastSearchQuery || (query !== state.lastSearchQuery)) {
        try {
          commit('CHANGE_LAST_SEARCH_QUERY', queryBefore)
          const { data } = await axiosServer({
            url: `/program_activity/v2/list/1/36?${query}`,
            method: 'GET',
            headers: {
              Authorization
            }
          })
          if (data.status === 200 || data.message === 'OK') {
            const searchTypeData = createSearchTypeData(payload.data, data.data)
            sendDataToGtm(searchTypeData)

            commit('ALL_SEARCH_LIST', data.data)
            data.filter.institution = data.filter.institution.filter((item) => {
              return item.key !== ''
            })

            if (payload.fromNavbar) {
              commit('CHANGE_FILTER_LIST', data.filter)
              commit('CHANGE_SORT_LIST', data.sort)
            }
            if (data['next-url'] !== '') {
              commit('CHANGE_LOAD_MORE_STATUS', true)
              let newUrl = data['next-url']
              commit('CHANGE_NEXT_URL', newUrl)
            } else {
              commit('CHANGE_LOAD_MORE_STATUS', false)
            }
          }
          commit('CHANGE_LOADING_PROGRAM', false)
          setTimeout(() => {
            commit('CHANGE_SEARCH_PROCESS', false)
          }, 1500)
        } catch (error) {
          console.log(error)
        }
      } else {
        commit('CHANGE_LOADING_PROGRAM', false)
      }
    },
    async loadMoreProgramBeforeLogin ({ commit, state, dispatch, rootState }, payload) {
      if (!rootState.storage.accessToken) {
        try {
          const { data } = await axiosServer({
            url: state.nextUrl,
            method: 'GET',
            headers: {
              Authorization: process.env.VUE_APP_APP_KEY
            }
          })
          if (data.status === 200 || data.message === 'OK') {
            let newArray = state.searchList.concat(data.data)
            commit('ALL_SEARCH_LIST', newArray)
            if (data['next-url'] !== '') {
              commit('CHANGE_LOAD_MORE_STATUS', true)
              let newUrl = data['next-url']
              commit('CHANGE_NEXT_URL', newUrl)
            } else {
              commit('CHANGE_LOAD_MORE_STATUS', false)
            }
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        dispatch('loadMoreProgram')
      }
    },
    async fetchActivityOnly ({ commit, rootState }, payload) {
      let headers
      if (!rootState.storage.accessToken) {
        headers = {
          Authorization: process.env.VUE_APP_APP_KEY
        }
      } else {
        headers = {
          Authorization: rootState.storage.accessToken
        }
      }
      try {
        const { data } = await axiosServer({
          url: `/program_activity/v2/product_detail/${payload.id}/activity`,
          method: 'GET',
          headers
        })
        commit('CHANGE_PROGRAM_CHAPTERLIST', data.data.chapter_list)
        return data
      } catch (error) {
        console.log(error)
        return { data: { chapter_list: [] } }
      }
    },
    async fetchProgramOnlyBeforeLogin ({ commit, dispatch, rootState }, payload) {
      if (!rootState.storage.accessToken) {
        try {
          const { data } = await axiosServer({
            url: `/program_activity/v2/product_detail/${payload.id}/description`,
            method: 'GET',
            headers: {
              Authorization: process.env.VUE_APP_APP_KEY
            }
          })
          if (data.status === 200 || data.message === 'OK') {
            commit('CHANGE_PROGRAM', data.data)
          } else {
            let router = payload.router
            if (
              (data.status === 400 && data.message === 'Not found slug') ||
              (data.status === 404)
            ) {
              router.push('/404')
            }
            if (data.status === 114 && data.message === 'program not available') {
              commit('SET_IS_DELETED', true)
            }
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        await dispatch('fetchProgramOnly', payload)
      }
    },
    async fetchOneProgramBeforeLogin ({ commit, dispatch, rootState }, id) {
      if (!rootState.storage.accessToken) {
        try {
          const { data } = await axiosServer({
            url: `/program_activity/v2/product_detail/${id}`,
            method: 'GET',
            headers: {
              Authorization: process.env.VUE_APP_APP_KEY
            }
          })
          if (data.status === 200) {
            commit('CHANGE_PROGRAM', data.data)
            return true
          } else {
            return false
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        dispatch('fetchOneProgram', id)
      }
    },
    async getProgramForSertifkat ({ commit, rootState, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/v2/product_detail/${payload}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        dispatch('storage/checkToken', data, { root: true })
        return data
      } catch (error) {
        console.log(error)
      }
    },
    async getListProgramChild ({ commit, rootState, state, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/enrolled/${payload.user_id}/${payload.page}/12`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          if (payload.isInit) {
            if (!data.data.length) {
              commit('SET_IS_PROGRAMMU_EMPTY', true)
            } else {
              commit('SET_IS_PROGRAMMU_EMPTY', false)
            }
          }
          const arrProgramsId = []
          data.data.forEach(program => {
            arrProgramsId.push(program.id)
          })
          const programsFirstActivity = await dispatch('getPorgramFirstActivityBulk', arrProgramsId)
          commit('CHANGE_LEN_SEMUA', data.data.length)
          let totalKelasmu = data.data.filter(item => item.category_name === 'KELASMU')
          commit('CHANGE_LEN_KELASMU', totalKelasmu.length)
          let totalUjianmu = data.data.filter(item => item.category_name === 'UJIANMU')
          commit('CHANGE_LEN_UJIANMU', totalUjianmu.length)
          let totalKarirmu = data.data.filter(item => item.category_name === 'KARIERMU')
          commit('CHANGE_LEN_KARIRMU', totalKarirmu.length)
          let totalPrakerja = data.data.filter(item => item.category_name === 'PRAKERJA')
          commit('CHANGE_LEN_PRAKERJA', totalPrakerja.length)
          if (payload.page !== 1) {
            commit('CHANGE_ALL_MYPROGRAM', [...state.allMyProgram, ...data.data])
            commit('SET_PROGRAMS_FIRST_ACTIVITY', [...state.programsFirstActivity, ...programsFirstActivity])
          } else {
            commit('CHANGE_ALL_MYPROGRAM', data.data)
            commit('SET_PROGRAMS_FIRST_ACTIVITY', [...programsFirstActivity])
          }
          if (data['next-url']) {
            commit('CHANGE_LOAD_MORE_STATUS', true)
            let newUrl = data['next-url']
            commit('CHANGE_NEXT_URL', newUrl)
          } else {
            commit('CHANGE_NEXT_URL', '')
            commit('CHANGE_LOAD_MORE_STATUS', false)
          }
          return true
        } else {
          dispatch('storage/checkToken', data, { root: true })
          return false
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async searchProgramProgrammu ({ commit, rootState, state, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: `/program_activity/search-enrolled/${payload.user_id}/all/${payload.search}/${payload.page}`,
          method: 'GET',
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200 || data.message === 'OK') {
          const arrProgramsId = []
          data.data.forEach(program => {
            arrProgramsId.push(program.id)
          })
          const programsFirstActivity = await dispatch('getPorgramFirstActivityBulk', arrProgramsId)
          if (payload.page !== 1) {
            commit('CHANGE_ALL_MYPROGRAM', [...state.allMyProgram, ...data.data])
            commit('SET_PROGRAMS_FIRST_ACTIVITY', [...state.programsFirstActivity, ...programsFirstActivity])
          } else {
            commit('CHANGE_ALL_MYPROGRAM', data.data)
            commit('SET_PROGRAMS_FIRST_ACTIVITY', [...programsFirstActivity])
          }
          if (data['next-url']) {
            commit('CHANGE_LOAD_MORE_STATUS', true)
            let newUrl = data['next-url']
            commit('CHANGE_NEXT_URL', newUrl)
          } else {
            commit('CHANGE_NEXT_URL', '')
            commit('CHANGE_LOAD_MORE_STATUS', false)
          }
          return true
        } else {
          dispatch('storage/checkToken', data, { root: true })
          return false
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async getPorgramFirstActivityBulk ({ commit, rootState, state, dispatch }, payload) {
      try {
        const { data } = await axiosServer({
          url: '/program/first-activity-multi-program/',
          method: 'POST',
          data: {
            program_id_list: payload
          },
          headers: {
            Authorization: rootState.storage.accessToken
          }
        })
        if (data.status === 200) {
          return data.data
        } else {
          dispatch('storage/checkToken', data, { root: true })
          return []
        }
      } catch (err) {
        return []
      }
    }
  }
}

export default program
