<template>
  <div class="modal-container-component">
    <div class="modal-title-container">
      <img src="https://cdn.sekolah.mu/asset/images/prakerja_closed.svg" alt="Sekolah.mu">
      <h2>Hai, Program Prakerja tahun 2021 belum dimulai!</h2>
    </div>
    <div class="modal-body-container">
      <p>yuk lihat-lihat program untuk menambahkan keahlianmu!</p>
    </div>
    <div class="modal-footer-container">
      <button-primary-solid-box class="route-btn" @onClick="gotoKariermu">
        Lihat Program
      </button-primary-solid-box>
    </div>
  </div>
</template>
<script>
import ButtonPrimarySolidBox from '@/components/atoms/buttons/ButtonPrimarySolidBox'

export default {
  components: {
    ButtonPrimarySolidBox
  },
  computed: {
    urlBelajar () {
      return process.env.VUE_APP_HOST_URL
    }
  },
  methods: {
    gotoKariermu () {
      window.location.href = `${this.urlBelajar}/program?category=Kariermu`
    }
  }
}
</script>
<style scoped>
*{
  font-family: Inter, sans-serif;
}
h2, p{
  margin: 0;
}
.modal-container-component{
  margin: 22px;
}
.modal-title-container{
  text-align: center;
  margin-bottom: 9px;
}
.modal-title-container img{
  width: 237px;
  height: 169px;
  margin-bottom: 15px;
}
.modal-title-container h2{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-persian-blue-900);
}
.modal-body-container{
  text-align: center;
  margin-bottom: 28px;
}
.modal-body-container p{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-neutral-800);
}
.modal-footer-container{
  text-align: center;
}
.route-btn{
  width: 206px;
}
</style>
