<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <nav v-if="isLogin === false">
      <div class="container navbar">
        <div class="left-nav">
          <img
            src="../assets/icons/icon_sekolahmu.png"
            alt="/"
            style="width: 143px; cursor: pointer"
            @click="toHome()"
          />
          <div id="nav-menu">
            <!-- <div class="nav-menus gotham-book">Tentang Kami</div> -->
            <b-dropdown
              size="lg"
              variant="link"
              toggle-class="text-decoration-none"
              class="pl-4 pr-4"
              no-caret
            >
              <template v-slot:button-content>
                <div class="nav-menus gotham-book">Tentang Kami</div>
              </template>
              <template v-slot:default>
                <div id="dropdown-tentang-kami">
                  <div
                    class="menu-dropdown"
                    @click="toRouting('/about')"
                  >Profil Sekolah.mu</div>
                  <div
                    class="menu-dropdown"
                    @click="toRouting('/board-of-school')"
                  >Dewan Sekolah.mu</div>
                  <div
                    class="menu-dropdown"
                    @click="toRouting('/partner')"
                  >Mitra Kolaborasi</div>
                  <div
                    class="menu-dropdown"
                    @click="toRouting('/contact')"
                  >Kontak Kami</div>
                </div>
              </template>
            </b-dropdown>
            <!-- <div class="nav-menus gotham-book">Program</div> -->
            <!-- <div class="nav-menus gotham-book">Blog</div> -->
          </div>
        </div>
        <div class="right-nav">
          <div class="right-nav-wrapper">
            <div
              class="nav-menus gotham-book"
              @click="toRouting('/help')"
            >Bantuan</div>
            <span
              class="button-masuk-new gotham-book"
              @click="toggleMasuk()"
            >
              Masuk
            </span>
            <!-- <div id="button-masuk" @click="toggleMasuk()">
              <div class="text-inside-button gotham-book">Masuk</div>
            </div>
            <div id="button-daftar" @click="toggleDaftar()">
              <div class="text-inside-button gotham-book">Daftar</div>
            </div> -->
          </div>
          <div
            id="nav-burger"
            @click="toggleDropdown()"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <img
                :src="imageDropdown"
                :key="imageDropdown"
              />
            </transition>
          </div>
        </div>
      </div>
      <b-collapse
        v-model="dropdown"
        id="nav-collapse"
      >
        <div class="inside-collapse">
          <div
            class="nav-menus gotham-book"
            @click="toRouting('/about')"
          >Tentang Kami</div>
          <span
            class="button-masuk-new gotham-book"
            @click="toggleMasuk()"
          >
            Masuk
          </span>
          <!-- <div class="nav-menus gotham-book">Program</div> -->
          <!-- <div class="nav-menus gotham-book">Blog</div> -->
          <!-- <span class="button-masuk" @click="toggleMasuk()">
            <div class="text-inside-button gotham-book">Masuk</div>
          </span>
          <span class="button-daftar" @click="toggleDaftar()">
            <div class="text-inside-button gotham-book">Daftar</div>
          </span> -->
        </div>
      </b-collapse>

      <b-modal
        v-model="modalMasuk"
        hide-footer
        hide-header
        centered
        class="modal-test"
      >
        <form
          class="modal-body"
          @submit.prevent="login()"
        >
          <div class="icon-close">
            <font-awesome-icon
              icon="times"
              style="color: #BDBDBD; cursor: pointer"
              @click="modalMasuk = false"
            />
          </div>
          <div class="modal-title gotham-book">Yuk, Belajar di Sekolah.mu!</div>
          <div class="modal-description">Gunakan email kamu untuk mendaftar</div>
          <div
            class="modal-description error-message"
            v-if="isError"
          >Email/Password Salah</div>
          <input
            type="email"
            class="form-control input-masuk"
            placeholder="Email"
            v-model="emailUser"
            :class="errorInput"
          />
          <div class="password-wrapper">
            <input
              :type="inputType"
              class="form-control input-masuk"
              placeholder="Password"
              v-model="passwordUser"
              :class="errorInput"
            />
            <font-awesome-icon
              class="icon-password"
              icon="eye"
              v-if="!isShowPassword"
              @click="toggleShowPassword()"
            />
            <font-awesome-icon
              class="icon-password"
              icon="eye-slash"
              v-else
              @click="toggleShowPassword()"
            />
          </div>
          <div class="input-checkbox">
            <input
              type="checkbox"
              class="checkbox-rule"
              v-model="isKeepLogin"
            >
            <label class="form-check-label rule-text">Ingat Saya</label>
          </div>
          <button
            class="button-sign w-100"
            v-if="isLoading"
            disabled
          >
            <b-spinner
              small
              label="Small Spinner"
            ></b-spinner> Lanjut
          </button>
          <button
            class="button-sign w-100"
            type="submit"
            v-else-if="isClickable"
          >Lanjut</button>
          <button
            class="button-sign w-100"
            v-else
            disabled
          >Lanjut</button>
        </form>
      </b-modal>

      <!-- <b-modal v-model="modalDaftar" hide-footer hide-header centered class="modal-test">
        <div class="modal-body" v-if="!isSend">
          <div class="icon-close">
            <font-awesome-icon
              icon="times"
              style="color: #BDBDBD; cursor: pointer"
              @click="modalDaftar = false"
            />
          </div>
          <div class="modal-title gotham-book">Yuk, Belajar di Sekolahmu!</div>
          <div class="modal-description">Gunakan email kamu untuk mendaftar</div>
          <input
            type="email"
            class="form-control input-email"
            placeholder="contoh: andiazra@gmail.com"
            v-model="email"
          />
          <div class="input-checkbox">
            <input type="checkbox" class="checkbox-rule" v-model="isAgree" />
            <label class="form-check-label rule-text"
              >Saya setuju dengan peraturan sekolahmu</label
            >
          </div>
          <button class="button-sign" v-if="email">Lanjut</button>
          <button class="button-sign" disabled v-else>Lanjut</button>
          <div class="modal-footer">
            Sudah Punya Akun?
            <span>Masuk</span>
          </div>
        </div>
      </b-modal> -->

      <!-- <b-modal v-model="modalMasuk" hide-footer hide-header centered class="modal-test">
        <form class="modal-body" @submit.prevent="login()">
          <div class="icon-close">
            <font-awesome-icon icon="times" style="color: #BDBDBD; cursor: pointer" @click="modalMasuk = false"/>
          </div>
          <div class="modal-title gotham-book">Yuk, Mulai Belajar!</div>
          <div class="modal-description">
            Belum punya akun? <span @click="toRegister()">Daftar di sini</span>
          </div>
          <input type="email" class="form-control input-masuk" placeholder="Email" v-model="emailUser"/>
          <input type="password" class="form-control input-masuk" placeholder="Password" v-model="passwordUser"/>
          <div class="input-checkbox">
            <div>
              <input type="checkbox" class="checkbox-rule" v-model="isKeepLogin">
              <label class="form-check-label rule-text">Ingat Saya</label>
            </div>
          </div>
          <button class="button-sign w-100" v-if="isLoading" disabled>Lanjut <b-spinner small label="Small Spinner"></b-spinner></button>
          <button class="button-sign w-100" type="submit" v-else-if="isClickable">Lanjut</button>
          <button class="button-sign w-100" v-else disabled>Lanjut</button>
        </form>
      </b-modal> -->

    </nav>
    <nav v-else>
      <div
        class="container navbar"
        :class="{'d-none': isSearchMobile}"
      >
        <div class="left-nav">
          <img
            src="../assets/icons/icon_sekolahmu.png"
            alt="/"
            style="width: 143px; cursor: pointer"
            @click="toHome()"
          />
          <form
            class="search-bar"
            @submit.prevent="searchProgram()"
          >
            <select
              class="custom-select gotham-book select-search"
              v-model="searchSelect"
            >
              <option
                selected
                value="semua"
              >Semua</option>
              <option value="kelasmu">Kelasmu</option>
              <option value="ujianmu">Ujianmu</option>
              <option value="karirmu">Karirmu</option>
            </select>
            <input
              type="text"
              class="input-search"
            >
            <img src="../assets/icons/icon_search.png">
          </form>
        </div>
        <div class="right-nav">
          <div class="right-nav-wrapper">
            <div class="gotham-book nav-programmu">Programmu</div>
            <img
              :src="$cookies.get('avatar')"
              class="image-user"
              @click="toggleDropdownProfile()"
            />
            <b-collapse
              v-model="dropdownProfile"
              id="nav-collapse-profile"
            >
              <div class="inside-profile">
                <div class="image-wrapper">
                  <img :src="$cookies.get('avatar')" />
                  <div class="user-info">
                    <div class="inside-name">Indra</div>
                    <div class="inside-profession">Murid</div>
                  </div>
                </div>
                <div
                  class="nav-menus"
                  @click="toProfile()"
                >Lihat Profil</div>
                <div
                  class="nav-menus"
                  @click="logout()"
                >Keluar</div>
                <!-- <div class="nav-menus gotham-book">Program</div> -->
                <!-- <div class="nav-menus gotham-book">Blog</div> -->
                <!-- <span class="button-masuk" @click="toggleMasuk()">
                  <div class="text-inside-button gotham-book">Masuk</div>
                </span>
                <span class="button-daftar" @click="toggleDaftar()">
                  <div class="text-inside-button gotham-book">Daftar</div>
                </span> -->
              </div>
            </b-collapse>
          </div>
          <img
            src="../assets/icons/icon_search.png"
            class="toggle-search"
            @click="toggleSearchMobile()"
          />
          <div
            id="nav-burger"
            @click="toggleDropdownLogin()"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <img
                :src="imageDropdownLogin"
                :key="imageDropdownLogin"
              />
            </transition>
          </div>
        </div>
      </div>
      <!-- //!search mobile -->
      <div
        class="container search-mobile"
        :class="{'d-none': !isSearchMobile}"
      >
        <form
          class="search-bar2"
          @submit.prevent="searchProgram()"
        >
          <input
            type="text"
            class="input-search2"
            ref="search"
          >
          <img src="../assets/icons/icon_search.png">
        </form>
        <div
          class="search-cancel"
          @click="toggleSearchMobile()"
        >Batal</div>
      </div>

      <b-collapse
        v-model="dropdownLogin"
        id="nav-collapse"
      >
        <div class="inside-collapse">
          <img :src="$cookies.get('avatar')" />
          <div
            class="nav-menus gotham-book"
            @click="toProfile()"
          >Lihat Profil</div>
          <div class="nav-menus gotham-book">Programmu</div>
          <div
            class="nav-menus gotham-book"
            @click="logout()"
          >Keluar</div>
          <!-- <div class="nav-menus gotham-book">Program</div> -->
          <!-- <div class="nav-menus gotham-book">Blog</div> -->
          <!-- <span class="button-masuk" @click="toggleMasuk()">
            <div class="text-inside-button gotham-book">Masuk</div>
          </span>
          <span class="button-daftar" @click="toggleDaftar()">
            <div class="text-inside-button gotham-book">Daftar</div>
          </span> -->
        </div>
      </b-collapse>
    </nav>
  </transition>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Toast from '../configs/Toast'
import deviceDetector from 'mobile-device-detect'
import setAuthPayload from '@/mixins/setAuthPayload.js'

export default {
  components: {
    FontAwesomeIcon
  },
  mixins: [setAuthPayload],
  data () {
    return {
      modalDaftar: false,
      isSend: false,
      email: '',
      isAgree: false,
      modalMasuk: false,
      isKeepLogin: false,
      passwordUser: '',
      emailUser: '',
      isClickable: false,
      isLoading: false,
      dropdown: false,
      isShowPassword: false,
      errorMessage: '',
      isError: false,
      searchSelect: 'semua',
      searchOptions: [
        'Semua',
        'Kelasmu',
        'Ujianmu',
        'Karirmu'
      ],
      dropdownLogin: false,
      isSearchMobile: false,
      dropdownProfile: false,
      isMobile: deviceDetector.isMobile,
      browserName: deviceDetector.browserName,
      longitude: '',
      latitude: ''
    }
  },
  methods: {
    toggleDaftar () {
      this.isSend = false
      this.modalDaftar = !this.modalDaftar
    },
    toggleMasuk () {
      this.modalMasuk = !this.modalMasuk
    },
    toHome () {
      this.$router.push('/')
    },
    toRegister () {
      this.toggleDaftar()
      this.toggleMasuk()
    },
    async login () {
      try {
        this.isLoading = true

        const result = await this.$axiosServerGolang({
          url: '/auth/login',
          data: {
            email: this.emailUser,
            password: this.passwordUser,
            agent: this.browserName,
            operation_system: this.OSName,
            longitude: this.longitude,
            latitude: this.latitude,
            source: this.isMobile ? btoa('sekolahmu-webview') : btoa('sekolahmu-web')
          },
          headers: {
            Authorization: process.env.VUE_APP_APP_KEY
          },
          method: 'post'
        })

        this.isLoading = false

        if (result.data.status === 400) {
          this.isError = true
          this.errorMessage = result.data.message
        } else if (result.data.status === 200) {
          let time = 0
          if (this.isKeepLogin === true) {
            time = '30d'
          }
          Toast.fire({
            icon: 'success',
            title: 'Login berhasil'
          })

          this.$cookies.set('access-token', result.data.token.token, time)
          this.$cookies.set('name', result.data.data.name, time)
          this.$cookies.set('avatar', result.data.data.avatar, time)
          this.$cookies.set('profession', result.data.data.profession_name, time)
          this.$cookies.set('user-id', result.data.data.id, time)

          this.isError = false
          this.errorMessage = ''

          this.toggleMasuk()

          this.$store.commit('user/CHANGE_IS_LOGIN', true)
        }
      } catch (err) {
        this.isLoading = false
        console.log(err)
      }
    },
    toggleDropdown () {
      this.dropdown = !this.dropdown
    },
    toggleDropdownLogin () {
      this.dropdownLogin = !this.dropdownLogin
      console.log(123)
    },
    toggleShowPassword () {
      this.isShowPassword = !this.isShowPassword
    },
    toggleSearchMobile () {
      this.isSearchMobile = !this.isSearchMobile
      if (this.isSeachMobile) {
        this.$refs.search.focus()
      }
    },
    toggleDropdownProfile () {
      this.dropdownProfile = !this.dropdownProfile
    },
    searchProgram () {
      console.log('search')
    },
    toProfile () {
      this.$router.push('/profile')
    },
    logout () {
      this.$cookies.remove('access-token')
      this.$cookies.remove('name')
      this.$cookies.remove('avatar')
      this.$cookies.remove('profession')
      this.$store.commit('user/CHANGE_IS_LOGIN', false)
      this.$store.commit('user/CHANGE_IS_LOGIN_FROM_PROGRAM_DETAIL', false)
      this.$router.push('/')
    },
    toRouting (route) {
      this.$router.push(`${route}`)
    }
  },
  computed: {
    imageDropdown () {
      if (this.dropdown) {
        return require('../assets/icons/icon_close_nav.png')
      } else {
        return require('../assets/icons/icon_bars.png')
      }
    },
    imageDropdownLogin () {
      if (this.dropdownLogin) {
        return require('../assets/icons/icon_close_nav.png')
      } else {
        return require('../assets/icons/icon_bars.png')
      }
    },
    inputType () {
      if (this.isShowPassword) {
        return 'text'
      } else {
        return 'password'
      }
    },
    errorInput () {
      if (this.isError) {
        return {
          'error-input': true
        }
      }
    },
    isLogin () {
      return this.$store.state.user.isLogin
    }
  },
  watch: {
    emailUser (newValue, oldValue) {
      if (this.passwordUser && newValue) {
        this.isClickable = true
      } else {
        this.isClickable = false
      }
    },
    passwordUser (newValue, oldValue) {
      if (this.emailUser && newValue) {
        this.isClickable = true
      } else {
        this.isClickable = false
      }
    }
  }
}
</script>

<style scoped>
nav {
  background-color: var(--color-neutral-100);
  height: 54px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.select-search {
  background-image: linear-gradient(45deg, transparent 50%, #e0e0e0 60%),
    linear-gradient(135deg, #e0e0e0 40%, transparent 50%) !important;
  background-position: calc(105% - 30px) 14px, calc(105% - 20px) 14px, 100% 0;
  background-size: 10px 10px, 10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: rgba(0, 0, 0, 0.7);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 0;
  text-indent: 25px;
  width: 150px;
}

.search-bar {
  display: flex;
  border: solid 2px #e0e0e0;
  border-radius: 20px;
  width: 700px;
  margin-left: 40px;
  position: relative;
}

.search-bar img {
  height: 50%;
  position: absolute;
  top: 9px;
  right: 20px;
  cursor: pointer;
}

.input-search {
  border: 0;
  border-left: solid 2px #e0e0e0;
  width: 70%;
  text-indent: 10px;
}

.search-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
}

.search-bar2 {
  display: flex;
  border: solid 2px #e0e0e0;
  border-radius: 20px;
  width: 80%;
  position: relative;
  height: 100%;
}

.search-bar2 img {
  height: 55%;
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
}

.input-search2 {
  border: 0;
  width: 80%;
  text-indent: 10px;
  margin-left: 10px;
}

.search-cancel {
  color: rgba(0, 0, 0, 0.5);
}

.navbar {
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
}

#nav-collapse {
  position: absolute;
  top: 53px;
  width: 100%;
  background-color: var(--color-neutral-100);
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#nav-collapse-profile {
  position: absolute;
  top: 53px;
  width: 20%;
  background-color: var(--color-neutral-100);
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 10px;
}

.inside-profile {
  align-items: center;
  justify-content: center;
  margin: 25px;
}

.image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.image-wrapper img {
  width: 50px;
  object-fit: contain;
  border-radius: 100%;
}

.user-info {
  word-break: break-word;
  margin-left: 14px;
}

.inside-name {
  color: var(--color-persian-blue-900);
}

.inside-profession {
  color: var(--color-deep-lemon-500);
  font-weight: 600;
  font-size: 14px;
}

.inside-profile .nav-menus {
  margin: 10px 0px;
}

.inside-collapse {
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.inside-collapse .nav-menus {
  margin: 25px 0px;
  /* padding-bottom: 25px */
}

.inside-collapse img {
  width: 80px;
  border-radius: 100%;
  margin-top: 25px;
}

/* #inside-collapse .button-masuk {
  border: 1px solid var(--color-persian-blue-900);
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  padding:5px 33px;
  margin-bottom: 15px
}

#inside-collapse .button-masuk:hover {
  background-color: var(--color-persian-blue-900);
}

#inside-collapse .button-masuk:hover .text-inside-button {
  color: var(--color-neutral-100);
}

#inside-collapse .button-daftar {
  border-radius: 20px;
  background: var(--color-deep-lemon-500);
  border: 1px solid var(--color-deep-lemon-500);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px 33px;
  margin-bottom: 25px
}
#inside-collapse .button-daftar:hover {
  border: 1px solid var(--color-deep-lemon-500);
  box-sizing: border-box;
  background-color: var(--color-neutral-100);
}

#inside-collapse .button-daftar:hover .text-inside-button {
  color: var(--color-deep-lemon-500);
} */

.button-masuk-new {
  background-color: var(--color-persian-blue-500);
  color: white;
  border-radius: 30px;
  padding: 7px 23px;
  cursor: pointer;
  border: 1px solid var(--color-persian-blue-500);
  box-sizing: border-box;
  transition: 0.3s;
}

.button-masuk-new:hover {
  color: var(--color-persian-blue-500);
  background-color: var(--color-neutral-100);
}

#nav-burger {
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#nav-burger img {
  height: 20px;
  transition: 0.3s;
}

.left-nav {
  display: flex;
  align-items: center;
}

.right-nav {
  display: flex;
  align-items: center;
}

.right-nav-wrapper {
  display: flex;
  align-items: center;
}

#nav-menu {
  display: flex;
  margin-left: 22px;
}

.nav-menus {
  margin-left: 20px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  cursor: pointer;
  /* font-family: 'Gotham Book' */
}

#dropdown-tentang-kami {
  padding: 0px 20px;
  width: 100%;
}

.menu-dropdown {
  color: var(--color-persian-blue-900);
  margin: 6px 0px;
  cursor: pointer;
}

.menu-dropdown:hover {
  text-decoration: underline;
}

.nav-programmu {
  color: #808080;
  margin-right: 30px;
  cursor: pointer;
}

.image-user {
  width: 40px;
  border-radius: 50px;
  cursor: pointer;
}

#button-masuk {
  border: 1px solid var(--color-persian-blue-900);
  box-sizing: border-box;
  border-radius: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83px;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
}

#button-masuk:hover {
  background-color: var(--color-persian-blue-900);
}

#button-masuk:hover .text-inside-button {
  color: var(--color-neutral-100);
}

.text-inside-button {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-persian-blue-900);
  cursor: pointer;
  transition: 0.3s;
}

#button-daftar {
  border-radius: 20px;
  background: var(--color-deep-lemon-500);
  margin-left: 5px;
  border: 1px solid var(--color-deep-lemon-500);
  box-sizing: border-box;
  width: 83px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
#button-daftar:hover {
  border: 1px solid var(--color-deep-lemon-500);
  box-sizing: border-box;
  background-color: var(--color-neutral-100);
}

#button-daftar:hover .text-inside-button {
  color: var(--color-deep-lemon-500);
}

.modal-test {
  background-color: red;
  color: green;
}

.modal-body {
  display: flex;
  flex-direction: column;
}

.icon-close {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
}

.modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #172d5c;
  display: flex;
  justify-content: center;
}

.modal-description {
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
  align-items: center;
}

.error-message {
  color: #eb5757;
  margin-top: 0px;
}

.modal-description span {
  color: var(--color-deep-lemon-500);
  cursor: pointer;
  margin-left: 5px;
}

.input-email {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 20px;
}

.input-masuk {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 10px;
}

.error-input {
  border: 1px solid #eb5757;
}

.password-wrapper {
  position: relative;
}

.icon-password {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 19px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
}

.input-checkbox {
  display: flex;
  align-items: center;
  border-radius: 0;
  margin-top: 13px;
  margin-bottom: 13px;
}

.checkbox-rule {
  margin-right: 10px;
}

.rule-text {
  color: #bdbdbd;
}

.button-sign {
  background-color: var(--color-persian-blue-500);
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-neutral-100);
  border-radius: 10px;
}

.button-sign:hover {
  background-color: var(--color-persian-blue-400);
}

.button-sign:active {
  background-color: #236693;
}

.button-sign:disabled {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.3);
}

.modal-footer {
  border: 0;
  display: flex;
  justify-content: center;
}

.modal-footer span {
  color: var(--color-deep-lemon-500);
  cursor: pointer;
}

.toggle-search {
  width: 24px;
  margin-right: 30px;
  cursor: pointer;
}

@media screen and (min-width: 991px) {
  #nav-burger {
    display: none;
  }

  #nav-collapse {
    display: none;
  }

  .toggle-search {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .search-bar {
    width: 550px;
  }
}

@media screen and (max-width: 991px) {
  .right-nav-wrapper {
    display: none;
  }

  #nav-menu {
    display: none;
  }

  .search-bar {
    display: none;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
