import API from '@/api'

const coachmark = {
  namespaced: true,
  state: {
    coachmarkLists: []
  },
  mutations: {
    UPDATE_COACHMARK_LIST (state, payload) {
      state.coachmarkLists = payload
    }
  },
  actions: {
    checkCoachmark ({ state }, payload) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.coachmarkLists.find(item => item === payload))
        }, 10)
      })
    },
    async getCoachmarkConfig ({ commit, rootState, state }, payload) {
      if (state.coachmarkLists.length === 0 || payload?.force) {
        const data = await API.coachmark.getCoachmarkConfig({
          accessToken: rootState.storage.accessToken
        })

        if (data.status === 200) {
          commit('UPDATE_COACHMARK_LIST', data.data)
        }
      }
    },
    async updateCoachmarkConfig ({ commit, rootState, state }, { name, status = true }) {
      const data = await API.coachmark.updateCoachmarkConfig({
        accessToken: rootState.storage.accessToken,
        payload: {
          platform: 'sekolahmu',
          name,
          status,
          device: 'web',
          user_id: rootState.storage.userId
        }
      })

      if (data.status === 200) {
        let newLists = []

        if (status) {
          newLists = [...state.coachmarkLists, name]
        } else {
          newLists = state.coachmarkLists.filter(e => e !== name)
        }

        commit('UPDATE_COACHMARK_LIST', newLists)
      }
    }
  }
}

export default coachmark
