<template>
  <button
    :class="[`${fontClass}`, `button-${variant || 'primary'}-local`, `button-${size}-local`, { 'pill-button': pill, 'w-100': block, 'button-secondary-padding': secondaryPadding}]"
    :style="{
      '--disabled-link-color': `var(${disabledLinkColor});`,
      'max-width': `${buttonWidthFit ? 'fit-content' : 'unset'}`
    }"
    @click.stop="onClick()"
    :disabled="isLoading || isDisabled"
  >
    <slot></slot> <b-spinner v-if="isLoading" small></b-spinner>
  </button>
</template>

<script>
export default {
  name: 'ButtonAtom',
  props: {
    variant: String,
    size: {
      type: String,
      default: 'small',
      validator: (value) => ['small', 'medium', 'large', 'extra-large'].indexOf(value) !== -1
    },
    to: [String, Object],
    href: String,
    target: {
      type: String,
      default: '_self'
    },
    withLogEvent: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    pill: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    disabledLinkColor: {
      type: String,
      default: '--disable-color'
    },
    buttonWidthFit: {
      type: Boolean,
      default: false
    },
    isLoading: Boolean,
    secondaryPadding: {
      type: Boolean,
      default: true
    },
    fontClass: {
      type: String,
      default: 'font-label-1'
    }
  },
  methods: {
    onClick () {
      let routeData = this.target && this.target === '_blank'
        ? this.$router.resolve({ path: this.to })
        : null

      this.withLogEvent && this.$emit('handleLogEvent')
      switch (true) {
        case !!this.to && !routeData:
          this.$router.push(this.to)
          break

        case !!this.href || !!routeData:
          window.open(routeData?.href || this.href, this.target)
          break

        default:
          this.$emit('onClick')
          break
      }
    }
  }
}
</script>

<style>
/* primary button style */
.button-primary-local {
  background: var(--color-persian-blue-500);
  border: 1px solid var(--color-persian-blue-500);
  box-sizing: border-box;
  border-radius: 48px;
  padding: 6px 20px;
  color: var(--color-neutral-100);
  transition: 0.3s;
}

.button-primary-local:hover:enabled {
  color: var(--color-neutral-100);
  background: var(--color-persian-blue-400);
  border: 1px solid var(--color-persian-blue-400);
}

/* secondary button style */
.button-secondary-local {
  background: var(--color-neutral-100);
  border: 1px solid var(--color-persian-blue-500);
  box-sizing: border-box;
  border-radius: 48px;
  color: var(--color-persian-blue-500);
  transition: 0.3s;
}

.button-secondary-padding {
  padding: 6px 20px;
}

.button-secondary-local:hover:enabled {
  color: var(--color-persian-blue-400);
  border: 1px solid var(--color-persian-blue-400);
}

/* optional button style */
.button-optional-local {
  background: var(--color-deep-lemon-500);
  border: 1px solid var(--color-deep-lemon-500);
  color: var(--color-neutral-100);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 20px;
  font-weight: 600;
  /* filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1)); */
  transition: 0.3s;
}

.button-optional-local:hover:enabled {
  background: var(--color-neutral-100);
  color: var(--color-deep-lemon-500);
}

/* link button style */
.button-link-local {
  background: none;
  outline: none;
  border: none;
  padding: 6px 0;
  color: var(--color-persian-blue-500);
}

.button-link-local:hover:enabled {
  text-decoration: none;
  cursor: pointer;
}

.button-link-local:disabled {
  color: var(--color-text-300);
}

.button-white-solid {
  background: var(--color-neutral-100);
  border: 1px solid var(--color-neutral-100);
  box-sizing: border-box;
  border-radius: 20px;
  padding: 5px 20px;
  color: #333333;
  font-weight: 600;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

/* Remove outline */
.button-secondary-local:focus, .button-primary-local:focus, .button-optional-local:focus, .button-link-local:focus {
  outline:0 !important;
  box-shadow: none !important;
}

/* disabled button style */
.button-secondary-local:disabled {
  border: 2px solid var(--color-neutral-500);
  background: var(--color-neutral-500);
  color: var(--color-ui-text-placeholder);
}

.button-primary-local:disabled, .button-optional-local:disabled {
  border: 1px solid var(--color-neutral-500);
  color: var(--color-neutral-800);
  background: var(--color-neutral-500);
}

/* pill button style */
.pill-button {
  border-radius: 17.5px !important;
}

/** sizes variant, copied from design system,
for style compatibility with existing component */
.button-small-local {
  min-height: 40px;
}
.button-medium-local {
  min-height: 44px;
}
.button-large-local {
  min-height: 48px;
}
.button-extra-large-local {
  min-height: 48px;
}
</style>
