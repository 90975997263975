export default {
  methods: {
    appendParam (url, paramKey = '', paramValue = false) {
      if (paramValue) {
        let linker = '&'

        if (url.endsWith('&') || url.endsWith('?')) {
          linker = ''
        }

        if (!(url.endsWith('&') || url.endsWith('?')) && !url.includes('?')) {
          linker = '?'
        }

        return `${url}${linker}${paramKey}=${paramValue}`
      }
      return url
    }
  }
}
