<template>
  <div id="navbar-icon-programmu" class="wrapper">
    <div class="position-relative">
      <img class="icon-image" src="https://cdn.sekolah.mu/assets/v2/menu-icon/daftar-pilihan.svg" alt="Sekolah.mu">
      <div class="counter gotham-book" v-if="badgeCounter">{{ badgeCounter }}</div>
    </div>
    <div class="icon-wording">
      <p class="font-body-4">Daftar Pilihan</p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'NavbarIconCart',
  computed: {
    ...mapState('notification', ['shoppingCartCounter']),
    badgeCounter () {
      if (this.shoppingCartCounter < 100) {
        return this.shoppingCartCounter
      } else {
        return '99+'
      }
    }
  }
}
</script>
<style scoped>
h5, p{
  margin: 0;
}
#navbar-icon-programmu{
  text-align: center;
  cursor: pointer;
}
.icon-wording{
  line-height: 16px;
  white-space: nowrap;
  color: var(--color-neutral-800);
  margin-top: 2px;
}
.icon-image {
  height: 24px;
  width: 24px;
}
.counter{
  position: absolute;
  top: -3px;
  left: 39px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  background: var(--color-crimson-500);
  color: var(--color-neutral-100);
  border-radius: 32px;
  padding: 0px 4.5px;
  border: solid 1px var(--color-neutral-100);
}
@media screen and (max-width: 921px) {
  .icon-wording {
    display: none;
  }
  .counter {
    left: 12px;
  }
}
</style>
