const routes = [
  {
    path: '/payment-scheme',
    name: 'BillPaymentSchemeV5',
    meta: {
      title: 'Skema Pembayaran',
      isLoginNeeded: true,
      module: 'payment',
      usingMobileHeader: true,
      hasBackButtonMobile: true,
      backButtonMobileLink: '/daftar-tagihan',
      headerTitle: 'Skema dan Pengaturan Tagihan'
    },
    component: () => import(/* webpackChunkName: "BillPaymentSchemeV5" */ '../views/EducationPayment/Scheme/PaymentScheme.vue')
  }
]

export default routes
