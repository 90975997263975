function XSSFilter (htmlString, addWhitelist) {
  let options = {
    whiteList: {
      a: ['target', 'href', 'title', 'class', 'rel', 'style'],
      abbr: ['title', 'class'],
      address: ['class'],
      area: ['shape', 'coords', 'href', 'alt', 'class'],
      article: ['class'],
      aside: ['class'],
      audio: ['autoplay', 'controls', 'loop', 'preload', 'src', 'class'],
      b: ['class'],
      bdi: ['dir', 'class'],
      bdo: ['dir', 'class'],
      big: ['class'],
      blockquote: ['cite', 'class'],
      br: ['class'],
      caption: ['class'],
      center: ['class'],
      cite: ['class'],
      code: ['class'],
      col: ['align', 'valign', 'span', 'width', 'class'],
      colgroup: ['align', 'valign', 'span', 'width', 'class'],
      dd: ['class'],
      del: ['datetime', 'class'],
      details: ['open', 'class'],
      div: ['class', 'style'],
      dl: ['class'],
      dt: ['class'],
      em: ['class'],
      font: ['color', 'size', 'face', 'class'],
      footer: ['class'],
      figure: ['class'],
      h1: ['class'],
      h2: ['class'],
      h3: ['class'],
      h4: ['class'],
      h5: ['class'],
      h6: ['class'],
      header: ['class'],
      hr: ['class'],
      i: ['class'],
      img: ['src', 'alt', 'title', 'width', 'height', 'class', 'style'],
      ins: ['datetime'],
      li: ['class'],
      mark: ['class'],
      nav: ['class'],
      ol: ['class'],
      p: ['class', 'style'],
      pre: ['class'],
      s: ['class'],
      section: ['class'],
      small: ['class'],
      span: ['class'],
      sub: ['class'],
      sup: ['class'],
      strong: ['class'],
      table: ['width', 'border', 'align', 'valign', 'border', 'class'],
      tbody: ['align', 'valign', 'class'],
      td: ['width', 'rowspan', 'colspan', 'align', 'valign', 'class'],
      tfoot: ['align', 'valign', 'class'],
      th: ['width', 'rowspan', 'colspan', 'align', 'valign', 'class'],
      thead: ['align', 'valign', 'class'],
      tr: ['rowspan', 'align', 'valign', 'class'],
      tt: ['class'],
      u: ['class'],
      ul: ['class'],
      video: ['autoplay', 'controls', 'loop', 'preload', 'src', 'height', 'width', 'class'],
      iframe: ['width', 'height', 'src', 'frameborder', 'allowfullscreen', 'class']
    }
  }
  if (addWhitelist) {
    for (let tag in addWhitelist) {
      if (tag in options.whiteList) {
        options.whiteList[tag] = [ ...new Set(options.whiteList[tag].concat(addWhitelist[tag])) ]
      } else {
        options.whiteList[tag] = addWhitelist[tag]
      }
    }
  }
  return window.filterXSS(htmlString, options)
}

export default XSSFilter
