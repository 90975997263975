<template>
  <div class="modal-profile">
    <b-button variant="link" @click.prevent="$emit('toggleProfileUncompleted')">
      <img src="../../assets/icons/icon_close.svg"  />
    </b-button>
    <!-- <font-awesome-icon
      icon="times"
      @click.prevent="$emit('toggleProfileUncompleted')"
      style="position: absolute; top: 10; right: 10; color: rgba(0, 0, 0, 0.3); cursor: pointer;"
    /> -->
    <p class="profile-title gotham-book">Profilmu Belum Lengkap</p>
    <p class="profile-warning-desc">Lengkapi <b>Data Akun</b>, <b>Data Diri</b>, dan <b>Data Lembaga</b> untuk memulai aktivitas program.</p>
    <b-button
      class="button-profile"
      to="/profile"
      @click.native="$route.name.toLowerCase() === 'profile' && $emit('toggleProfileUncompleted')"
    >Lengkapi Profil</b-button>
  </div>
</template>

<script>

export default {
  components: {
  }
}
</script>

<style scoped>
.modal-profile{
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-title{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #172D5C;
}

.profile-warning-desc{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  margin: 15px 0px;
}

.button-profile{
  background: var(--color-persian-blue-500);
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: var(--color-neutral-100);
  border: 1px solid var(--color-persian-blue-500);
  width: 100%;
}
</style>
