const toggleInspectElement = (set) => {
  document.onkeydown = e => {
    if (event.keyCode === 123) {
      console.log('You cannot inspect Element')
      return set
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
      console.log('You cannot inspect Element')
      return set
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
      console.log('You cannot inspect Element')
      return set
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
      console.log('You cannot inspect Element')
      return set
    }
    if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
      console.log('You cannot inspect Element')
      return set
    }
  }
  // prevents right clicking
  document.addEventListener('contextmenu', e => e.preventDefault())
}

export default toggleInspectElement
