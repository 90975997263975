const activityCategory = {
  AKTIVITAS_DISKUSI: 'Diskusi',
  AKTIVITAS_MATERI_BELAJAR: 'Materi',
  AKTIVITAS_PERTEMUAN: 'Pertemuan',
  AKTIVITAS_TUGAS: 'Tugas'
}

const activityCategoryTypes = Object.keys(activityCategory).map((key) => {
  return {
    value: key,
    text: activityCategory[key]
  }
})

export default { activityCategory, activityCategoryTypes }
