<template>
  <topic-wrapper>
    <div class="mb-2">
      <s-tag
        bg-color="--color-eucalyptus-100"
        text-color="--color-eucalyptus-700"
      >
        Topik Tambahan
      </s-tag>
    </div>
    <div class="topic-addon ff-primary">
      <p class="topic-title fs-16 fw-600 text-eucalyptus-700">
        {{data.addon_title}}
      </p>
      <p
        class="topic-addon-description fs-14 mb-2 primary-font-color"
        :class="{ 'two-line-ellipsis': !isFullDescriptionVisible }"
        v-html="$XSSFilter(data.addon_description)"
        v-if="data.addon_description.length"
      >
      </p>
      <div class="fs-14 fw-600 mb-3 text-persian-blue-500 cursor-pointer" @click="toggleFullDescripton" v-if="data.addon_description.length > 80">
        {{toggleDescriptionText}}
        <img
          src="@/assets/icons/activity-v2/chevron-down.svg"
          class="img-fluid toggle-description-icon unrotate-class"
          :class="{ 'rotate-class': isFullDescriptionVisible }"
        />
      </div>
      <span
        class="d-block fs-14 mb-3 fw-400"
        :class="{
          'text-crimson-500': isThresholdQuota,
          'text-gargoyle': !isThresholdQuota
        }"
      >
        {{data.addon_quota_remaining}} Kuota Tersisa
      </span>
      <div class="d-flex justify-content-between">
        <div>
          <span class="d-block text-placeholder fs-12 fw-400">Harga</span>
          <span class="primary-font-color fw-600">+{{formatRupiah(data.addon_prices)}}</span>
        </div>
        <div>
          <s-button
            pill
            variant="secondary"
            class="btn-enroll-addon"
            :disabled="!data.addon_quota_remaining"
            id="enroll-topic-addon"
            @onClick="$store.commit('activityV2/SET_ADDON_BUY_MODAL_VISIBILITY', true)"
          >
            {{joinTopicText}}
          </s-button>
        </div>
      </div>
    </div>
  </topic-wrapper>
</template>
<script>
import TopicWrapper from './TopicWrapper'
import STag from '@/components/atoms/Tags/STag'
import SButton from '@/components/atoms/buttons/SButton'
import { formatRupiahMixin } from '@/mixins/formatRupiahMixin'

export default {
  name: 'TopicAddon',
  mixins: [formatRupiahMixin],
  components: {
    TopicWrapper,
    STag,
    SButton
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isFullDescriptionVisible: false
    }
  },
  computed: {
    isThresholdQuota () {
      return this.data.addon_quota_remaining <= 5
    },
    toggleDescriptionText () {
      return this.isFullDescriptionVisible ? 'Tutup Penjelasan' : 'Selengkapnya'
    },
    joinTopicText () {
      return this.data.addon_quota_remaining ? 'Ikut Topik' : 'Kuota Habis'
    }
  },
  methods: {
    toggleFullDescripton () {
      this.isFullDescriptionVisible = !this.isFullDescriptionVisible
    }
  }
}
</script>
<style scoped>
.topic-addon-description {
  word-break: break-all;
}
</style>
