/*
  @param
  platform: String

  @query
  keyword: String - [suggestion]
  search: String - [program, paket kelas]
  platform: String - [suggestion]
  platinum_id: Int
*/

import objectToQueryParams from './objectToQueryParams'
const SRO = {
  SUGGESTION: 'suggestion',
  PROGRAM: 'program',
  LEARNING_PACKAGE: 'learning-package'
}

export async function generateSuggestionURL (env, type, { param, query }) {
  const isDev = ['localhost', 'development'].includes(env)
  let baseURL = `/discovery${isDev ? '/' : '/sro/'}`
  if (isDev) {
    delete query.platform_id
    if (type === SRO.SUGGESTION) {
      delete query.platform
    }
  }
  const queries = await objectToQueryParams(query)
  switch (type) {
    case SRO.SUGGESTION:
      baseURL = `${baseURL}suggestion-keyword`
      break
    case SRO.PROGRAM:
      baseURL = `${baseURL}program-recommendation/${param}`
      break
    case SRO.LEARNING_PACKAGE:
      baseURL = `${baseURL}learning-package-recommendation/${param}`
      break
  }
  return `${baseURL}${queries}`
}
