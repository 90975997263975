<template>
  <b-modal
    id="activity-rating-modal"
    hide-footer
    hide-header
    centered
    size="rating"
    class="modal-rating"
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="modal-rating">
      <div v-if="!modalRating && !isStartRating">
        <div class="modal-image">
          <img src="https://cdn.sekolah.mu/assets/v3/account/ulas-kami.svg" />
        </div>
        <div class="modal-title font-body-1-new">{{ ratingTitle }}</div>
        <div class="modal-description font-paragraph-5">{{ ratingDescription }}</div>
        <s-button id="button-rating" @onClick="modalRating = true">
          Beri Ulasan Kelas
        </s-button>
      </div>
      <div v-if="modalRating || isStartRating">
        <div class="modal-rating-title font-body-1-new">Berikan Ulasan<br>Untuk Kelas Ini</div>
        <div class="star-wrapper">
          <img
            src="https://cdn.sekolah.mu/assets/v3/action/review-star.svg"
            class="rating-stars"
            v-for="num in starTrue"
            :key="num"
            :id="`active-star-${num}`"
            @click="clickStarTrue(num)"
          />
          <img
            src="https://cdn.sekolah.mu/assets/v3/action/review-star-false.svg"
            class="rating-stars-false"
            v-for="num in starFalse"
            :key="num + 5"
            :id="`inactive-star-${num}`"
            @click="clickStarFalse(num)"
          />
        </div>
        <s-textarea
          class="text-area-rating w-100"
          rows="5"
          label="Masukkan ulasanmu"
          v-model="review"
        ></s-textarea>

        <s-button
          id="rating-action-button"
          class="w-100 s-mt-4"
          @onClick="isLoading = true"
          :disabled="!isEnabledSendButton"
          :is-loading="isLoading"
        >
          Kirim Ulasan
        </s-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import deviceDetector from 'mobile-device-detect'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import activityV2SidebarMixin from '@/mixins/activityV2SidebarMixin'
import ProgramActivityAPI from '@/api/program-activity'
import regex from '@/constants/regex'

export default {
  name: 'ActivityRatingModal',
  mixins: [activityV2SidebarMixin],
  components: {
    SButton: () => import('@/components/atoms/buttons/SButton.vue')
  },
  props: {
    ratingType: {
      type: String,
      default: 'done'
    },
    dataAktivitas: {
      type: Object,
      default: () => ({})
    },
    isStartRating: {
      type: Boolean,
      default: false
    },
    additionalData: Object
  },
  data () {
    return {
      modalRating: false,
      starTrue: 0,
      starFalse: 5,
      review: '',
      isLoading: false
    }
  },
  computed: {
    ...mapState('activityV2', ['activityDashboard', 'report']),
    ...mapState('storage', ['accessToken']),
    isEnabledSendButton () {
      const trimmedReview = this.review.trim()
      return this.starTrue > 0 && trimmedReview.length >= 5 && regex.ALPHANUMERIC_QUESTION_EQUAL_DOTS.test(trimmedReview)
    },
    ratingTitle () {
      // temp return same string while waiting for product confirmation
      if (this.ratingType === 'done') {
        return 'Selamat! Kamu Sudah Menyelesaikan Kelas'
      } else {
        return 'Selamat! Kamu Sudah Menyelesaikan Kelas'
      }
    },
    ratingDescription () {
      if (this.ratingType === 'done') {
        return 'Terima kasih sudah menyelesaikan kelas ini. Beri ulasan untuk kelas ini agar kami dapat memberikan pengalaman belajar yang lebih baik.'
      } else {
        return 'Terima kasih sudah menyelesaikan kelas ini. Beri ulasan untuk kelas ini agar kami dapat memberikan pengalaman belajar yang lebih baik.'
      }
    }
  },
  methods: {
    clickStarTrue (num) {
      this.starTrue = num
      this.starFalse = 5 - num
    },
    clickStarFalse (num) {
      this.starTrue = this.starTrue + num
      this.starFalse = 5 - this.starTrue
    },
    async postReview () {
      try {
        const { scoring_date } = this.report
        const { id, percentage_progress, is_rated, is_available_to_rate, pmo_id } = this.activityDashboard
        const data = await ProgramActivityAPI.sendReview({
          Authorization: this.accessToken,
          payload: {
            program_id: id,
            rating: {
              value: this.starTrue,
              comment: this.review
            },
            source: deviceDetector.isMobile ? 'web-mobile' : 'web',
            percentage_progress,
            scoring_date,
            pmo_id,
            is_available_to_rate,
            is_rated
          }
        })

        if (data.status === 200) {
          this.$bvModal.hide('activity-rating-modal')
          // switch to Assessment tab
          this.switchToTab('assessment')
          this.$emit('posted')
        } else if (data.status === 116) {
          const result = await Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-primary btn-block font-weight-bold',
              cancelButton: 'd-none'
            },
            buttonsStyling: false
          }).fire({
            width: 400,
            title: `<p style="color: #1A2E5A;font-size: 20px;">Gagal Mengirim Ulasan</p>`,
            html: `<p style="color: #828282;font-size: 15px;">${data.message}</p>`,
            confirmButtonText: 'Tutup'
          })
          result && this.$bvModal.hide('activity-rating-modal')
        } else {
          throw data
        }
      } catch (error) {
        this.$store.dispatch('storage/checkToken', error)
      }
    }
  },
  watch: {
    isLoading (value) {
      value && this.$nextTick(this.postReview)
    }
  }
}
</script>

<style scoped>
.modal-rating {
  padding: 15px;
}

.modal-image {
  text-align: center;
}

.modal-title {
text-align: center;
  /* undefined color will confirm in next develop */
  color: var(--color-persian-blue-900);
  margin-top: 10px;
}

.modal-description {
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 10px;
}

.modal-program-title {
  /* undefined color will confirm in next develop */
  color: #172d5c;
  font-size: 20px;
  text-align: center;
}

.modal-subtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.modal-subtitle span {
  font-weight: 600;
}

.modal-rating-title {
  color: var(--color-persian-blue-900);
  text-align: center;
}

#button-rating {
  width: 100%;
  margin-top: 20px;
}

.star-wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.rating-stars {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.rating-stars-false {
  width: 32px;
  height: 32px;
  padding-left: 3px;
  padding-top: 3px;
  cursor: pointer;
}

.text-area-rating {
  margin-top: 20px;
}

.button-kirim {
  width: 100%;
  background: var(--color-ui-element-blue);
  border-radius: 10px;
  color: var(--color-neutral-100);
  font-weight: 600;
  padding: 10px;
  border: 1px solid var(--color-ui-element-blue);
  margin-top: 20px;
  transition: 0.3s;
}

.button-kirim:hover:enabled {
  color: var(--color-ui-element-blue);
  background-color: var(--color-neutral-100);
}

.button-kirim-disabled {
  width: 100%;
  background: var(--color-neutral-500);
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  padding: 10px;
  border: 1px solid var(--color-neutral-500);
  margin-top: 20px;
  transition: 0.3s;
  cursor: default;
}

.btn-primary {
  background: var(--color-ui-element-blue) !important;
}
</style>
