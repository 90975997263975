const tabs = ['activity', 'announcement', 'assessment']

export default {
  methods: {
    getIndexOfTab (name) {
      return tabs.indexOf(name)
    },
    toggleSidebar (eventName) {
      this.$store.commit('activityV2/TOGGLE_SIDEBAR', !this.isSidebarVisible)
      if (eventName) {
        this.logEventAnalytic(eventName, { screen_url: window.location.href })
      }
    },
    switchToTab (tabName) {
      this.activeTabIndex = this.getIndexOfTab(tabName)
    },
    logEventAnalytic (eventName, additionalField = {}, tab) {
      this.$store.dispatch('logEventTracker', {
        module: 'ACTIVITY_V2',
        key: eventName,
        additionalField
      })
      if (eventName !== 'halaman_belajar_tanyamu_click' && tab !== this.sidebarActiveTab) {
        this.$store.commit('activityV2/SET_CHAT_BOX', false)
      }
    }
  },
  computed: {
    isSidebarVisible () {
      return this.$store.state.activityV2.isSidebarVisible
    },
    activeTabIndex: {
      get () {
        return this.$store.state.activityV2.sidebarActiveTab
      },
      set (value) {
        this.$store.commit('activityV2/SET_SIDEBAR_ACTIVE_TAB', value)
      }
    },
    sidebarActiveTab () {
      return tabs[this.activeTabIndex]
    }
  }
}
