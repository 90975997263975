<template>
  <div class="reply-comment-container" style="position: relative;">
    <div class="modal-close-button" style="position: absolute; top: 0px; right: 10px;">
      <img @click.prevent="closeReplyMessage" style="cursor: pointer; width: 10px;" src="../../assets/icons/icon_close.svg" />
    </div>
    <div class="reply-box-left"></div>
    <div class="reply-box-right">
      <div class="replied-name">{{ replyComment.username }}</div>
      <div class="reply-content">
        <span v-if="replyComment.payload.url" class="text-right" style="max-width: 100px">
          <template v-if="replyComment.payload.url.toLowerCase().match(/\.(svg)$/)">
            <div class="inter-medium_small-normal unsupported-svg">File svg tidak ditampilkan.</div>
          </template>
          <template v-else>
            <a :href="replyComment.payload.url" target="_blank" rel="noopener noreferrer">
              <b-img
                :style="imageStyle"
                :src="replyComment.payload.url"
                v-if="
                  replyComment.payload.url &&
                    replyComment.payload.url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/)
                "
              ></b-img>
              <audio
                controls
                :style="audioStyle"
                :src="replyComment.payload.url"
                v-else-if="
                  replyComment.payload.url &&
                    replyComment.payload.url.match(/\.(m4a|mp3|ogg})$/)
                "
              ></audio>
              <video
                controls
                :style="videoStyle"
                :src="replyComment.payload.url"
                v-else-if="
                  replyComment.payload.url &&
                    replyComment.payload.url.match(/\.(mp4|avi|3gp})$/)
                "
              ></video>
              <span v-else>
                {{ replyComment.payload.file_name }}
                <b-icon-download class="ml-2"></b-icon-download>
              </span>
            </a>
          </template>
          <div :style="captionStyle">{{ replyComment.payload.caption }}</div>
        </span>
        <span :style="textStyle" v-else v-html="urlify(replyComment.message)"></span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    replyComment: Object
  },
  data () {
    return {
      imageStyle: {
        maxWidth: '300px'
      },
      textStyle: {
        whiteSpace: 'pre-line',
        wordBreak: 'break-word'
      },
      audioStyle: {},
      videoStyle: {},
      captionStyle: {
        whiteSpace: 'pre-line',
        textAlign: 'left',
        marginTop: '8px',
        wordBreak: 'break-word'
      }
    }
  },
  methods: {
    closeReplyMessage () {
      this.$emit('closeReplyMessage')
    },
    urlify (text) {
      let filtered = this.$XSSFilter(text)
      const urlRegex = /(https?:\/\/[^\s]+)/g
      return filtered.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>'
      })
    }
  },
  created () {
    this.imageStyle.maxWidth = '125px'
    this.videoStyle.maxWidth = '125px'
  }
}
</script>
<style scoped>
.reply-comment-container{
  background: #FAFAFA;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
}
.reply-box-left{
  background: #FEC203;
  width: 3px;
  min-width: 3px !important;
  margin-right: 7px;
}
.reply-comment-container .replied-name{
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-persian-blue-900);
  margin-bottom: 4px;
}
.reply-comment-container .reply-content{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #333333;
  max-height: 100px;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
}
.reply-comment-container .reply-content::-webkit-scrollbar{
  display: none;
}
.unsupported-svg{
  color: var(--color-neutral-800);
  background: var(--color-neutral-700);
  padding: 2px 14px;
  border-radius: 24px;
}
</style>
