import deviceDetector from 'mobile-device-detect'

export default {
  data () {
    return {
      latitude: '',
      longitude: '',
      isMobile: deviceDetector.isMobile,
      browserName: deviceDetector.browserName
    }
  },
  computed: {
    OSName () {
      const OSName = `${deviceDetector.browserName} ${deviceDetector.browserVersion}`
      return OSName
    }
  },
  methods: {
    getCoords () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = `${position.coords.latitude}`
        this.longitude = `${position.coords.longitude}`
      })
    }
  }
}
