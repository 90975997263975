import store from '../store'

const Route = [
  // Portofolio Public Redirect
  {
    path: '/u/:username',
    name: 'Portofolio Redirect',
    meta: {
      title: 'Portofolio'
    },
    component: () => import(/* webpackChunkName: 'portofolioRedirect' */ '../views/Redirect.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('portofolioV2/CHANGE_IS_PUBLIC_MODE', true)
      next(`/u/${to.params.username}/portofolio`)
    }
  },

  // Portofolio V2 Route
  {
    path: '/portofolio/:type',
    name: 'Base Portofolio',
    meta: {
      title: 'Portofolio',
      layout: 'no-footer'
    },
    component: () => import(/* webpackChunkName: 'portofolioUser' */ '../views/portofolioV2/Portofolio.vue'),
    beforeEnter: (to, from, next) => {
      // if (!store.state.user.isLogin) {
      //   next('/')
      // }
      store.commit('portofolioV2/CHANGE_IS_PUBLIC_MODE', false)
      next()
    }
  },
  {
    path: '/portofolio/aktivitas/:id',
    name: 'Portofolio Aktivitas',
    meta: {
      title: 'Portofolio Aktivitas',
      layout: 'no-footer'
    },
    component: () => import(/* webpackChunkName: 'portofolioAktivitasUser' */ '../views/portofolioV2/PortofolioAktivitas.vue'),
    beforeEnter: (to, from, next) => {
      // if (!store.state.user.isLogin) {
      //   next('/')
      // }
      store.commit('portofolioV2/CHANGE_IS_PUBLIC_MODE', false)
      next()
    }
  },
  {
    path: '/portofolio/aktivitas/:id/:item_id',
    name: 'Detail Portofolio Aktivitas',
    meta: {
      title: 'Portofolio Aktivitas',
      layout: 'no-footer'
    },
    component: () => import(/* webpackChunkName: 'detailPortofolioAktivitasUser' */ '../views/portofolioV2/PortofolioAktivitasDetail.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('portofolioV2/CHANGE_IS_PUBLIC_MODE', false)
      next()
    }
  },

  // Public Portofolio V2 Route
  {
    path: '/u/:username/:type',
    name: 'Base Public Portofolio',
    meta: {
      title: 'Portofolio',
      layout: 'no-footer'
    },
    component: () => import(/* webpackChunkName: 'publicPortofolio' */ '../views/portofolioV2/PublicPortofolio.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('portofolioV2/CHANGE_IS_PUBLIC_MODE', true)
      next()
    }
  },
  {
    path: '/u/:username/aktivitas/:id',
    name: 'Public Portofolio Aktivitas',
    meta: {
      title: 'Portofolio Aktivitas',
      layout: 'no-footer'
    },
    component: () => import(/* webpackChunkName: 'publicPortofolio' */ '../views/portofolioV2/PortofolioAktivitas.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('portofolioV2/CHANGE_IS_PUBLIC_MODE', true)
      next()
    }
  },
  {
    path: '/u/:username/aktivitas/:id/:item_id',
    name: 'Detail Public Portofolio Aktivitas',
    meta: {
      title: 'Portofolio Aktivitas',
      layout: 'no-footer'
    },
    component: () => import(/* webpackChunkName: 'detailPublicPortofolio' */ '../views/portofolioV2/PortofolioAktivitasDetail.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('portofolioV2/CHANGE_IS_PUBLIC_MODE', true)
      next()
    }
  }
]

export default Route
