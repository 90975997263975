<template>
  <div id="web-filter" class="filter-wrapper">
    <div class="filter-title-wrapper">
      <s-button
        id="search-activity-close-btn"
        @click="toggleShowFilter"
        variant="ghost"
      >
        <img src="@/assets/icons/icon_close.svg" alt="close-icon" />
      </s-button>
      <div class="inter-normal-bold">Filter Aktivitas</div>
      <s-button
        id="search-activity-reset-btn"
        class="inter-medium_small-bold"
        variant="ghost"
        @click="resetFilter"
      >
        Reset
      </s-button>
    </div>
    <div class="category-wrapper">
      <div class="inter-normal-bold s-mt-4">Kategori Aktivitas</div>
      <div>
        <b-form-checkbox-group
          id="search-activity-type-checkbox-group"
          class="pointer-checkbox"
          v-model="filterData.selected"
          :options="activityCategoryTypes"
          stacked
        />
      </div>
      <s-button
        id="search-activity-apply-filter-btn"
        class="w-100"
        variant="primary"
        @click="applyFilter"
      >
        Terapkan Filter
      </s-button>
    </div>
  </div>
</template>

<script>
import categoryType from '@/constants/activityCategoryTypes.js'
import activityFilterMixin from '@/mixins/activityV2/filterActivity.js'

export default {
  name: 'SearchActivityWebFilter',
  props: {
    filterData: {
      type: Object,
      default: () => {
        return {
          selected: [],
          isShowFilter: false,
          currentSelectedTotal: 0
        }
      }
    }
  },
  mixins: [activityFilterMixin],
  data () {
    return {
      activityCategoryTypes: categoryType.activityCategoryTypes
    }
  },
  computed: {
    isApplyBtnDisabled () {
      return this.filterData.selected.length === 0
    }
  },
  methods: {
    toggleShowFilter () {
      this.filterData.isShowFilter = !this.filterData.isShowFilter
    },
    resetFilter () {
      this.sendClickEventTracker('hb_aktivitas_reset_filter_click')
      this.filterData.selected = []
      this.applyFilter()
    },
    applyFilter () {
      this.sendClickEventTracker('hb_aktivitas_button_filter_click')
      this.$emit('applyFilter')
      this.filterData.currentSelectedTotal = this.filterData.selected.length
      this.toggleShowFilter()
    }
  }
}
</script>

<style scoped>
.filter-wrapper {
  background-color: var(--color-neutral-100);
  width: 100%;
  z-index: 2;
  box-shadow: 0px 8px 10px 1px rgba(51, 51, 51, 0.1);
  border-radius: 10px;
  padding: 0 0 16px;
}
.filter-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.category-wrapper {
  margin: 0 16px;
}
.pointer-checkbox ::v-deep .custom-control-label {
  cursor: pointer;
}
</style>
