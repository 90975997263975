<template>
  <div id="fun-report-invitation-modals">
    <b-modal
      v-model="isOpenModalFunReportInvitation"
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      size="error-message"
      content-class="modal-content-semi_round"
    >
      <div class="modal-illustration">
        <img src="https://cdn.sekolah.mu/assets/fun-report/open_apps_illustration.svg" alt="Sekolah.mu" class="open-apps">
      </div>
      <div class="description-text">
        <h5 class="font-label-xl-sb title">Buka Aplikasi Sekolah.mu</h5>
        <p class="font-label-md-rg-lh16 subtitle">Yuk, buka aplikasi Sekolah.mu untuk lihat Jurnal Refleksimu di tahun 2021!</p>
      </div>
      <div class="modal-buttons">
        <s-button variant="primary" pill class="goto-apps-button" id="funreport-goto-apps-button" @onClick="openFunReportApps">Buka Aplikasi</s-button>
        <s-button variant="link" class="goto-apps-close" id="funreport-close-button" @onClick="closeFunReportApps">Tutup</s-button>
      </div>
    </b-modal>

    <b-modal
      v-model="isOpenModalFunReportInvitationQR"
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      size="error-message"
      content-class="modal-content-semi_round"
    >
      <div class="close-button">
        <font-awesome-icon icon="times" @click="closeQRInvitationModal" class="times-icon"/>
      </div>
      <div class="qr-code-container">
        <img :src="qrCode" alt="Sekolah.mu" class="qr-code">
      </div>
      <div class="description-text">
        <h5 class="font-label-xl-sb title">Buka Aplikasi Sekolah.mu</h5>
        <p class="font-label-md-rg-lh16 subtitle">Scan QR code di atas untuk lihat jurnal refleksimu di tahun 2021!</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SButton from '@/components/atoms/buttons/SButton.vue'

export default {
  name: 'FunReportInvitationModals',
  components: {
    FontAwesomeIcon,
    SButton
  },
  computed: {
    activeEnv () {
      return this.$store.state.activeEnv
    },
    qrCode () {
      if (this.activeEnv === 'localhost' || this.activeEnv === 'development') return 'https://cdn.sekolah.mu/assets/fun-report/deeplink_fun_report_qr_code_dev.png'
      else if (this.activeEnv === 'staging') return 'https://cdn.sekolah.mu/assets/fun-report/deeplink_fun_report_qr_code_staging.png'
      else return 'https://cdn.sekolah.mu/assets/fun-report/deeplink_fun_report_qr_code.png'
    },
    isOpenModalFunReportInvitation: {
      get () {
        return this.$store.state.funReport.isOpenModalFunReportInvitation
      },
      set (value) {
        this.$store.commit('funReport/CHANGE_IS_OPEN_MODAL_INVITATION', value)
      }
    },
    isOpenModalFunReportInvitationQR: {
      get () {
        return this.$store.state.funReport.isOpenModalFunReportInvitationQR
      },
      set (value) {
        this.$store.commit('funReport/CHANGE_IS_OPEN_MODAL_INVITATION_QR', value)
      }
    }
  },
  methods: {
    closeQRInvitationModal () {
      this.$emit('onCloseModal')
      this.isOpenModalFunReportInvitationQR = false
    },
    closeFunReportApps () {
      this.isOpenModalFunReportInvitation = false
      window.firebaseAnalytics.logEvent('jr_tutup_click', {
        user_profession: this.userInfo.profession_name,
        user_year_level: this.userInfo.year_level_name,
        user_is_parent: !!((this.userInfo.profession_name === 'Orangtua' || this.userInfo.child.length))
      })
    },
    openFunReportApps () {
      window.location.replace(`sekolahmu://funreport`)
      setTimeout(() => {
        if (!document.hidden) {
          window.open(`https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=mu.sekolah.android`, '_blank')
        }
        this.isOpenModalFunReportInvitation = false
      }, 1000)
      window.firebaseAnalytics.logEvent('jr_buka_aplikasi_click', {
        user_profession: this.userInfo.profession_name,
        user_year_level: this.userInfo.year_level_name,
        user_is_parent: !!((this.userInfo.profession_name === 'Orangtua' || this.userInfo.child.length))
      })
    }
  }
}
</script>
<style scoped>
h5, p{
  margin: 0;
}
.close-button{
  text-align: end;
}
.close-button .times-icon{
  cursor: pointer;
  color: var(--color-neutral-700);
}
.qr-code-container{
  width: 100%;
  height: 192px;
  text-align: center;
  margin-bottom: 16px;
}
.qr-code{
  width: 192px;
  height: 192px;
}
.description-text{
  text-align: center;
}
.description-text .title{
  color: var(--color-ui-text-main-title);
  margin-bottom: 8px;
}
.description-text .subtitle{
  color: var(--color-ui-text-primary);
}
.modal-illustration{
  height: 128px;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}
.modal-illustration .open-apps{
  height: 128px;
  width: 128px;
}
.modal-buttons{
  margin-top: 16px;
}
.goto-apps-button{
  margin-bottom: 16px;
}
.goto-apps-button, .goto-apps-close{
  width: 100%;
}
</style>
