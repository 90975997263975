import deviceDetector from 'mobile-device-detect'

export default {
  data () {
    return {
      isMobile: deviceDetector.isMobile
    }
  },
  computed: {
    platinumSubdomain () {
      return this.$store.state.platinumSubdomain
    }
  },
  methods: {
    encodeSource (registerSource) {
      let source = 'sekolahmu-web'
      if (this.isMobile) {
        source = 'sekolahmu-webview'
      }
      if (this.platinumSubdomain) {
        source = `${this.platinumSubdomain}.${source}`
      }
      if (registerSource) {
        source = `${source}-${registerSource}`
      }
      return btoa(source)
    }
  }
}
