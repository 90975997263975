import axiosServerGolang from '@/configs/axiosServerGolang'

const SEKOLAHMU_ID = 1
const platformId = SEKOLAHMU_ID
const baseUrl = '/payment/voucher'

export default {
  async getVoucherList ({ page, itemPerPage, body, accessToken }) {
    const url = `${baseUrl}/public/${platformId}/${page}/${itemPerPage}`

    try {
      const result = await axiosServerGolang({
        method: 'POST',
        url,
        headers: {
          Authorization: accessToken
        },
        data: body
      })
      if (result.status === 200) {
        return result
      } else {
        throw new Error(result)
      }
    } catch (error) {
      return { status: error.status, message: error.message }
    }
  },
  async applyVoucherV5 (accessToken, body, type = 'learning-package-v2') {
    try {
      const response = await axiosServerGolang({
        version: 'v5',
        url: `/payment/apply-voucher?type=${type}`,
        method: 'POST',
        headers: { Authorization: accessToken },
        data: body
      })
      return response.data
    } catch (error) {
      return error
    }
  },
  async deleteVoucherV5 (accessToken, body, type = 'learning-package-v2') {
    try {
      const response = await axiosServerGolang({
        version: 'v5',
        url: `/payment/detach-voucher?type=${type}`,
        method: 'DELETE',
        headers: { Authorization: accessToken },
        data: body
      })
      return response.data
    } catch (error) {
      return error
    }
  }
}
