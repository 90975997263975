export default {
  email: 'suaramu@sekolahmu.co.id',
  phone: '0813-8811-1756',
  whatsapp: 'https://wa.me/6281388111756?text=Halo%2C%20saya%20mau%20tahu%20lebih%20lanjut%20tentang%20Sekolah.mu.'
}

export const smmContact = {
  phone: '0859-0379-5420',
  whatsapp: 'https://wa.me/6285903795420'
}

export const livingEnglishContact = {
  phone: '0811-9530-499',
  whatsapp: 'https://wa.me/628119530499'
}
