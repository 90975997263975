<template>
  <div
    class="tags-atom font-label-sm-sb"
    :style="styleObject"
    :class="classObject"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TagAtom',
  props: {
    bgColor: {
      type: String,
      default: '--color-ui-element-blue'
    },
    textColor: {
      type: String,
      default: '--color-neutral-100'
    },
    customStyle: {
      type: Object,
      default: () => ({})
    },
    customClasses: {
      type: Array,
      default: () => ([])
    },
    borderRadiusSize: {
      type: String,
      default: 'lg',
      validator: (value) => ['sm', 'lg'].includes(value)
    }
  },
  computed: {
    styleObject () {
      return {
        color: this.textColor.includes('#') ? this.textColor : `var(${this.textColor})`,
        backgroundColor: this.bgColor.includes('#') ? this.bgColor : `var(${this.bgColor})`,
        ...this.customStyle
      }
    },
    classObject () {
      return {
        [`tags-atom__radius-${this.borderRadiusSize}`]: true,
        ...this.customClasses
      }
    }
  }
}
</script>

<style scoped>
.tags-atom {
  display: inline-block;
  padding: 4px 12px;
  height: min-content;
  text-align: center;
}

.tags-atom__radius-sm {
  border-radius: 4px;
}

.tags-atom__radius-lg {
  border-radius: 20px;
}
</style>
