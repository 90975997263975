<template>
  <b-dropdown
    id="navbar-others-menu-v2"
    data-title="Menu Lainnya"
    class="others-dropdown-menu"
    right
    variant="link"
    no-caret
    lazy
    @show="$emit('logEventAnalytic', 'halaman_belajar_kebab_menu_click')"
  >
    <template #button-content>
      <font-awesome-icon
        class="activity-icon ellipsis"
        :icon="['fas', 'ellipsis-v']"
      />
    </template>
    <b-dropdown-item
      v-if="isTanyamu"
      class="others-drodpown-item inter-medium_small-normal"
      @click.prevent="showTanyamuKelas"
    >
      <img
        class="activity-icon"
        alt="tanyamu"
        src="https://cdn.sekolah.mu/assets/v2/menu-icon/tanyamu.svg"
      />
      Tanyamu
    </b-dropdown-item>
    <b-dropdown-item
      v-if="hasProgramStudyGroupActivity"
      class="others-drodpown-item inter-medium_small-normal"
      href="#"
      @click.native="showProgramGroupActivityModal(), $emit('logEventAnalytic', 'kelompok_click')"
    >
      <img
        class="activity-icon"
        alt="kelompok-belajar-kelas"
        src="https://cdn.sekolah.mu/assets/icon-mini/halaman-belajar/icon-kelompok-belajar-kelas.svg"
      />
      Kelompok Belajar Kelas
    </b-dropdown-item>
    <b-dropdown-item
      class="others-drodpown-item inter-medium_small-normal"
      v-if="isTanyamu || isMobile"
      @click="showShareModal"
    >
      <img
        class="activity-icon"
        alt="bagikan-aktivitas"
        src="https://cdn.sekolah.mu/assets/icon-mini/halaman-belajar/icon-bagikan-aktivitas.svg"
      />
      Bagikan Aktivitas
    </b-dropdown-item>
    <b-dropdown-item
      class="others-drodpown-item inter-medium_small-normal"
      href="https://panduan.sekolah.mu/"
      target="_blank"
      @click.native="$emit('logEventAnalytic', 'halaman_belajar_panduan_click')"
    >
      <img
        class="activity-icon"
        src="https://cdn.sekolah.mu/assets/v2/menu-icon/bantuan.svg"
        alt="bantuan"
      />
      Panduan
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import activityV2GeneralMixin from '@/mixins/activityV2/general'
import { mapState } from 'vuex'

export default {
  name: 'OthersDropdownMenu',
  mixins: [activityV2GeneralMixin],
  props: {
    isTanyamu: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    facilRoomId: {
      type: Number,
      default: 0
    },
    isTanyamuClosed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FontAwesomeIcon
  },
  computed: {
    ...mapState('user', ['userInfo', 'isLogin']),
    hasProgramStudyGroupActivity () {
      return this.$store.state.activityV2.validate.has_program_study_group_activity
    }
  },
  methods: {
    showShareModal () {
      this.$emit('showShareModal')
    },
    showTanyamuKelas () {
      this.$emit('toggleChatBox')
    },
    showProgramGroupActivityModal () {
      if (this.isLogin) {
        window.firebaseAnalytics.logEvent('users_belajar_click', {
          user_id: this.userInfo.id,
          user_is_admin: this.userInfo.is_admin,
          source: this.isMobile ? 'sekolahmu-webview' : 'sekolahmu-web'
        })
      }
      this.$store.commit('activityV2/PROGRAM_GROUP_ACTIVITY_MODAL_TYPE', 'PROGRAM_CLASS')
      this.$store.commit('activityV2/TOGGLE_PROGRAM_GROUP_ACTIVITY_MODAL', true)
    }
  }
}
</script>

<style scoped>
.others-drodpown-item {
  padding: 12px 0 0 0;
}
.others-drodpown-item .activity-icon {
  width: 20px;
  height: 20px;
}
.activity-icon.ellipsis {
  color: var(--color-persian-blue-400);
  font-size: 18px;
}
</style>
