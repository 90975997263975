function setupPushNotifTanyamuBody (comment) {
  if (comment.payload.url) {
    if (comment.payload.url.match(/\.(jpeg|jpg|gif|png)$/)) {
      return {
        body: comment.payload.caption || `${comment.username} mengirimkan file gambar.`,
        image: comment.payload.url
      }
    } else if (comment.payload.url.match(/\.(m4a|mp3|ogg})$/)) {
      return {
        body: comment.payload.caption || `${comment.username} mengirimkan file audio.`,
        image: ''
      }
    } else if (comment.payload.url.match(/\.(mp4|avi|3gp})$/)) {
      return {
        body: comment.payload.caption || `${comment.username} mengirimkan file video.`,
        image: ''
      }
    } else {
      return {
        body: comment.payload.caption || `${comment.username} mengirimkan file.`,
        image: ''
      }
    }
  } else {
    let body = ''
    if (comment.message.length > 0) {
      body = comment.username + ': ' + comment.message
    }
    return {
      body,
      image: ''
    }
  }
}

export default setupPushNotifTanyamuBody
