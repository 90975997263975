<template>
  <div id="profile-header" class="profile-header">
    <div class="account-info-container">
      <h5 class="font-subtitle-sb-2 account-name">Hai, {{ userInfo.parent_login_id ? parentLogin.parent_name : userInfo.name }}</h5>
      <p class="font-label-3 account-state">Kamu sedang berada di akun <span :class="userInfo.parent_login_id ? 'font-label-2' :''">{{ userInfo.parent_login_id ? userInfo.name : 'utama' }}</span>.</p>
      <div class="account-avatar-container">
        <b-img-lazy :src="userInfo.avatar || ''" alt="sekolah.mu" class="account-avatar"></b-img-lazy>
      </div>
      <h5 class="font-subtitle-sb-4 account-avatar-name">{{ userInfo.name }}</h5>
      <div class="font-subtitle-sb-5 user-year-level-type">
        {{ yearLevelType }}
      </div>
    </div>

    <div id="profile-relation-list" class="relation-list-container">
      <div class="font-label-3 relation-list-toggle" id="relation-list-toggle" @click="toggleDropdownRelation" v-if="isShowRelationList">
        {{ listToggleText }}
        <i :class="{ 'chevron-down': !isOpenRelationList, 'chevron-up': isOpenRelationList }"></i>
      </div>
      <b-collapse
        v-model="isOpenRelationList"
        class="relation-list-collapse"
      >
        <template v-if="yearLevelType === 'Dewasa & Orangtua'">
          <div class="relation-list" v-for="(item, index) in childList" :key="index" @click="switchAccount(item.child_user_id)">
            <b-img-lazy :src="item.child_avatar" alt="sekolah.mu" class="relation-list-avatar"></b-img-lazy>
            <h5 class="font-subtitle-sb-4 relation-list-name">{{ item.child_name }}</h5>
          </div>
          <div class="font-subtitle-sb-4 add-child-route" id="add-child-route" @click="gotoRelationPage" v-if="childList.length < 5">
            <img src="@/assets/icons/add_relation_icon.svg" alt="sekolah.mu" class="add-relation-icon">
            Tambah Akun Anak
          </div>
        </template>
        <template v-else-if="yearLevelType === 'Anak/Remaja' && userInfo.parent_login_id !== 0">
          <div class="relation-list parent-avatar" @click="switchAccount(parentLogin.parent_user_id)">
            <div>
              <b-img-lazy :src="parentLogin.parent_avatar" alt="sekolah.mu" class="relation-list-avatar"></b-img-lazy>
              <div class=green-dot></div>
            </div>
            <h5 class="font-subtitle-sb-4 relation-list-name">{{ parentLogin.parent_name }}</h5>
          </div>
          <div class="relation-list" v-for="(item, index) in siblingList" :key="`${index}-0`" @click="switchAccount(item.child_user_id)">
            <b-img-lazy :src="item.child_avatar" alt="sekolah.mu" class="relation-list-avatar"></b-img-lazy>
            <h5 class="font-subtitle-sb-4 relation-list-name">{{ item.child_name }}</h5>
          </div>
        </template>
      </b-collapse>
    </div>

  </div>
</template>

<script>
import authAPI from '@/api/auth.js'

import deviceDetector from 'mobile-device-detect'
import setAuthPayload from '@/mixins/setAuthPayload.js'

export default {
  name: 'ProfileHeader',
  data () {
    return {
      isOpenRelationList: false,
      isMobile: deviceDetector.isMobile
    }
  },
  mixins: [setAuthPayload],
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo
    },
    accessToken () {
      return this.$store.state.storage.accessToken
    },
    childList () {
      return this.userInfo.child || []
    },
    parentList () {
      return this.userInfo.parent || []
    },
    parentLogin () {
      return this.parentList.find(item => item.parent_user_id === this.userInfo.parent_login_id)
    },
    siblingList () {
      return this.userInfo.sibling || []
    },
    yearLevelType () {
      if (this.userInfo.profession_name === 'Murid') {
        return 'Anak/Remaja'
      } else {
        return 'Dewasa & Orangtua'
      }
    },
    isShowRelationList () {
      if (this.yearLevelType === 'Dewasa & Orangtua') {
        return true
      } else if (this.yearLevelType === 'Anak/Remaja') {
        return this.userInfo.parent_login_id !== 0
      }
    },
    listToggleText () {
      return this.isOpenRelationList ? 'Sembunyikan Daftar Relasi' : 'Lihat Daftar Relasi'
    },
    isSMM () {
      return this.$store.state.isSMM
    }
  },
  methods: {
    toggleDropdownRelation () {
      this.isOpenRelationList = !this.isOpenRelationList
      if (this.isOpenRelationList) {
        this.createFirebaseEvent('lihat_daftar_relasi_click')
      }
    },
    gotoRelationPage () {
      this.isOpenRelationList = false
      this.createFirebaseEvent('tambah_akun_anak_click')
      this.$emit('closeDropdown')
      this.$router.push({
        path: '/profile',
        query: {
          page: 'akun-relasi'
        }
      })
    },
    async switchAccount (targetAccountId) {
      try {
        const authorization = this.accessToken
        const payload = {
          target_account_id: targetAccountId,
          source: this.isMobile ? btoa('sekolahmu-webview') : btoa('sekolahmu-web'),
          agent: this.browserName,
          operation_system: this.OSName,
          longitude: this.longitude,
          latitude: this.latitude
        }
        const { data } = await authAPI.switchAccount({
          authorization,
          payload
        })
        if (data.status === 200) {
          this.createFirebaseEvent('akun_relasi_click')
          this.isOpenRelationList = false
          this.$store.dispatch('storage/loginSetStorage', data)
          await this.$store.dispatch('user/fetchUserData', { isRefresh: true })
          this.$emit('closeDropdown')
          this.$store.dispatch('notification/removeAllSubscription')
          setTimeout(() => {
            this.$router.replace('/switch-account-redirection')
          }, 1000)
        }
      } catch (error) {}
    },
    // navbar tour toggle function
    toggleRelationList (value) {
      this.isOpenRelationList = value
    },
    createFirebaseEvent (eventName) {
      window.firebaseAnalytics.logEvent(eventName, {
        screen_url: window.location.href,
        user_id: this.userInfo.id,
        source: `${this.isMobile ? 'mweb' : 'web'}_${this.isSMM ? 'smm.sekolahmu' : 'sekolahmu'}`
      })
    }
  }
}
</script>

<style scoped>
h5, p{
  margin: 0;
}
.profile-header{
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-neutral-500);
}
.account-info-container{
  width: 100%;
}
.account-name, .account-state, .account-avatar-container, .account-avatar-name{
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.account-avatar-container{
  margin-top: 8px;
  margin-bottom: 4px;
}
.account-avatar{
  width: 63px;
  height: 63px;
  border-radius: 50%;
  object-fit: cover;
}
.user-year-level-type{
  padding: 4px 16px;
  background-color: var(--color-persian-blue-100);
  border-radius: 24px;
  margin: 0 auto;
  margin-top: 8px;
  width: fit-content;
  color: var(--color-persian-blue-500);
}
.relation-list-container{
  margin-top: 8px;
}
.relation-list-toggle{
  color: var(--color-persian-blue-500);
  text-align: center;
  cursor: pointer;
}
.chevron-down {
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  margin-left: 8px;
}
.chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px
}
.chevron-up {
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  margin-left: 8px;
}
.chevron-up::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(225deg);
  left: 4px;
  top: 7px
}
.relation-list{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 8px;
  cursor: pointer;
}
.relation-list:last-child{
  margin-bottom: 0;
}
.relation-list-avatar{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 12px;
}
.add-child-route{
  color: var(--color-persian-blue-500);
  display: flex;
  align-items: center;
  padding-left: 9px;
  margin-top: 14px;
  cursor: pointer;
}
.add-relation-icon{
  width: 27px;
  height: 27px;
  margin-right: 14px;
}
.green-dot {
  position: relative;
  z-index: 4;
  bottom: 16px;
  left: 32px;
  border-radius: 100%;
  border: 2px solid var(--color-neutral-100);
  background-color: var(--color-eucalyptus-600);
  width: 12px;
  height: 12px;
}
.parent-avatar {
  position: relative;
  top: 12px;
}
</style>
